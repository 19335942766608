import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { debounceTime, delay } from 'rxjs/operators';

import { AuthService } from '@auth/services/auth.service';
import { getErrorMessage, emailErrorMessages, EMAIL_PATTERN, compareValues } from '@shared/utils/form-validators';
import { NotifyService } from '@app/shared/services/notify.service';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  signUpForm = this.fb.group({
    email: ['', [Validators.email, Validators.required, Validators.pattern(EMAIL_PATTERN)]],
    password: [, [Validators.required, Validators.minLength(6)]],
    repeatPass: [, [Validators.required]]
  }, {
    validators: [compareValues('password', 'repeatPass')]
  });

  errorMessage: string;
  emailErrorMessage: string;
  passwordErrorMessage: string;
  pass2ErrorMessage: string;
  accountCreated: boolean;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private notify: NotifyService
  ) { }

  ngOnInit(): void {
    const emailControl = this.signUpForm.get('email');
    emailControl.valueChanges.pipe(debounceTime(500)).subscribe(
      _ => this.emailErrorMessage = getErrorMessage(emailControl, emailErrorMessages)
    );
    const passControl = this.signUpForm.get('password');
    const repeatPassControl = this.signUpForm.get('repeatPass');

    passControl.valueChanges.pipe(debounceTime(500)).subscribe(
      _ => this.passwordErrorMessage = getErrorMessage(passControl, emailErrorMessages)
    );

    repeatPassControl.valueChanges.pipe(debounceTime(500)).subscribe(
      _ => this.pass2ErrorMessage = getErrorMessage(repeatPassControl, emailErrorMessages)
    );

    this.signUpForm.valueChanges.subscribe(
      errors => this.errorMessage = errors.errors
    );
  }

  signUp(): void {

    this.signUpForm.markAllAsTouched;
    this.signUpForm.markAsDirty;

    if (this.signUpForm.invalid) {

      this.notify.error(
        'Falta información',
        'Por favor complete la información',
        5000,
        {
          text: 'Entendido',
          orientation: 'izq'
      });

      return;
    }

    const { email, password } = this.signUpForm.controls;

    this.authService.signUp(email?.value, password?.value).subscribe(
      () => {
        this.accountCreated = true;
      },
      error => {

        this.notify.error(
          'Error inesperado!',
          JSON.stringify(error),
          4000,
          {
            text: 'Entendido',
            orientation: 'izq'
        });

        this.errorMessage = error;
      }
    );
  }

  aceptar() {
    this.router.navigate(['/']);
  }
}
