import { createSelector } from '@ngrx/store';
import { getProjectsModuleState } from '../reducers/index';
import { toArray } from 'lodash';

export const selectGananciasOcacionalesList = createSelector(
  getProjectsModuleState,
  (state) => state.gananciasOcacionales.entities
);

export const selectGananciasOcacionalesById = (id: number) =>
  createSelector(getProjectsModuleState, (state) => {
    const data: any = state.gananciasOcacionales.entities;
    if (data) return toArray(data).find((item) => item.id === id);
    else return {};
  });
