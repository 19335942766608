<div class="row full-height-div">
  <div class="col-5 bg">
    <mat-icon class="bg_message_logo" svgIcon="rentaApp_logo_white"></mat-icon>
    <div >
      <div class="main">
        <input type="checkbox" id="chk" aria-hidden="true" />

        <div class="bg_login_left signup" [formGroup]="loginForm">
          <form *ngIf="!recoveryPass">
            <label for="chk" aria-hidden="true">Ingresar</label>

            <div class="bg_login_left_input">
              <mat-icon svgIcon="email"></mat-icon>
              <input
                type="text"
                name="e-mail"
                placeholder="E-mail"
                formControlName="email"
              />
            </div>

            <span class="error_form">
              {{ emailLoginErrorMessage }}
            </span>

            <div class="bg_login_left_input">
              <mat-icon
                *ngIf="passLogin.value === ''; else viewPass"
                svgIcon="key"
              ></mat-icon>
              <ng-template #viewPass>
                <mat-icon
                  (click)="fieldPassLogin = !fieldPassLogin"
                  [svgIcon]="!fieldPassLogin ? 'view' : 'no_view'"
                >
                </mat-icon>
              </ng-template>
              <input
                [type]="fieldPassLogin ? 'text' : 'password'"
                name="password"
                placeholder="Contraseña"
                formControlName="password"
              />
            </div>

            <span class="error_form">{{ errorMessage }}</span>

            <app-button
              [icon]="'log_in'"
              [text]="'Ingresar'"
              [idButton]="'login'"
              [clase]="'btn_primary_login'"
              (clicker)="login()"
            >
            </app-button>

            <button
              type="button"
              class="login-with-google-btn"
              (click)="this.signWitGoogle()"
            >
              Ingresar con Google
            </button>
          </form>

          <a
            *ngIf="!recoveryPass"
            class="recovery"
            (click)="this.changeRecovery()"
          >
            ¿Olvidaste la constraseña?
          </a>

          <form
            class="signup_recovery"
            *ngIf="recoveryPass"
            [formGroup]="resetPassForm"
          >
            <p class="signup_recovery_title" aria-hidden="true">
              Recuperar Cuenta
            </p>
            <p class="signup_recovery_message">
              Enviaremos un correo para que puedas recuperar tu cuenta
            </p>
            <div class="bg_login_left_input">
              <mat-icon svgIcon="email"></mat-icon>
              <input
                type="text"
                name="e-mail"
                placeholder="E-mail"
                formControlName="email"
              />
            </div>

            <span class="error_form">{{ errorResetMessage }}</span>

            <app-button
              [icon]="'key'"
              [text]="'Recuperar cuenta'"
              [idButton]="'recovery'"
              [clase]="'btn_primary_login'"
              (clicker)="resetPassword()"
              [disabling]="this.resetPassForm.invalid"
            >
            </app-button>

            <a class="recovery" (click)="this.changeRecovery()"
              >Volver al ingreso</a
            >
          </form>
        </div>

        <div class="login" [formGroup]="signUpForm">
          <label for="chk" aria-hidden="true">Registrarme</label>

          <form>
            <div class="bg_login_left_input">
              <mat-icon svgIcon="email"></mat-icon>
              <input
                type="text"
                name="e-mail"
                placeholder="E-mail"
                formControlName="email"
              />
            </div>

            <span class="error_form">{{ emailSignupErrorMessage }}</span>

            <div class="bg_login_left_input">
              <mat-icon
                *ngIf="!passRegister.value; else viewPassRegister"
                svgIcon="key"
              ></mat-icon>
              <ng-template #viewPassRegister>
                <mat-icon
                  (click)="fieldPassRegister = !fieldPassRegister"
                  [svgIcon]="!fieldPassRegister ? 'view' : 'no_view'"
                >
                </mat-icon>
              </ng-template>

              <input
                [type]="fieldPassRegister ? 'text' : 'password'"
                name="password"
                placeholder="Contraseña"
                formControlName="password"
              />
            </div>

            <span class="error_form">{{ passwordErrorMessage }}</span>

            <div class="bg_login_left_input">
              <mat-icon
                *ngIf="!passRepeat.value; else viewPassRepeat"
                svgIcon="key"
              ></mat-icon>
              <ng-template #viewPassRepeat>
                <mat-icon
                  (click)="fieldPassRepeat = !fieldPassRepeat"
                  [svgIcon]="!fieldPassRepeat ? 'view' : 'no_view'"
                >
                </mat-icon>
              </ng-template>

              <input
                [type]="fieldPassRepeat ? 'text' : 'password'"
                name="password"
                placeholder="Confirma contraseña"
                formControlName="repeatPass"
              />
            </div>

            <span class="error_form">{{ pass2ErrorMessage }}</span>

            <span
              class="error_form"
              *ngIf="
                signUpForm.get('password')?.errors?.noEquals ||
                signUpForm.get('repeatPass')?.errors?.noEquals
              "
            >
              Las contraseñas deben coincidir
            </span>

            <app-button
              [icon]="'check_rounded'"
              [text]="'Registrarme'"
              [idButton]="'signUp'"
              [clase]="'btn_primary_login'"
              (clicker)="signUp()"
              [disabling]="this.signUpForm.invalid"
            >
            </app-button>

            <button
              type="button"
              class="login-with-google-btn"
              (click)="this.signWitGoogle()"
            >
              Registrarme con Google
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="col-7 bg2 d-flex">
    <div
      class="bg_message"
      style="
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 21px;
        padding: 14px;
        width: 100%;
      "
    >
      <p class="bg_messages_text" style="font-size: 23px">
        <b
          >Bienvenido a la aplicación que esta revolucionando la manera en que
          declaras renta.</b
        >
        <br />
      </p>

      <div class="bg_message_terms" style="font-size: 18px">
        <app-terms-and-conditions
          [link]="true"
          [message]="
            'Al ingresar a Appi-renta aceptas nuestros Términos y Condiciones'
          "
        >
        </app-terms-and-conditions>
      </div>
    </div>
  </div>
</div>
