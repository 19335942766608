import { createReducer, on, Action } from '@ngrx/store';
import { autorreTotalesAdapter, AutorreTotalesState } from '@store/states';
import { getTotalesAutorretencionesSuccess } from '../actions';

export const initialState: AutorreTotalesState = autorreTotalesAdapter.getInitialState({})

const autorreTotalesReducer = createReducer(
  initialState,

  on(getTotalesAutorretencionesSuccess, (state, { totalesAutorretencionesSuccess }) => {
    state.ids = [];
    state.entities = {};
    return autorreTotalesAdapter.upsertOne(totalesAutorretencionesSuccess, { ...state })
  }),
)

export function reducer(state: AutorreTotalesState | undefined, action: Action) {
  return autorreTotalesReducer(state, action);
}
