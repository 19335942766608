import { Component, OnInit, Inject} from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})

export class NotificationComponent implements OnInit{

  constructor(
    private router: Router,
    public snackBarRef: MatSnackBarRef<NotificationComponent>,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: any,
  ) {}
  
  ngOnInit(){
  }

  onClick(event: any){
    if(event === this.data.button.type && this.data.button.link) {
      this.router.navigate([this.data.button.link]).then((res: any) => {
        if(res) {
          setTimeout(() => {
            this.snackBarRef.dismissWithAction();
          }, 1000);
        }
      });
    } else {
      this.snackBarRef.dismissWithAction();
    }
  }

}