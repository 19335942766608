import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  MSG_DURATION,
  NotifyService,
} from '@app/shared/services/notify.service';
import { getPerdidasCompensar, getPerdidasCompensarError, getPerdidasCompensarSuccess, setDeletePerdidasCompensar, setDeletePerdidasCompensarError, setDeletePerdidasCompensarSuccess, setPerdidasCompensar, setPerdidasCompensarError, setPerdidasCompensarSuccess } from '../actions';
import { PerdidasCompensarStore } from '../models';
import { CompensacionPerdidasService } from '@app/shared/services/compensacion-perdidas.service';

@Injectable()
export class PerdidasCompensarEffects {
  getPerdidasCompensar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPerdidasCompensar),
      switchMap(({ projectId }) => {
        return this.compensacionPerdidasService.getPerdidasCompensar(projectId).pipe(
          map((perdidasCompensar) => {
            let perdidasCompensarSuccess: PerdidasCompensarStore = {
              idProject: projectId,
              ...perdidasCompensar,
            };
            return getPerdidasCompensarSuccess({ perdidasCompensarSuccess });
          }),
          catchError((error) => {
            this.notify.error('Algo salio mal!', JSON.stringify(error), 5000, {
              text: 'Entendido',
              orientation: 'izq',
            });
            return of(getPerdidasCompensarError({ error }));
          })
        );
      })
    )
  );

  savePerdidasCompensar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPerdidasCompensar),
      switchMap(({ perdidasCompensarForm, projectId }) => {
        return this.compensacionPerdidasService
          .savePerdidasCompensar(projectId, perdidasCompensarForm)
          .pipe(
            map((perdidasCompensar) => {
               let perdidasCompensarForm: PerdidasCompensarStore = {
              idProject: projectId,
              ...perdidasCompensar,
            };
              this.notify.success(
                '',
                'Guardado de registro exitoso',
                MSG_DURATION
              );
              return setPerdidasCompensarSuccess({ perdidasCompensarForm });
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setPerdidasCompensarError({ error }));
            })
          );
      })
    )
  );

  deletePerdidasCompensar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDeletePerdidasCompensar),
      switchMap(({ projectId, idRow, objPerdidasCompensar }) => {
        return this.compensacionPerdidasService
          .deletePerdidasCompensar(projectId, idRow, objPerdidasCompensar)
          .pipe(
            map((perdidasCompensar) => {
              this.notify.success(
                '',
                'Borrado de registro exitoso',
                MSG_DURATION
              );
              return setDeletePerdidasCompensarSuccess({ perdidasCompensar });
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setDeletePerdidasCompensarError({ error }));
            })
          );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private compensacionPerdidasService: CompensacionPerdidasService,
    private notify: NotifyService
  ) {}
}
