import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AuthService } from '@auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppGuard implements CanActivate, CanLoad {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }
  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.userIsComplete();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.userIsComplete();
  }

  private userIsComplete(): Observable<boolean> {
    return this.authService.isLoggedIn().pipe(
      switchMap(user => {

        if (user) {
          if(!user.emailVerified){
            this.authService.logOut();
            this.router.navigate(['/auth/login']);
            return of(false);
          }
          return this.authService.isUserInfoComplete();
        }

        this.router.navigate(['/auth/login']);
        return of(false);
      }),
      map(isComplete => {
        if (isComplete) {
          return true;
        }
        this.router.navigate(['/profile/complete']);
        return false;
      })
    );
  }
}
