import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '@environment';
import { projectModuleState  } from '@store/states';

import { reducer } from './project.reducer';
import { reducer as reducerProjectStatus } from './project-status.reducer';
import { reducer as reducerAnnexedStatus } from './annexed-status.reducer';
import { reducer as reducerDepuracion } from './depuracion.reducer';
import { reducer as reducerBalance } from './balance.reducer';
import { reducer as reducerERI } from './eri.reducer';
import { reducer as  extrasReducer } from './extras.reducer';
import { reducer as reducerSeccion1Gmf } from './seccion1-gmf.reducer';
import { reducer as reducerSeccion2Gmf } from './seccion2-gmf.reducer';
import { reducer as reducerSeccion3Gmf } from './seccion3-gmf.reducer';
import { reducer as reducerDonacion } from './donacion.reducer';
import { reducer as reducerInfoContribuyente } from './info-contribuyente.reducer';
import { reducer as reducerLastYear } from './last-year.reducer';
import { reducer as reducerRegistroESF} from './esf.reducer';
import { reducer as reducerRentaOpciones } from './renta-opciones.reducer';
import { reducer as reducerAnticipoRenta } from './anticipo-renta.reducer';
import { reducer as reducerRentaGravable } from './renta-gravable.reducer';
import { reducer as reducerRentaExenta } from './renta-exenta.reducer';
import { reducer as reducerprovisionImpuestoGanancia } from './provision-impuesto-ganancia.reducer';
import { reducer as reducerAfectacionFiscal } from './afectacion-fiscal.reducer';
import { reducer as reducerGananciasOcacionales } from './ganancias-ocacionales.reducer';
import { reducer as reducerImpuestoExterior } from './impuesto-exterior.reducer';
import { reducer as reducerImpuestoDiferido } from './impuesto-diferido.reducers';
import { reducer as reducerCalculoDescuento } from './calculo-descuento-reducer';
import { reducer as reducerAutorretenciones } from './autorretenciones.reducer';
import { reducer as reducerAutorreTotales } from './autorre-totales.reducer';
import { reducer as reducerAjusteDepuracion } from './ajuste-depuracion.reducer';
import { reducer as reducerRelacionDeclaraciones } from './relacion-declaraciones.reducer';
import { reducer as reducerAjusteDepuracionIca } from './ajuste-depuracion-ica.reducer';
import { reducer as reducerAjustePasivosIca } from './ajuste-pasivos-ica.reducer';
import { reducer as reducerPerdidasCompensar} from './perdidas-compensar.reducer';
import { reducer as reducerPerdidasCompensarInsolvencia} from './perdidas-compensar-insolvencia.reducer';
import { reducer as reducerExcesosPresuntiva} from './excesos-presuntiva.reducer';
import { reducer as reducerNomina} from './nomina.reducer';
import { reducer as reducerIntPresuntos } from './intereses-presuntos.reducer';
import { reducer as reducerLimiteBeneficios } from './limite-beneficios.reducer';
import { reducer as distribucionReducer } from './distribucion.reducer';

export const reducers: ActionReducerMap<projectModuleState> = {
  projects: reducer,
  project_status: reducerProjectStatus,
  annexedStatus: reducerAnnexedStatus,
  depuracion: reducerDepuracion,
  balance: reducerBalance,
  eri: reducerERI,
  extras: extrasReducer,
  donacion:reducerDonacion,
  distribucion :distribucionReducer,
  impuestoExterior:reducerImpuestoExterior,
  calculoDescuento:reducerCalculoDescuento,
  seccion1Gmf: reducerSeccion1Gmf,
  seccion2Gmf: reducerSeccion2Gmf,
  seccion3Gmf: reducerSeccion3Gmf,
  infoContribuyente: reducerInfoContribuyente,
  lastYear: reducerLastYear,
  registroESF: reducerRegistroESF,
  rentaOpciones: reducerRentaOpciones,
  anticipoRenta: reducerAnticipoRenta,
  rentaGravable: reducerRentaGravable,
  rentaExenta: reducerRentaExenta,
  provisionImpuestoGanancia: reducerprovisionImpuestoGanancia,
  afectacionFiscal: reducerAfectacionFiscal,
  gananciasOcacionales: reducerGananciasOcacionales,
  impuestoDiferido: reducerImpuestoDiferido,
  autorretenciones: reducerAutorretenciones,
  autorreTotales: reducerAutorreTotales,
  ajusteDepuracion: reducerAjusteDepuracion,
  relacionDeclaraciones: reducerRelacionDeclaraciones,
  ajusteDepuracionIca: reducerAjusteDepuracionIca,
  ajustePasivosIca: reducerAjustePasivosIca,
  perdidasCompensar: reducerPerdidasCompensar,
  perdidasCompensarInsolvencia: reducerPerdidasCompensarInsolvencia,
  excesosPresuntiva: reducerExcesosPresuntiva,
  nomina:reducerNomina,
  interesesPresuntivos:reducerIntPresuntos,
  limiteBeneficios : reducerLimiteBeneficios,

};

export const metaReducers: MetaReducer<projectModuleState>[] = !environment.production ? [] : [];

export const getProjectsModuleState = createFeatureSelector<projectModuleState>(
  'projectModule'
);
