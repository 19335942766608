import { Component, Input} from '@angular/core';

export interface messageAlert {
  type: string
  title: string
  message: string
}

@Component({
  selector: 'app-depuracion-window-alerts',
  templateUrl: './depuracion-window-alerts.component.html',
  styleUrls: ['./depuracion-window-alerts.component.scss']
})

export class DepuracionWindowAlertsComponent {
  @Input() icon: string = '';
  @Input() message: messageAlert;
  @Input() title: string = '';
  @Input() type: string = 'alert' || 'danger' || 'info' || 'success';

  constructor(
  ) { }
  
  public setIcon(types: string):any{
    switch (types) {
      case 'alert':
        return 'warning'
     
      case 'danger':
        return 'cancel_bold'
     
      case 'info':
        return 'info'
   
      case 'success':
        return 'check_solid'
    
    }
  }


}
