import { LastYearInfo } from '@app/pages/info-contribuyente/model/lastYearInfo';
import { createAction, props } from '@ngrx/store';

export const GET_INFO_LAST_YEAR =
  '[Info Last Year] get info last year';
export const GET_INFO_LAST_YEAR_SUCCESS =
  '[Info Last Year] get info last year success';
export const GET_INFO_LAST_YEAR_FAIL =
  '[Info Last Year] get info last year fail';

export const SET_INFO_LAST_YEAR =
  '[Info Last Year] set info last year';
export const SET_INFO_LAST_YEAR_SUCCESS =
  '[Info Last Year] set info last year success';
export const SET_INFO_LAST_YEAR_FAIL =
  '[Info Last Year] set info last year fail';

export const getInfoLastYear = createAction(
  GET_INFO_LAST_YEAR,
  props<{ id: number }>()
);

export const getInfoLastYearSuccess = createAction(
  GET_INFO_LAST_YEAR_SUCCESS,
  props<{ lastyearInfo: LastYearInfo }>()
);

export const getInfoLastYearFail = createAction(
  GET_INFO_LAST_YEAR_FAIL,
  props<{ error: any }>()
);

export const setInfoLastYear = createAction(
  SET_INFO_LAST_YEAR,
  props<{ payload: any, id: number }>()
);

export const setInfoLastYearSuccess = createAction(
  SET_INFO_LAST_YEAR_SUCCESS,
  props<{ lastYearUpdated: LastYearInfo }>()
);

export const setInfoLastYearFail = createAction(
  SET_INFO_LAST_YEAR_FAIL,
  props<{ error: any }>()
);
