export const MenusLinks = [
  {
    name: 'Información del Contribuyente',
    route: '/infoContribuyente/info',
  },
  {
    name: 'Información Año Anterior',
    route: '/infoContribuyente/anio_anterior',
  },
  {
    name: 'Balance Cliente',
    route: '/infoContribuyente/balance',
  },
  {
    name: 'Balance Terceros',
    route: '/infoContribuyente/balance_terceros',
  },
  {
    name: 'Cuenta de Afectación Fiscal',
    route: '/renta/fiscal',
  },
  {
    name: 'Rentas Gravables',
    route: '/renta/gravable',
  },
  {
    name: 'Retefuente',
    route: '/patrimonio/retefuente',
  },
  {
    name: 'Ganancias Ocasionales',
    route: '/anx-depuracion/ganancia_ocasional',
  },
  {
    name: 'Detalle Gasto Exterior',
    route: '/anx-depuracion/gasto_exterior',
  },
  {
    name: '4 x Mil - GMF',
    route: '/anx-depuracion/gmf',
  },
  {
    name: 'Anticipo de Renta',
    route: '/anx-depuracion/anticipo_renta',
  },
  {
    name: 'Autorretenciones',
    route: '/anx-depuracion/autorretenciones',
  },
  {
    name: 'Renta Exenta',
    route: '/anex-dep/renta',
  },
  {
    name: 'Compensación de Pérdidas',
    route: '/anx-depuracion/compensacion_perdidas',
  },
  {
    name: 'Gastos de personal',
    route: '/anx-depuracion/gasto_personal',
  },
  {
    name: 'Descuentos Tributarios',
    route: '/anx-depuracion/descuentos_tributarios',
  },
  {
    name: 'Ingresos no Constitutivos de\n Renta ni Ganancia Ocasional',
    route: '/anex-dep/incrgo',
  },
  {
    name: 'Industria y Comercio\n Avisos y Tableros',
    route: '/anx-depuracion/industria_comercio',
  },
  {
    name: 'Conciliación Patrimonio',
    route: '/conciliacion/patrimonio',
  },
  {
    name: 'Comparación Patrimonial',
    route: '/conciliacion/comparacion-patrimonial',
  },
  {
    name: 'Conciliación Ingresos',
    route: '/conciliacion/ingresos',
  },
  {
    name: 'Conciliación Renta',
    route: '/conciliacion/renta',
  },
  {
    name: 'Caratula',
    route: '/formato2516/caratula',
  },
  {
    name: 'ESF - Patrimonio',
    route: '/formato2516/ESF',
  },
  {
    name: 'ERI - Renta Líquida',
    route: '/formato2516/ERI',
  },
  {
    name: 'Impuesto Diferido',
    route: '/formato2516/impuesto',
  },
  {
    name: 'Provisión Impuesto a Ganancia',
    route: '/anx-info/provision',
  },

  {
    name: 'Beneficio de Auditoria',
    route: '/anx-info/auditoria',
  },
  {
    name: 'Intereses Presuntivos',
    route: '/anx-depuracion/intereses_presuntivos',
  },
  {
    name: 'Tasa Mínima De Tributación',
    route: '/anx-depuracion/tasa_minima_tributacion',
  },
  {
    name: 'Distribución de dividendos',
    route: '/anx-depuracion/distribucion_dividendos',
  },
  {
    name: 'VAA - Limite de Beneficios y Estimulos Tributarios',
    route: '/anx-depuracion/limite_beneficios',
  },
  {
    name: 'ESAL: Inversiones - Rentas gravables',
    route: '/anx-depuracion/esal',
  },
  {
    name: 'ECE - Entidades Controladas Exterior',
    route: '/anx-depuracion/ece',
  },
  {
    name: 'Obras por Impuestos',
    route: '/anx-depuracion/obras-por-impuestos',
  },
  {
    name: '(IA) Puntos Adicionales Renta',
    route: '/anx-depuracion/puntos-adicionales',
  },
  {
    name: 'Cartera - Provisión y Deterioro',
    route: '/anx-depuracion/cartera-provision',
  },
  {
    name: 'Activos Fijos',
    route: '/formato2516/activosFijos',
  },
  {
    name: 'Ingresos y Facturación',
    route: '/formato2516/ingresos-facturacion',
  },
  {
    name: 'Reclasificación y división cuentas',
    route: '/anx-depuracion/reclasificacion-division',
  },
  {
    name: 'Liquidación sanciones',
    route: '/conciliacion/sanciones',
  },
];
