<form [formGroup]="form">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="mb-3">
                    <label for="recipient-name" class="col-form-label">Código - Número de
                        Cuenta</label>
                    <select formControlName="account" class="form-select" #cuenta
                        [ngClass]="{'is-invalid': isInvalid('account')}" (blur)="validarEleccion('account',cuenta.value)">
                        <option value="" disabled>Seleccione una opción</option>
                        <option *ngFor="let c of fielDataHeader;let i=index" value="{{c}}">
                            {{c}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="col">
                <div class="mb-3">
                    <label for="recipient-name" class="col-form-label">Nombre de la cuenta</label>
                    <select #nameAccount formControlName="nameAccount" class="form-select" (blur)="validarEleccion('nameAccount',nameAccount.value)"
                        [ngClass]="{'is-invalid': isInvalid('nameAccount')}">
                        <option value="" disabled>Seleccione una opción</option>
                        <option *ngFor="let c of fielDataHeader;let i=index" value="{{c}}">
                            {{c}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="mb-3">
                    <label for="recipient-name" class="col-form-label">Saldo anterior / Saldo
                        Inicial</label>
                    <select #previousBalance formControlName="previousBalance" class="form-select" (blur)="validarEleccion('previousBalance',previousBalance.value)"
                        [ngClass]="{'is-invalid': isInvalid('previousBalance')}">
                        <option value="" disabled>Seleccione una opción</option>
                        <option *ngFor="let c of fielDataHeader;let i=index" value="{{c}}">
                            {{c}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="col">
                <div class="mb-3">
                    <label for="recipient-name" class="col-form-label">Valor Débito</label>
                    <select #debit formControlName="debit" class="form-select" (blur)="validarEleccion('debit',debit.value)"
                        [ngClass]="{'is-invalid': isInvalid('debit')}">
                        <option value="" disabled>Seleccione una opción</option>
                        <option *ngFor="let c of fielDataHeader;let i=index" value="{{c}}">
                            {{c}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="mb-3">
                    <label for="recipient-name" class="col-form-label">Valor Crédito</label>
                    <select #credit formControlName="credit" class="form-select" (blur)="validarEleccion('credit',credit.value)"
                        [ngClass]="{'is-invalid': isInvalid('credit')}">
                        <option value="" disabled>Seleccione una opción</option>
                        <option *ngFor="let c of fielDataHeader;let i=index" value="{{c}}">
                            {{c}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col">
                <div class="mb-3">
                    <label for="recipient-name" class="col-form-label">Saldo Final</label>
                    <select #finalBalance formControlName="finalBalance" class="form-select" (blur)="validarEleccion('finalBalance',finalBalance.value)"
                        [ngClass]="{'is-invalid': isInvalid('finalBalance')}">
                        <option value="" disabled>Seleccione una opción</option>
                        <option *ngFor="let c of fielDataHeader;let i=index" value="{{c}}">
                            {{c}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">

            <div class="col">
                <div class="mb-3">
                    <label for="recipient-name" class="col-form-label">Dato Adicional 1</label>
                    <select #adicionalData1 formControlName="adicionalData1" class="form-select" (blur)="validarEleccion('adicionalData1',adicionalData1.value)">
                        <option value="" disabled>Seleccione una opción</option>
                        <option *ngFor="let c of fielDataHeader;let i=index" value="{{c}}">
                            {{c}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col">
                <div class="mb-3">
                    <label for="recipient-name" class="col-form-label">Dato Adicional 2</label>
                    <select #adicionalData2 formControlName="adicionalData2" class="form-select" (blur)="validarEleccion('adicionalData2',adicionalData2.value)">
                        <option value="" disabled>Seleccione una opción</option>
                        <option *ngFor="let c of fielDataHeader;let i=index" value="{{c}}">
                            {{c}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="mb-3">
                    <label for="recipient-name" class="col-form-label">Dato Adicional 3</label>
                    <select #adicionalData3 formControlName="adicionalData3" class="form-select" (blur)="validarEleccion('adicionalData3',adicionalData3.value)">
                        <option value="" disabled>Seleccione una opción</option>
                        <option *ngFor="let c of fielDataHeader;let i=index" value="{{c}}">
                            {{c}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="col">
                <div class="mb-3">
                    <label for="recipient-name" class="col-form-label">Dato Adicional 4</label>
                    <select #adicionalData4 formControlName="adicionalData4" class="form-select" (blur)="validarEleccion('adicionalData4',adicionalData4.value)">
                        <option value="" disabled>Seleccione una opción</option>
                        <option *ngFor="let c of fielDataHeader;let i=index" value="{{c}}">
                            {{c}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="mb-3">
                    <label for="recipient-name" class="col-form-label">Dato Adicional 5</label>
                    <select #adicionalData5 formControlName="adicionalData5" class="form-select" (blur)="validarEleccion('adicionalData5',adicionalData5.value)">
                        <option value="" disabled>Seleccione una opción</option>
                        <option *ngFor="let c of fielDataHeader;let i=index" value="{{c}}">
                            {{c}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</form>
<p-footer>
    <div class="modal-footer">
        <div class="modal-btn-guardar col-3 align-self-center">
            <button type="button" (click)="guardar()" class="renta-btn mt-1">
                <i class="fas fa-save"></i>
                Guardar
            </button>
        </div>
        <div class="modal-btn-guardar col-3 align-self-center">
            <button type="button" (click)="cancel()" class="renta-btn renta-error">
                <i class="fas fa-times"></i>
                Cancelar
            </button>
        </div>
    </div>
</p-footer>