import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  MSG_DURATION,
  NotifyService,
} from '@app/shared/services/notify.service';
import { AutorretencionesService } from '@app/shared/services/autorretenciones.service';
import { getTotalesAutorretenciones, getTotalesAutorretencionesError, getTotalesAutorretencionesSuccess } from '../actions';
import { TotalesAutorretenciones } from '../models';

@Injectable()
export class AutorreTotalesEffects {
  getAutorreTotales$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTotalesAutorretenciones),
      switchMap(({ projectId }) => {
        return this.autorretencionesService.getTotalesAutorretenciones(projectId).pipe(
          map((autorreTotales) => {
            let totalesAutorretencionesSuccess: TotalesAutorretenciones = {
              idProject: projectId,
              ...autorreTotales,
            };
            return getTotalesAutorretencionesSuccess({ totalesAutorretencionesSuccess });
          }),
          catchError((error) => {
            this.notify.error('Algo salio mal!', JSON.stringify(error), MSG_DURATION, {
              text: 'Entendido',
              orientation: 'izq',
            });
            return of(getTotalesAutorretencionesError({ error }));
          })
        );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private autorretencionesService: AutorretencionesService,
    private notify: NotifyService
  ) {}
}
