import { createSelector } from '@ngrx/store';
import { getProjectsModuleState } from '../reducers/index';
import { toArray } from 'lodash';

export const selectLastYearList = createSelector(
  getProjectsModuleState,
  (state) => state.lastYear.entities
);

export const selectLastYearById = (id: number) =>
  createSelector(getProjectsModuleState, (state) => {
    const data: any = state.lastYear.entities;
    if (data) return toArray(data).find((item) => item.projectId === id);
    else return {};
  });
