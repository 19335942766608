import { createAction, props } from '@ngrx/store';
import { InteresesPresuntosStore, AccionistaData } from '../models';

export const ADD_SHAREHOLDER = '[Intereses Presuntivos] add shareholder';
export const GET_SHAREHOLDER = '[Intereses Presuntivos] get shareholder data';
export const SET_SHAREHOLDER = '[Intereses Presuntivos] set shareholder data';


export const getShareholdersData = createAction(
    GET_SHAREHOLDER,
    props<{idProject: number}>()
)

export const addSharedoler = createAction(
    ADD_SHAREHOLDER,
    props<{shareholderInfo: AccionistaData, idProject: number}>()
);

export const setShareholder = createAction(
  SET_SHAREHOLDER,
  props<{shareholderInfo: AccionistaData[], idProject: number}>()
);