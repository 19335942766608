import { createReducer, on, Action } from '@ngrx/store';
import { getPerdidasCompensarInsolvenciaSuccess, setPerdidasCompensarInsolvenciaSuccess } from '../actions';
import { perdidasCompensarInsolvenciaAdapter, PerdidasCompensarInsolvenciaState } from '../states';

export const initialState: PerdidasCompensarInsolvenciaState = perdidasCompensarInsolvenciaAdapter.getInitialState({})

const perdidasCompensarInsolvenciaReducer = createReducer(
  initialState,

  on(getPerdidasCompensarInsolvenciaSuccess, (state, { insolvenciaCompensarSuccess }) => {
    state.ids = [];
    state.entities = {};
    return perdidasCompensarInsolvenciaAdapter.upsertOne(insolvenciaCompensarSuccess, { ...state })
  }),

  on(setPerdidasCompensarInsolvenciaSuccess, (state, { insolvenciaCompensarSuccess }) => {
    state.ids = [];
    state.entities = {};
    return perdidasCompensarInsolvenciaAdapter.upsertOne(insolvenciaCompensarSuccess, state)
  })
)

export function reducer(state: PerdidasCompensarInsolvenciaState | undefined, action: Action) {
  return perdidasCompensarInsolvenciaReducer(state, action);
}
