import { createSelector } from '@ngrx/store';
import { getProjectsModuleState } from '../reducers/index';
import { toArray } from 'lodash';

export const selectInfoContribuyenteList = createSelector(
  getProjectsModuleState,
  (state) => state.infoContribuyente.entities
);

export const selectInfoContribujenteById = (id: number) =>
  createSelector(getProjectsModuleState, (state) => {
    const data: any = state.infoContribuyente.entities;
    if (data) return toArray(data).find((item) => item.id === id);
    else return {};
  });
