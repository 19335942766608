import { createSelector } from '@ngrx/store';
import { getProjectsModuleState } from '../reducers/index';
import { toArray } from 'lodash';


export const selectAjusteDepuracionIcaList = createSelector(
  getProjectsModuleState,
  state => state.ajusteDepuracionIca.entities
);

export const selectAjusteDepuracionIcaById = (id: number) =>
  createSelector(getProjectsModuleState, state => {
  const data: any = state.ajusteDepuracionIca.entities;
  if(data) {
    return toArray(data).find(item => {
      return item.id === id;
    });
  } else {
    return {};
  }
});
