export interface routesHelp {
  id: number;
  route: string;
  showHelp: boolean;
  urlVideo: string;
}

export const ROUTES_HELP: routesHelp[] = [
  //DEPURACION WINDOW
  {
    id: 0,
    route: 'depuracion/dep',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=Z5zXMshJe1s',
  },
  {
    id: 0,
    route: 'f110',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=T6s03rXizJk',
  },
  //INFORMACION
  {
    id: 1,
    route: '/home',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=7RNQhlkT2Fw',
  },
  {
    id: 1,
    route: 'infoContribuyente/info',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=05aNlmunFuc',
  },
  {
    id: 2,
    route: 'infoContribuyente/anio_anterior',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=DMEJx8YKTKM',
  },
  {
    id: 3,
    route: 'infoContribuyente/balance',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=L7PklTTFla4',
  },
  {
    id: 3.1,
    route: 'infoContribuyente/balance_terceros',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=NC8TaNh4oso',
  },
  //PATRIMONIO
  {
    id: 4,
    route: '',
    showHelp: true,
    urlVideo: '',
  },
  {
    id: 5,
    route: '',
    showHelp: true,
    urlVideo: '',
  },
  {
    id: 6,
    route: '',
    showHelp: true,
    urlVideo: '',
  },
  {
    id: 7,
    route: 'patrimonio/retefuente',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=Js_tjSPjZiY',
  },

  //  DEPURACION
  {
    id: 8,
    route: 'anx-depuracion/ganancia_ocasional',
    showHelp: true,
    urlVideo: '',
  },
  {
    id: 9,
    route: 'anx-depuracion/gasto_exterior',
    showHelp: true,
    urlVideo: '',
  },
  {
    id: 10,
    route: 'anx-depuracion/gmf',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=iv0PqKFaP5s',
  },
  {
    id: 11,
    route: 'anx-depuracion/anticipo_renta',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=Pox28aTQkuQ',
  },
  {
    id: 12,
    route: 'anex-dep/renta',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=a25FNIwLACU',
  },
  {
    id: 13,
    route: 'anex-dep/incrgo',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=HKStrtnCf-A',
  },
  {
    id: 14,
    route: 'anx-depuracion/gasto_personal',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=1oqryz3e22g',
  },
  {
    id: 15,
    route: 'anx-depuracion/tasa_minima_tributacion',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=1oqryz3e22g',
  },
  {
    id: 16,
    route: 'anx-depuracion/distribucion_dividendos',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=1oqryz3e22g',
  },
  {
    id: 17,
    route: 'anx-depuracion/autorretenciones',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=ihSYa6NEzwg',
  },
  {
    id: 18,
    route: 'anx-depuracion/compensacion_perdidas',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=4CbBRhCcWuA',
  },
  {
    id: 19,
    route: 'anx-depuracion/descuentos_tributarios',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=39ifgAj19sw',
  },
  {
    id: 20,
    route: 'anx-depuracion/intereses_presuntivos',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=39ifgAj19sw',
  },
  {
    id: 21,
    route: 'anx-depuracion/industria_comercio',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=Rq8z5PDtzOk',
  },
  {
    id: 22,
    route: 'anx-depuracion/puntos-adicionales',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=Rq8z5PDtzOk',
  },
  {
    id: 23,
    route: '',
    showHelp: true,
    urlVideo: '',
  },
  {
    id: 24,
    route: '',
    showHelp: true,
    urlVideo: '',
  },

  //CONCILIACION
  {
    id: 25,
    route: 'conciliacion/patrimonio',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=h-cv6VHP3Mc',
  },
  {
    id: 26,
    route: 'conciliacion/ingresos',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=dC_w-KN900A',
  },
  {
    id: 27,
    route: 'conciliacion/renta',
    showHelp: true,
    urlVideo: '',
  },
  {
    id: 28,
    route: 'conciliacion/comparacion-patrimonial',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=dC_w-KN900A',
  },
  {
    id: 29,
    route: '',
    showHelp: true,
    urlVideo: '',
  },
  //GRAVABLES
  {
    id: 30,
    route: 'renta/fiscal',
    showHelp: true,
    urlVideo: '',
  },
  {
    id: 31,
    route: 'renta/gravable',
    showHelp: true,
    urlVideo: '',
  },
  //ANEXOSEINFORMACION
  {
    id: 32,
    route: '',
    showHelp: true,
    urlVideo: '',
  },
  {
    id: 33,
    route: '',
    showHelp: true,
    urlVideo: '',
  },
  {
    id: 34,
    route: 'anx-info/provision',
    showHelp: true,
    urlVideo: '',
  },
  {
    id: 35,
    route: 'anx-info/auditoria',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=RhFSRox8QEg',
  },
  //RUTAS FUERA DE NIVEL DE PROYECTO
  {
    id: 36,
    route: 'settings/manage_users',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=XYlajFRFcSg',
  },
  {
    id: 37,
    route: 'settings/profile',
    showHelp: true,
    urlVideo:
      'https://www.youtube.com/watch?v=B_-93y732b8&list=PLIB9rPXbNKWp2SpyuDtq7reJ3MjY1qHFu&index=3',
  },
  //FORMATO 2516
  {
    id: 38,
    route: 'formato2516/caratula',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=pn_Itfn4AaE',
  },
  {
    id: 38,
    route: 'formato2516/ESF',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=dfexFsimni0',
  },
  {
    id: 39,
    route: 'formato2516/ERI',
    showHelp: true,
    urlVideo: '',
  },
  {
    id: 45,
    route: 'formato2516/ingresos-facturacion',
    showHelp: true,
    urlVideo: '',
  },
  {
    id: 46,
    route: 'formato2516/activosFijos',
    showHelp: true,
    urlVideo: '',
  },

  // Nuevos agregados de Deupración
  {
    id: 40,
    route: 'anx-depuracion/limite_beneficios',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=dfexFsimni0',
  },
  {
    id: 41,
    route: 'anx-depuracion/esal',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=dfexFsimni0',
  },
  {
    id: 42,
    route: 'anx-depuracion/ece',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=dfexFsimni0',
  },
  {
    id: 43,
    route: 'anx-depuracion/obras-por-impuestos',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=dfexFsimni0',
  },
  {
    id: 44,
    route: 'anx-depuracion/cartera-provision',
    showHelp: true,
    urlVideo: 'https://www.youtube.com/watch?v=dfexFsimni0',
  },
  {
    id: 47,
    route: 'anx-depuracion/reclasificacion-division',
    showHelp: true,
    urlVideo: '',
  },
  {
    id: 48,
    route: 'conciliacion/sanciones',
    showHelp: true,
    urlVideo: '',
  },
];
