<div class="nav">
  <div class="container">
    <div class="row">
      <div class="col-md-3 col-lg-2 col-xl-2 col-xxl-2">
        <div class="brand" [routerLink]="['/home']">
          <img src="/assets/svg/rentaApp_logo.svg" alt="Renta App" />
        </div>
      </div>
      <div class="col-md-5 col-lg-5 col-xl-5 col-xxl-6 marCol">
        <section
          *ngIf="
            plan != null &&
              plan?.memberType == 'DEMO' &&
              plan?.status == 'ACTIVE';
            else allPlan
          "
        >
          <div class="coming-soon">
            <div class="time-countdown">
              <ul>
                Restante DEMO
                {{
                  hours
                }}:
                {{
                  minutes
                }}:
                {{
                  seconds
                }}
              </ul>
            </div>
          </div>
        </section>
        <ng-template #allPlan>
          <app-folder-projects> </app-folder-projects>
        </ng-template>
      </div>
      <div class="col-md-4 col-lg-5 col-xl-5 col-xxl-4 marCo2">
        <div class="row end">
          <div
            class="col-2 col-md-2 col-lg-2"
            style="display: flex; justify-content: center; align-items: center"
          >
            <app-notifications></app-notifications>
          </div>
          <div class="col-6 col-md-6 col-lg-6">
            <div class="menu_extra text-center" *ngIf="showExtraMenu">
              <a
                *ngFor="let menu of menuList"
                (click)="this.menuClick(menu.click)"
              >
                {{ menu.label }}
              </a>
            </div>
          </div>
          <div class="col-4 col-md-4 col-lg-4">
            <app-menu-user
              [display]="this.display"
              (menuMobile)="menuMobile.emit($event)"
            >
            </app-menu-user>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
