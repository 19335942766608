import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {  Router } from '@angular/router';
import { Observable,  Subscription, timer } from 'rxjs';

import { DepuracionService } from '@shared/services/depuracion.service';
import { ListProyectosService } from '@proyectos/services/list-proyectos.service';

import { MenuItem, PartidaDobleModel, PartidaDobleValues } from '../models/menu';
import { environment } from '@environment';
import { map } from 'rxjs/operators';
import { toArray } from 'lodash';
import { Project } from '@app/pages/proyectos/proyecto';
import { MatDialog } from '@angular/material/dialog';
import { ModalGrlComponent } from '../components/modal-grl/modal-grl.component';
import { NotifyService } from '@app/shared/services/notify.service';
import { Store, select } from '@ngrx/store';
import { selectProyectList } from '@store/selectors';
import { getDepuracion } from '@store/actions/depuracion.actions';
import { selectCurrentRoute } from '@app/reducers';
import { DOCUMENT } from '@angular/common';
import { PlanService } from '@app/pages/plans/services/plan.service';
import { CurrentPlan } from '@app/pages/plans/models/plan';
import { BalanceService } from '@app/pages/info-contribuyente/services/balance.service';
import { ResponseModel } from '../models/response.model';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  _second = 1000;
  _minute = this._second * 60;
  _hour = this._minute * 60;
  _day = this._hour * 24;
  end: any;
  now: any;
  day: any;
  hours: any;
  minutes: any;
  seconds: any;
  source = timer(0, 1000);
  clock: Subscription;
  plan: CurrentPlan;
  userMenuItems: MenuItem[] = [];
  displayDialog = false;
  dialogStyle = { width: '90vw', height: '100vh' };
  minimized = false;
  postion = 'center';

  public menuList = [
    {
      label: 'DEPURACIÓN RENTA',
      route: '',
      icon: 'copy',
      click: 'depuracion',
    },
    {
      label: 'FORMULARIO 110',
      route: '',
      icon: 'f110',
      click: 'f110',
    },
  ];

  public showDepuracionDialog = false;
  public currentProject$: Observable<Project>;
  public proyectos$: Observable<Project[]>;
  public levelSelected = '';
  public niveles$: Observable<string[]>;
  public idProject: string;

  public hostRoute: string;
  public routeNow$: Observable<any>;

  public name: string = 'RentaApp';
  public nit: string = '123.456.789';

  @Input() showExtraMenu: boolean;
  @Input() projectId: number;
  @Input() sizeDisplay: any;
  @Output() menuMobile = new EventEmitter();

  public display;
  public vHeight: number = 50;

  constructor(
    private proyecSrv: ListProyectosService,
    private depService: DepuracionService,
    private store: Store,
    private notifyService: NotifyService,
    private planService: PlanService,
    private router: Router,
    private dialog: MatDialog,
    private balanceService: BalanceService,
    @Inject(DOCUMENT) private document
  ) {
    this.routeNow$ = this.store.pipe(
      select(selectCurrentRoute),
      map((route) => route)
    );

    this.hostRoute = document.location.host;
    this.proyectos$ = this.store.pipe(
      select(selectProyectList),
      map((list) => toArray(list))
    );
  }

  ngOnInit(): void {
    this.planService.actualPlan().subscribe((ap) => {
      this.plan = ap;
      if (ap.memberType == 'DEMO' && ap.status == 'ACTIVE') {
        this.clock = this.source.subscribe((t) => {
          this.now = new Date();
          let fechaPlan = new Date(ap.creationDate);
          this.end = fechaPlan.setHours(fechaPlan.getHours() + 6);
          this.showDate();
        });
      }
    });

    this.sizeDisplay.subscribe((event: any) => {
      this.display = event;
    });
  }
  showDate() {
    let distance = this.end - this.now;
    this.day = Math.floor(distance / this._day);
    this.hours = Math.floor((distance % this._day) / this._hour);
    this.minutes = Math.floor((distance % this._hour) / this._minute);
    this.seconds = Math.floor((distance % this._minute) / this._second);
    if (distance <= 0) {
      this.notifyService.warning(
        'fin de DEMO',
        'Termino el periodo de prueba, para seguir usando la aplicacion, por favor compre un plan'
      );
      this.clock.unsubscribe();
      this.planService.inactivePlan().subscribe((_) => {});
      this.plan.status = 'EXPIRED';
      this.router.navigate(['/plans/pricing']);
    }
  }

  redirectWindowDepuracion() {
    // if (document.location.host.includes('localhost')) {
      
    //   // const depUrl =
    //   //   'http://localhost:4200' + `/proyecto/${this.projectId}/depuracion/dep`;
    //   // window.open(depUrl);
    // } else {
    //   // const depUrl =
    //   //   environment.appUrl + `/proyecto/${this.projectId}/depuracion/dep`;
    //   // window.open(depUrl);
    // }
    //const newWindow = window.open("", "_blank");
    this.router.navigate([]).then(result => {
      window.open(`/proyecto/${this.projectId}/depuracion/dep`, "_blank");
    });
  }

  public showDialog(): void {
    this.depService.nivelActual$(this.projectId).subscribe(
      (level) => {
        if (level.depuracionStatus === 'COMPLETED') {
           this.redirectWindowDepuracion();
        } else {
          this.niveles$ = this.depService.levels$(this.projectId).pipe(
            map((l) =>
              l.sort(function (a, b) {
                return a > b ? 1 : -1;
              })
            )
          );

          this.nivelSelctor();
        }
      },
      () => {
        this.notifyService.error(
          'Ocurrió un error inesperado',
          'No fue posible cargar el nivel de depuración',
          4000,
          {
            text: 'Entendido',
            orientation: 'izq',
          }
        );
      }
    );
  }

  public nivelSelctor() {
    const optionArray = [];
    this.niveles$.subscribe((data: any) => {
      return optionArray.push(data);
    });

    this.dialog
      .open(ModalGrlComponent, {
        width: '60%',
        data: {
          name: 'Depuración Renta',
          textOne:
            'Seleccione el nivel deseado para depurar la declaración de renta, el aplicativo le brinda la libertad de seleccionar cualquier nivel de su preferencia. No obstante, nuestros profesionales sugieren optar por nivel 6 en adelante, siempre y cuando el balance se encuentre cuadrado (partida doble) en el nivel seleccionado, de lo contrario, se sugiere trabajar a nivel auxiliar. ',
          buttons: [
            {
              icon: 'arrow_left',
              text: 'Regresar',
              id: 'back',
              clase: 'btn_primary',
            },
            {
              icon: 'arrow_right',
              text: 'Continuar',
              id: 'next',
              clase: 'btn_primary',
              direction: 'der',
            },
          ],
          select: {
            label: 'Niveles contables que presenta su balance:',
            type: 'depuracion',
            options: optionArray,
          },
        },
      })
      .afterClosed()
      .subscribe((info: any) => {
        let message: any;
        if (info.emiter === 'select' && info.data !== '') {
          const arrayButtons = [
            {
              icon: 'arrow_left',
              text: 'Regresar',
              id: 'back',
              clase: 'btn_primary',
            },
            {
              icon: 'arrow_right',
              text: 'Continuar',
              id: 'next',
              clase: 'btn_primary',
              direction: 'der',
            },
          ];
          const level = info.data === 'Auxiliar' ? 'aux' : info.data;
          this.balanceService.balanceResuemen(this.projectId).subscribe({
            next: (response: ResponseModel) => {
              const data = response.data.resumen.partidaDoble as PartidaDobleModel;
              console.log(data);
              const getLevelBalanced: PartidaDobleValues = data[level].balanced;
              if (getLevelBalanced) {
                message = {
                  icon: 'doubt',
                  textOne: 'Seleccionaste',
                  textFocus: 'Nivel ' + info.data,
                  textTwo: '¿Desea Continuar?',
                };
              } else if (!getLevelBalanced && info.data !== '') {
                message = {
                  icon: 'warning',
                  textOne: 'La suma de los valores ($) en el',
                  textFocus:
                    'nivel seleccionado, ' + info.data + ', no concuerda ',
                  textThree:
                    'con los niveles superiores o principales. Este nivel no arroja efecto cero (0) ni sigue el principio de partida doble.',
                  textTwo: '¿Desea Continuar?',
                };
              }
  
              const dialogNext = this.dialog.open(ModalGrlComponent, {
                width: '60%',
                data: {
                  ...message,
                  buttons: arrayButtons,
                },
              });
  
              dialogNext.afterClosed().subscribe((click: any) => {
                if (click.data === 'next') {
                  this.depService.changeStatus(info.data).subscribe(
                    data=> localStorage.setItem('level', info.data)
                  );
                  this.store.dispatch(
                    getDepuracion({ projectId: this.projectId, level: info.data })
                  );
  
                  this.depService
                    .getDepuracion(this.projectId, info.data)
                    .subscribe({
                      next: (response) => {
                        if (response.list.length > 0) {
                          this.notifyService
                            .success(
                              'Configuración exitosa!',
                              'Se configuro el nivel ' +
                                info.data +
                                ' correctamente',
                              2000,
                              {
                                text: 'Entendido',
                                orientation: 'izq',
                              }
                            )
                            .afterOpened()
                            .subscribe(() => {
                              this.openWidowDep();
                            });
                        }
                      },
                      error: (err) => {
                        this.notifyService.error(
                          'Ocurrió un error inesperado',
                          'No fue posible cargar el nivel de depuración',
                          4000,
                          {
                            text: 'Entendido',
                            orientation: 'izq',
                          }
                        );
                      },
                    });
                } else {
                  dialogNext.close();
                }
              });
            },
            error: (err) => {
              console.log(err);
            }
          });
        } else {
          this.notifyService.info(
            'Saliste de Depuración!',
            'Debe seleccionar un nivel para continuar',
            4000,
            {
              text: 'Entiendo',
              orientation: 'izq',
            }
          );
        }
      });
  }

  private openWidowDep() {
    this.redirectWindowDepuracion();
  }

  cerrarDialogo(cerrado: boolean) {
    this.displayDialog = !cerrado;
  }

  showF110() {
    this.router.navigate([]).then(result => {
      window.open(`/proyecto/${this.projectId}/f110`, "_blank");
    });
  }

  public menuClick(option: string) {
    switch (option) {
      case 'depuracion':
        this.showDialog();
        break;
      case 'f110': {
        this.showF110();
      }
    }
  }
}
