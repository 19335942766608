import { createReducer, on, Action } from '@ngrx/store';
import { getAjustePasivosIcaSuccess, setAjustePasivosIcaSuccess, setDeleteAjustePasivosIcaSuccess } from '../actions';
import { ajustePasivosIcaAdapter, AjustePasivosIcaState } from '../states';

export const initialState: AjustePasivosIcaState = ajustePasivosIcaAdapter.getInitialState({})

const ajustePasivosIcaReducer = createReducer(
  initialState,

  on(getAjustePasivosIcaSuccess, (state, { ajustePasivosIcaSuccess }) => {
    state.ids = [];
    state.entities = {};
    return ajustePasivosIcaAdapter.upsertOne(ajustePasivosIcaSuccess, { ...state })
  }),

  on(setAjustePasivosIcaSuccess, (state, { ajustePasivosIcaForm }) => {
    state.ids = [];
    state.entities = {};
    return ajustePasivosIcaAdapter.upsertOne(ajustePasivosIcaForm, state)
  }),

  on(setDeleteAjustePasivosIcaSuccess, (state, { ajustePasivosIca }) => {
    return ajustePasivosIcaAdapter.removeOne(ajustePasivosIca.idProject, state)
  }),
)

export function reducer(state: AjustePasivosIcaState | undefined, action: Action) {
  return ajustePasivosIcaReducer(state, action);
}
