import { createReducer, on, Action } from '@ngrx/store';
import { RentaGravableState, rentaGravableAdapter } from '../states';
import * as actionsRentaGravable from '@store/actions/renta-gravable.actions';

export const initialState: RentaGravableState =
  rentaGravableAdapter.getInitialState({});

const rentaGravableReducer = createReducer(
  initialState,

  on(actionsRentaGravable.setCrearRenglonRentaSuccess, (state, { registroDepuracionCreado }) => {
    state.entities = {};
    state.ids = [];
    return rentaGravableAdapter.upsertOne(registroDepuracionCreado, state);
  }),
);

export function reducer(state: RentaGravableState | undefined, action: Action) {
  return rentaGravableReducer(state, action);
}
