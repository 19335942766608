import { createAction, props } from '@ngrx/store';
import { Nomina, NominaStore, NominaStoreSave } from '../models/nomina.model';

export const GET_NOMINA = '[nomina] get nomina';
export const GET_NOMINA_SUCCESS = '[nomina] get nomina success';
export const GET_NOMINA_ERROR = '[nomina] get nomina with error';

export const SET_NOMINA = '[nomina] set nomina save info';
export const SET_NOMINA_SUCCESS = '[nomina] set nomina save info success';
export const SET_NOMINA_ERROR = '[nomina] set nomina save info with error';

export const SET_DELETE_NOMINA = '[nomina] set delete nomina';
export const SET_DELETE_NOMINA_SUCCESS = '[nomina] set delete nomina success';
export const SET_DELETE_NOMINA_ERROR = '[nomina] set delete nomina with error';

//get Nomina
export const getNomina = createAction(
  GET_NOMINA,
  props<{projectId: number}>()
);

export const getNominaSuccess = createAction(
  GET_NOMINA_SUCCESS,
  props<{ nominaSuccess: NominaStore}>()
);

export const getNominaError = createAction(
  GET_NOMINA_ERROR,
  props<{ error: string }>()
);

//create Nomina
export const setNomina = createAction(
  SET_NOMINA,
  props<{ nominaForm:NominaStoreSave, projectId: number }>()
);

export const setNominaSuccess = createAction(
  SET_NOMINA_SUCCESS,
  props<{ nominaForm:NominaStore }>()
);

export const setNominaError = createAction(
  SET_NOMINA_ERROR,
  props<{ error: string }>()
);

//delete Nomina
export const setDeleteNomina = createAction(
  SET_DELETE_NOMINA,
  props<{ projectId: number, objNomina: Nomina[] }>()
);

export const setDeleteNominaSuccess = createAction(
  SET_DELETE_NOMINA_SUCCESS,
  props<{ nomina: any }>()
);

export const setDeleteNominaError = createAction(
  SET_DELETE_NOMINA_ERROR,
  props<{ error: string }>()
);
