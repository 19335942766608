
    <div [class]="modal ? 'header modal': 'header'">
        <div class="header_title">

            <div class="header_title_group">
                <div *ngIf="icon !== ''" class="header_title_group_icon">
                    <mat-icon class="header_title_icon"
                    [svgIcon]="icon"></mat-icon>
                </div>
                <div class="header_title_group_text">
                    {{title}}
                </div>
            </div>

            <div class="header_title_buttons" *ngIf="buttons.length">

                <app-help-buttom></app-help-buttom>
                
                <div *ngFor="let but of buttons">

                    <ng-container *ngIf="but.type === 'icon'; else buttons">
                        <app-button-icon class="header_title_buttons_normal"
                            (clicker)="evClick($event)" 
                            [icon]="but.icon"
                            [message]="but.message"
                            [clase]="but.class"
                            [idButton]="but.idButton"
                        >
                        </app-button-icon>
                    </ng-container>

                    <ng-template #buttons>
                        <app-button class="header_title_buttons_normal"
                            (clicker)="evClick($event)" 
                            [icon]="but.icon"
                            [text]="but.label"
                            [clase]="but.class"
                            [idButton]="but.idButton"
                            [iconDir]="but.iconDir"
                        >
                        </app-button>
                    </ng-template>
                </div>

            </div>
        </div>
        <div class="header_line">
            <hr class="hr1">
            <hr class="hr2">
        </div>
    </div>


<div class="list">

    <div class="list_card grow_small">
        <p class="list_card_title">Activos</p>
        <p class="list_card_number">{{activos | currency}}</p>
    </div>

    <div class="list_card grow_small">
        <p class="list_card_title">Pasivos</p>
        <p class="list_card_number">{{pasivos | currency}}</p>
    </div>

    <div class="list_card grow_small">
        <p class="list_card_title">Patrimonio</p>
        <p class="list_card_number">{{patrimonio | currency}}</p>
    </div>

    <div class="list_card grow_small">
        <p class="list_card_title">Ingresos</p>
        <p class="list_card_number">{{ingresos | currency}}</p>
    </div>

    <div class="list_card grow_small">
        <p class="list_card_title">Gastos</p>
        <p class="list_card_number">{{gastos | currency}}</p>
    </div>

    <div class="list_card grow_small">
        <p class="list_card_title">Costos</p>
        <p class="list_card_number">{{costos | currency}}</p>
    </div>

    <div class="list_card grow_small">
        <p class="list_card_title">Costos de producción</p>
        <p class="list_card_number">{{costosProduccion | currency}}</p>
    </div>

    <div class="list_card grow_small">
        <p class="list_card_title">Cuentas de orden deudoras</p>
        <p class="list_card_number">{{cuentasDeudoras | currency}}</p>
    </div>

    <div class="list_card grow_small">
        <p class="list_card_title">Cuentas de orden acreedoras</p>
        <p class="list_card_number">{{cuentasAcreedoras | currency}}</p>
    </div>

</div>
