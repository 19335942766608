import { createSelector } from '@ngrx/store';
import { getProjectsModuleState } from '../reducers/index';
import { toArray } from 'lodash';

export const selectRegisterESFList = createSelector(
  getProjectsModuleState,
  (state) => state.registroESF.entities
);

export const selectRegriterESFById = (id: number) =>
  createSelector(getProjectsModuleState, (state) => {
    const data: any = state.registroESF.entities;
    if (data) return toArray(data).find((item) => item.projectId === id);
    else return {};
  });
