import { createSelector } from '@ngrx/store';
import { getProjectsModuleState } from '../reducers/index';
import { toArray } from 'lodash';
import { ERIstore } from '@app/pages/formato2516/components/eri-renta-liquida/eri';


export const selectEriList = createSelector(
  getProjectsModuleState,
  state => state.eri.entities
);

export const selectEriById = (id: number) =>
  createSelector(getProjectsModuleState, state => {
  let data: any = state.eri.entities;
  if(data) {
    return toArray(data).find((item: any) => {
      return item.id === id;
    });
  } else {
    return {};
  }
});


