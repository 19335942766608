import { Project } from '@store/models';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export interface ProjectState extends EntityState<Project> {
  id_year: number,
  tasa_diaria: number,
  tasa_anual: number,
  idProject: number,
  nit: number,
  uvt: number
}

export const projectAdapter: EntityAdapter<Project> = createEntityAdapter<Project>({
  selectId: project => project.id,
});
