export const ICONS = [
    'rentaApp_logo',
    'rentaApp_logo_white',
    '4',
    'user_line',
    'user_bold',
    'user_line_bold',
    'plus',
    'plus_bold',
    'cog_line',
    'cog_bold',
    'menu_bars',
    'menu_bars_mini',
    'key',
    'db',
    'copy',
    'invoice',
    'close_rounded',
    'arrow_down',
    'log_in',
    'log_out',
    'check_rounded',
    'edit_pen',
    'paper',
    'info',
    'get_money',
    'calendar',
    'calendar_check',
    'indicator',
    'settings',
    'wallet',
    'bank',
    'magnet',
    'paper_status',
    'up_percent',
    'fly',
    'people',
    'arrow_down_rigth',
    'arrow_circle',
    'money',
    'down_percent',
    'pay',
    'star',
    'building',
    'hand_bag_money',
    'hand_money',
    'stats_analize',
    'hand_house',
    'coins',
    'square_dot',
    'paper_text',
    'pin',
    'paper_law',
    'graph_up',
    'search_paper',
    'paper_clip',
    'archive',
    'download',
    'upload',
    'arrow_next',
    'save_bold',
    'file_paper',
    'cancel_bold',
    'list_bold',
    'file_excel',
    'file_code',
    'block_row',
    'block_colum',
    'block_all',
    'block_reset',
    'reload',
    'youtube',
    'balance3',
    'agenda',
    'f110',
    'check_solid',
    'arrow_right',
    'arrow_left',
    'warning',
    'doubt',
    'renta-isotipo',
    'search',
    'list_number',
    'edit',
    'trash',
    'dots_menu',
    'folder',
    'folder_open',
    'circle',
    'list_check',
    'email',
    'view',
    'no_view',
    'shopping',
    'help',
    'calendar_error',
    'safari',
    'edge',
    'iexplorer',
    'chrome',
    'firefox',
    'support',
    'file_upload',
    'invoice_pay',
    'drag_drop',
    'send_plane',
    'tasa_minima',
    'divi',
    'atrasado',
    'donacion',
    'enterprise',
    'obras_impuestos',
    'menos',
    'ingresosFactura',
    'activosFijos',
    'reclasificacion',
]
