import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';


export interface ButtonTool {
  idButton: string,
  class: string,
  icon: string,
  message: string
}

@Component({
  selector: 'app-menu-tools',
  templateUrl: './menu-tools.component.html',
  styleUrls: ['./menu-tools.component.scss']
})
export class MenuToolsComponent {
  @Output() clicker: EventEmitter<string>  = new EventEmitter<string>();

  @Input() buttonsArray: ButtonTool[] = [];
  //@Input() eventStatusStart: boolean = false;
  //@Input() eventStatusStart: EventEmitter<boolean>  = new EventEmitter<boolean>();


  public butSelect: string;
  //public stateProject: string = 'por_tramitar';


  constructor() { }

  public onEmit(id: string){
    this.butSelect = id;
    this.clicker.emit(id)
  }

  // stateColor() {
  //   let color: string;
  //   switch (this.stateProject) {
  //     case 'Por Tramitar':
  //       color = '#a6a6a6'
  //       break;

  //     case 'En Proceso':
  //       color = '#F2CF3B'
  //       break;

  //     case 'Terminado':
  //       color = '#19BF71'
  //       break;

  //     case 'No Aplica':
  //       color = '#000'
  //       break;
  //   }

  //   return color;
  // }
}
