import { createAction, props } from '@ngrx/store';
//import { Project } from '@proyectos/proyecto';
import { Project } from '@store/models';

export const GET_PROJECT_LIST = '[project] get project list';
export const GET_PROJECT_LIST_SUCCESS = '[project] get project list success';
export const GET_PROJECT_LIST_ERROR = '[project] get project list with error';
export const GET_PROJECT_LIST_BY_NAME = '[project] get project list by name';
export const GET_PROJECT_LIST_BY_NIT = '[project] get project list by NIT';

export const SET_PROJECT_INFO = '[project] set project save info';
export const SET_PROJECT_INFO_SUCCESS = '[project] set project save info success';
export const SET_PROJECT_INFO_ERROR = '[project] set project save info with error';

export const SELECT_PROJECT_INFO = '[project] select project info';
export const SELECT_PROJECT_INFO_SUCCESS = '[project] select project info success';
export const SELECT_PROJECT_INFO_ERROR = '[project] select project info with error';

export const SET_DELETE_PROJECT = '[project] set delete project';
export const SET_DELETE_PROJECT_SUCCESS = '[project] set delete project success';
export const SET_DELETE_PROJECT_ERROR = '[project] set delete project with error';

export const SET_YEAR_PROYECT ='[project] set year proyect';
export const SET_YEAR_PROYECT_SUCCES ='[project] set year proyect succes';

export const SET_DAY_TASA = '[project] set day tasa proyect'; 
export const SET_YEAR_TASA = '[project] set year tasa proyect';
export const SET_ID_PROJECT = '[project] set id proyect';
export const SET_NIT_PROJECT = '[project] set NIT proyect';
export const SET_UVT_PROJECT = '[project] set UVT proyect';

//get list
export const getProjectList = createAction(
  GET_PROJECT_LIST,
);
export const getProjectsListByName = createAction(
  GET_PROJECT_LIST_BY_NAME,
  props<{name:string}>()
)
export const getProjectsListByNIT = createAction(
  GET_PROJECT_LIST_BY_NIT,
  props<{nit:string}>()
)

export const getProjectListSuccess = createAction(
  GET_PROJECT_LIST_SUCCESS,
  props<{ projectList: Project[] }>()
);

export const getProjectListError = createAction(
  GET_PROJECT_LIST_ERROR,
  props<{ error: string }>()
);

//create project
export const setProjectInfo = createAction(
  SET_PROJECT_INFO,
  props<{ projectForm: Project, dialog: any }>()
);

export const setProjectInfoSuccess = createAction(
  SET_PROJECT_INFO_SUCCESS,
  props<{ projectSuccess: Project }>()
);

export const setProjectInfoError = createAction(
  SET_PROJECT_INFO_ERROR,
  props<{ error: string }>()
);

//select proyect para precarga de data de back al store

export const selectProjectInfo = createAction(
  SELECT_PROJECT_INFO,
  props<{ projectId: number}>()
);

export const selectProjectInfoSuccess = createAction(
  SELECT_PROJECT_INFO_SUCCESS,
  props<{ projectData: Project }>()
);

export const selectProjectInfoError = createAction(
  SELECT_PROJECT_INFO_ERROR,
  props<{ error: string }>()
);


//delete project
export const setDeleteProject = createAction(
  SET_DELETE_PROJECT,
  props<{ idProject: string }>()
);

export const setDeleteProjectSuccess = createAction(
  SET_DELETE_PROJECT_SUCCESS,
  props<{ project: any }>()
);

export const setDeleteProjectError = createAction(
  SET_DELETE_PROJECT_ERROR,
  props<{ error: string }>()
);

export const setYearProject = createAction(
  SET_YEAR_PROYECT,
  props<{date:number}>()
)
export const setYearProjectSuccess = createAction(
  SET_YEAR_PROYECT_SUCCES,
  props<{year: number}>()
)
export const setDayTasaProjectSuccess = createAction(
  SET_DAY_TASA,
  props<{diaria: number}>()
)
export const setYearTasaProjectSuccess = createAction(
  SET_YEAR_TASA,
  props<{anual: number}>()
)

export const setIdProjectSuccess = createAction(
  SET_ID_PROJECT,
  props<{idProject: number}>()
)

export const setNitProjectSuccess = createAction(
  SET_NIT_PROJECT,
  props<{nit: number}>()
)

export const setUvtProject = createAction(
  SET_UVT_PROJECT,
  props<{uvt: number}>()
)