import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotifyService } from '@app/shared/services/notify.service';
import { GananciaOcasionalService } from '@app/pages/anex-depuracion/services/ganancia-ocasional.service';
import * as actionsGananciaOcacional from '../actions/ganancias-ocacionales.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { GananciaOcasionalOtraList } from '@app/pages/anex-depuracion/model/ganacia-ocasional';

@Injectable()
export class GananciasOcacionalesEffects {
  getGananciaOcasionalOtraList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsGananciaOcacional.getGanaciaOcacionalLista),
      switchMap((action) => {
        return this.gananciasOcacionalesService
          .getGananciaOcasionalOtraList(action.projectId)
          .pipe(
            map((response: GananciaOcasionalOtraList[]) => {
              return actionsGananciaOcacional.getGanaciaOcacionalListaSuccess({
                gananciaOcacionalOtraLista: {
                  projectId: action.projectId,
                  gananciaOcacionalOtraLista: response,
                },
              });
            }),
            catchError((error) => {
              this.notify.error(
                'Algo salio mal! ',
                'No pudimos cargar la información del contribuyente',
                4000
              );
              return of(
                actionsGananciaOcacional.getGanaciaOcacionalListaFail({ error })
              );
            })
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private notify: NotifyService,
    private gananciasOcacionalesService: GananciaOcasionalService
  ) {}
}
