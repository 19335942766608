import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifyService } from '@app/shared/services/notify.service';
import { ModalData } from './modal-models';

@Component({
  selector: 'app-modal-input',
  templateUrl: './modal-input.component.html',
  styleUrls: ['./modal-input.component.scss'],
})
export class ModalInputComponent implements OnInit {
  /**
   * Formulario
   */
  formGroup: FormGroup;

  /**
   * Constructor
   * @param dialogRef Utilizado para completar las acciones del usuario mediante el modal
   * @param formBuilder FormBuilder, permite crear los atributos del formulario
   * @param notifyService Servicio para mostrar mensajes toast
   * @param data Recibe las propiedades para crear la interfaz del modal
   */
  constructor(
    public dialogRef: MatDialogRef<ModalInputComponent>,
    private formBuilder: FormBuilder,
    private notifyService: NotifyService,
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  ) {}

  /**
   * Inicializa el formulario
   */
  ngOnInit() {
    this.createForm();
  }

  /**
   * Inicializa el formulario y define los controles del formulario con su validación
   */
  createForm() {
    this.formGroup = this.formBuilder.group({
      renglones: [
        '',
        [Validators.required, Validators.min(1), Validators.max(400)],
      ],
    });
  }

  /**
   * Valida si un control dentro de un FormGroup es invalido o no
   * Si es invalido, regresa true caso contrario false
   * @param controlName Nombre del control que se quiere validar si tiene error
   */
  isInvalid(controlName: string): boolean {
    return (
      this.formGroup.get(controlName).errors &&
      this.formGroup.get(controlName).touched
    );
  }

  /**
   * Valida la información enviada por el modal cuando el usuario selecciona una acción y la devuelve nuevamente al componente con el valor
   * @param datos devuelve la acción seleccionada por el usuario en este caso 'next' o 'back'
   */
  emitingData(datos: any) {
    let info: any;
    switch (datos) {
      case 'next':
        if (this.formGroup.invalid) {
          this.notifyService.error(
            'Valor Incorrecto',
            'Debe ingresar un valor entre 1 y 100',
            3000
          );
          return;
        }
        info = {
          emiter: 'next',
          data: this.formGroup.controls.renglones.value,
        };
        break;

      case 'back':
        info = {
          emiter: 'back',
          data: '',
        };
        break;
    }
    this.dialogRef.close(info);
  }
}
