import { FormControl } from '@angular/forms';

export class UrlValidator {

  public static isValid(control: FormControl) {
    if (!control.value) {
      return null
    }

    const re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(control.value);

    if (re) {
      return null;
    } else {
      return { 'invalidUrl': true };
    }

  }

}
// end file
