import { createReducer, Action } from '@ngrx/store';
import { DistribucionState, distribucionAdapter } from "../states/distribucion.statue";


export const initialState : DistribucionState  = distribucionAdapter.getInitialState({});

const distribucionReducer = createReducer(initialState);
export function reducer (
    state: DistribucionState | undefined,
    action: Action
){
    return distribucionReducer(state, action);
  }