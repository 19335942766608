
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-req-system',
  templateUrl: './req-system.component.html',
  styleUrls: ['./req-system.component.scss']
})
export class RequerimentsSystemComponent {
  constructor(
    private dialog: MatDialog
  ) { }

  openDialog() {
    this.dialog.open(DialogRequerimentsSystem);

  }
}

@Component({
  selector: 'dialog-req-system',
  templateUrl: 'dialog-req-system.html',
  styleUrls: ['dialog-req-system.scss']
})
export class DialogRequerimentsSystem {}
