import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  MSG_DURATION,
  NotifyService,
} from '@app/shared/services/notify.service';
import { IndustriaComercioService } from '@app/shared/services/industria-comercio.service';
import { getRelacionDeclaraciones, getRelacionDeclaracionesError, getRelacionDeclaracionesSuccess, setDeleteRelacionDeclaraciones, setDeleteRelacionDeclaracionesError, setDeleteRelacionDeclaracionesSuccess, setRelacionDeclaraciones, setRelacionDeclaracionesError, setRelacionDeclaracionesSuccess } from '../actions';
import { RelacionDeclaracionesStore } from '../models';

@Injectable()
export class RelacionDeclaracionesEffects {
  getRelacionDeclaraciones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRelacionDeclaraciones),
      switchMap(({ projectId }) => {
        return this.industriaComercioService.getRelacionDeclaraciones(projectId).pipe(
          map((relacionDeclaraciones) => {
            let relacionDeclaracionesSuccess: RelacionDeclaracionesStore = {
              idProject: projectId,
              ...relacionDeclaraciones,
            };
            return getRelacionDeclaracionesSuccess({ relacionDeclaracionesSuccess });
          }),
          catchError((error) => {
            this.notify.error('Algo salio mal!', JSON.stringify(error), 5000, {
              text: 'Entendido',
              orientation: 'izq',
            });
            return of(getRelacionDeclaracionesError({ error }));
          })
        );
      })
    )
  );

  saveRelacionDeclaraciones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setRelacionDeclaraciones),
      switchMap(({ relacionDeclaracionesForm, projectId }) => {
        console.log("dataToSaveForm", relacionDeclaracionesForm)
        return this.industriaComercioService
          .saveRelacionDeclaraciones(projectId, relacionDeclaracionesForm)
          .pipe(
            map((relacionDeclaraciones) => {
              let relacionDeclaracionesForm: RelacionDeclaracionesStore = {
                idProject: projectId,
                ...relacionDeclaraciones,
              };
              console.log("response", relacionDeclaraciones);

              this.notify.success(
                '',
                'Guardado de registros exitoso',
                MSG_DURATION
              );
              return setRelacionDeclaracionesSuccess({ relacionDeclaracionesForm });
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setRelacionDeclaracionesError({ error }));
            })
          );
      })
    )
  );

  deleteRelacionDeclaraciones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDeleteRelacionDeclaraciones),
      switchMap(({ projectId, objRelacionDeclaraciones }) => {
        return this.industriaComercioService
          .deleteRelacionDeclaraciones(projectId, objRelacionDeclaraciones)
          .pipe(
            map((relacionDeclaraciones) => {
              this.notify.success(
                '',
                'Borrado de registros exitoso',
                MSG_DURATION
              );
              return setDeleteRelacionDeclaracionesSuccess({ relacionDeclaraciones });
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setDeleteRelacionDeclaracionesError({ error }));
            })
          );
      })
    )
  );
  // getValueRelacionDeclaraciones$ = createEffect(()=>{
  //   this.actions$.pipe(
  //     ofType(),
  //     switchMap(({objRelacionDeclaraciones}) =>{
  //       return this.industriaComercioService.getValueRelacionDeclaraciones(objRelacionDeclaraciones)
  //       .pipe(
  //         map((relacionDeclaraciones) => {
  //           let relacionDeclaracionesForm: RelacionDeclaracionesStore = {
  //          idProject: projectId,
  //          ...relacionDeclaraciones,
  //        };
  //          this.notify.success(
  //            '',
  //            'Guardado de registros exitoso',
  //            MSG_DURATION
  //          );
  //          return setRelacionDeclaracionesSuccess({ relacionDeclaracionesForm });
  //        }),
  //       )
  //     })
  //   )
  // }) 

  public constructor(
    private actions$: Actions,
    private industriaComercioService: IndustriaComercioService,
    private notify: NotifyService
  ) {}
}
