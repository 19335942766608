import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';

import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { environment } from '@environment';
import { AuthService } from '@auth/services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AppInterceptor implements HttpInterceptor {

  apiBaseUrl = environment.apiBaseUrl;

  constructor(private auth: AuthService){}

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    // const token = JSON.parse(localStorage.getItem('token'));

    return this.auth.getToken().pipe(
      take(1),
      mergeMap(value => {

        if(req.url.includes('/assets/')){
          return next.handle(req)
        }

        if(value){
          let request = req.clone({
            url: this.apiBaseUrl + req.url,
            headers: req.headers.set('Authorization', `Bearer ${value.token}`)
          });

          return next.handle(request).pipe(
            catchError( error => throwError(error))
          );
        }else {
          return next.handle(req);
        }
      })
    );

  }

}
