import { vector } from "@app/pages/formato2516/components/eri-renta-liquida/eri";

export interface RegistroDepuracion {
    id: number;
    account: number;
    auxiliar?: boolean;
    clase:string
    creditos: number;
    debitos: number;
    descAjusteFiscal: string;
    descripcion2516: string;
    entity2516:number;
    efConversion: number;
    item: string;
    nameAccount: string;
    nivel: number;
    renglon: number;
    type: string;
    tarifasList: TarifaDepuracion[];
    valContable: number;
    valFiscal: number;
    mayorPositivo?:boolean;
    modificado?:boolean;
    sinEfFiscal?:boolean;
}

export interface TarifaDepuracion {
    id:number;
    amount: number;
    valorTarifa: number;
    mayor?:boolean;
}

export interface RegistroCompleto {
    conversionEnabled?: boolean;
    projectId?:number;
    list: RegistroDepuracion[];
    listByParent?: any;
    isCategoria?: any;
}

export interface RegistroDepuracionMore {
    more: boolean;
    registro: RegistroDepuracion;
}

export interface MathDepLevel {
    nivel: string;
    balanceMatching: boolean;
    depuracionStatus: string;
}



export const renglonesERIInvertido=[36,37,38,...vector(148,171),...vector(216,239),...vector(271,294)];
export const renglonesESFInvertido=[vector(31,37),...vector(53,60),72,92,93,104,105,111,112,116,117,120,122,126,127,130,134,135,138];