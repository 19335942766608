<button
    type="button"
    [disabled]="disabledLine"
    [class]="clase ? clase: 'btn_icon_primary'" 
    [id]="idButton" (click)="onEmit()" 
    [pTooltip]="message"
    tooltipPosition="bottom" 
    placeholder="Bottom">
    <mat-icon 
    [svgIcon]="icon">
    </mat-icon>
</button>


