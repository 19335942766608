import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {Balance, BalanceErrorMessage} from '@app/pages/info-contribuyente/components/balance/balance';
import { NotifyService } from '@app/shared/services/notify.service';

import { isInvalid } from '@shared/utils/form-validators';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'form-map-balance',
  templateUrl: './form-map-balance.component.html',
  styleUrls: ['./form-map-balance.component.scss'],
})

export class FormMapBalanceComponent implements OnInit {

  fielDataHeader: object[] = [];
  balanceData: object[] = [];

  form = this.fb.group({
    id: null,
    account: ['', Validators.required],
    nameAccount: ['', Validators.required],
    previousBalance: ['', Validators.required],
    debit: ['', Validators.required],
    credit: ['', Validators.required],
    finalBalance: ['', Validators.required],
    adicionalData1: [''],
    adicionalData2: [''],
    adicionalData3: [''],
    adicionalData4: [''],
    adicionalData5: ['']
  });

  constructor(
    private fb: FormBuilder,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notify: NotifyService,
  ) { }

  ngOnInit(): void {
    this.fielDataHeader = this.config.data.fielDataHeader.sort();
    this.balanceData = this.config.data.balanceData;
  }

  validarEleccion(control: string, valor: string): void {
    if (valor !== '') {
      const seleccionados = [];
      for (const c in this.form.controls) {
        if (this.form.controls[c].value !== '') { seleccionados.push(this.form.controls[c].value); }
      }
      if (seleccionados.filter(s => s === valor).length > 1) {

        this.notify.error(
          'Error de seleccion!',
          'La columna ya fue elegida',
          5000
      );

        this.form.get(control).patchValue('');
      }
    }
  }

  isInvalid(controlName: string): boolean {
    return isInvalid(controlName, this.form);
  }

  guardar(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid){

      this.notify.error(
        'Información incompleta',
        'Por favor seleccione los campos requeridos',
        5000,
      {
        text: 'Entendido',
        orientation: 'izq'
      });

      return;
    }

    const orderedBalanceData: Balance[] = [];
    try {
      this.balanceData.forEach(
        (balanceRow, i) => {
          const balanceMaped = this.mapBalanceHeaders(balanceRow);
          const validBalance = this.isValidaBalanceRow(balanceMaped, i);

          if (validBalance.valid) { orderedBalanceData.push(balanceMaped); }
          else { throw Error(validBalance.errorMessage); }
        }
      );
    }catch (e){

      // this.toast.add({ severity: 'error', summary: 'Falla en conversion', detail: e});

      this.notify.error(
        'Error!',
        `Falla en conversion ${e}`,
        5000
    );

      return;
    }

    this.ref.close(orderedBalanceData);
  }

  isValidaBalanceRow(balanceMaped: Balance, i: number): BalanceErrorMessage {
    let errorMessage = '';
    let valid = true;
    const { account, finalBalance, credit, debit, nameAccount,
      previousBalance} = {...balanceMaped};

    if (isNaN(+account)){
        errorMessage = ('Número de cuenta es obligatoria en fila ' + (i + 2));
        valid = false;
        return {errorMessage, valid};
      }

    if (nameAccount === undefined || nameAccount === null){
        errorMessage =  'Nombre de cuenta es obligatoria en fila ' + (i + 2);
        valid = false;
        return {errorMessage, valid};
      }

    if (nameAccount.length <= 0){
        errorMessage =  'Nombre de cuenta es obligatoria en fila ' + (i + 2);
        valid = false;
        return {errorMessage, valid};
      }

    if (isNaN(+credit) || credit === undefined){
        errorMessage =  'El valor crédito debe ser númerico en fila ' + (i + 2);
        valid = false;
        return {errorMessage, valid};
      }

    if (isNaN(+debit) || debit === undefined){
        errorMessage =  'El valor débito debe ser númerico en fila ' + (i + 2);
        valid = false;
        return {errorMessage, valid};
      }


    if (previousBalance === undefined || previousBalance === null || isNaN(+previousBalance)){
        balanceMaped.previousBalance = 0;
      }

    if (finalBalance === undefined || finalBalance === null || isNaN(+previousBalance)){
        balanceMaped.finalBalance = 0;
      }

    return {errorMessage, valid};

  }

  mapBalanceHeaders(balanceRow: object): Balance {
    const headers = this.form.getRawValue();
    const balanceMaped = {} as Balance;
    Object.keys(headers).forEach( key => {
      balanceMaped[key] = balanceRow[headers[key]];
      if (balanceMaped[key] === undefined) { delete balanceMaped[key]; }
    });
    return balanceMaped;
  }

  cancel(): void {
    this.ref.close();
  }
}
