import { createAction, props } from '@ngrx/store';
import { AjusteDepuracionIcaStore } from '../models';

export const GET_AJUSTEDEPURACIONICA = '[ajusteDepuracionIca] get ajusteDepuracionIca';
export const GET_AJUSTEDEPURACIONICA_SUCCESS = '[ajusteDepuracionIca] get ajusteDepuracionIca success';
export const GET_AJUSTEDEPURACIONICA_ERROR = '[ajusteDepuracionIca] get ajusteDepuracionIca with error';

export const SET_AJUSTEDEPURACIONICA = '[ajusteDepuracionIca] set ajusteDepuracionIca save info';
export const SET_AJUSTEDEPURACIONICA_SUCCESS = '[ajusteDepuracionIca] set ajusteDepuracionIca save info success';
export const SET_AJUSTEDEPURACIONICA_ERROR = '[ajusteDepuracionIca] set ajusteDepuracionIca save info with error';

export const SET_DELETE_AJUSTEDEPURACIONICA = '[ajusteDepuracionIca] set delete ajusteDepuracionIca';
export const SET_DELETE_AJUSTEDEPURACIONICA_SUCCESS = '[ajusteDepuracionIca] set delete ajusteDepuracionIca success';
export const SET_DELETE_AJUSTEDEPURACIONICA_ERROR = '[ajusteDepuracionIca] set delete ajusteDepuracionIca with error';

//get AjusteDepuracionIca
export const getAjusteDepuracionIca = createAction(
  GET_AJUSTEDEPURACIONICA,
  props<{projectId: number}>()
);

export const getAjusteDepuracionIcaSuccess = createAction(
  GET_AJUSTEDEPURACIONICA_SUCCESS,
  props<{ ajusteDepuracionIcaSuccess: AjusteDepuracionIcaStore  }>()
);

export const getAjusteDepuracionIcaError = createAction(
  GET_AJUSTEDEPURACIONICA_ERROR,
  props<{ error: string }>()
);

//create AjusteDepuracionIca
export const setAjusteDepuracionIca = createAction(
  SET_AJUSTEDEPURACIONICA,
  props<{ ajusteDepuracionIcaForm: AjusteDepuracionIcaStore, projectId: number }>()
);

export const setAjusteDepuracionIcaSuccess = createAction(
  SET_AJUSTEDEPURACIONICA_SUCCESS,
  props<{ ajusteDepuracionIcaForm: AjusteDepuracionIcaStore }>()
);

export const setAjusteDepuracionIcaError = createAction(
  SET_AJUSTEDEPURACIONICA_ERROR,
  props<{ error: string }>()
);

//delete AjusteDepuracionIca
export const setDeleteAjusteDepuracionIca = createAction(
  SET_DELETE_AJUSTEDEPURACIONICA,
  props<{ projectId: number, objAjusteDepuracionIca: AjusteDepuracionIcaStore }>()
);

export const setDeleteAjusteDepuracionIcaSuccess = createAction(
  SET_DELETE_AJUSTEDEPURACIONICA_SUCCESS,
  props<{ ajusteDepuracionIca: AjusteDepuracionIcaStore }>()
);

export const setDeleteAjusteDepuracionIcaError = createAction(
  SET_DELETE_AJUSTEDEPURACIONICA_ERROR,
  props<{ error: string }>()
);
