<section class="empty">

  <div class="empty_icon">
    <img src="../../../../assets/img/no-document.png">
  </div>

  <div class="empty_message">
    {{ message }}
  </div>

    <app-button class="empty_button"
      (clicker)="onEmit(button.idButton)"
      [icon]="button.icon"
      [text]="button.label"
      [clase]="'btn_empty_state'"
      [idButton]="button.idButton"
      [iconDir]="button.iconDir">
    </app-button>

</section>

