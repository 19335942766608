import { createAction, props } from '@ngrx/store';
import { Seccion1GmfStore, Seccion1Tabla } from '../models/cuatrox-mil-gmf.model';

export const GET_SECCION1GMF = '[seccion1gmf] get seccion1gmf';
export const GET_SECCION1GMF_SUCCESS = '[seccion1gmf] get seccion1gmf success';
export const GET_SECCION1GMF_ERROR = '[seccion1gmf] get seccion1gmf with error';

export const SET_SECCION1GMF = '[seccion1gmf] set seccion1gmf save info';
export const SET_SECCION1GMF_SUCCESS = '[seccion1gmf] set seccion1gmf save info success';
export const SET_SECCION1GMF_ERROR = '[seccion1gmf] set seccion1gmf save info with error';

export const SET_DELETE_SECCION1GMF = '[seccion1gmf] set delete seccion1gmf';
export const SET_DELETE_SECCION1GMF_SUCCESS = '[seccion1gmf] set delete seccion1gmf success';
export const SET_DELETE_SECCION1GMF_ERROR = '[seccion1gmf] set delete seccion1gmf with error';

//get Seccion1Gmf
export const getSeccion1Gmf = createAction(
  GET_SECCION1GMF,
  props<{projectId: number}>()
);

export const getSeccion1GmfSuccess = createAction(
  GET_SECCION1GMF_SUCCESS,
  props<{ seccion1GmfSuccess: Seccion1GmfStore  }>()
);

export const getSeccion1GmfError = createAction(
  GET_SECCION1GMF_ERROR,
  props<{ error: string }>()
);

//create Seccion1Gmf
export const setSeccion1Gmf = createAction(
  SET_SECCION1GMF,
  props<{ seccion1GmfForm:Seccion1GmfStore, projectId: number }>()
);

export const setSeccion1GmfSuccess = createAction(
  SET_SECCION1GMF_SUCCESS,
  props<{ seccion1GmfForm:Seccion1GmfStore }>()
);

export const setSeccion1GmfError = createAction(
  SET_SECCION1GMF_ERROR,
  props<{ error: string }>()
);

//delete Seccion1Gmf
export const setDeleteSeccion1Gmf = createAction(
  SET_DELETE_SECCION1GMF,
  props<{ projectId: number, objSeccion1Gmf: Seccion1Tabla[] }>()
);

export const setDeleteSeccion1GmfSuccess = createAction(
  SET_DELETE_SECCION1GMF_SUCCESS,
  props<{ seccion1Gmf: any }>()
);

export const setDeleteSeccion1GmfError = createAction(
  SET_DELETE_SECCION1GMF_ERROR,
  props<{ error: string }>()
);
