import { RegistroCompleto } from '@app/pages/depuracion/models/registro-depuracion';
import { createAction, props } from '@ngrx/store';
import { DepuracionStore, DepuracionStatus } from '../models/depuracion.model';

export const VIEW_DEPURACION = '[depuracion] view depuracion';
export const VIEW_DEPURACION_SUCCESS = '[depuracion] view depuracion success';
export const VIEW_DEPURACION_ERROR = '[depuracion] view depuracion with error';

export const GET_DEPURACION = '[depuracion] get depuracion';
export const GET_DEPURACION_SUCCESS = '[depuracion] get depuracion success';
export const GET_DEPURACION_ERROR = '[depuracion] get depuracion with error';

export const SET_DEPURACION_INFO = '[depuracion] set depuracion save info';
export const SET_DEPURACION_INFO_SUCCESS = '[depuracion] set depuracion save info success';
export const SET_DEPURACION_INFO_ERROR = '[depuracion] set depuracion save info with error';

export const SET_DELETE_DEPURACION = '[depuracion] set delete depuracion';
export const SET_DELETE_DEPURACION_SUCCESS = '[depuracion] set delete depuracion success';
export const SET_DELETE_DEPURACION_ERROR = '[depuracion] set delete depuracion with error';

//view Depuracion
export const viewDepuracion = createAction(
  VIEW_DEPURACION,
  props<{projectId: number}>()
);

export const viewDepuracionSuccess = createAction(
  VIEW_DEPURACION_SUCCESS,
  props<{ depuracionViewSuccess: DepuracionStatus }>()
);

export const viewDepuracionError = createAction(
  VIEW_DEPURACION_ERROR,
  props<{ error: string }>()
);

//get Depuracion
export const getDepuracion = createAction(
  GET_DEPURACION,
  props<{projectId: number, level:string}>()
);

export const getDepuracionSuccess = createAction(
  GET_DEPURACION_SUCCESS,
  props<{ depuracionSuccess: DepuracionStore }>()
);

export const getDepuracionError = createAction(
  GET_DEPURACION_ERROR,
  props<{ error: string }>()
);


//crate depuracion
export const setDepuracionInfo = createAction(
  SET_DEPURACION_INFO,
  props<{ depuracionForm:RegistroCompleto, projectId: number, dialog?: any }>()
);

export const setDepuracionInfoSuccess = createAction(
  SET_DEPURACION_INFO_SUCCESS,
  props<{ depuracion:DepuracionStore }>()
);

export const setDepuracionInfoError = createAction(
  SET_DEPURACION_INFO_ERROR,
  props<{ error: string }>()
);
//delete depuracion
export const setDeleteDepuracion = createAction(
  SET_DELETE_DEPURACION,
  props<{ idDepuracion: string }>()
);

export const setDeleteDepuracionSuccess = createAction(
  SET_DELETE_DEPURACION_SUCCESS,
  props<{ depuracion: any }>()
);

export const setDeleteDepuracionError = createAction(
  SET_DELETE_DEPURACION_ERROR,
  props<{ error: string }>()
);
