import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
/** 
 * Componente para confirmar operaciones.
 */
export class ConfirmDialogComponent {

  /**
   * Muestra un mensaje al usuario para confirmar una operación
   * @param dialogo Referencia al componente ConfirmDialogoComponent 
   * @param mensaje Mensaje que se quiere mostrar al usuario
   */
  constructor(
    public dialogo: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public mensaje: string
  ) { }

  /**
   * Cierra el componente y retorna un false
   */
  close(): void {
    this.dialogo.close(false);
  }

  /**
   * Cierrar el diálogo y retorna un true
   */
  confirm(): void {
    this.dialogo.close(true)
   }
}
