import { Injectable } from "@angular/core";
import { DescuentoTributarioService } from "@app/pages/anex-depuracion/services/descuento-tributario.service";
import { MSG_DURATION, NotifyService } from "@app/shared/services/notify.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { getCalculoDescuento, getCalculoDescuentoError, getCalculoDescuentoSuccess, setCalculoDescuento, setCalculoDescuentoSuccess, setDeleteCalculoDescuentoError } from "../actions/calculo-descuento.actions";
import { CalculoDescuentoDTO } from "../models/descuento-tributario.model";

@Injectable()
export class CalculoDescuentoEffects {
  getCalculoDescuento$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCalculoDescuento),
      switchMap(({ projectId }) => {
        return this.descTService.getCalculoDescuento(projectId).pipe(
          map((calculoDescuento) => {
            let calculoDescuentoSuccess: CalculoDescuentoDTO = {
              idProject: projectId,
              ...calculoDescuento,
            };
            return getCalculoDescuentoSuccess({ calculoDescuentoSuccess });
          }),
          catchError((error) => {
            this.notify.error('Algo salio mal!', JSON.stringify(error), 5000, {
              text: 'Entendido',
              orientation: 'izq',
            });
            return of(getCalculoDescuentoError({ error }));
          })
        );
      })
    )
  );

  saveCalculoDescuento$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCalculoDescuento),
      switchMap(({ calculoDescuentoForm, projectId }) => {
        return this.descTService
          .saveCalculoDescuento(calculoDescuentoForm,projectId)
          .pipe(
            map((calculoDescuento:CalculoDescuentoDTO) => {
               let calculoDescuentoForm: CalculoDescuentoDTO = {
              idProject: projectId,
              ...calculoDescuento
            };
              this.notify.success(
                '',
                'Guardado de registros exitoso',
                MSG_DURATION
              );
              return setCalculoDescuentoSuccess({ calculoDescuentoForm:calculoDescuentoForm });
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setDeleteCalculoDescuentoError({ error }));
            })
          );
      })
    )
  );

  /*deleteCalculoDescuento$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDeleteCalculoDescuento),
      switchMap(({ projectId, idCalculoDescuento,totales }) => {
        return this.descTService
          .deleteCalculoDescuento(projectId, idCalculoDescuento,totales)
          .pipe(
            map((calculoDescuentoForm) => {
              this.notify.success(
                '',
                'Borrado de registros exitoso',
                MSG_DURATION
              );
              return setDeleteCalculoDescuentoSuccess({calculoDescuentoForm} );
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setDeleteCalculoDescuentoError({ error }));
            })
          );
      })
    )
  );*/

  public constructor(
    private actions$: Actions,
    private descTService: DescuentoTributarioService,
    private notify: NotifyService
  ) {}
}
