import { createAction, props } from "@ngrx/store"


export const SET_ONE_SUMA = '[project] set one suma'; 
export const SET_TWO_SUMA = '[project] set two suma';
export const setOneSuma = createAction(
    SET_ONE_SUMA,
    props<{suma: number}>()
  )
  export const setTwoSuma = createAction(
    SET_TWO_SUMA,
    props<{suma: number}>()
  )