import { createReducer, on, Action } from '@ngrx/store';
import { getNominaSuccess, setDeleteNominaSuccess, setNominaSuccess } from '../actions';
import { nominaAdapter, NominaState } from '../states';

export const initialState: NominaState = nominaAdapter.getInitialState({})

const nominaReducer = createReducer(
  initialState,

  on(getNominaSuccess, (state, { nominaSuccess }) => {
    state.ids = [];
    state.entities = {};
    return nominaAdapter.upsertOne(nominaSuccess, { ...state })
  }),

  on(setNominaSuccess, (state, { nominaForm }) => {
    state.ids = [];
    state.entities = {};
    return nominaAdapter.upsertOne(nominaForm, state)
  }),

  on(setDeleteNominaSuccess, (state, { nomina }) => {
    return nominaAdapter.removeOne(nomina.id, state)
  }),
)

export function reducer(state: NominaState | undefined, action: Action) {
  return nominaReducer(state, action);
}
