const renta = 'Api-';
export const infoContribuyente =`${renta}InfoContribuyente` ;
export const anioAnterior = `${renta}AñoAnterior` ;
export const infoBalance = `${renta}Balance`;
export const infoBalanceTerceros = `${renta}BalanceTerceros`;
export const depuracion = `${renta}Depuración`;
export const f110 = `${renta}F110`;

export const CuentasAfectacionFiscal = `${renta}AfectacionFiscal`;
export const CuentasRentaGravable = `${renta}RentaGravable`;

export const Retefuente = `${renta}Retefuente`;

export const GananciasOcaciones =`${renta}GananciasOcasionales`;
export const GastosExterior = `${renta}GastosExterior`;
export const CuatroMil = `${renta}4*Mil`;
export const AnticipoRentas = `${renta}AnticipoRenta`;
export const Autorretenciones = `${renta}Autorretenciones`;
export const RentaExenta = `${renta}RentaExenta`;
export const CompensacionPerdidas = `${renta}CompensaciónPérdida`;
export const GastosPersonal =`${renta}GastosPersonales`;
export const DescuentosTributarios = `${renta}DescuentosTributarios`;
export const IngresosNoConstitutivos  = `${renta}IngresosNoConstitutivos`;
export const IndustriaComercio = `${renta}IndustriaComercio`;
export const InteresesPresuntivos = `${renta}InteresesPresuntivos`;
export const PuntosAdicionales = `${renta}PuntosAdicionales`;
export const CarteraProvision = `${renta}CarteraProvision`;
export const TasaMinima = `${renta}TasaMinima`;
export const Dividendos = `${renta}Dividendos`;
export const LimiteBeneficios = `${renta}LimiteBeneficios`;
export const ESAL = `${renta}ESAL`;
export const ECE = `${renta}ECE`;
export const Reclasificacion = `${renta}Reclasificación-División`;

export const ConciliacionPatrimonio = `${renta}ConciliaciónPatrimonio`;
export const ConciliacionIngresos = `${renta}ConciliaciónIngresos`;
export const ConciliacionRenta =`${renta}ConciliaciónRenta`;
export const ComparacionPatrimonial = `${renta}ComprarciónPatrimonial`;
export const LiquidacionSanciones = `${renta}Sanciones`;

export const Caratula = `${renta}Caratula`;
export const ESFPatrimonio = `${renta}ESP-Patrimonio`;
export const ERIRenta = `${renta}ERI-RentaLiquida`;
export const ImpuestoDiferidos  =`${renta}ImpuestoDiferido`;
export const Ingresos  =`${renta}Ingresos-Facturacion`;
export const Activos  =`${renta}ActivosFijos`;

export const ImpuestoCorriente = `${renta}ProvisiónImpuesto`;
export const BeneficioAuditoria = `${renta}BeneficioAuditoria`;

export const ObrasImpuestos = `${renta}ObrasImpuetos`;