import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { RegistroESF } from '../components/esfpatrimonio/model/esf';
import { RegistroImpuestoDiferido, RegistroTotalID } from '../components/impuesto-diferido/model/impuesto-diferido';


@Injectable({
  providedIn: 'root'
})
export class ImpuestoDiferidoService {

  constructor(private  http: HttpClient) { }

  getImpuestoDiferido(idProject:number, deleteData: boolean = false): Observable<RegistroTotalID>{
    return this.http.get<RegistroTotalID>(`/impuesto_diferido?id=${idProject}&borrar=${deleteData}`);
  }
  guardarImpuestoDiferido(idProject:number,impuesto:RegistroTotalID):Observable<RegistroTotalID>{
    return this.http.post<RegistroTotalID>("/impuesto_diferido?id="+idProject,impuesto);
  }
  getValorRenglon(idProject:number,renglon:number[]): Observable<RegistroESF[]>{
    return this.http.get<RegistroESF[]>("/impuesto_diferido/renglon?id="+idProject+"&renglones="+renglon);
  }
}