import { createSelector } from '@ngrx/store';
import { getProjectsModuleState } from '../reducers/index';
import { toArray } from 'lodash';

export const selectImpuestoDiferidoList = createSelector(
  getProjectsModuleState,
  (state) => state.impuestoDiferido.entities
);

export const selectLImpuestoDiferidoById = (id: number) =>
  createSelector(getProjectsModuleState, (state) => {
    const data: any = state.impuestoDiferido.entities;
    if (data) return toArray(data).find((item) => item.projectId === id);
    else return {};
  });
