import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DepuracionService } from '@app/shared/services/depuracion.service';
import { NotifyService } from '@app/shared/services/notify.service';
import {
  Renglon2516,
  RenglonRenta,
} from '../../../pages/depuracion/models/renglon';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-depuracion-window-modal-edit',
  templateUrl: './depuracion-window-modal-edit.component.html',
  styleUrls: ['./depuracion-window-modal-edit.component.scss'],
})
export class DepuracionModalEdit implements OnInit {
  renglones2516$: Observable<Renglon2516[]>;
  form = this.fb.group({
    renta: ['', Validators.required],
    renglon2516: ['', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private depService: DepuracionService,
    private notifyService: NotifyService,
    public dialogRef: MatDialogRef<DepuracionModalEdit>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {}

  ngOnInit(): void {
    this.form.patchValue(this.data.patch);
    if (this.data.registro.renglon === -1) {
      this.renglones2516$ = this.depService.renglones2516$(
        this.data.projectId,
        +this.data.registro.renglon,
        -1
      );
    } else {
      if (this.data.registro.renglon > 0) {
        this.renglones2516$ = this.depService.renglones2516$(
          this.data.projectId,
          +this.data.registro.renglon,
          +this.data.registro.clase
        );
      }
    }
  }

  isInvalid(controlName: string): boolean {
    return (
      this.form.get(controlName).errors && this.form.get(controlName).touched
    );
  }

  cambioRenglon(renta: string) {
    if (this.data.registro.renglon === -1) {
      this.renglones2516$ = this.depService.renglones2516$(
        this.data.projectId,
        +renta,
        -1
      );
    } else {
      this.renglones2516$ = this.depService.renglones2516$(
        this.data.projectId,
        +renta,
        +this.data.registro.clase
      );
    }
  }

  guardar(datos) {
    this.dialogRef.close({
      event: 'save',
      data: datos,
      registro: this.data.registro,
    });
  }
}
