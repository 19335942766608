import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ListProyectosService } from '@proyectos/services/list-proyectos.service';
import {
  getProjectList,
  getProjectListSuccess,
  getProjectListError,
  setProjectInfo,
  setProjectInfoSuccess,
  setProjectInfoError,
  setStatusProjectInitialState,
  getAllAnnexedStatus,
  getProjectsListByName,
  getProjectsListByNIT,
  setYearProject,
  setYearProjectSuccess,
} from '../actions';
import { NotifyService } from '@app/shared/services/notify.service';
import { Project } from '@store/models';
import { StatusService } from '@app/shared/services/status-project.service';
import { Router } from '@angular/router';
import { SpinnerService } from '@app/shared/services/spinner.service';
import { Store } from '@ngrx/store';

@Injectable()
export class ProjectEffects {
  getProjectList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjectList),
      switchMap(() =>
        this.proyectosService.getProyectos(0).pipe(
          map((list: Project[]) => {
            //of(setStatusProjectList({ projectList: list }))
            return getProjectListSuccess({ projectList: list });
          }),
          catchError((error) => {
            this.notify.error(
              'Ago salio mal! ',
              'No pudimos cargar los Proyectos',
              4000
            );
            return of(getProjectListError({ error }));
          })
        )
      )
    )
  );
  getProjectsListByName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjectsListByName),
      switchMap(({name}) =>
        this.proyectosService.getFilterProyectByName(name).pipe(
          map((list: Project[]) => {
            return getProjectListSuccess({ projectList: list });
          }),
          catchError((error) => {
            this.notify.error(
              'Ago salio mal! ',
              'No pudimos cargar los Proyectos',
              4000
            );
            return of(getProjectListError({ error }));
          })
        )
      )
    )
  );
  getProjectsListByNIT$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjectsListByNIT),
      switchMap(({nit}) =>
        this.proyectosService.getFilterProyectByNit(nit).pipe(
          map((list: Project[]) => {
            return getProjectListSuccess({ projectList: list });
          }),
          catchError((error) => {
            this.notify.error(
              'Ago salio mal! ',
              'No pudimos cargar los Proyectos',
              4000
            );
            return of(getProjectListError({ error }));
          })
        )
      )
    )
  );

  public setProjectInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setProjectInfo),
      switchMap(({ projectForm, dialog }) => {
        dialog.close(0);
        this.loading.show('Estamos creando tu proyecto');
        return this.proyectosService.crearProyecto(projectForm).pipe(
          map((idResponse: number) => {
            let project: Project;

            if (idResponse) {
              project = { ...projectForm, id: idResponse };
              this.loading.hide();
            }

            return setProjectInfoSuccess({
              projectSuccess: project,
            });
          }),
          catchError((error) => {
            this.notify.error('Algo salio mal!', JSON.stringify(error), 4000, {
              text: 'Entendido',
              orientation: 'izq',
            });
            return of(setProjectInfoError({ error }));
          })
        );
      })
    )
  );

 
  
  // public setYearProject$ = createEffect(()=>
  // // this.actions$.pipe(
  // //   ofType(setYearProject),
  // //   switchMap(({year})) =>{

  // //   }
  // //  )
  // this.actions$.pipe(
  //   ofType(setYearProject),
  //   switchMap(({date}) => {
  //    return setYearProjectSuccess({year:date})
  //    catchError((error) => {
  //     this.notify.error(
  //       'Ago salio mal! ',
  //       'No pudimos cargar los Proyectos',
  //       4000
  //     );
  //     return of(getProjectListError({ error }));
  //   })
  //   })
  // )
  // )


  public constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store,
    private proyectosService: ListProyectosService,
    private statusService: StatusService,
    private loading: SpinnerService,
    private notify: NotifyService
  ) {}
}
