import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MailReport } from '@app/shared/models/mail-ticket.model';
import { FormGenerate } from '@app/shared/services/form.service';
import { NotifyService } from '@app/shared/services/notify.service';
import { MailService } from '@app/shared/services/send-mail';
import { environment } from '@environment';
import { of } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-dialog-ticket',
  templateUrl: './dialog-ticket.component.html',
  styleUrls: ['./dialog-ticket.component.scss'],
  encapsulation: ViewEncapsulation.None
})
/**
 * Componente para confirmar operaciones.
 */
export class DialogTicketComponent implements OnInit{

  public form: FormGroup;

  constructor(
    public dialogo: MatDialogRef<DialogTicketComponent>,
    private builder: FormBuilder,
    private genForm: FormGenerate,
    private mailService: MailService,
    private notify: NotifyService,
    @Inject(MAT_DIALOG_DATA)
    public mensaje: string,
  ) { }

  ngOnInit(): void {
    this.buildForm()
  }

  private buildForm(){

    this.form = this.builder.group({});

    this.genForm.genInputString('subject', this.form, true);
    this.genForm.genInputString('description', this.form, true);
    this.genForm.genInputFiles('attachment', this.form);

  }

  get attachment(){
    return this,this.form.get('attachment');
  }

  handlerDropzone(files: any[]){
    this.attachment.setValue(files)
  }


  close(): void {
    this.dialogo.close(false);
  }

  confirm(value) {

    this.mailService.getBase64(value.attachment)
    .subscribe((response) => {
      if(response){

        const mail: MailReport = {
          content: value.description,
          correos: environment.mailSupport,
          adjuntos: response,
          asunto: value.subject,
        }

        setTimeout(() => {

          this.mailService.sendMailTicket(mail).subscribe((res: any) => {
            if(res.respuestaEnvio === 'enviado'){
              this.notify.success(
               'Reporte Enviado',
               'El reporte se ha enviado correctamente',
               5000
             );

             this.dialogo.close(true);

            } else{
              this.notify.error(
                'Error de envio',
                'No pudimos reportar el error, intentalo más tarde',
                5000
              );

              this.dialogo.close(true);

            }
          })

        }, 2000);

      }

    });



   }

}
