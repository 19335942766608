import { createAction, props } from '@ngrx/store';
import { RentaGravableStore } from '../models';

export const SET_CREAR_RENGLON_RENTA = '[Renta Gravable] crear reglon renta';
export const SET_CREAR_RENGLON_RENTA_SUCCESS =
  '[Renta Gravable] crear reglon renta success';
export const SET_CREAR_RENGLON_RENTA_FAIL =
  '[Renta Gravable] crear reglon renta fail';

export const setCrearRenglonRenta = createAction(
  SET_CREAR_RENGLON_RENTA,
  props<{ projectId: number, payload: any }>()
);

export const setCrearRenglonRentaSuccess = createAction(
  SET_CREAR_RENGLON_RENTA_SUCCESS,
  props<{ registroDepuracionCreado: RentaGravableStore }>()
);

export const setCrearRenglonRentaFail = createAction(
  SET_CREAR_RENGLON_RENTA_FAIL,
  props<{ error: any }>()
);
