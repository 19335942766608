import { createReducer, on, Action } from '@ngrx/store';
import { getAjusteDepuracionIcaSuccess, setAjusteDepuracionIcaSuccess, setDeleteAjusteDepuracionIcaSuccess } from '../actions';
import { ajusteDepuracionIcaAdapter, AjusteDepuracionIcaState } from '../states';

export const initialState: AjusteDepuracionIcaState = ajusteDepuracionIcaAdapter.getInitialState({})

const ajusteDepuracionIcaReducer = createReducer(
  initialState,

  on(getAjusteDepuracionIcaSuccess, (state, { ajusteDepuracionIcaSuccess }) => {
    state.ids = [];
    state.entities = {};
    return ajusteDepuracionIcaAdapter.upsertOne(ajusteDepuracionIcaSuccess, { ...state })
  }),

  on(setAjusteDepuracionIcaSuccess, (state, { ajusteDepuracionIcaForm }) => {
    state.ids = [];
    state.entities = {};
    return ajusteDepuracionIcaAdapter.upsertOne(ajusteDepuracionIcaForm, state)
  }),

  on(setDeleteAjusteDepuracionIcaSuccess, (state, { ajusteDepuracionIca }) => {
    return ajusteDepuracionIcaAdapter.removeOne(ajusteDepuracionIca.idProject, state)
  }),
)

export function reducer(state: AjusteDepuracionIcaState | undefined, action: Action) {
  return ajusteDepuracionIcaReducer(state, action);
}
