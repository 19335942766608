<!-- <ng-container *ngIf="this.display !== 'phone'; else navResponsive"> -->
  <app-user-profile-button
    [name]="(currentUser$ | async)?.displayName"
    [role]="'Administrador'"
    [menuOptions]="menuOptions"
    [image]="(currentUser$ | async)?.photoURL"
    (clicker)="goToRoute($event)"
  >
  </app-user-profile-button>
<!-- </ng-container> -->

<!-- <ng-template #navResponsive>
  <div class="menu_responsive">
    <mat-icon
      (click)="menuMobile.emit(true)"
      class="icon"
      svgIcon="menu_bars"
    ></mat-icon>
    <a mat-button class="user-responsive" [matMenuTriggerFor]="menu">
      <img src="/assets/icons/elementos-34.png" alt="" />
    </a>
  </div>
</ng-template> -->

<!-- <mat-menu #menu="matMenu" class="drop_menu" xPosition="before">
  <button
    mat-menu-item
    [class]="
      menu.label === 'cerrar sesión' ? 'drop_menu_btn' : 'drop_menu_btn last'
    "
    *ngFor="let menu of menuOptions"
    (click)="this.goToRoute(menu)"
  >
    <span>
      <mat-icon [svgIcon]="menu.icon"></mat-icon>
      {{ menu.label | titlecase }}
    </span>
  </button>
</mat-menu> -->
