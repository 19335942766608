import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-dropzone',
  templateUrl: './progress-dropzone.component.html',
  styleUrls: ['./progress-dropzone.component.scss']
})
export class ProgressDropzoneComponent {
  @Input() progress = 0;

  ngOnInit() {}
}
