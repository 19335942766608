import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { NotifyService } from '@app/shared/services/notify.service';

@Injectable()
export class AfectacionFiscalEffects {

  constructor(
    private actions$: Actions,
    private notify: NotifyService,
  ) {}
}
