<ng-container *ngIf="iconDir === 'der' else iconIzq">
    <button [disabled]="disabling" [class]="clase" [id]="idButton" (click)="!disabling ? onEmit(): ''">
        <div [class]="clase + '_text'">
            {{text}}
        </div>
    
        <div  [class]="clase + '_icon'">
            <mat-icon [svgIcon]="icon"></mat-icon>
        </div>
    </button>
</ng-container>

<ng-template #iconIzq>
    <button [disabled]="disabling" [class]="clase" [id]="idButton" (click)="!disabling ? onEmit(): ''" >
        <div [class]="clase +  '_icon'">
            <mat-icon [svgIcon]="icon"></mat-icon>
        </div>
        
        <div [class]="clase + '_text'">
            {{text}}
        </div>
    
    </button>
</ng-template>

