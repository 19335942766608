import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { LANGUAGE_CODE } from '@angular/fire/auth';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// Ngrx
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import * as fromReducers from '@store/reducers';
import * as fromEffects from '@store/effects';

import { metaReducers, reducers } from './reducers';

import { ConfirmationService, MessageService } from 'primeng/api';

import { AppRoutingModule } from '@app/app-routing.module';
import { SharedModule } from '@shared/shared.module';

import { AppComponent } from '@app/app.component';
import { environment } from '@environment';

import { httpInterceptorProviders } from '@shared/services';
import { DialogService } from 'primeng/dynamicdialog';
import { CustomSerializer } from './reducers/custom-route-serializer';
import { AuthModule } from '@auth/auth.module';
import { registerLocaleData } from '@angular/common';
import {
  SpreadsheetAllModule,
  SpreadsheetModule,
} from '@syncfusion/ej2-angular-spreadsheet';
registerLocaleData(LANGUAGE_CODE, 'es');

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConf),
    SharedModule,
    AuthModule,
    StoreModule.forFeature('projectModule', fromReducers.reducers, {
      metaReducers: fromReducers.metaReducers,
    }),

    EffectsModule.forFeature(fromEffects.EFFECTS),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),

    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),

    EffectsModule.forRoot([]),
    // Habilitamops devTools en Dev solamente
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
          name: 'Renta App',
          maxAge: 25,
          logOnly: environment.production,
        }),
  ],

  providers: [
    httpInterceptorProviders,
    { provide: LANGUAGE_CODE, useValue: 'es' },
    MessageService,
    ConfirmationService,
    DialogService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
