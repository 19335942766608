import { Component  } from '@angular/core';
import { environment } from '@environment';
import { ICONS } from '@shared/utils/icons';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { AuthService } from './shared/services/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = environment.appName;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private auth: AuthService,
  ){
    this.auth.checkSession();
    this.registerIcons();
  }

  varde ="neif";
  nonode! : string;

  private registerIcons() {
    ICONS.map((icon) =>
      this.matIconRegistry.addSvgIcon(
        icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `/assets/svg/${icon}.svg`
        )
      )
    );
  }

}
