import {FormGroup} from '@angular/forms';
import {CostosContable, Determinacion, Fiscal, FiscalType} from './model/ganacia-ocasional';

export function calcDeterminacion(form: FormGroup): void {
    const fechaCompra = form.get('fechaCompra').value;
    const fechaVenta = form.get('fechaVenta').value;
    if (fechaCompra && fechaVenta) {
        const compra = new Date(fechaCompra);
        const venta = new Date(fechaVenta);
        const milis = venta.getTime() - compra.getTime();
        const diffTime = milis / 1000 / 60 / 60;
        const twoYears = 17520;
        const determinacion = form.get('determinacion');
        if (diffTime >= twoYears) {
            determinacion?.setValue(Determinacion.ocasional);
        } else {
            determinacion?.setValue(Determinacion.liquida);
        }
    }
}

export function calcGananciaOcasional(form: FormGroup): number {

    const utilidadPerdida = form.get('utilidadPerdida').value;
    const gonoGravadas = form.get('gonoGravadas').value;
    let ingresos = 0;
    let costos = 0;
    let gastos = 0;
    const ajustesFiscales: Fiscal[] = form.get('ajustesFiscales').value;
    ajustesFiscales.forEach(a => {
        ingresos += a.type === FiscalType.ingreso ? a.valor : 0;
        costos += a.type === FiscalType.costo ? a.valor : 0;
        gastos += a.type === FiscalType.gasto ? a.valor : 0;
    });
    return utilidadPerdida - gonoGravadas + ingresos - costos - gastos;
}

export function calcImpuestoGo(totalGO: number): number{
    if (totalGO < 0) {
      return 0;
    }
    return Math.round(totalGO * .1);
}

export function calcUtilidadPerdida(form: FormGroup): number {
    const totalIngreso = form.get('totalIngreso').value;
    const costoATomar = form.get('costoATomar').value;
    return totalIngreso - costoATomar;
}

export function calcCostos(costos: CostosContable[]): number {
    let totalCostos = 0;
    costos?.forEach(c => totalCostos += c.valor);
    return totalCostos;
}

export function calcCostoATomar(form: FormGroup): void {
    const totalCosto = form.get('totalCosto').value;
    const totalGasto = form.get('totalGasto').value;
    const totalInversion = form.get('totalInversion').value;

    const costoATomar = totalCosto + totalGasto + totalInversion;

    form.get('costoATomar').setValue(costoATomar);
}

