<app-modal-cabecera-grl
  [title]="this.data.name || 'Subir Excel'"
  [iconTitle]="'indicator'"
>
</app-modal-cabecera-grl>
<mat-dialog-content class="modalGrl">
  <div>
    <div class="modalGrl_contain">
      <div class="modalGrl_contain_icon" *ngIf="data.icon">
        <mat-icon [svgIcon]="data.icon"></mat-icon>
      </div>
      <div class="modalGrl_contain_message">
        <p>
          {{ data.textOne }} <b *ngIf="data.textFocus">{{ data.textFocus }}</b
          >{{ data?.textThree }}
        </p>
      </div>
    </div>
    <div class="modalGrl_contain_message text-center mt-4">
      <p>
        {{ data.textTwo }}
      </p>
    </div>
  </div>
  <div class="modalGrl_select" *ngIf="data.select">
    <!--select solo para depuracion-->
    <ng-container *ngIf="data.select.type === 'depuracion'; else other">
      <label>{{ data.select.label }}</label>
      <select [formControl]="selectedData" class="mt-3">
        <option value="" disabled>Escoge Nivel</option>
        <option *ngFor="let level of data.select.options[0]" [value]="level">
          Nivel {{ level }}
        </option>
      </select>
    </ng-container>
    <!--todos los demas selects-->
    <ng-template #other>
      <label>{{ data.select.label }}</label>
      <select [formControl]="selectedData">
        <option value="" disabled>Selecciona</option>
        <option *ngFor="let option of data.select.options" [value]="option">
          {{ option }}
        </option>
      </select>
    </ng-template>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="modalGrl_buttons">
  <app-button
    *ngFor="let but of data.buttons"
    [icon]="but.icon"
    [text]="but.text"
    [idButton]="but.id"
    [clase]="but.clase"
    (clicker)="this.emitingData($event, 'button')"
    [iconDir]="but.direction"
  >
  </app-button>
</mat-dialog-actions>
