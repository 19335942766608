import {Validators} from '@angular/forms';

export interface Generics<T, U> {
    name: T;
    description: U;
}

export interface Option {
    name: string;
    description: string;
}

export interface TotalGo {
    ingresosContables: number;
    costosContables: number;
    utilidadContable: number;
    costosFiscales: number;
    costosContablesATomar: number;
    costosFiscalesATomar: number;
    totalUtilidadOPerdida: number;
    ajusteValorFiscalERI: number;
    recuperacionDeducciones: number;
    totalGONoGravadas: number;
    totalAjustesFiscalesIngresos: number;
    totalAjustesFiscalesCostos: number;
    gananciaOcasional: number;
    impuesto: number;
}

export interface TotalesGo {
    gananciaOcasional: TotalGo;
    rentaLiquidaUtilidad: TotalGo;
    rentaLiquidaPerdida: TotalGo;
}

export interface DepAccount {
    id?: string;
    nameAccount: string;
}

export enum Determinacion {
    ocasional = 'GANANCIA_OCASIONAL',
    liquida = 'RENTA_LIQUIDA'
}

export interface GanaciaOcasionalMore {
    go: GanaciaOcasional;
    addMore: boolean;
}

export interface GanaciaOcasionalOtraMore {
    go: GananciaOcasionalOtra;
    addMore: boolean;
}

export interface GanaciaOcasional {
    id?: number;
    ajustesFiscales?: Fiscal[];
    costoAConsiderar?: string;
    costosContables?: CostosContable[];
    costosFiscales?: Fiscal[];
    depreciaciones?: CostosContable[];
    depreciacionDeducible?: number;
    depreciacionDeducibleDescripcion?: string;
    determinacion?: Determinacion;
    fechaCompra?: Date;
    fechaVenta?: Date;
    gonoGravadas?: number;
    gonoGravadasDescripcion?: string;
    nombreActivo?: string;
    reconocimiento2516?: string;
    recuperacionDeducciones?: number;
    utilidadPerdida?: number;
    valoresHistoricos?: CostosContable[];
    valorCostoAConsiderar?: number;
    venta?: string;
    ventaActivos?: CostosContable[];
}

export interface GananciaOcasionalList {
    id: number;
    nombreActivo: string;
    venta: string;
    reconocimiento2516: string;
    fechaCompra: Date;
    fechaVenta: Date;
    determinacion: string;
    valoresHistoricos: number;
    depreciaciones: number;
    ventaActivos: number;
    costosContables: number;
    costosFiscales: number;
    valorCostoAConsiderar: number;
    costoAConsiderar: string;
    utilidadPerdida: number;
    depreciacionDeducible: number;
    depreciacionDeducibleDescripcion: string;
    recuperacionDeducciones: number;
    ajustesFiscales: number;
    gonoGravadas: number;
    gonoGravadasDescripcion: string;
}

export interface GananciaOcasionalOtraList {
    id: number;
    venta: string;
    tipo: string;
    descripcionOtroTipo: string;
    fechaCompra: Date;
    fechaVenta: Date;
    determinacion: string;
    ingresos: number;
    valoresInversion: number;
    costos: number;
    gastos: number;
    ajustesFiscales: number;
    costoATomar: number | null;
    utilidadPerdida: number | null;
    gonoGravadas: number | null;
    gonoGravadasDescripcion: null | string;
}

export interface GananciaOcasionalOtra {
    id?: number;
    ajustesFiscales?: Fiscal[];
    costoATomar: number;
    costos?: CostosContable[];
    descripcionOtroTipo: string;
    determinacion: string;
    fechaCompra: Date;
    fechaVenta: Date;
    gastos?: CostosContable[];
    gonoGravadas?: number;
    gonoGravadasDescripcion?: string;
    ingresos?: CostosContable[];
    tipo: string;
    utilidadPerdida?: number;
    valoresInversion?: CostosContable[];
    venta: string;
}

export enum FiscalType {
    costo = 'COSTO',
    gasto = 'GASTO',
    ingreso = 'INGRESO',
    otro = 'OTROS',
}

export const CTR_COSTO_CONTABLE = {
    id: null,
    account: [undefined, [Validators.required]],
    name: [{ value: '', disabled: true }],
    valor: [0, [Validators.required]],
    descripcion: [''],
    depuracionId: null
};
export const CTR_COSTO_CONTABLE_ESAL = {
    id: null,
    account: [undefined, [Validators.required]],
    name: [{ value: '', disabled: true }],
    valor: [{ value: 0, disabled: true }, [Validators.required]],
    descripcion: [0],
    depuracionId: null
};
export const CTR_COSTO_CONTABLE_ECE = {
    id :[null],
    account: [undefined, [Validators.required]],
    name: [{ value: '', disabled: true }],
    valorContable: [0, [Validators.required]],
    ajuste: ['', [Validators.required]],
    valorAjuste: [0, [Validators.required]],
};
export const CTR_COSTO_CONTABLE2 = {
    id: null,
    account: [undefined, [Validators.required]],
    name: [{ value: '', disabled: true }],
    valor: [0, [Validators.required]],
    descripcion: [''],
    depuracionId: null,
    list:[''],
    valor2: [0, [Validators.required]],

};

export const CTR_COSTO_FISCAL = {
    id: null,
    concepto: ['', [Validators.required]],
    descripcion: [''],
    valor: [0, [Validators.required]],
    depuracionId: null
};

export const CTRL_AJUSTE_FISCAL = {
    id: null,
    descripcion: [''],
    type: ['', [Validators.required]],
    valor: [0, [Validators.required]],
    depuracionId: null
};

export enum CostoFiscal {
    costoFiscal = 'COSTO_FISCAL',
    costoContable = 'COSTO_CONTABLE'
}

export interface Fiscal {
    id?: number;
    concepto?: string;
    descripcion: string;
    type?: FiscalType;
    valor: number;
    depuracionId: number;
}

export interface CostosContable {
    id?: number;
    account: string;
    descripcion: string;
    name: string;
    valor: number;
    depuracionId: number;
}
export interface CostosContable2 {
    id?: number;
    account: string;
    descripcion: string;
    name: string;
    valor: number;
    depuracionId: number;
    list: string;
    valortotal:string

}

export interface CostosContableEce {
    id?: number | null;
    account: string;
    name: string;
    valorContable: number;
    ajuste: string;
    valorAjuste: number;
}

export const INIT_GO: GanaciaOcasional = {
    id: null,
    ajustesFiscales: null,
    costoAConsiderar: '',
    costosContables: null,
    costosFiscales: null,
    depreciaciones: null,
    depreciacionDeducible: 0,
    depreciacionDeducibleDescripcion: '',
    determinacion: null,
    fechaCompra: null,
    fechaVenta: null,
    gonoGravadas: 0,
    gonoGravadasDescripcion: '',
    nombreActivo: '',
    reconocimiento2516: '',
    recuperacionDeducciones: 0,
    utilidadPerdida: 0,
    valoresHistoricos: null,
    venta: '',
    ventaActivos: null,
    valorCostoAConsiderar: 0
};

export const INIT_GO_OTRA: GananciaOcasionalOtra = {
    id: null,
    ajustesFiscales: null,
    determinacion: null,
    fechaCompra: null,
    fechaVenta: null,
    venta: '',
    tipo: '',
    descripcionOtroTipo: '',
    ingresos: null,
    valoresInversion: [],
    costos: [],
    gastos: [],
    costoATomar: 0
};
