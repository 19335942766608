import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '@auth/services/auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfileGuard implements CanActivate, CanLoad {

  constructor(
    private authService: AuthService,
    private router: Router
  ){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.isInfoComplete();
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> {
      return this.isInfoComplete();
  }

  private isInfoComplete(): Observable<boolean> {
    return this.authService.isUserInfoComplete().pipe(
      map(isComplete => {
        if (isComplete) {
          this.router.navigate(['404']);
          return false;
        }

        return true;
      })
    );
  }
}
