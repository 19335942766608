import { Component } from '@angular/core';
import { ICONS } from '@app/shared/utils/icons';

@Component({
  selector: 'app-documentacion',
  templateUrl: './documentacion.component.html',
  styleUrls: ['./documentacion.component.scss']
})
export class DocumentacionComponent {
  public iconsList = ICONS;

  public buttonsExample = [
    { idButton: 'new', 
      icon: 'plus', 
      label: 'Nuevo'
    },
    { idButton: 'info', 
      icon: 'info', 
      label: 'Info'
    },
  ]

  public buttonJson = `
  <app-button 
    [icon]="'user_line'"
    [text]="'Soy Button'"
    [idButton]="'hey_button'"
    (clicler)="escuchando($evento)">
  </app-button>
  `

  public titleJson =`
  <app-title 
    [title]="'Cabecera'"
    [icon]="'archive'"
    [sticky]="false"
    >
  </app-title>
  `;

  public titleButtonsJson =`
  <app-title
    [title]="'Cabecera'"
    [icon]="'star'"
    [sticky]="false"
    [buttons]="buttonsExample"
    (clicked)="alertButton($event)"
    >
  </app-title>
  `;

  public arrayButtonsJson = `
    // ejemplo de array de Botones
    public buttonsExample = [
    { 
      idButton: 'new', 
      icon: 'plus', 
      label: 'Nuevo'
    },
    { idButton: 'info', 
      icon: 'info', 
      label: 'Info'
    }
  ]
  `
  public buttonOnlyIcon = `
  <app-button-icon 
    (clicker)="evClick($event)"
    [icon]="'save_bold'"
    [message]="'Hola Click'"
    [idButton]="'Soy_Button_icon'"
    [disabledLine]="true">
  </app-button-icon>
  `
 

  constructor(
  ) { }

  alertButton(msj: String) {
    alert(msj)
  }

}
