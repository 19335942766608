export const MENU = [
  {
    id: '2',
    title: 'Datos de Información',
    icon: 'db',
    visible: false,
    active: false,
    showSub: false,
    submenu: [
      {
        name: 'Información del Contribuyente',
        icon: 'user_line',
        route: '/infoContribuyente/info',
        active: false,
      },
      {
        name: 'Información Año Anterior',
        icon: 'calendar',
        route: '/infoContribuyente/anio_anterior',
        active: false,
      },
      {
        id: 1,
        name: 'Balances',
        icon: 'indicator',
        active: false,
        showSubLinks: false,
        menuLinks: [
          {
            id: 1,
            name: 'Balance Cliente',
            icon: 'user_line',
            route: '/infoContribuyente/balance',
            active: false,
          },
          {
            id: 2,
            name: 'Balance por Terceros',
            icon: 'balance3',
            route: '/infoContribuyente/balance_terceros',
            active: false,
          },
        ],
      },
    ],
  },
  {
    id: '3',
    title: 'Depuración-Anexos',
    icon: 'invoice',
    visible: false,
    active: false,
    showSub: false,
    submenu: [
      {
        name: 'Ingresos no Constitutivos de\n Renta ni Ganancia Ocasional',
        icon: 'star',
        route: '/anex-dep/incrgo',
        active: false,
      },
      {
        name: 'Intereses Presuntivos',
        icon: 'up_percent',
        route: '/anx-depuracion/intereses_presuntivos',
        active: false,
      },
      {
        name: '4 x Mil - GMF',
        icon: '4',
        route: '/anx-depuracion/gmf',
        active: false,
      },
      {
        name: 'Industria y Comercio\n Avisos y Tableros',
        icon: 'wallet',
        route: '/anx-depuracion/industria_comercio',
        active: false,
      },
      {
        name: 'Detalle Gasto Exterior',
        icon: 'fly',
        route: '/anx-depuracion/gasto_exterior',
        active: false,
      },
      {
        name: 'Gastos de personal',
        icon: 'people',
        route: '/anx-depuracion/gasto_personal',
        active: false,
      },
      {
        name: 'Cartera - Provisión y Deterioro',
        icon: 'indicator',
        route: '/anx-depuracion/cartera-provision',
        active: false,
      },
      {
        name: 'Reclasificación y división cuentas',
        icon: 'reclasificacion',
        route: '/anx-depuracion/reclasificacion-division',
        active: false,
      },
      {
        name: 'Liquidación sanciones',
        icon: 'get_money',
        route: '/conciliacion/sanciones',
        active: false,
      },
    ],
  },
  {
    id: '4',
    title: 'Depuración-Cáluclos especiales',
    icon: 'copy',
    visible: false,
    active: false,
    showSub: false,
    submenu: [
      {
        name: 'ESAL: Inversiones - Rentas gravables',
        icon: 'donacion',
        route: '/anx-depuracion/esal',
        active: false,
      },
      {
        name: 'ECE - Entidades Controladas Exterior',
        icon: 'enterprise',
        route: '/anx-depuracion/ece',
        active: false,
      },
    ],
  },
  {
    id: '5',
    title: 'Renta Líquida\n  (CÁLCULOS, LÍMITES, EXENCIONES)',
    icon: 'paper_status',
    visible: false,
    active: false,
    showSub: false,
    submenu: [
      {
        name: 'Compensación de Pérdidas',
        icon: 'pay',
        route: '/anx-depuracion/compensacion_perdidas',
        active: false,
      },
      {
        name: 'Renta Exenta',
        icon: 'money',
        route: '/anex-dep/renta',
        active: false,
      },
      {
        name: 'Ganancias Ocasionales',
        icon: 'paper_status',
        route: '/anx-depuracion/ganancia_ocasional',
        active: false,
      },
    ],
  },
  {
    id: '6',
    title: 'Rentas Gravables',
    icon: 'get_money',
    visible: false,
    active: false,
    showSub: false,
    submenu: [
      {
        name: 'Cuenta de Afectación Fiscal',
        icon: 'user_line',
        route: '/renta/fiscal',
        active: false,
      },
      {
        name: 'Rentas Gravables',
        icon: 'get_money',
        route: '/renta/gravable',
        active: false,
      },
    ],
  },
  {
    id: '7',
    title: 'LIQUIDACIÓN PRIVADA - "Reforma Trib 2277"',
    icon: 'tasa_minima',
    visible: false,
    active: false,
    showSub: false,
    submenu: [
      {
        name: 'Descuentos Tributarios',
        icon: 'down_percent',
        route: '/anx-depuracion/descuentos_tributarios',
        active: false,
      },
      {
        name: 'VAA - Limite De Beneficios y Estímulos Tributarios',
        icon: 'atrasado',
        route: '/anx-depuracion/limite_beneficios',
        active: false,
      },
      {
        name: 'IA - Tasa Mínima de Tributación',
        icon: 'tasa_minima',
        route: '/anx-depuracion/tasa_minima_tributacion',
        active: false,
      },
      {
        name: 'Obras Por Impuestos',
        icon: 'obras_impuestos',
        route: '/anx-depuracion/obras-por-impuestos',
        active: false,
      },
      {
        name: '(IA) Puntos Adicionales Renta',
        icon: 'coins',
        route: '/anx-depuracion/puntos-adicionales',
        active: false,
      },
    ],
  },
  {
    id: '8',
    title: 'RETENCIONES - ANTICIPO - SANCIONES',
    icon: 'magnet',
    visible: false,
    active: false,
    showSub: false,
    submenu: [
      {
        name: 'Retefuente',
        icon: 'magnet',
        route: '/patrimonio/retefuente',
        active: false,
      },
      {
        name: 'Autorretenciones',
        icon: 'arrow_circle',
        route: '/anx-depuracion/autorretenciones',
        active: false,
      },
      {
        name: 'Anticipo de Renta',
        icon: 'arrow_down_rigth',
        route: '/anx-depuracion/anticipo_renta',
        active: false,
      },
      {
        name: 'Sanciones',
        icon: 'arrow_down_rigth',
        route: '',
        active: false,
      },
    ],
  },
  {
    id: '9',
    title: 'Conciliacones Renta\n y Dividendos',
    icon: 'check_rounded',
    visible: false,
    active: false,
    showSub: false,
    submenu: [
      {
        name: 'Conciliación Patrimonio',
        icon: 'building',
        route: '/conciliacion/patrimonio',
        active: false,
      },
      {
        name: 'Conciliación Ingresos',
        icon: 'hand_bag_money',
        route: '/conciliacion/ingresos',
        active: false,
      },
      {
        name: 'Conciliación Renta',
        icon: 'hand_money',
        route: '/conciliacion/renta',
        active: false,
      },
      {
        name: 'Comparación Patrimonial',
        icon: 'stats_analize',
        route: '/conciliacion/comparacion-patrimonial',
        active: false,
      },
      {
        name: 'Distribución de dividendos',
        icon: 'divi',
        route: '/anx-depuracion/distribucion_dividendos',
        active: false,
      },
    ],
  },
  {
    id: '10',
    title: 'Formato 2516',
    icon: 'edit_pen',
    visible: false,
    active: false,
    showSub: false,
    submenu: [
      {
        name: 'Caratula',
        icon: 'edit_pen',
        route: '/formato2516/caratula',
        active: false,
      },
      {
        name: 'ESF - Patrimonio',
        icon: 'hand_house',
        route: '/formato2516/ESF',
        active: false,
      },
      {
        name: 'ERI - Renta Líquida',
        icon: 'coins',
        route: '/formato2516/ERI',
        active: false,
      },
      {
        name: 'Impuesto Diferido',
        icon: 'square_dot',
        route: '/formato2516/impuesto',
        active: false,
      },
      {
        name: 'Activos Fijos',
        icon: 'activosFijos',
        route: '/formato2516/activosFijos',
        active: false,
      },
      {
        name: 'Ingresos y facturación',
        icon: 'ingresosFactura',
        route: '/formato2516/ingresos-facturacion',
        active: false,
      },
    ],
  },
  {
    id: '11',
    title: 'Provisión - Beneficio Auditoría',
    icon: 'paper',
    visible: false,
    active: false,
    showSub: false,
    submenu: [

      {
        name: 'Provisión Impuesto a Ganancia',
        icon: 'graph_up',
        route: '/anx-info/provision',
        active: false,
      },

      {
        name: 'Beneficio de Auditoria',
        icon: 'search_paper',
        route: '/anx-info/auditoria',
        active: false,
      },
    ],
  },
  {
    id: '1',
    title: 'Proyectos',
    icon: 'menu_bars',
    submenu: [],
    visible: true,
    active: false,
    showSub: false,
  },
  {
    id: 'logout',
    title: 'Salir',
    icon: 'log_out',
    visible: true,
    showSub: false,
  },
];

export const URLS_LOCKED = [
  {
    name: 'perfil',
    url: 'settings/profile',
  },
  {
    name: 'users',
    url: 'settings/manage_users',
  },
  {
    name: 'plans',
    url: 'plans/',
  },
];
