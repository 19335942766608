import { RegistroESF } from '@app/pages/formato2516/components/esfpatrimonio/model/esf';
import { RegistroTotalID } from '@app/pages/formato2516/components/impuesto-diferido/model/impuesto-diferido';
import { createAction, props } from '@ngrx/store';
import { ImpuestoDiferidoStore } from '../models/impuesto-diferido.model';

export const GET_IMPUESTO_DIFERIDO =
  '[Impuesto Diferido] get impuesto diferido';
export const GET_IMPUESTO_DIFERIDO_SUCCESS =
  '[Impuesto Diferido] get impuesto diferido success';
export const GET_IMPUESTO_DIFERIDO_FAIL =
  '[Impuesto Diferido] get impuesto diferido fail';


export const getImpuestoDiferido = createAction(
  GET_IMPUESTO_DIFERIDO,
  props<{ projectId: number }>()
);

export const getImpuestoDiferidoSuccess = createAction(
  GET_IMPUESTO_DIFERIDO_SUCCESS,
  props<{ registroTotal: Omit<ImpuestoDiferidoStore, 'newImpuestoDiferidoRegistro' | 'registrosRenglones'>  }>( 
));

export const getImpuestoDiferidoFail = createAction(
  GET_IMPUESTO_DIFERIDO_FAIL,
  props<{ error: any }>()
);

export const GET_VALOR_RENGLON =
  '[Impuesto Diferido] get impuesto diferido valor renglon';
export const GET_VALOR_RENGLON_SUCCESS =
  '[Impuesto Diferido] get impuesto diferido valor renglon success';
export const GET_VALOR_RENGLON_FAIL =
  '[Impuesto Diferido] get impuesto diferido valor renglon fail';


export const getValorRenglon = createAction(
  GET_VALOR_RENGLON,
  props<{ projectId: number, renglon: any }>()
);

export const getValorRenglonSuccess = createAction(
  GET_VALOR_RENGLON_SUCCESS,
  props<{ resgistrosESF: Omit<ImpuestoDiferidoStore, 'newImpuestoDiferidoRegistro' | 'impuestoDiferidoRegistro'> }>()
);

export const getValorRenglonFail = createAction(
  GET_VALOR_RENGLON_FAIL,
  props<{ error: any }>()
);

export const SET_IMPUESTO_DIFERIDO =
  '[Impuesto Diferido] set impuesto diferido';
export const SET_IMPUESTO_DIFERIDO_SUCCESS =
  '[Impuesto Diferido] set impuesto diferido success';
export const SET_IMPUESTO_DIFERIDO_FAIL =
  '[Impuesto Diferido] set impuesto diferido fail';


export const setImpuestoDiferido = createAction(
  SET_IMPUESTO_DIFERIDO,
  props<{ projectId: number, payload: any }>()
);

export const setImpuestoDiferidoSuccess = createAction(
  SET_IMPUESTO_DIFERIDO_SUCCESS,
  props<{ registroTotal: Omit<ImpuestoDiferidoStore, 'registrosRenglones' | 'impuestoDiferidoRegistro'> }>()
);

export const setImpuestoDiferidoFail = createAction(
  SET_IMPUESTO_DIFERIDO_FAIL,
  props<{ error: any }>()
);




