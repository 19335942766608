import { Injectable } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  FormBuilder,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Observable, EMPTY, BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';

import {
  GanaciaOcasional,
  Generics,
  GananciaOcasionalOtra,
  DepAccount,
  FiscalType,
  GananciaOcasionalOtraList,
  GananciaOcasionalList,
  CTR_COSTO_CONTABLE,
  CTRL_AJUSTE_FISCAL,
  CTR_COSTO_FISCAL,
  TotalesGo,
} from '../model/ganacia-ocasional';
import { ListProyectosService } from '@proyectos/services/list-proyectos.service';
import { compareDate } from '@shared/utils/form-validators';
import {
  calcCostos,
  calcImpuestoGo,
  calcGananciaOcasional,
} from '../form-functions.util';

@Injectable({
  providedIn: 'root',
})
export class GananciaOcasionalService {
  constructor(
    private http: HttpClient,
    private projectSrv: ListProyectosService,
    private fb: FormBuilder
  ) {}

  baseUrl = '/ganancia_ocasional';
  private reconocimientoSubject$ = new BehaviorSubject<string>('TOTAL');
  seletedReconocimientoAction$ = this.reconocimientoSubject$.asObservable();

  private tipoSubject$ = new BehaviorSubject<string>('TOTAL');
  tipoAction$ = this.tipoSubject$.asObservable();

  private msj$ = new BehaviorSubject<boolean>(false);
  showMsj$ = this.msj$.asObservable();

  opcionesAjusteFiscal: FiscalType[] = [
    FiscalType.costo,
    FiscalType.gasto,
    FiscalType.ingreso,
    FiscalType.otro,
  ];

  changeReconocimiento(reconocimiento: string): void {
    this.reconocimientoSubject$.next(reconocimiento);
  }

  showAjustesMsj(): void {
    this.msj$.next(true);
  }

  hideAjustesMsj(): void {
    this.msj$.next(false);
  }

  getReconocimiento(): Observable<Generics<string, string>[]> {
    return this.http.get<Generics<string, string>[]>(
      `${this.baseUrl}/reconocimiento2516`
    );
  }

  getGananciaOcasional(
    id: string,
    idProject: number
  ): Observable<GanaciaOcasional> {
    return this.http.get<GanaciaOcasional>(
      `${this.baseUrl}?projectId=${idProject}&gananciaId=${id}`
    );
  }

  getGananciaOcasionalList(
    idProject: number
  ): Observable<GananciaOcasionalList[]> {
    return this.http
      .get<GananciaOcasionalList[]>(
        `${this.baseUrl}/list?projectId=${idProject}`
      )
      .pipe(tap((go) => (go.length > 0 ? this.showAjustesMsj() : '')));
  }

  getGananciaOcasionalOtra(
    id: number,
    idProject: number
  ): Observable<GananciaOcasionalOtra> {
    return this.http.get<GananciaOcasionalOtra>(
      `${this.baseUrl}/otra?projectId=${idProject}&gananciaId=${id}`
    );
  }

  getGananciaOcasionalOtraList(
    idProject: number
  ): Observable<GananciaOcasionalOtraList[]> {
    return this.http
      .get<GananciaOcasionalOtraList[]>(
        `${this.baseUrl}/otra/list?projectId=${idProject}`
      )
      .pipe(tap((otraGo) => (otraGo.length > 0 ? this.showAjustesMsj() : '')));
  }

  getVentaList(): Observable<Generics<string, string>[]> {
    return this.http.get<Generics<string, string>[]>(`${this.baseUrl}/venta`);
  }

  getOtrasTipo(): Observable<Generics<string, string>[]> {
    return this.http.get<Generics<string, string>[]>(
      `${this.baseUrl}/otras_tipo`
    );
  }

  getAccount(account: number, idProject: number): Observable<DepAccount> {
    const url = `${this.baseUrl}/account?projectId=${idProject}&account=${account}`;
    return this.http.get<DepAccount>(url);
  }
  getAccountDepuracion(account: number, idProject: number): Observable<any> {
    return this.http.get(
      `/autorentenciones/accountValue?projectId=${idProject}&account=${account}`
    );
  }

  saveGO(
    go: GanaciaOcasional,
    idProject: number
  ): Observable<GanaciaOcasional> {
    return this.http.post<GanaciaOcasional>(
      `${this.baseUrl}?projectId=${idProject}`,
      go
    );
  }

  saveOtraGO(
    go: GananciaOcasionalOtra,
    idProject: number
  ): Observable<GananciaOcasionalOtra> {
    return this.http.post<GananciaOcasionalOtra>(
      `${this.baseUrl}/otra?projectId=${idProject}`,
      go
    );
  }

  getTotales(): Observable<TotalesGo> {
    return combineLatest([
      this.seletedReconocimientoAction$,
      this.projectSrv.currentProject$,
    ]).pipe(
      tap(([r]) => localStorage.setItem('reconocimiento', r)),
      debounceTime(1000),
      switchMap(([reconocimiento, p]) => {
        if (p) {
          return this.http.get<TotalesGo>(
            `${this.baseUrl}/totales?projectId=${p.id}&reconocimiento2516=${reconocimiento}`
          );
        }
        return EMPTY;
      })
    );
  }

  getOtrosTotales(): Observable<TotalesGo> {
    return combineLatest([
      this.tipoAction$,
      this.projectSrv.currentProject$,
    ]).pipe(
      tap(([r]) => localStorage.setItem('otrosTiposGo', r)),
      debounceTime(1000),
      switchMap(([tipo, p]) => {
        if (p) {
          return this.http.get<TotalesGo>(
            `${this.baseUrl}/otra/totales?projectId=${p.id}&tipo=${tipo}`
          );
        }
        return EMPTY;
      })
    );
  }

  getFormGO(gaOc: GanaciaOcasional): FormGroup {
    const fechaCompra =
      gaOc.fechaCompra &&
      new Date(gaOc.fechaCompra).toISOString().substring(0, 10);
    const fechaVenta =
      gaOc.fechaVenta &&
      new Date(gaOc.fechaVenta).toISOString().substring(0, 10);

    const form = new FormGroup(
      {
        id: new FormControl(gaOc.id || null),
        ajustesFiscales: this.fb.array([]),
        costoAConsiderar: new FormControl(gaOc.costoAConsiderar),
        costosContables: this.fb.array([]),
        costosFiscales: this.fb.array([]),
        depreciacionDeducible: new FormControl(gaOc.depreciacionDeducible, [
          Validators.required,
        ]),
        determinacion: new FormControl(gaOc.determinacion, [
          Validators.required,
          Validators.min(0),
        ]),
        depreciaciones: this.fb.array([]),
        depreciacionDeducibleDescripcion: new FormControl(
          gaOc.depreciacionDeducibleDescripcion
        ),
        fechaCompra: new FormControl(fechaCompra, [Validators.required]),
        fechaVenta: new FormControl(fechaVenta, [Validators.required]),
        gonoGravadas: new FormControl(gaOc.gonoGravadas),
        gonoGravadasDescripcion: new FormControl(gaOc.gonoGravadasDescripcion),
        impuestoGanaciaOc: new FormControl(0),
        nombreActivo: new FormControl(gaOc.nombreActivo, [Validators.required]),
        recuperacionDeducciones: new FormControl(gaOc.recuperacionDeducciones, [
          Validators.required,
          Validators.min(0),
        ]),
        reconocimiento2516: new FormControl(gaOc.reconocimiento2516, [
          Validators.required,
        ]),
        totalCostoActivo: new FormControl(0),
        totalCostoContable: new FormControl(0),
        totalGananciaOcasional: new FormControl(0),
        totalCostosFiscales: new FormControl(0),
        totalIngresoContable: new FormControl(0),
        utilidadPerdida: new FormControl(gaOc.utilidadPerdida, [
          Validators.required,
        ]),
        venta: new FormControl(gaOc.venta, [Validators.required]),
        ventaActivos: this.fb.array([]),
        valorCostoAConsiderar: new FormControl(gaOc.valorCostoAConsiderar, [
          Validators.required,
        ]),
        valoresHistoricos: this.fb.array([]),
      },
      {
        validators: [compareDate('fechaCompra', 'fechaVenta')],
      }
    );

    gaOc.costosContables?.forEach((cC) => {
      const vhCtr = this.fb.group({ ...CTR_COSTO_CONTABLE });
      vhCtr.patchValue(cC);
      (form.get('costosContables') as FormArray).push(vhCtr);
    });

    gaOc.ajustesFiscales?.forEach((aF) => {
      const vhCtr = this.fb.group({ ...CTRL_AJUSTE_FISCAL });
      vhCtr.patchValue(aF);
      (form.get('ajustesFiscales') as FormArray).push(vhCtr);
    });

    gaOc.depreciaciones?.forEach((d) => {
      const vhCtr = this.fb.group({ ...CTR_COSTO_CONTABLE });
      vhCtr.patchValue(d);
      (form.get('depreciaciones') as FormArray).push(vhCtr);
    });

    gaOc.valoresHistoricos?.forEach((vH) => {
      const vhCtr = this.fb.group({ ...CTR_COSTO_CONTABLE });
      vhCtr.patchValue(vH);
      (form.get('valoresHistoricos') as FormArray).push(vhCtr);
    });

    gaOc.ventaActivos?.forEach((vA) => {
      const vhCtr = this.fb.group({ ...CTR_COSTO_CONTABLE });
      vhCtr.patchValue(vA);
      (form.get('ventaActivos') as FormArray).push(vhCtr);
    });

    gaOc.costosFiscales?.forEach((cF) => {
      const vhCtr = this.fb.group({ ...CTR_COSTO_FISCAL });
      vhCtr.patchValue(cF);
      (form.get('costosFiscales') as FormArray).push(vhCtr);
    });

    form?.get('determinacion').disable();
    form?.get('impuestoGanaciaOc').disable();
    form?.get('recuperacionDeducciones').disable();
    form?.get('totalCostoActivo').disable();
    form?.get('totalCostoContable').disable();
    form?.get('totalCostosFiscales').disable();
    form?.get('totalGananciaOcasional').disable();
    form?.get('totalIngresoContable').disable();
    form?.get('utilidadPerdida').disable();
    form?.get('valorCostoAConsiderar').disable();

    return form;
  }

  getFormOtraGO(gaOc: GananciaOcasionalOtra): FormGroup {
    const fechaCompra =
      gaOc.fechaCompra &&
      new Date(gaOc.fechaCompra).toISOString().substring(0, 10);
    const fechaVenta =
      gaOc.fechaVenta &&
      new Date(gaOc.fechaVenta).toISOString().substring(0, 10);

    const form = new FormGroup(
      {
        id: new FormControl(gaOc.id || null),
        ajustesFiscales: this.fb.array([]),
        costoATomar: new FormControl(gaOc.costoATomar, [
          Validators.required,
          Validators.min(0),
        ]),
        costos: this.fb.array([]),
        descripcionOtroTipo: new FormControl(gaOc.descripcionOtroTipo),
        determinacion: new FormControl(gaOc.determinacion, [
          Validators.required,
          Validators.min(0),
        ]),
        fechaCompra: new FormControl(fechaCompra, [Validators.required]),
        fechaVenta: new FormControl(fechaVenta, [Validators.required]),
        gonoGravadas: new FormControl(gaOc.gonoGravadas),
        gonoGravadasDescripcion: new FormControl(gaOc.gonoGravadasDescripcion),
        impuestoGanacia: new FormControl(0),
        gastos: this.fb.array([]),
        ingresos: this.fb.array([]),
        tipo: new FormControl(gaOc.tipo, [Validators.required]),
        totalCosto: new FormControl(0),
        totalGananciaOcasional: new FormControl(0),
        totalGasto: new FormControl(0),
        totalIngreso: new FormControl(0),
        totalInversion: new FormControl(0),
        utilidadPerdida: new FormControl(gaOc.utilidadPerdida),
        valoresInversion: this.fb.array([]),
        venta: new FormControl(gaOc.venta, [Validators.required]),
      },
      {
        validators: [compareDate('fechaCompra', 'fechaVenta')],
      }
    );

    form?.get('costoATomar').disable();
    form?.get('determinacion').disable();
    form?.get('impuestoGanacia').disable();
    form?.get('totalCosto').disable();
    form?.get('totalGananciaOcasional').disable();
    form?.get('totalGasto').disable();
    form?.get('totalIngreso').disable();
    form?.get('totalInversion').disable();
    form?.get('utilidadPerdida').disable();

    gaOc.ajustesFiscales?.forEach((cF) => {
      const vhCtr = this.fb.group({ ...CTRL_AJUSTE_FISCAL });
      vhCtr.patchValue(cF);
      (form.get('ajustesFiscales') as FormArray).push(vhCtr);
    });

    gaOc.costos?.forEach((cF) => {
      const vhCtr = this.fb.group({ ...CTR_COSTO_CONTABLE });
      vhCtr.patchValue(cF);
      (form.get('costos') as FormArray).push(vhCtr);
    });

    gaOc.gastos?.forEach((cF) => {
      const vhCtr = this.fb.group({ ...CTR_COSTO_CONTABLE });
      vhCtr.patchValue(cF);
      (form.get('gastos') as FormArray).push(vhCtr);
    });
    gaOc.ingresos?.forEach((cF) => {
      const vhCtr = this.fb.group({ ...CTR_COSTO_CONTABLE });
      vhCtr.patchValue(cF);
      (form.get('ingresos') as FormArray).push(vhCtr);
    });
    gaOc.valoresInversion?.forEach((cF) => {
      const vhCtr = this.fb.group({ ...CTR_COSTO_CONTABLE });
      vhCtr.patchValue(cF);
      (form.get('valoresInversion') as FormArray).push(vhCtr);
    });

    form?.get('totalCosto').setValue(calcCostos(gaOc.costos));
    form?.get('totalGasto').setValue(calcCostos(gaOc.gastos));
    form?.get('totalIngreso').setValue(calcCostos(gaOc.ingresos));
    form?.get('totalInversion').setValue(calcCostos(gaOc.valoresInversion));
    form?.get('totalGananciaOcasional').setValue(calcGananciaOcasional(form));
    form
      .get('impuestoGanacia')
      .setValue(calcImpuestoGo(+form.get('totalGananciaOcasional').value));

    return form;
  }

  changeTipoGo(tipo: string): void {
    this.tipoSubject$.next(tipo);
  }
}
