<div id="btn-menu-float" class="model-2">
  <div id="changeToogle" class="float-btn-group fixed-bottom fixed-bottom">

    <button
      class="btn-float btn-triger renta-colors "
      id="btn-help"
      (click)="this.helpClick(); this.clicked = !this.clicked"
      [pTooltip]="this.clicked ? 'Cerrar':'Soporte'">
      <mat-icon [svgIcon]="this.clicked ? 'cancel_bold': 'support'"></mat-icon>
    </button>

     <div class="btn-list">

      <a class="btn-float youtube" pTooltip="Video Tutoriales" (click)="tutoriales()">
        <mat-icon svgIcon="youtube"></mat-icon>
      </a>

      <a class="btn-float ticket"  pTooltip="Reporte de Errores" (click)="reportarBug()">
        <mat-icon svgIcon="warning"></mat-icon>
      </a>

    </div>
  </div>
</div>
