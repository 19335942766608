<div class="container" [formGroup]="form">
  <div class="row mb-4">
    <div class="col-8">
      <h5>Seleccione el nivel a depurar</h5>
      <select
        name="levels"
        class="renta-select"
        id="levels"
        formControlName="levelControl"
        *ngIf="niveles"
      >
        <option value="" selected>Nivel</option>
        <option *ngFor="let level of niveles" (click)="selectLevel(level)">
          {{ level }}
        </option>
      </select>
    </div>
  </div>

  <p-footer>
    <div class="row">
      <div class="col-6">
        <button
          type="button"
          (click)="confirm()"
          label="Continuar"
          class="renta-btn"
        >
          <i class="fas fa-arrow-right"></i>
          Continuar
        </button>
      </div>
      <div class="col-6">
        <button
          (click)="cerrarDialogo()"
          label="Cancelar"
          class="renta-btn renta-error float-end"
        >
          <i class="fas fa-times"></i>
          Cancelar
        </button>
      </div>
    </div>
  </p-footer>

  <p-confirmDialog #cd icon="pi pi-exclamation-triangle" [closeOnEscape]="true">
    <ng-template pTemplate="header">
      <h3>Nivel {{ form.get("levelControl").value }}</h3>
    </ng-template>
    <ng-template pTemplate="footer">
      <button
        type="button"
        class="renta-btn renta-success"
        label="Si"
        (click)="cd.accept()"
      >
        <i class="fas fa-check"></i>
        Si
      </button>
      <button
        type="button"
        class="renta-btn renta-error"
        label="No"
        (click)="cd.reject()"
      >
        <i class="fas fa-times"></i>
        No
      </button>
    </ng-template>
  </p-confirmDialog>
</div>
