import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  MSG_DURATION,
  NotifyService,
} from '@app/shared/services/notify.service';
import { GastoPersonalService } from '@app/pages/anex-depuracion/services/gasto-personal.service';
import { getNomina, getNominaError, getNominaSuccess, setDeleteNomina, setDeleteNominaError, setDeleteNominaSuccess, setNomina, setNominaError, setNominaSuccess } from '../actions';
import { NominaStore } from '../models';

@Injectable()
export class NominaEffects {
  getNomina$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getNomina),
      switchMap(({ projectId }) => {
        return this.nominaService.getNomina(projectId).pipe(
          map((nomina) => {
            let nominaSuccess: NominaStore = {
              idProject: projectId,
              ...nomina,
            };
            return getNominaSuccess({ nominaSuccess });
          }),
          catchError((error) => {
            this.notify.error('Algo salio mal!', JSON.stringify(error), 5000, {
              text: 'Entendido',
              orientation: 'izq',
            });
            return of(getNominaError({ error }));
          })
        );
      })
    )
  );

  saveNomina$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setNomina),
      switchMap(({ nominaForm, projectId }) => {
        return this.nominaService
          .saveNomina(projectId, nominaForm)
          .pipe(
            map((nomina) => {
               let nominaForm: NominaStore = {
              idProject: projectId,
              ...nomina,
            };
              this.notify.success(
                '',
                'Guardado de registros exitoso',
                MSG_DURATION
              );
              return setNominaSuccess({ nominaForm });
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setNominaError({ error }));
            })
          );
      })
    )
  );

  deleteNomina$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDeleteNomina),
      switchMap(({ projectId, objNomina }) => {
        return this.nominaService
          .deleteNomina(projectId, objNomina)
          .pipe(
            map((nomina) => {
              this.notify.success(
                '',
                'Borrado de registros exitoso',
                MSG_DURATION
              );
              return setDeleteNominaSuccess({ nomina });
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setDeleteNominaError({ error }));
            })
          );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private nominaService: GastoPersonalService,
    private notify: NotifyService
  ) {}
}
