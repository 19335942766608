import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Seccion1GmfStore,
  Seccion1Tabla,
  Seccion2GmfStore,
  Seccion2Tabla,
  Seccion3GmfStore,
  Seccion3Tabla,
} from '@app/store/models/cuatrox-mil-gmf.model';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CuatroxMilGmfService {
  constructor(private http: HttpClient) {}

  //Seccion1
  getSeccion1Gmf(projectId: number): Observable<Seccion1GmfStore> {
    return this.http.get<Seccion1GmfStore>(
      `/gravamen_financiero/priSec?id=${projectId}`
    );
  }

  deleteSeccion1Gmf(
    projectId: number,
    seccion1Tabla: Seccion1Tabla[]
  ): Observable<any> {
    return this.http.request<any>(
      'delete',
      `/gravamen_financiero/priSec?project=${projectId}`,
      { body: seccion1Tabla }
    );
  }

  saveSeccion1Gmf(
    projectId: number,
    objSeccion1Gmf: Seccion1GmfStore
  ): Observable<Seccion1GmfStore> {
    return this.http.post<Seccion1GmfStore>(
      `/gravamen_financiero/priSec?id=${projectId}`,
      objSeccion1Gmf
    );
  }

  //Seccion2
  getSeccion2Gmf(projectId: number): Observable<Seccion2GmfStore> {
    return this.http.get<Seccion2GmfStore>(
      `/gravamen_financiero/segSec?id=${projectId}`
    );
  }

  deleteSeccion2Gmf(
    projectId: number,
    seccion2Tabla: Seccion2Tabla[]
  ): Observable<any> {
    return this.http.request<any>(
      'delete',
      `/gravamen_financiero/segSec?project=${projectId}`,
      { body: seccion2Tabla }
    );
  }

  saveSeccion2Gmf(
    projectId: number,
    objSeccion2Gmf: Seccion2GmfStore
  ): Observable<Seccion2GmfStore> {
    return this.http.post<Seccion2GmfStore>(
      `/gravamen_financiero/segSec?id=${projectId}`,
      objSeccion2Gmf
    );
  }

  //Seccion3
  getSeccion3Gmf(projectId: number): Observable<Seccion3GmfStore> {
    return this.http.get<Seccion3GmfStore>(
      `/gravamen_financiero/terSec?id=${projectId}`
    );
  }

  deleteSeccion3Gmf(
    projectId: number,
    seccion3Tabla: Seccion3Tabla[]
  ): Observable<any> {
    return this.http.request<any>(
      'delete',
      `/gravamen_financiero/terSec?project=${projectId}`,
      { body: seccion3Tabla }
    );
  }

  saveSeccion3Gmf(
    projectId: number,
    objSeccion3Gmf: Seccion3GmfStore
  ): Observable<Seccion3GmfStore> {
    return this.http.post<Seccion3GmfStore>(
      `/gravamen_financiero/terSec?id=${projectId}`,
      objSeccion3Gmf
    );
  }

  getAccountValue(projectId: number, account: number): Observable<any> {
    return this.http.get(
      `/gravamen_financiero/accountValue?projectId=${projectId}&account=${account}`
    );
  }
}
