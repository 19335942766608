export interface routeAnex {
  id: number;
  route: string;
  annexed: string;
  tab: string;
}

export const ROUTES_ANEX: routeAnex[] = [
  {
    id: 1,
    route: 'infoContribuyente/info',
    annexed: 'INFORMACION',
    tab: 'CONTRIBUYENTE',
  },
  {
    id: 2,
    route: 'infoContribuyente/anio_anterior',
    annexed: 'INFORMACION',
    tab: 'ANIO_ANTERIOR',
  },
  {
    id: 3,
    route: 'infoContribuyente/balance',
    annexed: 'INFORMACION',
    tab: 'BALANCE',
  },
  {
    id: 4,
    route: '',
    annexed: 'PATRIMONIO',
    tab: 'AJ_FISCALES',
  },
  {
    id: 5,
    route: '',
    annexed: 'PATRIMONIO',
    tab: 'PROV_CARTERA',
  },
  {
    id: 6,
    route: '',
    annexed: 'PATRIMONIO',
    tab: 'CON_BANCARIA',
  },
  {
    id: 7,
    route: 'patrimonio/retefuente',
    annexed: 'PATRIMONIO',
    tab: 'RETEFUENTE',
  },
  {
    id: 8,
    route: 'anx-depuracion/ganancia_ocasional',
    annexed: 'DEPURACION',
    tab: 'GAN_OCASIONALES',
  },
  {
    id: 9,
    route: 'anx-depuracion/gasto_exterior',
    annexed: 'DEPURACION',
    tab: 'DET_EXTERIOR',
  },
  {
    id: 10,
    route: 'anx-depuracion/gmf',
    annexed: 'DEPURACION',
    tab: 'GMF',
  },
  {
    id: 11,
    route: 'anx-depuracion/anticipo_renta',
    annexed: 'DEPURACION',
    tab: 'ANTICIPO',
  },
  {
    id: 12,
    route: 'anex-dep/renta',
    annexed: 'DEPURACION',
    tab: 'RENTA_EXENTA',
  },
  {
    id: 13,
    route: 'anex-dep/incrgo',
    annexed: 'DEPURACION',
    tab: 'INCRGO',
  },
  {
    id: 14,
    route: 'anx-depuracion/intereses_presuntivos',
    annexed: 'DEPURACION',
    tab: 'INT_PRESUNTIVOS',
  },
  {
    id: 15,
    route: 'anx-depuracion/gasto_personal',
    annexed: 'DEPURACION',
    tab: 'GST_PERSONAL',
  },
  {
    id: 16,
    route: '',
    annexed: 'DEPURACION',
    tab: 'SUBCAPITALIZACION',
  },
  {
    id: 17,
    route: 'anx-depuracion/autorretenciones',
    annexed: 'DEPURACION',
    tab: 'AUTORRETENCIONES',
  },
  {
    id: 18,
    route: 'anx-depuracion/compensacion_perdidas',
    annexed: 'DEPURACION',
    tab: 'COMP_PERDIDAS',
  },
  {
    id: 19,
    route: 'anx-depuracion/descuentos_tributarios',
    annexed: 'DEPURACION',
    tab: 'DESC_TRIBUTARIOS',
  },
  {
    id: 20,
    route: 'conciliacion/comparacion-patrimonial',
    annexed: 'DEPURACION',
    tab: 'COMPAPATRIMONIAL',
  },
  {
    id: 21,
    route: 'anx-depuracion/industria_comercio',
    annexed: 'DEPURACION',
    tab: 'PAGOSICA',
  },
  {
    id: 22,
    route: 'anx-depuracion/distribucion_dividendos',
    annexed: 'DEPURACION',
    tab: 'DISTRIBUCION_DIVIDENDOS',
  },
  {
    id: 23,
    route: '',
    annexed: 'DEPURACION',
    tab: 'PROVISIONES',
  },
  {
    id: 24,
    route: '',
    annexed: 'DEPURACION',
    tab: 'INVERSIONES',
  },
  {
    id: 25,
    route: 'anx-depuracion/tasa_minima_tributacion',
    annexed: 'DEPURACION',
    tab: 'TASA_MINIMA_TRIBUTACION',
  },
  {
    id: 26,
    route: 'anx-depuracion/puntos-adicionales',
    annexed: 'DEPURACION',
    tab: 'PUNTOS_ADICIONALES',
  },
  {
    id: 27,
    route: 'anx-depuracion/cartera-provision',
    annexed: 'DEPURACION',
    tab: 'CARTERA_PROVISION',
  },
  {
    id: 28,
    route: 'anx-depuracion/obras-por-impuestos',
    annexed: 'DEPURACION',
    tab: 'OBRAS_POR_IMPUESTOS',
  },
  {
    id: 29,
    route: 'anx-depuracion/ece',
    annexed: 'DEPURACIONCALCULOSESPECIALES',
    tab: 'ECE',
  },
  {
    id: 30,
    route: 'anx-depuracion/esal',
    annexed: 'DEPURACIONCALCULOSESPECIALES',
    tab: 'ESAL',
  },
  {
    id: 31,
    route: 'conciliacion/patrimonio',
    annexed: 'CONCILIACION',
    tab: 'CON_PATRIMONIO',
  },
  {
    id: 32,
    route: 'conciliacion/ingresos',
    annexed: 'CONCILIACION',
    tab: 'CON_INGRESOS',
  },

  {
    id: 33,
    route: 'conciliacion/renta',
    annexed: 'CONCILIACION',
    tab: 'CON_RENTA',
  },
  {
    id: 34,
    route: '',
    annexed: 'CONCILIACION',
    tab: 'RENTA_PRESUNTIVA',
  },
  {
    id: 35,
    route: '',
    annexed: 'CONCILIACION',
    tab: 'LIQ_SANCION',
  },
  {
    id: 36,
    route: 'conciliacion/comparacion-patrimonial',
    annexed: 'CONCILIACION',
    tab: 'COMPARACION_PATRIMONIAL',
  },
  {
    id: 37,
    route: 'renta/fiscal',
    annexed: 'GRAVABLES',
    tab: 'AFEC_FISCAL',
  },
  {
    id: 38,
    route: 'renta/gravable',
    annexed: 'GRAVABLES',
    tab: 'RENTAS_GRAVABLES',
  },
  {
    id: 39,
    route: '',
    annexed: 'ANEXOSEINFORMACION',
    tab: 'RESUMEN_PRESENTACION',
  },
  {
    id: 40,
    route: '',
    annexed: 'ANEXOSEINFORMACION',
    tab: 'JURIDICASYANTICIPOS',
  },
  {
    id: 41,
    route: 'anx-info/provision',
    annexed: 'ANEXOSEINFORMACION',
    tab: 'PROV_IMP_GANANCIAS',
  },
  {
    id: 42,
    route: 'anx-info/auditoria',
    annexed: 'ANEXOSEINFORMACION',
    tab: 'BENF_AUDITORIA',
  },
  {
    id: 43,
    route: 'anx-depuracion/limite_beneficios',
    annexed: 'DEPURACION',
    tab: 'VAA_LIMITE_BENEFICIO',
  },
  {
    id: 44,
    route: 'anx-depuracion/tasa_minima_tributacion',
    annexed: 'DEPURACION',
    tab: 'TASA_MINIMA_TRIBUTACION',
  },
  {
    id: 45,
    route: 'formato2516/ingresos-facturacion',
    annexed: 'CONCILIACION',
    tab: 'CONCILIACION_INGRESO_CONTABLE',
  },
  {
    id: 46,
    route: 'formato2516/activosFijos',
    annexed: 'CONCILIACION',
    tab: "ACTIVOS_FIJOS",
  },
  {
    id: 47,
    route: '',
    annexed: 'CONCILIACION',
    tab: "DESAGREGAR_DEPURACION",
  },
  {
    id: 48,
    route: 'anx-depuracion/distribucion_dividendos',
    annexed: 'DEPURACION',
    tab: "DIVIDENDOS",
  },
  {
    id: 49,
    route: 'anx-depuracion/reclasificacion-division',
    annexed: 'DEPURACION',
    tab: "DESAGREGAR_DEPURACION",
  },
  {
    id: 50,
    route: 'conciliacion/sanciones',
    annexed: 'DEPURACION',
    tab: "SANCIONES",
  },
];
