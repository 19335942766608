import { createSelector } from "@ngrx/store";
import { getProjectsModuleState } from "../reducers";

export const selectOneData = createSelector(
    getProjectsModuleState,
    (state) => state.limiteBeneficios.resultado
  );

export const selectTwoData = createSelector(
    getProjectsModuleState,
    (state) => state.limiteBeneficios.resultadofila
  );