<div [class]="'toastDepu ' + 'toats_'+ message.type">
    <div class="toastDepu_icon">
        <mat-icon [svgIcon]="this.setIcon(message.type)"></mat-icon>
    </div>
    <div class="toastDepu_content">
        <div class="toastDepu_content_title">
            <p>
                {{message.title}}
            </p>
          
        </div>
        <div class="toastDepu_content_message">
            <p>
               {{message.message}}
            </p>
        </div>
    </div>
</div>
