import { createReducer, on, Action } from '@ngrx/store';
import { getAjusteDepuracionSuccess, setAjusteDepuracionSuccess, setDeleteAjusteDepuracionSuccess } from '../actions';
import { ajusteDepuracionAdapter, AjusteDepuracionState } from '../states';

export const initialState: AjusteDepuracionState = ajusteDepuracionAdapter.getInitialState({})

const ajusteDepuracionReducer = createReducer(
  initialState,

  on(getAjusteDepuracionSuccess, (state, { ajusteDepuracionSuccess }) => {
    state.ids = [];
    state.entities = {};
    return ajusteDepuracionAdapter.upsertOne(ajusteDepuracionSuccess, { ...state })
  }),

  on(setAjusteDepuracionSuccess, (state, { ajusteDepuracionForm }) => {
    state.ids = [];
    state.entities = {};
    return ajusteDepuracionAdapter.upsertOne(ajusteDepuracionForm, state)
  }),

  on(setDeleteAjusteDepuracionSuccess, (state, { ajusteDepuracion }) => {
    return ajusteDepuracionAdapter.removeOne(ajusteDepuracion.id, state)
  }),
)

export function reducer(state: AjusteDepuracionState | undefined, action: Action) {
  return ajusteDepuracionReducer(state, action);
}
