import { createReducer, on, Action } from '@ngrx/store';
import { lastYearInfoAdapter, LastYearInfoState } from '../states';
import * as actionsLastYear from '@store/actions/last-year.actions';

export const initialState: LastYearInfoState = lastYearInfoAdapter.getInitialState({
});

const lastYearReducer = createReducer(
  initialState,

  on(actionsLastYear.getInfoLastYearSuccess, (state, { lastyearInfo }) => {
    state.entities = {};
    state.ids = [];
    return lastYearInfoAdapter.upsertOne(lastyearInfo, state)
  }),

  on(actionsLastYear.setInfoLastYearSuccess, (state, { lastYearUpdated }) => {
    state.entities = {};
    state.ids = [];
    return lastYearInfoAdapter.upsertOne(lastYearUpdated, state)
  }),
);

export function reducer(state: LastYearInfoState | undefined, action: Action) {
  return lastYearReducer(state, action);
}
