import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { switchMap, tap, catchError } from 'rxjs/operators';
import firebase from 'firebase/app'
import UserCredential = firebase.auth.UserCredential;
import { environment } from '@environment';
import { UserAppService } from './user-app.service';
import { UserInfo } from '@app/pages/profile/models/profile.interface';
import { Store } from '@ngrx/store';
const FIREBASE_ERRORS = {
  'auth/email-already-exists': 'Otro usuario ya está utilizando el correo electrónico proporcionado.',
  'auth/email-already-in-use': 'Otro usuario ya está utilizando el correo electrónico proporcionado.',
  'auth/id-token-expired': 'Su sesión ha expirado',
  'auth/internal-error': 'Se encontró un error inesperado cuando se intentaba procesar la solicitud',
  'auth/wrong-password': 'Correo o contraseña invalidos',
  'auth/user-not-found': 'Correo o contraseña invalidos',
  'auth/too-many-requests': 'Su cuenta ha sido bloqueada temporalmente debido a muchos intentos de incio de sesión',
  'auth/cancelled-popup-request': 'No cierre la ventana de autenticación hasta que la operación haya terminado'
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userData: any;
  private _currentUserSubj$ = new BehaviorSubject<firebase.User>(null);
  currentUser$ = this._currentUserSubj$.asObservable();
  private _token$ = new BehaviorSubject<firebase.auth.IdTokenResult>(null);
  token$ = this._token$.asObservable();

  get currentUser(): firebase.User{
    return this._currentUserSubj$.value;
  }

  isLoggedIn(): Observable<firebase.User> {
    return this.afAuth.authState;
  }

  constructor(
    private afAuth: AngularFireAuth,
    private http: HttpClient,
  ) {
  }

  public checkSession() {
    this.afAuth.authState.pipe(
      switchMap(user => this.setCurrentUser(user))
    ).subscribe(token => this.setToken(token));
  }




  setCurrentUser(user: firebase.User) : Observable<string | null>{
      if(user){
        this._currentUserSubj$.next(user);
        return from(user.getIdToken());
      }
      this._currentUserSubj$.next(null);
      return of(null);
  }

  setToken(token: string) : void {
    if (token) {
      localStorage.setItem('token', JSON.stringify(token));
    } else {
      localStorage.removeItem('token');
    }
  }

  getCurrentUser() : Observable<firebase.User>{
    return from(this.afAuth.currentUser);
  }

  getToken() : Observable<firebase.auth.IdTokenResult>{
    return this.afAuth.idTokenResult;
  }

  login(email: string, password: string): Observable<UserCredential> {
    return from(this.afAuth
      .signInWithEmailAndPassword(email, password)).pipe(catchError(this.handleFireBaseError));
  }

  logOut(): Observable<void> {
    return from(this.afAuth.signOut());
  }

  signUp(email: string, password: string): Observable<UserCredential> {
    return from(this.afAuth
      .createUserWithEmailAndPassword(email, password)).pipe(
        tap(() => this.sendEmailVerification())
      ).pipe(catchError(this.handleFireBaseError));
  }

  handleFireBaseError(error: any){
    const errorM = FIREBASE_ERRORS[error.code] || error
    return throwError(errorM);
  }

  signWithGoogle(): Observable<UserCredential> {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.authLogin(provider).pipe(catchError(this.handleFireBaseError));
  }

  authLogin(provider: firebase.auth.AuthProvider): Observable<UserCredential> {
    return from(this.afAuth.signInWithPopup(provider)).pipe(catchError(this.handleFireBaseError));
  }

  sendEmailVerification() {
    return this.afAuth.user.pipe(catchError(this.handleFireBaseError)).subscribe(
      user => {
        user.sendEmailVerification({ url: environment.appUrl });
      }
    );
  }

  signWithEmail(email: string): Observable<void> {
    return from(this.afAuth.sendSignInLinkToEmail(
      email,
      {
        url: `${environment.appUrl}/auth/confirm`,
        handleCodeInApp: true
      })).pipe(catchError(this.handleFireBaseError));
  }

  confirmEmail(url: string, email: string): Observable<UserCredential | string> {
    if (this.afAuth.isSignInWithEmailLink(url)) {
      return from(this.afAuth.signInWithEmailLink(email, url)).pipe(catchError(this.handleFireBaseError));
    }

    return of('Hubo un error');
  }

  resetPassword(email: string): Observable<void> {
    const settings: firebase.auth.ActionCodeSettings = {
      url: environment.appUrl,
      handleCodeInApp: true,
    };
    return from(this.afAuth.sendPasswordResetEmail(email, settings)).pipe(catchError(this.handleFireBaseError));
  }

  completeProfile(userInfo: UserInfo): Observable<UserInfo>{
    return this.http.post<UserInfo>('/signup', userInfo);
  }

  isUserInfoComplete(): Observable<boolean> {
    return this.currentUser$.pipe(
      switchMap(
        user =>{
         if(user) {
          return  this.http.get<boolean>(`/check_user?email=${user.email}`)
         }

         return of(false);
        }
      )
    );
  }
}
