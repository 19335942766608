import { HttpEvent } from '@angular/common/http';
import { Balance } from '@app/pages/info-contribuyente/components/balance/balance';
import { BalanceCompleto } from '@app/pages/info-contribuyente/components/balance/balance-completo';
import { createAction, props } from '@ngrx/store';


export const GET_BALANCE = '[balance] get balance';
export const GET_BALANCE_SUCCESS = '[balance] get balance success';
export const GET_BALANCE_ERROR = '[balance] get balance with error';

export const GET_BALANCE_IN_PROGRESS = '[balance] get balance in progress';
export const GET_BALANCE_IN_PROGRESS_SUCCESS = '[balance] get balance in progress success';
export const GET_BALANCE_IN_PROGRESS_ERROR = '[balance] get balance in progress with error';

export const SET_BALANCE_INFO = '[balance] set balance save info';
export const SET_BALANCE_INFO_SUCCESS = '[balance] set balance save info success';
export const SET_BALANCE_INFO_ERROR = '[balance] set balance save info with error';

export const SET_DELETE_BALANCE = '[balance] set delete balance';
export const SET_DELETE_BALANCE_SUCCESS = '[balance] set delete balance success';
export const SET_DELETE_BALANCE_ERROR = '[balance] set delete balance with error';

//get Depuracion
export const getBalance = createAction(
  GET_BALANCE,
  props<{ projectId: number }>()
);

export const getBalanceSuccess = createAction(
  GET_BALANCE_SUCCESS,
  props<{ balanceSuccess: BalanceCompleto }>()
);

export const getBalanceError = createAction(
  GET_BALANCE_ERROR,
  props<{ error: string }>()
);

//crate balance
export const setBalance = createAction(
  SET_BALANCE_INFO,
  props<{ projectId: number, balanceForm:Balance[], deleteAnnex?: boolean }>()
);

export const setBalanceSuccess = createAction(
  SET_BALANCE_INFO_SUCCESS,
  props<{ balance:BalanceCompleto }>()
);

export const setBalanceError = createAction(
  SET_BALANCE_INFO_ERROR,
  props<{ error: string }>()
);

//delete balance
export const setDeleteBalance = createAction(
  SET_DELETE_BALANCE,
  props<{ idBalance: number }>()
);

export const setDeleteBalanceSuccess = createAction(
  SET_DELETE_BALANCE_SUCCESS,
  props<{ balanceDeleted: any }>()
);

export const setDeleteBalanceError = createAction(
  SET_DELETE_BALANCE_ERROR,
  props<{ error: string }>()
);
