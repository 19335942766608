import { Validators } from '@angular/forms';
import { CostosContable } from '../../pages/anex-depuracion/model/ganacia-ocasional';
export interface Donacion {
    id:number;
   project:number;
   ctaCostoGasto:string;
   nombreCtaContable:string;
   nit:number;
   razonSocial:string;
   conceptoDonacion:string;
   otroConceptoDonacion:string;
   porcentaje:number;
   valorDonacion:number;
   valorDescuento:number;
   descripcion:string;
   posicionFiscal:string;
}

export interface DonacionDTO {
   idProject?:number,
   listDonacionesPriSec:Donacion[],
   totalesDescuentosTributariosPriSec?:TotalDonacion;
}

export interface DonacionDTOSave {
   id:number;
   project:number;
   ctaCostoGasto:string;
   nombreCtaContable:string;
   nit:number;
   razonSocial:string;
   conceptoDonacion:string;
   otroConceptoDonacion:string;
   porcentaje:number;
   valorDonacion:number;
   valorDescuento:number;
   descripcion:string;
   posicionFiscal:string;
   totalDescuentosTributariosPriSecDTO?:TotalDonacion;
}

export interface DonacionMore {
   donacion: Donacion;
   addMore: boolean;
}

export interface TotalDonacion {
   valorTotalDonaciones:number;
   valorTotalDescuentos:number;
   conCertificacionDeLey:number;
   sinCertificiacionDeLey:number;
   noDescuentoTributario:number;
   descuentoTributario:number;
}

export interface PorcentajesDonacion {
   descripcion:string;
   porcentaje:number
}

export interface ImpuestoExterior{
   id: number,
   pais: string,
   impuestoRentaPaisExterno: number,
   impuestoRentaColombia: number,
   ingreso:CostosContable[],
   costoGasto:CostosContable[],
   impuestoPagadoExterior: number,
   trazabilidadImpuestoExterior: number,
   impuestoPagadoColombia: number,
   trazabilidadImpuestoPagoColombia: number,
   descuentoTributario: number
}

export interface ImpuestoExteriorDTOSave{
   id: number,
   pais: string,
   impuestoRentaPaisExterno: number,
   impuestoRentaColombia: number,
   ingreso:CostosContable[],
   costoGasto:CostosContable[],
   impuestoPagadoExterior: number,
   trazabilidadImpuestoExterior: number,
   impuestoPagadoColombia: number,
   trazabilidadImpuestoPagoColombia: number,
   descuentoTributario: number
   totalDescuentosTributariosSegSecDTO?:TotalImpuestoExterior
}

export interface TotalImpuestoExterior{
   impuestosPagadosExterior: number,
   topeMaximoPagoColombia: number,
   descuentosTributarios: number,
   totalGO:number,
   totalAnexo:number
}

export interface ImpuestoExteriorDTO {
   idProject?:number,
   listDonacionesSegSec:ImpuestoExterior[],
   totalesDescuentosTributariosSegSecDTO?:TotalImpuestoExterior;
}

export interface ImpuestoExteriorMore {
   impuestoExterior: ImpuestoExterior;
   addMore: boolean;
}

export interface Pais {
   pais: string;
}

export interface Tarifa {
   tarifa: number;
   descripcion:string;
}

export interface CalculoDescuento{
    id:number;
		 cuentaContableArt255:number;
		 nombreCuentaContableArt255:string;
		 valoresInversionArt255:number;
		 rentaArt255:number;
       trazabilidadArt255:number;
		 cuentaContableArt256:number;
		 nombreCuentaContableArt256:string;
		 valoresInversionArt256:number;
		 rentaArt256:number;
       trazabilidadArt256:number;
		 donaciones257:number;
       cuentaContableArt257_1: number;
       nombreCuentaContableArt257_1: string;
       valoresInversionArt257_1: number;
       rentaArt257_1: number;
       trazabilidadArt257_1: number;
		 impuestoRentaLiquidaGravable:number;
		 trazabilidadImpuestoRentaLiquidaGravable:number;
		 limitante:number;
		 totalConceptos:number;
		//PARTE B 
		 iva:IvaDescuento[];
		//Parte C 
		 impuestoNetoRenta:number;
       trazabilidadImpuestoNetoRenta:number;
		 valorInversion:number;
		 porcentajeLimitanteInversion:number;
		 limitanteInversion:number;
		 porcentajeLimitanteImpuestoRenta:number;
		 limitanteImpuestoRenta:number;
		//PARTE D
		 impauestoIndustriaComercioArt115:number;
		 valorDescuentoArt115:number;	
		//PARTE E
		otrosDescuentosTributarios:otrosDescuentos[];

}

export interface IvaDescuento{
    id:number;
	 cuentaContable:string;
	 nombreCuentaContable:string;
	 valorCuentaContable:number;
	 descuentoTributario:number;
	 vigenciaFiscalComoDescuento:number;
    trazabilidadDescuentoTributario:number
}

export interface otrosDescuentos{
    id:number;
	 concepto:string;
	 cuentaContable:string;
	 nombreCuentaContable:string;
	 valorCuentaContable:number;
	 valorDescuentoTributario:number;
}

export interface TotalCalculoDescuento{
    id:number;
	 totalDescuentoTributarioDelPeriodo:number;
	 totalDescuentoImpuestoExterior:number;
	 totalIva:number;
	 totalDescuentoTributarioAniosAnteriores:number;
	 totalArt115:number;
    totalArt104:number;
	 totalOtrosDescuentosTributarios:number;
	 totalDescuentoTributario:number;
    descuntosTributariosNoTomados:boolean;
    descuentosTributariosICA:boolean;
}

export interface TotalFinal{
   totalImpuestoRentasLiquidas:number;
	descTributariosVigenciaFiscal:number;
	totalDescTributariosAniosPosteriores:number;
}

export interface CalculoDescuentoDTO {
   idProject?:number,
   descuentos:CalculoDescuento[],
   total?:TotalCalculoDescuento;
   cuaSec?:TotalFinal
}

export interface CalculoDescuentoDTOSave{
   id:number;
      cuentaContableArt255:number;
      nombreCuentaContableArt255:string;
      valoresInversionArt255:number;
      rentaArt255:number;
      trazabilidadArt255:number;
      cuentaContableArt256:number;
      nombreCuentaContableArt256:string;
      valoresInversionArt256:number;
      rentaArt256:number;
      trazabilidadArt256:number;
      donaciones257:number;
      cuentaContableArt257_1: number;
      nombreCuentaContableArt257_1: string;
      valoresInversionArt257_1: number;
      rentaArt257_1: number;
      trazabilidadArt257_1: number;
      impuestoRentaLiquidaGravable:number;
      trazabilidadImpuestoRentaLiquidaGravable:number;
      limitante:number;
      totalConceptos:number;
     //PARTE B 
      iva:IvaDescuento[];
     //Parte C 
      impuestoNetoRenta:number;
      trazabilidadImpuestoNetoRenta:number;
      valorInversion:number;
      porcentajeLimitanteInversion:number;
      limitanteInversion:number;
      porcentajeLimitanteImpuestoRenta:number;
      limitanteImpuestoRenta:number;
     //PARTE D
      impauestoIndustriaComercioArt115:number;
      valorDescuentoArt115:number;	
     //PARTE E
     otrosDescuentosTributarios:otrosDescuentos[];
     totales?:TotalCalculoDescuento;
     cuaSec?:TotalFinal
}

export const OTRO_CONCEPTO: PorcentajesDonacion = {porcentaje: 0, descripcion: 'Otro concepto'};

export const INIT_DONACION: Donacion = {
   conceptoDonacion:"",
   ctaCostoGasto:"",
   descripcion:"",
   id:0,
   nit:null,
   nombreCtaContable:"",
   otroConceptoDonacion:"",
   porcentaje:0,
   posicionFiscal:"",
   project:0,
   razonSocial:"",
   valorDescuento:0,
   valorDonacion:0
};

export const INIT_IMPUESTO_EXTERIOR: ImpuestoExterior = {
   costoGasto:[],
   descuentoTributario:0,
   id:0,
   impuestoPagadoColombia:0,
   impuestoPagadoExterior:0,
   impuestoRentaColombia:0,
   impuestoRentaPaisExterno:0,
   ingreso:[],
   pais:"",
   trazabilidadImpuestoExterior:0,
   trazabilidadImpuestoPagoColombia:0
};

export const INIT_CALCULO_DESCUENTO: CalculoDescuento = {
   cuentaContableArt255:0,
   cuentaContableArt256:0,
   donaciones257:0,
   id:0,
   impauestoIndustriaComercioArt115:0,
   impuestoNetoRenta:0,
   trazabilidadImpuestoNetoRenta:0,
   impuestoRentaLiquidaGravable:0,
   iva:[],
   limitante:0,
   limitanteImpuestoRenta:0,
   limitanteInversion:0,
   nombreCuentaContableArt255:"",
   nombreCuentaContableArt256:"",
   trazabilidadArt255:0,
   trazabilidadArt256:0,
   otrosDescuentosTributarios:[],
   porcentajeLimitanteImpuestoRenta:50,
   porcentajeLimitanteInversion:40,
   rentaArt255:0,
   rentaArt256:0,
   totalConceptos:0,
   trazabilidadImpuestoRentaLiquidaGravable:0,
   valorDescuentoArt115:0,
   valorInversion:0,
   valoresInversionArt255:0,
   valoresInversionArt256:0,
   cuentaContableArt257_1: 0,
   nombreCuentaContableArt257_1: "",
   valoresInversionArt257_1: 0,
   rentaArt257_1: 0,
   trazabilidadArt257_1: 0
};

export const INIT_TOTAL_CALCULO_DESCUENTO: TotalCalculoDescuento = {
   id:0,
   totalArt115:0,
   totalDescuentoImpuestoExterior:0,
   totalDescuentoTributario:0,
   totalDescuentoTributarioAniosAnteriores:0,
   totalDescuentoTributarioDelPeriodo:0,
   totalIva:0,
   totalOtrosDescuentosTributarios:0,
   descuntosTributariosNoTomados:false,
   totalArt104:0,
   descuentosTributariosICA:false
}
export const INIT_TOTAL_GENERAL: TotalFinal = {
   descTributariosVigenciaFiscal:0,
   totalDescTributariosAniosPosteriores:0,
   totalImpuestoRentasLiquidas:0
}
export const CTR_IVA = {
   id:null,
	 cuentaContable:[undefined, [Validators.required]],
	 nombreCuentaContable:[{ value: '', disabled: true }],
	 valorCuentaContable:[0, [Validators.required]],
	 descuentoTributario:[0, [Validators.required]],
	 vigenciaFiscalComoDescuento:[0, [Validators.required]],
    trazabilidadDescuentoTributario:[0]
};
export const CTR_OTROS_DESCUENTOS = {
   id:null,
	 cuentaContable:[undefined, [Validators.required]],
	 nombreCuentaContable:[{ value: '', disabled: true }],
	 valorCuentaContable:[0, [Validators.required]],
	 valorDescuentoTributario:[0, [Validators.required]],
	 concepto:["", [Validators.required]]
};

export interface CalculusDtResponseModel {
   id: number;
   tipo: string;
   totalDescuento: number;
   calculoAplicativo: number;
   calculoUsuario: number;
   status: boolean;
   faltaAplicativo: number;
   faltaUsuario: number;
   aplicativo: boolean;
}