import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListProyectosService } from '@app/pages/proyectos/services/list-proyectos.service';
import { EsalInversionesA } from '@app/store/models/esal.model';
import { EMPTY, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EsalService {
  constructor(
    private http: HttpClient,
    private projecSrv: ListProyectosService
  ) {}
  saveEsalInversionesA(
    objEsalInver: EsalInversionesA
  ): Observable<EsalInversionesA> {
    return this.projecSrv.currentProject$.pipe(
      switchMap((project) => {
        if (project) {
          return this.http.post<any>(
            `/renta_exenta/pactoAndino?projectId=${project.id}`,
            objEsalInver
          );
        }
        return EMPTY;
      })
    );
  }
  getAllInversiones(idProject: number) {
    return this.http.get(`/esal/InversionesAfectuadas/?projectId=${idProject}`);
  }
  getAllTableInversiones(idProject: number) {
    return this.http.get(
      `/esal/InversionesAfectuadasTotal/?projectId=${idProject}`
    );
  }
  getAllTableRentaLiquida(idProject: number) {
    return this.http.get(`/esal/InversionesLiquidas/?projectId=${idProject}`);
  }

  getAllTableRentaGrabable(idProject: number) {
    return this.http.get(
      `/esal/InversionesLiquidasGravables/?projectId=${idProject}`
    );
  }
  saveData(idProject: number, nameSend: string, data: any) {
    return this.http.post(`/esal/${nameSend}/?projectId=${idProject}`, data);
  }
  getByOneData(idProyect: number, esal: number) {
    return this.http.get(`/esal/?projectId=${idProyect}&esalId=${esal}`);
  }
  deleteByOneData(idProyect: number, esal: number) {
    return this.http.delete(`/esal/?projectId=${idProyect}&esalId=${esal}`);
  }
}
