import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { selectCurrentRoute } from '@app/reducers';
import { setAnnexedStatus } from '@app/store/actions';
import { AnnexedStatus } from '@app/store/models/project-status.model';
import { selectAnnexedStatusById } from '@app/store/selectors/annexed-status.selectors';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { routeAnex, ROUTES_ANEX } from './annexed';

export interface ButtonTool {
  idButton: string,
  class: string,
  icon: string,
  message: string
}

@Component({
  selector: 'app-select-status-annexed',
  templateUrl: './select-status-annexed.component.html',
  styleUrls: ['./select-status-annexed.component.scss']
})
export class SelectStatusComponent implements OnInit{

  public statesGroup: any[] = [
    {
      name: 'Por tramitar',
      value: 'TRAMITAR',
      color: '#a6a6a6',
      class: 'gray'
    },
    {
      name: 'En proceso',
      value: 'PROCESO',
      color: '#F2CF3B',
      class: 'yellow'
    },
    {
      name: 'Terminado',
      value: 'TERMINADO',
      color: '#19BF71',
      class: 'green'
    },
    {
      name: 'No Aplica',
      value: 'NO_APLICA',
      color: '#000',
      class: 'black'
    }
  ];

  @Input() but: ButtonTool;
  @Input() eventStartStatus$: Observable<boolean>;
  public butSelect: string;
  public stateProject: string = 'por_tramitar';
  public projectId: number;

  public routeSubs$: Observable<any>;
  public annexedStatus$: Observable<string>;
  public route: string;

  public idAnnexed: number;
  public annex: string;
  public annexTab: string;

  constructor(
    private store: Store<any>
  ){
    this.connectToRoute();
  }

  ngOnInit(): void {
    this.connectToRoute();
    this.checkStartStatus()
  }
// this.statusService.getAllStatusTabAnnexed(projectId)
  checkStartStatus(){
    this.eventStartStatus$.subscribe((isChange: boolean) => {
      console.log("isChange",isChange)
      if(isChange){
        this.annexedStatus$.subscribe((status: string) => {
          console.log('entre a checkStartStatus switchCase => ', status);
          switch (status) {
            case 'TRAMITAR':
              this.setStatusAnnexed('PROCESO');
              break;
          }
        })
      }
    })
  }

  private connectToRoute(){

    this.routeSubs$ = this.store.pipe(
      select(selectCurrentRoute),
      map((route) => route.url)
    );

    this.routeSubs$.subscribe((route) => {
      this.annexedStatus$ = this.store.pipe(
        select(selectAnnexedStatusById(this.idAnnexed)),
        map((annex: AnnexedStatus) => annex?.status)
      );

      const url = route.split('/');
      this.route = url[3] + url[4];
      const urlFilter = url[3] + '/' + url[4];
      this.projectId = Number(url[2]);
      this.createStatusFormat(urlFilter);

    })
  }

  private createStatusFormat(url: string){
    ROUTES_ANEX.map((routing:routeAnex) => {
      if(routing.route !== '' && url === routing.route){
        this.idAnnexed = routing.id;
        this.annex = routing.annexed;
        this.annexTab = routing.tab
      }
    });
  }

 public setStatusAnnexed(status: string){
    const statusSend: AnnexedStatus = {
      id: this.idAnnexed,
      projectId: this.projectId,
      annexed: this.annex,
      tab: this.annexTab,
      status: status
    }

    this.store.dispatch(setAnnexedStatus({ annexedStatus: statusSend}))
  }



  public stateColor(state: string): string {
    let color: string;
    switch (state) {
      case 'TRAMITAR':
        color = '#a6a6a6'
        break;

      case 'PROCESO':
        color = '#F2CF3B'
        break;

      case 'TERMINADO':
        color = '#19BF71'
        break;

      case 'NO_APLICA':
        color = '#000'
        break;
    }

    return color;
  }

  public stateText(state: string): string {
    let status: string;
    switch (state) {
      case 'TRAMITAR':
        status = 'Por tramitar'
        break;

      case 'PROCESO':
        status = 'En proceso'
        break;

      case 'TERMINADO':
        status = 'Terminado'
        break;

      case 'NO_APLICA':
        status = 'No aplica'
        break;
    }

    return status;
  }

}
