import { createSelector } from '@ngrx/store';
import { getProjectsModuleState } from '../reducers/index';
import { toArray } from 'lodash';


export const selectAjusteDepuracionList = createSelector(
  getProjectsModuleState,
  state => state.ajusteDepuracion.entities
);

export const selectAjusteDepuracionById = (id: number) =>
  createSelector(getProjectsModuleState, state => {
  const data: any = state.ajusteDepuracion.entities;
  if(data) {
    return toArray(data).find(item => {
      console.log('el item en selector ajuystesdep => ', item)
      return item.projectId === id;
    });
  } else {
    return {};
  }
});
