import { ERIstore, RegistroERI } from '@app/pages/formato2516/components/eri-renta-liquida/eri';
import { createAction, props } from '@ngrx/store';

export const GET_ERI = '[eri] get eri';
export const GET_ERI_SUCCESS = '[eri] get eri success';
export const GET_ERI_ERROR = '[eri] get eri with error';

// export const UPDATE_ERI_INFO = '[eri] update eri info';
// export const UPDATE_ERI_INFO_SUCCESS = '[eri] update eri info success';
// export const UPDATE_ERI_INFO_ERROR = '[eri] update eri info with error';

export const UPDATE_ONE_ERI_INFO = '[eri] update one eri info';
export const UPDATE_ONE_ERI_INFO_SUCCESS = '[eri] update one eri info success';
export const UPDATE_ONE_ERI_INFO_ERROR = '[eri] update one eri info with error';

export const UPDATE_MANY_ERI_INFO = '[eri] update many eri info';
export const UPDATE_MANY_ERI_INFO_SUCCESS = '[eri] update many eri info success';
export const UPDATE_MANY_ERI_INFO_ERROR = '[eri] update many eri info with error';

export const SET_DELETE_ERI = '[eri] set delete eri';
export const SET_DELETE_ERI_SUCCESS = '[eri] set delete eri success';
export const SET_DELETE_ERI_ERROR = '[eri] set delete eri with error';

//get ERI
export const getEri = createAction(
  GET_ERI,
  props<{ projectId: number }>()
);

export const getEriSuccess = createAction(
  GET_ERI_SUCCESS,
  props<{ eriSuccess: ERIstore }>()
);

export const getEriError = createAction(
  GET_ERI_ERROR,
  props<{ error: string }>()
);
//update One ERI
export const updateOneEri = createAction(
  UPDATE_ONE_ERI_INFO,
  props<{ eriOneForm: RegistroERI[],id:number }>()
);

export const updateOneEriSuccess = createAction(
  UPDATE_ONE_ERI_INFO_SUCCESS,
  props<{ eriOneSuccess: ERIstore }>()
);

export const updateOneEriError = createAction(
  UPDATE_ONE_ERI_INFO_ERROR,
  props<{ error: string }>()
);

//update Many ERI

export const updateManyEri = createAction(
  UPDATE_MANY_ERI_INFO,
  props<{ eriManyForm:RegistroERI[],id:number }>()
);

export const updateManyEriSuccess = createAction(
  UPDATE_MANY_ERI_INFO_SUCCESS,
  props<{ eriManySuccess: ERIstore }>()
);

export const updateManyEriError = createAction(
  UPDATE_MANY_ERI_INFO_ERROR,
  props<{ error: string }>()
);

//delete ERI
export const setDeleteEri = createAction(
  SET_DELETE_ERI,
  props<{ projectId: number }>()
);

export const setDeleteEriSuccess = createAction(
  SET_DELETE_ERI_SUCCESS,
  props<{ projectId: number }>()
);

export const setDeleteEriError = createAction(
  SET_DELETE_ERI_ERROR,
  props<{ error: string }>()
);
