import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-title-balance',
  templateUrl: './title-balance.component.html',
  styleUrls: ['./title-balance.component.scss']
})
export class TitleBalanceComponent {

  @Input() title: string = '';
  @Input() icon: string = '';
  @Input() buttons = [];
  @Input() sticky: boolean = true;
  @Input() onlyIcons = false;
  @Input() modal = false;
  @Input() backColor: string = '#e5e5e5';
  //hedaer list
  @Input() activos: number = 0;
  @Input() pasivos: number = 0;
  @Input() patrimonio: number = 0;
  @Input() ingresos: number = 0;
  @Input() gastos: number = 0;
  @Input() costos: number = 0;
  @Input() costosProduccion: number = 0;
  @Input() cuentasDeudoras: number = 0;
  @Input() cuentasAcreedoras: number = 0;

  @Output() clicked: EventEmitter<string>  = new EventEmitter<string>();


  constructor() { }

  evClick(event: string){
    this.clicked.emit(event);
  }

}
