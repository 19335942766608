<app-modal-cabecera-grl
[title]="'Resumen'"
[iconTitle]="'user_line_bold'">
</app-modal-cabecera-grl>

    <mat-dialog-content>

        <p-table [value]="data | async" scrollHeight="200px" styleClass="p-datatable-gridlines">
            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="width:50px">
                    <col style="width:200px">
                    <col style="width:200px">
                    <col style="width:200px">
                    <col style="width:200px">
                    <col style="width:200px">
                    <col style="width:200px">
                </colgroup>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th></th>
                    <th></th>
                    <th>Valor contable </th>
                    <th>Efectos de conversion </th>
                    <th>Menor Fiscal </th>
                    <th>Mayor Fiscal </th>
                    <th>Saldo Fiscal </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr [ngClass]="{'row-accessories': rowData.type == null}">
                    <td>
                        {{rowData.type}}
                    </td>
                    <td>
                        {{rowData.detalle}}
                    </td>
                    <td>
                        {{rowData.totalValorContable | currency}}
                    </td>
                    <td>
                        {{rowData.totalEfConversion | currency}}
                    </td>
                    <td>
                        {{rowData.totalCredito | currency}}
                    </td>
                    <td>
                        {{rowData.totalDebito | currency}}
                    </td>
                    <td>
                        {{rowData.totalValorFiscal |
                        currency}}
                    </td>
                </tr>
            </ng-template>
        </p-table>

    </mat-dialog-content>
    
    <mat-dialog-actions align="end">
        <app-button
        [icon]="'check_solid'"
        [text]="'Cerrar'"
        [idButton]="'cerrar'"
        [clase]="'btn_primary'"
        (clicker)="$event === 'cerrar' ? this.cerrar(): ''">
        </app-button>
    </mat-dialog-actions>
