import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifyService } from '@app/shared/services/notify.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { createElement } from '@syncfusion/ej2-base';
import { Observable, Subscription } from 'rxjs';
import * as XLSX from 'xlsx';
import { ModalGrlComponent } from '../modal-grl/modal-grl.component';
import { ModalUploadBalanceComponent } from '../modal-upload-balance/modal-upload-balance.component';
/**
 * Componente que muestra la interfaz de carga de archivos excel
 */
@Component({
  selector: 'app-carga-excel',
  templateUrl: './carga-excel.component.html',
  styleUrls: ['./carga-excel.component.scss']
})
export class CargaExcelComponent implements OnInit, OnDestroy {

  subs: Subscription;
  /**
 * Datos que se obtienen del excel
 */
  @Output() datos = new EventEmitter<Object[]>();
  /**
 * Nombre del boton de cargaz
 */
  @Input() nombre: string;
  @Input() nombre_title: string;
  /**
 * Muestra el componente de carga
 */
  @Input() showLoad$: Observable<boolean>;
  /**
 * Componente de carga
 */
  @ViewChild('defaultupload')
  public uploadObj: UploaderComponent;
  /**
 * Hoja de trabajo que se extrae del excel
 */
  public workbook;
  /**
 * Lista de botones de carga
 */
  public buttons: Object;
  /**
 * Muestra el dialogo de eleccion de hoja del excel cargado
 */
  visibleHoja: boolean;
  /**
 * Lista de hojas del excel cargado
 */
  hojas: string[] = [];
  /**
 * formulario del componente
 */
  form = this.fb.group({
    hoja: ['', Validators.required],
  })
/**
 * Constructor
 * @param fb construye el formulario
 */
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinnerService: SpinnerService,
    private notifyService: NotifyService,
    private dialog: MatDialog
  ) { }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
/**
 * Inicializa el componente
 */
  ngOnInit(): void {

    this.subs = this.showLoad$.subscribe((data: boolean) => {
      this.workbook = null;
      this.hojas = [];
      this.form = null;
      this.form = this.fb.group({
        hoja: ['', Validators.required],
      });
      if (data) {
        document.getElementsByClassName('e-file-select-wrap')[0]
          .querySelector('button').click();
      }
    });

  }

  public onFileRemove(args): void {
    args.cancel = true;
  }
/**
 * Obtiene las hojas del archivo cargado
 * @param file archivo cargado
 */
  obtenerDatos(file) {
    this.workbook = null;
    this.hojas = [];
    let reader = new FileReader();
    reader.onload = (e) => {
      let data = (<any>e.target).result;
      this.workbook = XLSX.read(data, {
        type: 'binary'
      });
      this.workbook.SheetNames.forEach((function (sheetName) {
        this.hojas.push(sheetName);
      }).bind(this), this);
      this.selectHoja(this.hojas)
    };

    reader.onerror = function (ex) {
      console.log(ex);
    };
    reader.readAsBinaryString(file);
  };

  public selectHoja(hojas: any){
    this.dialog.open(ModalGrlComponent, {
      width: '60%',
      data: {
        name: this.nombre_title,
        buttons: [
          {
            icon: 'cancel_bold',
            text: 'Cancelar',
            id: 'back',
            clase: 'btn_danger',
          },
          {
            icon: 'check_solid',
            text: 'Siguiente',
            id: 'next',
            clase: 'btn_success'
          }
        ],
        select: {
          label: 'Selecciona hoja',
          type: 'other',
          options: hojas
        }
      }
    }).afterClosed().subscribe((click: any) => {
      //aqui empieza la carga real
      if(click.emiter === 'select' && click.data !== ''){
        this.cargarDatos(click.data)
      } else {
        this.notifyService.info('No subiste Balance!', 'Debes subir un balance para continuar', 4000,
          {
            text: 'Entiendo',
            orientation: 'izq'
          });
      }

    })
  }
/**
 * Carga los datos dependiendo de la hoja elegida
 */
 private cargarDatos(hoja) {
  if (hoja) {
    let XL_row_object = XLSX.utils.sheet_to_json(this.workbook.Sheets[hoja]);
    let json_object = JSON.stringify(XL_row_object);
    const datos = JSON.parse(json_object);
    if(datos){
      this.datos.emit(datos);
      this.uploadObj.clearAll()
    }

  }
  else {
    this.notifyService.error('Error en hoja!', 'Debes seleccionar una Hoja' , 4000,
    {
      text: 'Entiendo',
      orientation: 'izq'
    });
  }
}

 /**
 * Guarda el archivo cargado
 */

  public onSuccess(args: any): void {
    let files = args.target.files; // FileList object
    this.obtenerDatos(files[0]);
    this.uploadObj.clearAll()
  }

  public onUploadFailure(args: any): void {
    alert('File failed to upload');
  }

  /**
 * Verifica si se eligio alguna hoja
 * @param controlName nombre del control de la lista de hojas
 * @returns validez
 */
  isInvalid(controlName: string): boolean {
    return this.form.get(controlName).errors && this.form.get(controlName).touched;
  }

}
