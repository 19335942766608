import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  MSG_DURATION,
  NotifyService,
} from '@app/shared/services/notify.service';
import {
  getSeccion1Gmf,
  getSeccion1GmfError,
  getSeccion1GmfSuccess,
  setDeleteSeccion1Gmf,
  setDeleteSeccion1GmfError,
  setDeleteSeccion1GmfSuccess,
  setSeccion1Gmf,
  setSeccion1GmfError,
  setSeccion1GmfSuccess,
} from '@store/actions/seccion1-gmf.actions';
import { CuatroxMilGmfService } from '@app/shared/services/cuatrox-mil-gmf.service';
import { Seccion1GmfStore } from '../models/cuatrox-mil-gmf.model';

@Injectable()
export class Seccion1GmfEffects {
  getSeccion1Gmf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSeccion1Gmf),
      switchMap(({ projectId }) => {
        return this.cuatroxMilGmfService.getSeccion1Gmf(projectId).pipe(
          map((seccion1Gmf) => {
            let seccion1GmfSuccess: Seccion1GmfStore = {
              idProject: projectId,
              ...seccion1Gmf,
            };
            return getSeccion1GmfSuccess({ seccion1GmfSuccess });
          }),
          catchError((error) => {
            this.notify.error('Algo salio mal!', JSON.stringify(error), 5000, {
              text: 'Entendido',
              orientation: 'izq',
            });
            return of(getSeccion1GmfError({ error }));
          })
        );
      })
    )
  );

  saveSeccion1Gmf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setSeccion1Gmf),
      switchMap(({ seccion1GmfForm, projectId }) => {
        return this.cuatroxMilGmfService
          .saveSeccion1Gmf(projectId, seccion1GmfForm)
          .pipe(
            map((seccion1Gmf) => {
               let seccion1GmfForm: Seccion1GmfStore = {
              idProject: projectId,
              ...seccion1Gmf,
            };
              this.notify.success(
                '',
                'Guardado de registros exitoso',
                MSG_DURATION
              );
              return setSeccion1GmfSuccess({ seccion1GmfForm });
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setSeccion1GmfError({ error }));
            })
          );
      })
    )
  );

  deleteSeccion1Gmf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDeleteSeccion1Gmf),
      switchMap(({ projectId, objSeccion1Gmf }) => {
        return this.cuatroxMilGmfService
          .deleteSeccion1Gmf(projectId, objSeccion1Gmf)
          .pipe(
            map((seccion1Gmf) => {
              this.notify.success(
                '',
                'Borrado de registros exitoso',
                MSG_DURATION
              );
              return setDeleteSeccion1GmfSuccess({ seccion1Gmf });
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setDeleteSeccion1GmfError({ error }));
            })
          );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private cuatroxMilGmfService: CuatroxMilGmfService,
    private notify: NotifyService
  ) {}
}
