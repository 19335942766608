import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProjectStore } from '@app/store/models';
import { Project } from '@proyectos/proyecto';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
/**
 * Clase para el manejo de servicios de proyectos
 */
@Injectable({
  providedIn: 'root'
})
export class ListProyectosService {
  /**
 * Lista de proyectos (behavior)
 */
  private _proyectos$ = new BehaviorSubject<Project[]>([]);
  /**
   * Lista de proyectos (observable)
   */
  proyectos$ = this._proyectos$.asObservable();

  private _currentProjectAction$ = new BehaviorSubject<Project>(null);
  currentProject$ = this._currentProjectAction$.asObservable();
  _currentProject : Project = null;

  set currentProject(project : Project) {
    this._currentProject = project;
    this._currentProjectAction$.next(project);
  }

  get currentProject() : Project{
    return this._currentProject;
  }

  /**
   * Cliente para realizar llamados al backend
   *
   * @param http Cliente Http para llamar a backend
   */
  constructor(private http: HttpClient) {

  }
  /**
   * Funcion para agregar proyectos
   *
   * @param {Project} proyecto  Proyecto
   *
   * @returns Confirmacion de creacion de proyecto
   */
  agregarProyecto(proyecto: Project): void {
    this._proyectos$.next([...this._proyectos$.value, proyecto])
  }
  /**
   * Funcion para crea proyectos (backend)
   *
   * @param {Project} proyecto  Proyecto
   *
   * @returns Confirmacion de creacion de proyecto
   */
  crearProyecto(proyecto: Project): Observable<Object> {
    return this.http.post("/project", proyecto);
  }
  /**
   * Funcion para obtener lista de proyectos
   *
   * @param {number} idEmpresa  Id de la organizacion del usuario
   *
   * @returns Lista de proyectos dependiendo de la organiz  acion
   */
  getProyectos(idEmpresa: number): Observable<Project[]> {
    return this.http.get<Project[]>(`/projects`);
  }

  conteoBalance(id:number):Observable<number>{
    return this.http.get<number>(`/balance/conteo?id=${id}`);
  }
  getFilterProyectByName (name: string) :Observable<ProjectStore[]>{
    return this.http.get<ProjectStore[]>(`/projects/search?projectName=${name}`);
  }
  getFilterProyectByNit (nit: string) :Observable<ProjectStore[]>{
    return this.http.get<ProjectStore[]>(`/projects/search?identificationNumber=${nit}`);
  }
  // getProjectsBD(user_id:)
}
