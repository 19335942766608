import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import {EMPTY, Observable, BehaviorSubject, combineLatest, of} from 'rxjs';
import {catchError, debounceTime, map, switchMap, tap} from 'rxjs/operators';

import { CTR_COSTO_CONTABLE, Option,CTR_COSTO_CONTABLE_ESAL, CTR_COSTO_CONTABLE_ECE} from '../model/ganacia-ocasional';
import {CalculusResponseModel, ConvenioExentaList, ConvenioExentaResponse, DEFAULT_CONCEPTO, EceModel, RentaExenta, RentaExentaList, RentaExentaMore, RentaList, TOTALES_RENTA, TotalesChcModel} from '../model/renta-exenta';
import { ListProyectosService } from '@proyectos/services/list-proyectos.service';

@Injectable({
  providedIn: 'root'
})
export class RentaExentaService {

  private ajusteMsj$ = new BehaviorSubject(false);
  showAjusteMsj$ = this.ajusteMsj$.asObservable();

  private conceptoList$ = new BehaviorSubject<Option[]>([]);
  conceptoListAction$ = this.conceptoList$.asObservable();

  private currentConcepto$ = new BehaviorSubject<string>(DEFAULT_CONCEPTO.name);
  selectedConcep: string = DEFAULT_CONCEPTO.name;
  currentConceptoAction$ = this.currentConcepto$.asObservable();

  operacionesWithConcepto$ = combineLatest([this.getConceptos(), this.getOperColombiaList()]).pipe(
    map(([conceptos, rentaLists]) =>
      rentaLists.filter(
        renta => renta.concepto = conceptos.find(concpeto => renta.concepto === concpeto.name).description
      ))
  );

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private projecSrv: ListProyectosService
  ) { }

  getTotales(): Observable<RentaExentaList> {
    return combineLatest([this.currentConceptoAction$, this.projecSrv.currentProject$]).pipe(
      debounceTime(1000),
      switchMap(([concepto, p]) => {
        if (p) {
          return this.getTotalesOrDefault(p, concepto);
        }
        return of(TOTALES_RENTA);
      })
    );
  }

  private getTotalesOrDefault(p, concepto): Observable<RentaExentaList> {
    return this.http.get<RentaExentaList>(`/renta_exenta/totalRentaExenta?projectId=${p.id}&concepto=${concepto}`).pipe(
      catchError(() => of(TOTALES_RENTA))
    );
  }

  changeConcepto(concepto: string): void{
    this.selectedConcep = concepto;
    this.currentConcepto$.next(concepto);
  }

  getRentaList(): Observable<RentaList[]> {
    return this.projecSrv.currentProject$.pipe(
      debounceTime(1000),
      switchMap(p => {
        if (p) { return this.http.get<RentaList[]>(`/renta_exenta/listPactoAndino?projectId=${p.id}`).pipe(
          tap(rentas => rentas.length > 0 ? this.ajusteMsj$.next(true) : '')
        );
        }
        return EMPTY;
      })
    );
  }

  getOperColombiaList(): Observable<RentaList[]> {
    return this.projecSrv.currentProject$.pipe(
      debounceTime(600),
      switchMap(p => {
        if (p) { return this.http.get<RentaList[]>(`/renta_exenta/listOperacionColombia?projectId=${p.id}`).pipe(
          tap(rentas => rentas.length > 0 ? this.ajusteMsj$.next(true) : '')
        );
        }
        return EMPTY;
      })
    );
  }

  getRentaExenta(id: number): Observable<RentaExenta>{
    return this.projecSrv.currentProject$.pipe(
      switchMap(p => {
        if (p) { return this.http.get<RentaExenta>(`/renta_exenta?rentaExentaId=${id}&projectId=${p.id}`); }
        return EMPTY;
      })
    );
  }

  getConceptos(): Observable<Option[]> {
    return this.http.get<Option[]>('/options/conceptoRentaExenta').pipe(
      tap(conceptos => this.conceptoList$.next(conceptos)),
      map(conceptos => {
        conceptos.unshift(DEFAULT_CONCEPTO);
        return conceptos;
      })
    );
  }

  saveRenta(renta: RentaExenta): Observable<RentaExenta>{
    return this.projecSrv.currentProject$.pipe(
      switchMap(
        project => {
          if (project) { return this.http.post<RentaExenta>(`/renta_exenta/pactoAndino?projectId=${project.id}`, renta); }
          return EMPTY;
        }
      )
    );
  }

  saveOperColombia(renta: RentaExenta): Observable<RentaExenta>{
    return this.projecSrv.currentProject$.pipe(
      switchMap(
        project => {
          if (project) { return this.http.post<RentaExenta>(`/renta_exenta/operacionColombia?projectId=${project.id}`, renta); }
          return EMPTY;
        }
      )
    );
  }

  hideAjusteMsj(): void {
    this.ajusteMsj$.next(false);
  }

  getRentaForm(renta: RentaExenta): FormGroup {
    const form = new FormGroup({
      id: this.fb.control(renta.id),
      concepto: this.fb.control(renta.concepto, [Validators.required]),
      costos: this.fb.array([]),
      gastos: this.fb.array([]),
      ingresos: this.fb.array([]),
      otroConcepto: this.fb.control(renta.otroConcepto),
      pais: this.fb.control(renta.pais, [Validators.required]),
      totalIngreso: this.fb.control(0),
      totalCosto: this.fb.control(0),
      totalGasto: this.fb.control(0),
      convenio: this.fb.control(renta?.convenio || '')
    });

    form?.get('totalIngreso').disable();
    form?.get('totalCosto').disable();
    form?.get('totalGasto').disable();

    let totalIngreso = 0;
    let totalCosto = 0;
    let totalGasto = 0;
    renta.ingresos?.forEach( g => {
      const ctr = this.fb.group({...CTR_COSTO_CONTABLE});
      totalIngreso += g.valor;
      ctr.patchValue(g);
      (form.get('ingresos') as FormArray).push(ctr);
    });

    renta.costos?.forEach( g => {
      const ctr = this.fb.group({...CTR_COSTO_CONTABLE});
      totalCosto += g.valor;
      ctr.patchValue(g);
      (form.get('costos') as FormArray).push(ctr);
    });

    renta.gastos?.forEach( g => {
      const ctr = this.fb.group({...CTR_COSTO_CONTABLE});
      totalGasto += g.valor;
      ctr.patchValue(g);
      (form.get('gastos') as FormArray).push(ctr);
    });

    form?.get('totalIngreso').setValue(totalIngreso);
    form?.get('totalCosto').setValue(totalCosto);
    form?.get('totalGasto').setValue(totalGasto);

    return form;
  }
  getRentaFormEsal(renta: RentaExenta): FormGroup {
    const form = new FormGroup({
      id: this.fb.control(renta.id),
      concepto: this.fb.control(renta.concepto, [Validators.required]),
      costos: this.fb.array([]),
      gastos: this.fb.array([]),
      ingresos: this.fb.array([]),
      otroConcepto: this.fb.control(renta.otroConcepto),
      pais: this.fb.control(renta.pais, [Validators.required]),
      totalIngreso: this.fb.control(0),
      totalCosto: this.fb.control(0),
      totalGasto: this.fb.control(0),
      convenio: this.fb.control(renta?.convenio || '')
    });

    form?.get('totalIngreso').disable();
    form?.get('totalCosto').disable();
    form?.get('totalGasto').disable();

    let totalIngreso = 0;
    let totalCosto = 0;
    let totalGasto = 0;
    renta.ingresos?.forEach( g => {
      const ctr = this.fb.group({...CTR_COSTO_CONTABLE_ESAL});
      totalIngreso += g.valor;
      ctr.patchValue(g);
      (form.get('ingresos') as FormArray).push(ctr);
    });

    renta.costos?.forEach( g => {
      const ctr = this.fb.group({...CTR_COSTO_CONTABLE_ESAL});
      totalCosto += g.valor;
      ctr.patchValue(g);
      (form.get('costos') as FormArray).push(ctr);
    });

    renta.gastos?.forEach( g => {
      const ctr = this.fb.group({...CTR_COSTO_CONTABLE_ESAL});
      totalGasto += g.valor;
      ctr.patchValue(g);
      (form.get('gastos') as FormArray).push(ctr);
    });

    form?.get('totalIngreso').setValue(totalIngreso);
    form?.get('totalCosto').setValue(totalCosto);
    form?.get('totalGasto').setValue(totalGasto);

    return form;
  }

  getRentaEceForm(renta: EceModel): FormGroup {
    const form = new FormGroup({
      id : this.fb.control(renta.id),
      pais: this.fb.control(renta.pais, [Validators.required]),
      concepto: this.fb.control(renta.concepto, [Validators.required]),
      valorTarifa : this.fb.control(renta.valorTarifa, [Validators.required]),
      costos: this.fb.array([]),
      gastos: this.fb.array([]),
      ingresos: this.fb.array([]),
      totalIngreso: this.fb.control(0),
      totalCosto: this.fb.control(0),
      totalGasto: this.fb.control(0),
    });

    form?.get('totalIngreso').disable();
    form?.get('totalCosto').disable();
    form?.get('totalGasto').disable();

    let totalIngreso = 0;
    let totalCosto = 0;
    let totalGasto = 0;
    renta.ingresos?.forEach( g => {
      const ctr = this.fb.group({...CTR_COSTO_CONTABLE_ECE});
      totalIngreso += g.valorContable;
      ctr.patchValue(g);
      (form.get('ingresos') as FormArray).push(ctr);
    });

    renta.costos?.forEach( g => {
      const ctr = this.fb.group({...CTR_COSTO_CONTABLE_ECE});
      totalCosto += g.valorContable;
      ctr.patchValue(g);
      (form.get('costos') as FormArray).push(ctr);
    });

    renta.gastos?.forEach( g => {
      const ctr = this.fb.group({...CTR_COSTO_CONTABLE_ECE});
      totalGasto += g.valorContable;
      ctr.patchValue(g);
      (form.get('gastos') as FormArray).push(ctr);
    });

    form?.get('totalIngreso').setValue(totalIngreso);
    form?.get('totalCosto').setValue(totalCosto);
    form?.get('totalGasto').setValue(totalGasto);

    return form;
  }

  getTotalesConvenios(idProject: number): Observable<object> {
    return this.http.get(`/renta_exenta/totalConvenioRentaExenta?projectId=${idProject}`);
  }

  getConveniosOptions(): Observable<object> {
    return this.http.get('/options/convenioRentaExenta');
  }

  formatTotalsConveniosData(dataToFormat: ConvenioExentaResponse): ConvenioExentaList {

    let dataFormated: ConvenioExentaList = {
      totalCostos: dataToFormat.totalCostos,
      totalExentas: dataToFormat.totalExentas,
      totalGastos: dataToFormat.totalGastos,
      totalIngresos: dataToFormat.totalIngresos,
      can: {
        id: null,
        pais: null,
        concepto: null,
        totalCostos: null,
        totalGastos: null,
        totalIngresoNeto: null,
        totalIngresos: null
      },
      convenios: {
        id: null,
        pais: null,
        concepto: null,
        totalCostos: null,
        totalGastos: null,
        totalIngresoNeto: null,
        totalIngresos: null
      },
      otros: {
        id: null,
        pais: null,
        concepto: null,
        totalCostos: null,
        totalGastos: null,
        totalIngresoNeto: null,
        totalIngresos: null
      }
    }

    dataToFormat.totalesByConvenio.forEach(val => {
      if (val.convenio === 'CAN (Decisión 578)') {
        dataFormated.can = {
          id: parseInt(val?.id),
          pais: val?.pais,
          concepto: val?.concepto,
          totalCostos: val.totalCostos,
          totalGastos: val.totalGastos,
          totalIngresoNeto: val.totalIngresoNeto,
          totalIngresos: val.totalIngresos
        }
      } else if (val.convenio === 'Otros') {
        dataFormated.otros = {
          id: parseInt(val?.id),
          pais: val?.pais,
          concepto: val?.concepto,
          totalCostos: val.totalCostos,
          totalGastos: val.totalGastos,
          totalIngresoNeto: val.totalIngresoNeto,
          totalIngresos: val.totalIngresos
        }
      } else {
        dataFormated.convenios = {
          id: parseInt(val?.id),
          pais: val?.pais,
          concepto: val?.concepto,
          totalCostos: val.totalCostos,
          totalGastos: val.totalGastos,
          totalIngresoNeto: val.totalIngresoNeto,
          totalIngresos: val.totalIngresos
        }
      }
    });
    return dataFormated;

  }


  getChcTotalsData(idProject: number): Observable<object> {
    //return of ({status: 200, data: this.tableSectionCData, message: "Ok"});
    return this.http.get(`/renta_exenta/totalRentaExenta?projectId=${idProject}&concepto=TOTAL_CONCEP`);
  }

  getChrData(idProject: number): Observable<object> {

    //return of ({status: 200, data: this.table1SectionC, message: "Ok"});
    return this.http.get(`/renta_exenta/listCompaniasHoldingColombianas?projectId=${idProject}`);
  }

  setChcTableData(idProject: number, data: RentaList): Observable<object> {
    this.ajusteMsj$.next(true);
    return this.http.post(`/renta_exenta/operacionCHC?projectId=${idProject}`, data);
  }

  getCalculusTable(idProject: number): Observable<object> {
    return this.http.get(`/renta_exenta/calculoRentaExenta?projectId=${idProject}`);
  }

  putCalculusTable(idProject: number, data: CalculusResponseModel[]): Observable<object> {
    return this.http.put(`/renta_exenta/putCalculoRentaExenta?projectId=${idProject}`, data);
  }

}
