import { createAction, props } from '@ngrx/store';
import { PasivosIcaStore } from '../models';

export const GET_AJUSTEPASIVOSICA = '[AjustePasivosIca] get AjustePasivosIca';
export const GET_AJUSTEPASIVOSICA_SUCCESS = '[AjustePasivosIca] get AjustePasivosIca success';
export const GET_AJUSTEPASIVOSICA_ERROR = '[AjustePasivosIca] get AjustePasivosIca with error';

export const SET_AJUSTEPASIVOSICA = '[AjustePasivosIca] set AjustePasivosIca save info';
export const SET_AJUSTEPASIVOSICA_SUCCESS = '[AjustePasivosIca] set AjustePasivosIca save info success';
export const SET_AJUSTEPASIVOSICA_ERROR = '[AjustePasivosIca] set AjustePasivosIca save info with error';

export const SET_DELETE_AJUSTEPASIVOSICA = '[AjustePasivosIca] set delete AjustePasivosIca';
export const SET_DELETE_AJUSTEPASIVOSICA_SUCCESS = '[AjustePasivosIca] set delete AjustePasivosIca success';
export const SET_DELETE_AJUSTEPASIVOSICA_ERROR = '[AjustePasivosIca] set delete AjustePasivosIca with error';

//get AjustePasivosIca
export const getAjustePasivosIca = createAction(
  GET_AJUSTEPASIVOSICA,
  props<{projectId: number}>()
);

export const getAjustePasivosIcaSuccess = createAction(
  GET_AJUSTEPASIVOSICA_SUCCESS,
  props<{ ajustePasivosIcaSuccess: PasivosIcaStore  }>()
);

export const getAjustePasivosIcaError = createAction(
  GET_AJUSTEPASIVOSICA_ERROR,
  props<{ error: string }>()
);

//create AjustePasivosIca
export const setAjustePasivosIca = createAction(
  SET_AJUSTEPASIVOSICA,
  props<{ ajustePasivosIcaForm: PasivosIcaStore, projectId: number }>()
);

export const setAjustePasivosIcaSuccess = createAction(
  SET_AJUSTEPASIVOSICA_SUCCESS,
  props<{ ajustePasivosIcaForm: PasivosIcaStore }>()
);

export const setAjustePasivosIcaError = createAction(
  SET_AJUSTEPASIVOSICA_ERROR,
  props<{ error: string }>()
);

//delete AjustePasivosIca
export const setDeleteAjustePasivosIca = createAction(
  SET_DELETE_AJUSTEPASIVOSICA,
  props<{ projectId: number, objAjustePasivosIca: PasivosIcaStore }>()
);

export const setDeleteAjustePasivosIcaSuccess = createAction(
  SET_DELETE_AJUSTEPASIVOSICA_SUCCESS,
  props<{ ajustePasivosIca: PasivosIcaStore }>()
);

export const setDeleteAjustePasivosIcaError = createAction(
  SET_DELETE_AJUSTEPASIVOSICA_ERROR,
  props<{ error: string }>()
);
