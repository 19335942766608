
<mat-dialog-content class="modalGrl">

  <div class="modalGrl_close">
    <h4 class="modalGrl_close_title"><b>MEMBRESIA: </b>{{ data.memberType }}</h4>
    <mat-icon mat-dialog-close svgIcon="close_rounded"></mat-icon>
  </div>

  <div class="modalGrl_contain">

    <div class="modalGrl_contain_message">
      <p>
        <b>Plan: </b>{{data.description.plan}}
      </p>

      <div class="modalGrl_contain_message_row">
        <p>
          <b>Usuarios: </b>{{data.description.usuarios}}
        </p>
        <p>
          <b>Proyectos: </b>{{data.description.proyectos}}
        </p>
      </div>
      <div class="modalGrl_contain_message_row">
        <p>
          <b>Pago: </b>{{data.amount | currency}}
        </p>

        <p>
          <b>Status: </b>
          <span [class]="'status ' + data.organizationPaymentStatus">{{ setStatus(data.organizationPaymentStatus) | uppercase}}</span>
        </p>
      </div>
      <div class="modalGrl_contain_message_row">
        <p>
          <b>CUS: </b>{{data.cus}}
        </p>
        <app-button-icon
            [cdkCopyToClipboard]="data.cus"
            icon="copy"
            [clase]="'btn_icon_tools'"
            message="Copiar CUS"
            [disabledLine]="false">
          </app-button-icon>
      </div>
      <div class="modalGrl_contain_message_row">
        <p>
          <b>Referencia: </b>{{data.referencePol}}
        </p>
        <app-button-icon
            [cdkCopyToClipboard]="data.referencePol"
            icon="copy"
            [clase]="'btn_icon_tools'"
            message="Copiar Referencia"
            [disabledLine]="false">
          </app-button-icon>
      </div>

    </div>
  </div>

    <div class="modalGrl_buttons">
      <app-button
        [icon]="'check_solid'"
        [text]="'Entendido'"
        [idButton]="'guardar'"
        [clase]="'btn_primary'"
        mat-dialog-close>
      </app-button>
    </div>

</mat-dialog-content>
