import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Balance } from '../components/balance/balance';
import { BalanceCompleto } from '../components/balance/balance-completo';
import { ListProyectosService } from '@proyectos/services/list-proyectos.service';
import { catchError, tap, map, last, retry } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Subject } from 'rxjs';
import { FormMapBalanceComponent } from '@app/shared/components/form-map-balance/form-map-balance.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

import { SpinnerService } from '@app/shared/services/spinner.service';
import { LoadingMessageService } from '@app/shared/services/loading-message.service';
import { NotifyService } from '@app/shared/services/notify.service';

export interface ResponseLoading {
  msg: string;
  percent: number;
  total?: number;
  data?: {} | [];
  loading: boolean;
  determinate?: string;
}

export interface BalanceData {
  projectId: string;
  registroDTO: Balance[];
  deleteAnnex?: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class BalanceService {

  public sizeBalance = 0;
  public balanceReadyObs$ = new Subject<boolean>();
  public balanceReady$: Observable<boolean> = this.balanceReadyObs$.asObservable();

  constructor(
    private http: HttpClient,
    private projecSrv: ListProyectosService,
    private dialogService: DialogService,
    private spinner: SpinnerService,
    private notify: NotifyService,
    private loadinMessage: LoadingMessageService,
  ) { }

  loaderFalse(): void {
    let percenteFalse = 0;

    setTimeout(() => {

      const res = {
        msg: 'Estamos preparando la carga del balance...',
        data: null,
        loading: true,
        percent: percenteFalse,
        determinate: 'determinate'
      };
      this.spinner.showPercent(res);
      percenteFalse = percenteFalse + 1;

    }, 3000);
  }

  saveBalance(projectId: number, balance: Balance[], deleteAnnex?: boolean) {
    const send: BalanceData = {
      projectId: String(projectId),
      registroDTO: balance
    };
    if (deleteAnnex !== undefined) send.deleteAnnex = deleteAnnex;

    return this.uploadBalance(send);
  }

  uploadBalance(balance: any) {
    if (!balance) { return of<string>('No estas cargando balance'); }
    const req = new HttpRequest('POST', '/balance', balance, {
      reportProgress: true
    });
    this.spinner.show();
    return this.http.request(req).pipe(
      map(event => this.loadinMessage.getEventMessage(event)),

      tap((response: ResponseLoading) => {
        if (!response.data) {
          this.spinner.hide();
        } else {
          this.balanceReadyObs$.next(true);
        }
      }),

      last(),
      catchError(err => {
        if (err != null) {
          if (err.error != null) {
            if (err.error.errorCode == 1) {
              this.notify.error(
                'No pudimos Guardar el balance!',
                err.error.errorMessage,
                0,
                {
                  text: 'Entendido',
                  orientation: 'izq'
                });
            }
            else {
              this.notify.error(
                '[STORE]: No pudimos Guardar el balance!',
                'Sufrimos un error inesperado',
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq'
                });
            }
          }
          else {
            this.notify.error(
              '[STORE]: No pudimos Guardar el balance!',
              'Sufrimos un error inesperado',
              5000,
              {
                text: 'Entendido',
                orientation: 'izq'
              });
          }
        }
        else {
          this.notify.error(
            '[STORE]: No pudimos Guardar el balance!',
            'Sufrimos un error inesperado',
            5000,
            {
              text: 'Entendido',
              orientation: 'izq'
            });
        }
        return err
      })
    );
  }

  obtenerBalance(id: number) {
    return this.getBalance(id);
  }

  getBalance(id: number) {

    const req = new HttpRequest('GET', '/balance?id=' + id, {
      reportProgress: true
    });
    this.spinner.show();
    return this.http.request(req).pipe(
      map(event => this.loadinMessage.getEventMessage(event)),
      tap((response: ResponseLoading) => {

        this.spinner.hide();

      }),
      last(),
      catchError(err => err)
    );
  }


  saveBalanceTerceros(projectId: number, balance: Balance[]): Observable<object> {

    const bc: BalanceCompleto = {
      projectId: projectId,
      registroDTO: balance
    };

    return this.http.post('/balance/terceros', bc);
  }


  getBalanceTerceros(projectId: number): Observable<Balance[]> {
    return this.http.get<Balance[]>(`/balance/terceros?id=${projectId}`);
  }

  getMapedBalance(event: object): DynamicDialogRef {
    const header = Object.keys(event[0]);
    return this.dialogService.open(FormMapBalanceComponent, {
      data: {
        fielDataHeader: header,
        balanceData: event
      },
      width: '60vw',
      header: 'Cargar balance de terceros',
      closeOnEscape: true
    });
  }
  balanceResuemen(id: number) {
    return this.http.get(`/balance/resumen?projectId=${id}`);
  }
}
function fromPromise(error: any): any {
  throw new Error('Function not implemented.');
}


