import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { debounceTime } from 'rxjs/operators';

import { AuthService } from '@auth/services/auth.service';
import {
  compareValues,
  EMAIL_PATTERN,
  getErrorMessage,
} from '@shared/utils/form-validators';
import { emailErrorMessages } from '@shared/utils/form-validators';
import { NotifyService } from '@app/shared/services/notify.service';
import { ModalGrlComponent } from '@app/shared/components/modal-grl/modal-grl.component';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerService } from '@app/shared/services/spinner.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public fieldPassLogin: boolean;
  public fieldPassRegister: boolean;
  public fieldPassRepeat: boolean;

  public recoveryPass: boolean = false;

  public emailPattern: string = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';

  public loginForm = this.fb.group({
    email: [
      '',
      [
        Validators.email,
        Validators.required,
        Validators.pattern(EMAIL_PATTERN),
      ],
    ],
    password: ['', Validators.required],
  });

  public emailLoginErrorMessage = '';
  public emailSignupErrorMessage = '';
  public passErrorMessage = '';
  public errorMessage = '';

  public errorResetMessage = '';

  //signup
  signUpForm = this.fb.group(
    {
      email: [
        '',
        [
          Validators.email,
          Validators.required,
          Validators.pattern(EMAIL_PATTERN),
        ],
      ],
      password: [, [Validators.required, Validators.minLength(6)]],
      repeatPass: [, [Validators.required]],
    },
    {
      validators: [compareValues('password', 'repeatPass')],
    }
  );

  resetPassForm = this.fb.group({
    email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
  });

  //errorMessage: string;
  public emailErrorMessage: string;
  public passwordErrorMessage: string;
  public pass2ErrorMessage: string;
  public accountCreated: boolean;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private notifyService: NotifyService,
    public dialog: MatDialog,
    private spinner: SpinnerService,
  ) {}

  ngOnInit(): void {
    this.recoveryPass = false;
    this.sigUpActions();
    this.loginActions();
  }

  login(): void {
    if (!this.loginForm.invalid) {
      this.spinner.show()
      const { email, password } = this.loginForm.controls;
      this.authService.login(email?.value, password?.value).subscribe(
        (res) => {
         
          if (res.user.emailVerified) {
            this.router.navigate(['/home']);
            this.spinner.hide()
          } else {
            this.spinner.hide();
            this.authService.logOut();
            this.notifyService.info(
              'Verifica tu correo',
              'Para poder iniciar sesión haga click en el enlace de verificación enviado a su correo',
              3500
            );
          }
        },
        (error) => {
          this.notifyService.error('Algo va mal!', error, 4000);
          console.log('Error inesperado => ', error);
        }
      );
    }
  }

  get emailLogin() {
    return this.loginForm.get('email');
  }

  get passLogin() {
    return this.loginForm.get('password');
  }

  get passRegister() {
    return this.signUpForm.get('password');
  }

  get passRepeat() {
    return this.signUpForm.get('repeatPass');
  }

  changeRecovery() {
    this.recoveryPass = !this.recoveryPass;
  }

  loginActions() {
    const emailControl = this.loginForm.get('email');
    emailControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(
        (_) =>
          (this.emailLoginErrorMessage = getErrorMessage(
            emailControl,
            emailErrorMessages
          ))
      );

    const passwControl = this.loginForm.get('password');
    passwControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(
        (_) =>
          (this.passErrorMessage = getErrorMessage(
            passwControl,
            emailErrorMessages
          ))
      );
  }

  signWitGoogle(): void {
    this.authService.signWithGoogle().subscribe(
      (data: any) => localStorage.setItem('mail', data.user.email),
      (error: any) => {
        let message: string;
        if (
          error.message ===
          'The popup has been closed by the user before finalizing the operation.'
        ) {
          message = 'No pudimos completar la verificación con Google';
        } else {
          message =
            'Hemos tenido un error inesperado, verifica tu conexión de red e intentalo más tarde';
        }

        this, this.notifyService.error('Algo va mal!', message, 3000);
        console.log('Error Inesperado  => ', error);
      },

      () => this.router.navigate([''])
    );
  }

  signWithEmail(email: string) {
    this.authService.signWithEmail(email).subscribe(
      () => {
        alert('Ha sido enviado un link de activación a ' + email);
        localStorage.setItem('emailForSignIn', email);
      },
      (error) => (this.errorMessage = error)
    );
  }

  signUp(): void {
    this.signUpForm.markAllAsTouched;
    this.signUpForm.markAsDirty;

    if (this.signUpForm.invalid) {
      this.notifyService.error(
        'Falta información',
        'Por favor complete la información',
        3500
      );
      return;
    }

    const { email, password } = this.signUpForm.controls;

    this.authService.signUp(email?.value, password?.value).subscribe(
      (response) => {
        if (
          response.operationType === 'signIn' &&
          response.additionalUserInfo.isNewUser
        ) {
          this.dialog
            .open(ModalGrlComponent, {
              data: {
                icon: 'info',
                textOne: 'Enviamos un correo de confirmación a ',
                textFocus: ' ' + email.value + ' ',
                textTwo:
                  'Para finalizar el proceso de registro, por favor verifiquelo',

                buttons: [
                  {
                    icon: 'check_rounded',
                    text: 'Entendido',
                    id: 'back',
                    clase: 'btn_success',
                  },
                ],
              },
            })
            .afterClosed()
            .subscribe(() => {
              this.router.navigate(['']);
            });
        }
      },
      (error) => {
        this.notifyService.error('Algo salio mal!', error, 3500);
        console.log('error Inesperado => ', error);
      }
    );
  }

  sigUpActions() {
    const emailControl = this.signUpForm.get('email');
    emailControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(
        (_) =>
          (this.emailSignupErrorMessage = getErrorMessage(
            emailControl,
            emailErrorMessages
          ))
      );
    const passControl = this.signUpForm.get('password');
    const repeatPassControl = this.signUpForm.get('repeatPass');

    passControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(
        (_) =>
          (this.passwordErrorMessage = getErrorMessage(
            passControl,
            emailErrorMessages
          ))
      );

    repeatPassControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(
        (_) =>
          (this.pass2ErrorMessage = getErrorMessage(
            repeatPassControl,
            emailErrorMessages
          ))
      );

    this.signUpForm.valueChanges.subscribe(
      (errors) => (this.errorMessage = errors.errors)
    );
  }

  resetPassword(): void {
    if (this.resetPassForm.valid) {
      const { email } = this.resetPassForm.controls;
      this.authService.resetPassword(email.value).subscribe(
        () => {
          this.notifyService.info(
            'Enviamos un email de recuperación',
            'Revisa el mail ' + email.value + ' y sigue los pasos',
            4000
          );
          this.recoveryPass = false;
          this.router.navigate(['']);
        },
        (error) => {
          this.notifyService.error('Algo salio mal!', error);
          console.log('Error inesperado => ', error);
        }
      );
    } else {
      this.errorResetMessage = 'Ingrese un email por favor';
    }
  }

  help() {
    this.router.navigateByUrl(
      'https://www.youtube.com/playlist?list=PLIB9rPXbNKWp2SpyuDtq7reJ3MjY1qHFu'
    );
  }
}
