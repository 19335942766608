import { NgModule } from '@angular/core';
import { UploaderModule  } from '@syncfusion/ej2-angular-inputs';
import { SpreadsheetAllModule } from '@syncfusion/ej2-angular-spreadsheet';
import { DropDownButtonModule, SplitButtonModule, ProgressButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { ButtonModule, CheckBoxModule, RadioButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { GridModule, SearchService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { ChartModel } from '@syncfusion/ej2-charts';


@NgModule({
  declarations: [],
  imports: [],
  exports: [
    SpreadsheetAllModule,
    ProgressButtonModule,
    SplitButtonModule,
    DropDownButtonModule,
    ButtonModule,
    CheckBoxModule,
    DialogModule,
    RadioButtonModule,
    SwitchModule,
    UploaderModule,
    GridModule
  ],
  providers: [SearchService, ToolbarService]
})
export class SyncfusionModule { }
