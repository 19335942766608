import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@shared/guard/auth.guard';
import { AppGuard } from '@shared/guard/app.guard';
import { ProfileGuard } from '@profile/services/profile.guard';

import { BaseLayoutComponent } from '@shared/base-layout/base-layout.component';
import { PageNoFoundComponent } from '@shared/page-no-found/page-no-found.component';
import { DocumentacionComponent } from './shared/components/documentacion/documentacion.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [AuthGuard],
  },
  {
    path: '404',
    component: PageNoFoundComponent
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m=>m.ProfileModule),
    canLoad: [ProfileGuard],
    canActivate: [ProfileGuard]
  },
  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      {
        path: 'proyecto',
        loadChildren: () => import('./pages/proyectos/proyectos.module').then(m => m.ProyectosModule)
      },
      {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then(m=>m.HomeModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then(m=>m.SettingsModule)
      },
      {
        path: 'plans',
        loadChildren: () => import('./pages/plans/plans.module').then(m=>m.PlansModule)
      },
      {
        path: 'payment',
        loadChildren: () => import('./pages/payment/payment.module').then(m=>m.PaymentModule)
      },
      {
        path: 'docu',
        component: DocumentacionComponent
      },
      {
        path: '**',
        redirectTo: 'home',
        pathMatch: 'full'
      }
    ],
    canActivate: [AppGuard],
    canLoad: [AppGuard]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
