import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AjusteDepuracionStore, Autorretenciones, ConceptoMeses, ListAutorretencionesDepuracionDTO, TotalesAutorretenciones } from '@app/store/models/autorretenciones.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AutorretencionesService {

  private loadShowTotales = new BehaviorSubject<boolean>(true);
  public loadShowTotales$ = this.loadShowTotales.asObservable();

  constructor(private http: HttpClient) {}

  showTotales(data: boolean){
    if (data == null){
      return this.loadShowTotales.getValue();
    };
    this.loadShowTotales.next(data);
    return this.loadShowTotales.getValue();
  }

  saveAutorretenciones(projectId: number, autorretencionesForm: ConceptoMeses[]): Observable<ConceptoMeses[]> {
    return this.http.post<ConceptoMeses[]>(
      `/autorentenciones?id=${projectId}`,
      autorretencionesForm
    );
  }

  getAutorretenciones(projectId: number): Observable<Autorretenciones> {
    const response = this.http.get<Autorretenciones>(
      `/autorentenciones?id=${projectId}`
    );
    response.subscribe(data => {
      console.log(data);
    })
    return this.http.get<Autorretenciones>(
      `/autorentenciones?id=${projectId}`
    );
  }

  getTotalesAutorretenciones(projectId: number): Observable<TotalesAutorretenciones>{
    return this.http.get<TotalesAutorretenciones>(
      `/autorentenciones/totales?id=${projectId}`
    );
  }

  //Ajuste Depuracion

  getAjusteDepuracion(projectId: number): Observable<AjusteDepuracionStore> {
    return this.http.get<AjusteDepuracionStore>(
      `/autorentenciones/totalAutoDepuracion?id=${projectId}`
    );
  }

  deleteAjusteDepuracion(
    projectId: number,
    autorretencionesTotalesDepuracionDTO: ListAutorretencionesDepuracionDTO[]
  ): Observable<any> {
    return this.http.request<any>(
      'delete',
      `/autorentenciones/totalReg?project=${projectId}`,
      { body: autorretencionesTotalesDepuracionDTO }
    );
  }

  saveAjusteDepuracion(
    projectId: number,
    objAjusteDepuracion: AjusteDepuracionStore
  ): Observable<AjusteDepuracionStore> {
    return this.http.post<AjusteDepuracionStore>(
      `/autorentenciones/totalAutoDepuracion?id=${projectId}`,
      objAjusteDepuracion
    );
  }

  getAccountValueDepuracion(projectId: number, account: number): Observable<any> {
    return this.http.get(
      `/autorentenciones/accountValue?projectId=${projectId}&account=${account}`
    );
  }
}
