import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { delay } from 'rxjs/operators';

import { AuthService } from '@auth/services/auth.service';
import { OptionMenu } from '@app/shared/models/menu';
import { MatDialog } from '@angular/material/dialog';
import { ModalGrlComponent } from '@app/shared/components/modal-grl/modal-grl.component';
import { NotifyService } from '@app/shared/services/notify.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-menu-user',
  templateUrl: './menu-user.component.html',
  styleUrls: ['./menu-user.component.scss']
})
export class MenuUserComponent implements OnInit {

  menuOptions: OptionMenu[] = [
    {
      label: 'Crear Usuario',
      route: '/settings/manage_users',
      icon: 'user_line_bold'
    },
    {
      label: 'Ajustes Perfil',
      route: '/settings/profile',
      icon: 'cog_bold'
    },
    {
      label: 'Cambiar Contraseña',
      route: 'one',
      icon: 'key',
    },
    {
      label: 'Cerrar Sesión',
      route: 'two',
      icon: 'log_out',
    },
  ];

  currentUser$ = this.auth.getCurrentUser();
  currentUser = this.auth.currentUser;
  showFiller = false;
  @Input() display: string;
  @Output() menuMobile = new EventEmitter();

  constructor(
    private auth: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private notifyService: NotifyService,
  ) { }

  ngOnInit(): void {
  }

  goToRoute(menu: OptionMenu){
    switch (menu.route) {
       case 'one':
        this.changePassword()
        break;

      case 'two':
        this.logout()
        break;

      default:
        this.router.navigate([menu.route]);
        break;
    }
  }

  // changePassword() {

  //   const change = confirm('Está seguro que desea cambiar su contraseña');
  //   if(change){
  //     const email = this.currentUser.email;
  //     this.auth.resetPassword(email).subscribe(
  //       () => alert('Se ha enviado un correo para el cambio de contraseña a ' + email )
  //     );
  //   }
  //   else{
  //     alert('Se ha cancelado el cambio de contraseña');
  //   }


  // }

  changePassword() {
    this.dialog.open(ModalGrlComponent, {
      data: {
        icon: 'doubt',
        textOne: 'Está seguro que desea ',
        textFocus: 'cambiar su contraseña',
        buttons: [
          {
            icon: 'arrow_left',
            text: 'Regresar',
            id: 'back',
            clase: 'btn_primary'
          },
          {
            icon: 'check_solid',
            text: 'Si cambiarla',
            id: 'change',
            clase: 'btn_success',
          }
        ]
      }
    }).afterClosed().subscribe((click: any) => {
      if(click.data === 'change') {
        const email = this.currentUser.email;
        this.auth.resetPassword(email).subscribe(
          () => this.notifyService.info('Correo Enviado!', 'Se ha enviado un correo para el cambio de contraseña a ' + email, 4000, {
            text: 'Entendido',
            orientation: 'izq'
          })
        )
      } else {
        this.notifyService.error('Cambio cancelado!', 'Cancelaste la solicitud de cambio de contraseña', 4000, {
          text: 'Entendido',
          orientation: 'izq'
        })
      }
    })
  }

  logout() {
    this.auth.logOut().pipe(
      delay(1000)
    ).subscribe(
      () => {
        this.router.navigate(['/auth/login']);
      }
    );
  }

}
