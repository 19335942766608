import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  MSG_DURATION,
  NotifyService,
} from '@app/shared/services/notify.service';
import { CuatroxMilGmfService } from '@app/shared/services/cuatrox-mil-gmf.service';
import { Seccion3GmfStore } from '../models/cuatrox-mil-gmf.model';
import {
  getSeccion3Gmf,
  getSeccion3GmfError,
  getSeccion3GmfSuccess,
  setDeleteSeccion3Gmf,
  setDeleteSeccion3GmfError,
  setDeleteSeccion3GmfSuccess,
  setSeccion3Gmf,
  setSeccion3GmfError,
  setSeccion3GmfSuccess,
} from '../actions';

@Injectable()
export class Seccion3GmfEffects {
  getSeccion3Gmf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSeccion3Gmf),
      switchMap(({ projectId }) => {
        return this.cuatroxMilGmfService.getSeccion3Gmf(projectId).pipe(
          map((seccion3Gmf) => {
            let seccion3GmfSuccess: Seccion3GmfStore = {
              idProject: projectId,
              ...seccion3Gmf,
            };
            return getSeccion3GmfSuccess({ seccion3GmfSuccess });
          }),
          catchError((error) => {
            this.notify.error('Algo salio mal!', JSON.stringify(error), 5000, {
              text: 'Entendido',
              orientation: 'izq',
            });
            return of(getSeccion3GmfError({ error }));
          })
        );
      })
    )
  );

  saveSeccion3Gmf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setSeccion3Gmf),
      switchMap(({ seccion3GmfForm, projectId }) => {
        return this.cuatroxMilGmfService
          .saveSeccion3Gmf(projectId, seccion3GmfForm)
          .pipe(
            map((seccion3Gmf) => {
              let seccion3GmfForm: Seccion3GmfStore = {
                idProject: projectId,
                ...seccion3Gmf,
              };
              this.notify.success(
                '',
                'Guardado de registros exitoso',
                MSG_DURATION
              );
              return setSeccion3GmfSuccess({ seccion3GmfForm });
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setSeccion3GmfError({ error }));
            })
          );
      })
    )
  );

  deleteSeccion3Gmf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDeleteSeccion3Gmf),
      switchMap(({ projectId, objSeccion3Gmf }) => {
        return this.cuatroxMilGmfService
          .deleteSeccion3Gmf(projectId, objSeccion3Gmf)
          .pipe(
            map((seccion3Gmf) => {
              this.notify.success(
                '',
                'Borrado de registros exitoso',
                MSG_DURATION
              );
              return setDeleteSeccion3GmfSuccess({ seccion3Gmf });
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setDeleteSeccion3GmfError({ error }));
            })
          );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private cuatroxMilGmfService: CuatroxMilGmfService,
    private notify: NotifyService
  ) {}
}
