import { createSelector } from '@ngrx/store';
import { getProjectsModuleState } from '../reducers/index';
import { toArray } from 'lodash';


export const selectAutorreTotalesList = createSelector(
  getProjectsModuleState,
  state => state.autorreTotales.entities
);

export const selectAutorreTotalesById = (id: number) =>
  createSelector(getProjectsModuleState, state => {
  const data: any = state.autorreTotales.entities;
  if(data) {
    return toArray(data).find(item => {
      return item.id === id;
    });
  } else {
    return {};
  }
});
