import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { map, switchMap, catchError, tap, debounceTime } from "rxjs/operators";
import { from, of } from "rxjs";

import { NotifyService } from "@app/shared/services/notify.service";

import {
  getBalance,
  getBalanceError,
  getBalanceSuccess,
  getEri,
  setAnnexedStatus,
  setBalance,
  setBalanceError,
  setBalanceSuccess
} from "../actions";

import { BalanceService } from "@app/pages/info-contribuyente/services/balance.service";
import { BalanceCompleto } from "@app/pages/info-contribuyente/components/balance/balance-completo";
import { Store } from "@ngrx/store";
import { getProjectStatus } from "../actions/project-status.actions";
import { AnnexedStatus } from "../models";

@Injectable()
export class BalanceEffects {

  public getBalance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBalance),
      switchMap(({ projectId }) =>
        this.balanceService.obtenerBalance(projectId).pipe(

          map((balanceResponse: any) => {

            const balanceSuccess: BalanceCompleto =  balanceResponse.data;
            return getBalanceSuccess({ balanceSuccess });
          }),
          catchError((error) => {
            this.notify.error(
              'Algo salio mal!',
              'No pudimos obtener Balance!',
              4000,
          {
            text: 'Entendido',
            orientation: 'izq'
          });
            return of(getBalanceError({ error }));
          })
        )
      )
    )
  );

  public setBalance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setBalance),
      switchMap(({ projectId, balanceForm, deleteAnnex }) =>
        this.balanceService.saveBalance(projectId, balanceForm, deleteAnnex).pipe(
          map((balanceResponse: any) => {
            const balance: BalanceCompleto = balanceResponse.data;

            const status: AnnexedStatus = {
              id: 3,
              projectId: projectId,
              status: 'TERMINADO',
              tab: 'BALANCE',
              annexed: 'INFORMACION',
            }
            this.store.dispatch(setAnnexedStatus({ annexedStatus: status }));
            return setBalanceSuccess({ balance });

          }),
          catchError((error) => {
            /*this.notify.error(
              '[STORE]: No pudimos Guardar el balance!',
              'Sufrimos un error inesperado',
              5000,
          {
            text: 'Entendido',
            orientation: 'izq'
          });*/
            return of(setBalanceError({ error }));
          })
        )
      )
    )
  );

  public constructor(
    private actions$: Actions,
    private store: Store<any>,
    private balanceService: BalanceService,
    private notify: NotifyService,
  ) {}
}
