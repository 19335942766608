<div class="main-container">
    <main id="main-login" [formGroup]="resetPassForm">
        <div class="left-container">
            <img src="/assets/icons/logo-white.png" class="registro-img-responsive" alt="">
            <div class="login">

                <h2 class="login-text">Olvidé mi contraseña</h2>

                <div class="login-email">
                    <img src="/assets/icons/email.png" style="margin-right: 10px;" alt="">
                    <input type="email" name="e-mail" placeholder="E-mail" formControlName="email">
                </div>

                <span class="error">
                    <p>{{ errorMessage }}</p>
                </span>


                <div class="login-button">
                    <button (click)="resetPassword()">
                        <img src="/assets/icons/check-green.png" alt="">
                        <div>Enviar correo</div>
                    </button>
                </div>
            </div>

        </div>
        <div class="right-container">
            <div class="registro">
                <img src="/assets/icons/logo-white.png" class="registro-img" alt="">
                <h3>Recupera tu cuenta</h3>
                <p>Enviaremos un correo para que puedas recuperar tu cuenta</p>
            </div>
        </div>
    </main>
</div>
