import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';

import { SyncfusionModule } from '@third-party/syncfusion.module';
import { PrimeNgModule } from '@third-party/prime-ng.module';
import { COMPONENTS } from './components';
import { MaterialNgModule } from '@app/third-party/material-module';

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    MaterialNgModule,
    PrimeNgModule
  ],
  exports: [
    ...COMPONENTS,
  ],
})
export class AuthSharedModule { }
