import { createAction, props } from '@ngrx/store';
import { PerdidasCompensarSaveOne, PerdidasCompensarStore, TotalesPerdidasCompensar } from '../models';

export const GET_PERDIDASCOMPENSAR = '[PerdidasCompensar] get PerdidasCompensar';
export const GET_PERDIDASCOMPENSAR_SUCCESS = '[PerdidasCompensar] get PerdidasCompensar success';
export const GET_PERDIDASCOMPENSAR_ERROR = '[PerdidasCompensar] get PerdidasCompensar with error';

export const SET_PERDIDASCOMPENSAR = '[PerdidasCompensar] set PerdidasCompensar save info';
export const SET_PERDIDASCOMPENSAR_SUCCESS = '[PerdidasCompensar] set PerdidasCompensar save info success';
export const SET_PERDIDASCOMPENSAR_ERROR = '[PerdidasCompensar] set PerdidasCompensar save info with error';

export const SET_DELETE_PERDIDASCOMPENSAR = '[PerdidasCompensar] set delete PerdidasCompensar';
export const SET_DELETE_PERDIDASCOMPENSAR_SUCCESS = '[PerdidasCompensar] set delete PerdidasCompensar success';
export const SET_DELETE_PERDIDASCOMPENSAR_ERROR = '[PerdidasCompensar] set delete PerdidasCompensar with error';

//get PerdidasCompensar
export const getPerdidasCompensar = createAction(
  GET_PERDIDASCOMPENSAR,
  props<{projectId: number}>()
);

export const getPerdidasCompensarSuccess = createAction(
  GET_PERDIDASCOMPENSAR_SUCCESS,
  props<{ perdidasCompensarSuccess: PerdidasCompensarStore  }>()
);

export const getPerdidasCompensarError = createAction(
  GET_PERDIDASCOMPENSAR_ERROR,
  props<{ error: string }>()
);

//create PerdidasCompensar
export const setPerdidasCompensar = createAction(
  SET_PERDIDASCOMPENSAR,
  props<{ perdidasCompensarForm: PerdidasCompensarSaveOne, projectId: number }>()
);

export const setPerdidasCompensarSuccess = createAction(
  SET_PERDIDASCOMPENSAR_SUCCESS,
  props<{ perdidasCompensarForm: PerdidasCompensarStore }>()
);

export const setPerdidasCompensarError = createAction(
  SET_PERDIDASCOMPENSAR_ERROR,
  props<{ error: string }>()
);

//delete PerdidasCompensar
export const setDeletePerdidasCompensar = createAction(
  SET_DELETE_PERDIDASCOMPENSAR,
  props<{ projectId: number, idRow: number, objPerdidasCompensar: TotalesPerdidasCompensar }>()
);

export const setDeletePerdidasCompensarSuccess = createAction(
  SET_DELETE_PERDIDASCOMPENSAR_SUCCESS,
  props<{ perdidasCompensar: PerdidasCompensarStore }>()
);

export const setDeletePerdidasCompensarError = createAction(
  SET_DELETE_PERDIDASCOMPENSAR_ERROR,
  props<{ error: string }>()
);
