import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  MSG_DURATION,
  NotifyService,
} from '@app/shared/services/notify.service';
import { getAutorretenciones, getAutorretencionesError, getAutorretencionesSuccess, setAutorretenciones, setAutorretencionesError, setAutorretencionesSuccess } from '../actions';
import { AutorretencionesService } from '@app/shared/services/autorretenciones.service';
import { Autorretenciones } from '../models';
import { SpinnerService } from '@app/shared/services/spinner.service';

@Injectable()
export class AutorretencionesEffects {
  getAutorretenciones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAutorretenciones),
      switchMap(({ projectId }) => {
        return this.autorretencionesService.getAutorretenciones(projectId).pipe(
          map((autorretenciones:any) => {
            let autorretencionesSuccess: Autorretenciones = {
              idProject: projectId,
              conceptoMeses:autorretenciones
            };
            return getAutorretencionesSuccess({ autorretencionesSuccess });
          }),
          catchError((error) => {
            this.notify.error('Algo salio mal!', JSON.stringify(error), MSG_DURATION, {
              text: 'Entendido',
              orientation: 'izq',
            });
            return of(getAutorretencionesError({ error }));
          })
        );
      })
    )
  );

  saveAutorretenciones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setAutorretenciones),
      switchMap(({ autorretencionesForm, projectId }) => {
        this.spinner.show();
        return this.autorretencionesService
          .saveAutorretenciones(projectId, autorretencionesForm.conceptoMeses)
          .pipe(
            map((autorretenciones) => {
              let autorretencionesForm: Autorretenciones = {
              idProject: projectId,
              conceptoMeses: autorretenciones,
            };
              this.spinner.hide();
              this.notify.success(
                '',
                'Guardado de registros exitoso',
                MSG_DURATION
              );
              this.autorretencionesService.showTotales(true);
              return setAutorretencionesSuccess({ autorretencionesForm });
            }),
            catchError((error) => {
              console.log(error);
              this.spinner.hide();
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                MSG_DURATION,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setAutorretencionesError({ error }));
            })
          );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private autorretencionesService: AutorretencionesService,
    private notify: NotifyService,
    private spinner: SpinnerService,
  ) {}
}
