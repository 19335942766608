import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EMPTY, Observable } from 'rxjs';

import { CreatePayment, PayUData, Plan, CurrentPlan, PaymentHistory } from '../models/plan';
import { map, catchError } from 'rxjs/operators';
import { ResponsePay } from '@app/pages/payment/response';


@Injectable({
  providedIn: 'root'
})
export class PlanService {

  _currentPlant: number;
  planList: Plan[];

  get currentPlant(): number {
    return this._currentPlant;
  }

  set currentPlant(id: number) {
    this._currentPlant = id
  }

  constructor(
    private http: HttpClient
  ) { }

  actualPlan() : Observable<CurrentPlan>{
    return this.http.get<CurrentPlan>('/payment_plan').pipe(
      catchError(_ => EMPTY)
    );
  }

  getPlanList(): Observable<Plan[]>{
    return this.http.get<Plan[]>('/payment_plans');
  }

  getPlanById(id: number): Observable<Plan>{
    return this.getPlanList().pipe(
      map((resp: Plan[]) => resp.find(plan => plan.id === id) )
    );
  }

  createPayment(createPayment: CreatePayment) : Observable<PayUData> {
    return this.http.post<PayUData>('/create_payment', createPayment);
  }

  cancelPlan(): Observable<any> {
    return this.http.post<any>('/cancel_payment', {});
  }

  ver2516(anexo:string): Observable<any> {
    return this.http.post<any>('/view_2516?anexo='+anexo, {});
  }

  procesarPago(id:number): Observable<any> {
    return this.http.post<any>('/process?id='+id, {});
  }

  respuestaPago(response:ResponsePay): Observable<any> {
    return this.http.post<any>('/response', response);
  }

  inactivePlan(): Observable<any> {
    return this.http.post<any>('/inactivate', {});
  }

  historyPayments(email: string): Observable<PaymentHistory[]> {
    return this.http.post<PaymentHistory[]>(`/history`, { email: email });
  }
}
