import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader-component',
  templateUrl: './loader-component.component.html',
  styleUrls: ['./loader-component.component.scss']
})
export class LoaderComponentComponent {
  @Input() message: string = '';
  @Input() btn_reload: boolean = false;
  @Output() clicker = new EventEmitter()
  constructor(
  ) { }


}
