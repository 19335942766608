<app-modal-cabecera-grl
[title]="'Edicion Renta'"
[iconTitle]="'edit'">
</app-modal-cabecera-grl>
    <mat-dialog-content>
        <form [formGroup]="form">
            <div class="row mt-2">
                <div class="col">Codigo</div>
                <div class="col">{{data.registro?.account}}</div>
            </div>
            <div class="row mt-2">
                <div class="col">Cuenta</div>
                <div class="col"><label>{{data.registro?.nameAccount}}</label></div>
            </div>
            <div class="row mt-2"><label>Renta </label></div>
            <div class="row mt-2">
                <select formControlName="renta" #renta [ngClass]="{'is-invalid': isInvalid('renta')}"
                    class="form-select form-select mb-3" aria-label=".form-select-lg example"
                    (change)="cambioRenglon(renta.value)">
                    <option value="" disabled>Elija opción</option>
                    <option *ngFor="let renta of (data.renglonesRenta | async)" value="{{renta.value}}">
                        {{renta.description}}</option>
                </select>
            </div>
            <div class="row mt-2">
                <label>Renglon 2516</label>
                <select formControlName="renglon2516" [ngClass]="{'is-invalid': isInvalid('renglon2516')}"
                    class="form-select form-select mb-3" aria-label=".form-select-lg example">
                    <option value="" disabled>Elija opción</option>
                    <option *ngFor="let renglon of (this.renglones2516$ | async)" value="{{renglon.value}}">
                        {{renglon.description}}</option>
                </select>
            </div>
        </form>

    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <app-button
        [disabling]="!this.form.valid"
        [icon]="'save_bold'"
        [text]="'Guardar'"
        [idButton]="'save'"
        [clase]="'btn_success'"
        (clicker)="$event === 'save' ? this.guardar(this.form.value): ''">
        </app-button>
    </mat-dialog-actions>
