import { Action, createReducer, on } from "@ngrx/store";
import { getImpuestoExteriorSuccess, setDeleteImpuestoExteriorSuccess, setImpuestoExteriorSuccess } from "../actions/impuesto-exterior.actions";
import { ImpuestoExteriorState, impuestoExteriorAdapter } from "../states/impuesto-exterior.state";

export const initialState: ImpuestoExteriorState = impuestoExteriorAdapter.getInitialState({})

const impuestoExteriorReducer = createReducer(
  initialState,

  on(getImpuestoExteriorSuccess, (state, { impuestoExteriorSuccess }) => {
    state.ids = [];
    state.entities = {};
    return impuestoExteriorAdapter.upsertOne(impuestoExteriorSuccess, { ...state })
  }),

  on(setImpuestoExteriorSuccess, (state, { impuestoExteriorForm }) => {
    state.ids = [];
    state.entities = {};
    return impuestoExteriorAdapter.upsertOne(impuestoExteriorForm, {...state})
  }),

  on(setDeleteImpuestoExteriorSuccess, (state, { impuestoExteriorForm }) => {
    state.ids = [];
    state.entities = {};
    return impuestoExteriorAdapter.upsertOne(impuestoExteriorForm, state)
  }),
)

export function reducer(state: ImpuestoExteriorState | undefined, action: Action) {
  return impuestoExteriorReducer(state, action);
}
