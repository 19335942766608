import { createSelector } from "@ngrx/store";
import { toArray } from 'lodash';
import { getProjectsModuleState } from "../reducers";

export const selectCalculoDescuentoList = createSelector(
    getProjectsModuleState,
    state => state.calculoDescuento.entities
  );
  
  export const selectCalculoDescuentoById = (id: number) =>
    createSelector(getProjectsModuleState, state => {
    const data: any = state.calculoDescuento.entities;
    if(data) {
      return toArray(data).find(item => {
        return item.id === id;
      });
    } else {
      return {};
    }
  });