import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MailReport } from '../models/mail-ticket.model';

@Injectable({
  providedIn: 'root'
})

export class MailService {

  constructor(
    private http: HttpClient
  ) { }

    sendMailTicket(mail: MailReport): Observable<any>{
      return this.http.post('/options/envioCorreo', mail)
    }

    getBase64(files: File[]): Observable<string[]>{

        if(files.length){
          const arraStr: string[] = [];

          files.map((file: File) => {

            const reader = new FileReader();

            reader.onloadend = () => {
              let base64 = String(reader.result);
              arraStr.push(base64.replace(/^data:image\/[a-z]+;base64,/, ""));
            };

            reader.readAsDataURL(file);
          })

          return of(arraStr)
        } else {
          return of(['error no hay archivos'])
        }


    }
}
