import { createAction, props } from '@ngrx/store';
import { TotalesAutorretenciones } from '../models/autorretenciones.model';

export const GET_TOTALESAUTORRETENCIONES = '[totalesAutorretenciones] get totalesAutorretenciones';
export const GET_TOTALESAUTORRETENCIONES_SUCCESS = '[totalesAutorretenciones] get totalesAutorretenciones success';
export const GET_TOTALESAUTORRETENCIONES_ERROR = '[totalesAutorretenciones] get totalesAutorretenciones with error';

export const getTotalesAutorretenciones = createAction(
  GET_TOTALESAUTORRETENCIONES,
  props<{projectId: number}>()
);

export const getTotalesAutorretencionesSuccess = createAction(
  GET_TOTALESAUTORRETENCIONES_SUCCESS,
  props<{ totalesAutorretencionesSuccess: TotalesAutorretenciones  }>()
);

export const getTotalesAutorretencionesError = createAction(
  GET_TOTALESAUTORRETENCIONES_ERROR,
  props<{ error: string }>()
);
