import {AfterViewInit, Component, EventEmitter, Inject, Output, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Counts, ModalData} from '@shared/components/modal-upload-balance/modal-models';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-modal-gasto-exterior-errors',
  templateUrl: './modal-gasto-exterior-errors.component.html',
  styleUrls: ['./modal-gasto-exterior-errors.component.scss']
})
export class ModalGastoExteriorErrorsComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() clickerEvent: EventEmitter<string>  = new EventEmitter<string>();

  displayedColumns: string[] = ['cuenta', 'repeticiones'];
  dataSource: MatTableDataSource<Counts>;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ModalGastoExteriorErrorsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ModalData
  ) {
    this.dataSource = new MatTableDataSource(this.data.counts);
  }


  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  public emitingData(datos: any, emiter: string): void {
    let info: any;

    info = {
      emiter,
      data: datos
    };
    this.dialogRef.close(info);
  }

}
