import { NgModule } from '@angular/core';
import {ButtonModule} from 'primeng/button';
import {CardModule} from 'primeng/card';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {InputNumberModule} from 'primeng/inputnumber';
import {MenuModule} from 'primeng/menu';
import {PanelModule} from 'primeng/panel';
import {SkeletonModule} from 'primeng/skeleton';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {ToastModule} from 'primeng/toast';
import {TooltipModule} from 'primeng/tooltip';
import {ProgressBarModule} from 'primeng/progressbar';
import { ChartModule } from 'primeng/chart';
import { AccordionModule } from 'primeng/accordion';

@NgModule({
  exports: [
    ButtonModule,
    CardModule,
    ConfirmDialogModule,
    DropdownModule,
    DialogModule,
    InputNumberModule,
    MenuModule,
    PanelModule,
    SkeletonModule,
    TabViewModule,
    TableModule,
    ToastModule,
    TooltipModule,
    ProgressBarModule,
    ChartModule,
    AccordionModule
  ]
})
export class PrimeNgModule { }
