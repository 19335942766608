<p-toast></p-toast>
<mat-sidenav-container
  autosize
  [hasBackdrop]="true"
  style="overflow-x: hidden !important"
>
  <mat-sidenav
    #sideNav2
    mode="over"
    fixedInViewport="true"
    class="sidenav-2"
    
  >
    <div >
      <div class="close" (click)="sidenav2.close()">
        <mat-icon class="close_icon" svgIcon="close_rounded"></mat-icon>
      </div>
      <mat-nav-list
        *ngFor="let menu of menuOption"
        [ngClass]="{
          list: menu.visible,
          'list d-none': !menu.visible
        }"
        class="nav-list"
      >
        <mat-list-item
          mat-list-item
          class="list_item"
          (click)="goSubmenu(menu.id)"
          [ngClass]="{
            'list is_active': menu.active
          }"
        >
          <div class="list_item_col">
            <mat-icon
              mat-list-icon
              class="list_item_col_icon_one"
              [svgIcon]="menu.icon"
            >
            </mat-icon>

            <div class="list_item_col_text">
              {{ menu.title | uppercase }}
            </div>
          </div>

          <mat-icon
            class="list_item_icon_two"
            [ngClass]="{ rotated: menu.showSub }"
            svgIcon="arrow_down"
          >
          </mat-icon>
        </mat-list-item>
        <ng-container *ngIf="menu.showSub">
          <div class="submenu-style">
            <div
              id="submenu"
              *ngFor="let sub of menu.submenu; index as i"
              [ngClass]="{
                'list_submenu expanded': menu.showSub,
                list_submenu: !menu.showSub,
                sub_active: sub.active
              }"
            >
              <a
                class="list_submenu_btn"
                [ngClass]="{
                  'mt-3': i === 0,
                  space_link: sub?.id,
                  double_height: sub.icon == 'star' || sub.icon == 'wallet'
                }"
                (click)="goToRoute(menu.id, sub)"
              >
                <mat-icon [svgIcon]="sub.icon"></mat-icon>
                <span class="parrafo"> {{ sub.name }} </span>
                <mat-icon
                  *ngIf="sub?.id"
                  class="list_submenu_btn_icon_arrow"
                  [ngClass]="{ rotated: sub.showSubLinks }"
                  svgIcon="arrow_down"
                >
                </mat-icon>
              </a>

              <ng-container *ngIf="sub.showSubLinks">
                <div
                  *ngFor="let link of sub.menuLinks"
                  [ngClass]="{
                    'list_submenu_btn_subLinks expanded': sub.showSubLinks,
                    list_submenu_btn_subLinks: !sub.showSubLinks,
                    sub_active: sub.active
                  }"
                  class="list_submenu_btn_subLinks"
                >
                  <a
                    [class]="
                      link.active
                        ? 'list_submenu_btn_subLinks_link sublinks_active'
                        : 'list_submenu_btn_subLinks_link'
                    "
                    (click)="goToRoute(menu.id, sub, link)"
                  >
                    <mat-icon [svgIcon]="link.icon"></mat-icon>
                    <span>
                      {{ link.name }}
                    </span>
                  </a>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </mat-nav-list>
    </div>
    <div class="sidenav_logo" *ngIf="isExpanded || isShowing">
      <img src="assets/svg/rentaApp_logo_white.svg" />
      <p>V1.1</p>
    </div>
  </mat-sidenav>
  <mat-sidenav
    fixedInViewport="true"
    #menuMobile
    mode="over"
    position="end"
    
  >
    <app-side-bar-mobile
      *ngIf="!inDepuracion"
      (closeSidenav)="$event ? menuMobile.toggle() : ''"
    >
    </app-side-bar-mobile>
  </mat-sidenav>
  <mat-sidenav-content>
    <section [ngClass]="{ contenido: !inDepuracion }">
      <mat-sidenav-container class="content" autosize>
        <header *ngIf="!inDepuracion">
          <app-menu
            [showExtraMenu]="this.isBalance$ | async"
            [projectId]="this.projectId"
            [sizeDisplay]="sizeDisplay"
            (menuMobile)="$event ? menuMobile.toggle() : menuMobile.toggle()"
          >
          </app-menu>
        </header>
        <mat-sidenav
          *ngIf="!inDepuracion"
          #sidenav
          class="sidenav"
          mode="side"
          fixedInViewport="true"
          opened="true"
          (click)="sidenav2.toggle()"
          style="cursor: pointer"
        >
          <div >
            <hr *ngIf="isExpanded || isShowing" />

            <mat-nav-list
              *ngFor="let menu of menuOption"
              [ngClass]="{
                list: menu.visible,
                'list d-none': !menu.visible,
                'list is_active': menu.active
              }"
            >
              <mat-list-item mat-list-item class="list_item">
                <div class="list_item_col">
                  <mat-icon
                    mat-list-icon
                    class="list_item_col_icon_one"
                    [svgIcon]="menu.icon"
                  >
                  </mat-icon>
                </div>
              </mat-list-item>
            </mat-nav-list>
          </div>
        </mat-sidenav>

        <router-outlet></router-outlet>
      </mat-sidenav-container>
    </section>
    <app-button-ticket></app-button-ticket>
  </mat-sidenav-content>
</mat-sidenav-container>
