import { createSelector } from '@ngrx/store';
import { getProjectsModuleState } from '../reducers/index';
import { toArray } from 'lodash';

export const selectBalanceList = createSelector(
  getProjectsModuleState,
  state => state.balance.entities
);

export const selectBalanceById = (id: number) =>
  createSelector(getProjectsModuleState, state => {
  const data: any = state.balance.entities;
  if(data) {
    return toArray(data).find((item: any) => {
      return item.projectId === id;
    });
  } else {
    return {};
  }
});
