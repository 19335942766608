import { Injectable } from "@angular/core";
import { DescuentoTributarioService } from "@app/pages/anex-depuracion/services/descuento-tributario.service";
import { MSG_DURATION, NotifyService } from "@app/shared/services/notify.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { getCalculoDescuento } from "../actions/calculo-descuento.actions";
import { getDonacion, getDonacionError, getDonacionSuccess, setDeleteDonacion, setDeleteDonacionError, setDeleteDonacionSuccess, setDonacion, setDonacionSuccess } from "../actions/donacion.actions";
import { Donacion, DonacionDTO, DonacionDTOSave } from "../models/descuento-tributario.model";

@Injectable()
export class DonacionEffects {
  getDonacion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDonacion),
      switchMap(({ projectId }) => {
        return this.descTService.getDonaciones(projectId).pipe(
          map((donacion) => {
            let donacionSuccess: DonacionDTO = {
              idProject: projectId,
              ...donacion,
            };
            return getDonacionSuccess({ donacionSuccess });
          }),
          catchError((error) => {
            this.notify.error('Algo salio mal!', JSON.stringify(error), 5000, {
              text: 'Entendido',
              orientation: 'izq',
            });
            return of(getDonacionError({ error }));
          })
        );
      })
    )
  );

  saveDonacion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDonacion),
      switchMap(({ donacionForm, projectId }) => {
        return this.descTService
          .save(donacionForm,projectId)
          .pipe(
            map((donacion:DonacionDTO) => {
               let donacionForm: DonacionDTO = {
              idProject: projectId,
              ...donacion
            };
              this.notify.success(
                '',
                'Guardado de registros exitoso',
                MSG_DURATION
              );
              this.store.dispatch(getCalculoDescuento({projectId}));
              return setDonacionSuccess({ donacionForm:donacionForm });
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setDeleteDonacionError({ error }));
            })
          );
      })
    )
  );

  deleteDonacion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDeleteDonacion),
      switchMap(({ projectId, idDonacion,totales }) => {
        return this.descTService
          .deleteDonacion(projectId, idDonacion,totales)
          .pipe(
            map((donacionForm) => {
              this.notify.success(
                '',
                'Borrado de registros exitoso',
                MSG_DURATION
              );
              this.store.dispatch(getCalculoDescuento({projectId}));
              return setDeleteDonacionSuccess({donacionForm} );
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setDeleteDonacionError({ error }));
            })
          );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private descTService: DescuentoTributarioService,
    private store: Store<any>,
    private notify: NotifyService
  ) {}
}
