import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { StatusService } from '../services/status-project.service';

@Pipe({ name: 'getValueInput' })

export class inputValueFormater implements PipeTransform {

  constructor(
  ) { }

  public transform(value: number): number {
    if(Math.sign(value) === 0){
      return 0
    } else {
      return value
    }
  }
}