import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StatusModalComponent } from '../status-modal/status-modal.component';
import { StatusService } from '@app/shared/services/status-project.service';
import { Store, select } from '@ngrx/store';
import { selectCurrentRoute } from '@app/reducers';
import { map } from 'rxjs/operators';
import { StatusListModel } from '@app/store/models/project-status.model';

@Component({
  selector: 'app-annexes-dropdown',
  templateUrl: './annexes-dropdown.component.html',
  styleUrls: ['./annexes-dropdown.component.scss']
})
export class AnnexesDropdownComponent implements OnInit {

  @Input() statusList: StatusListModel[];
  @Input() idProject: number;

  constructor(
    public dialog: MatDialog,
    private store: Store<any>,
  ) { }

  ngOnInit(): void {
  }


  openStatusModal(status: string) {

    const statusModal = this.dialog.open(StatusModalComponent, {
      width: '60%',
      data: {
        status: status,
        idProject: this.idProject
      }
    });
  }


}
