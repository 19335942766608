import { createReducer, on, Action } from '@ngrx/store';
import { rentaExentaAdapter,  RentaExentaState } from '../states';
import * as actionsRentaExenta from '../actions/renta-exenta.actions'

export const initialState: RentaExentaState = rentaExentaAdapter.getInitialState({
});

const rentaExentaReducer = createReducer(
  initialState,

  on(actionsRentaExenta.setCrearRentaExentaSuccess, (state, { rentaExentaCreada }) => {
    state.entities = {};
    state.ids = [];
    return rentaExentaAdapter.upsertOne(rentaExentaCreada, state);
  }),
  
);

export function reducer(state: RentaExentaState | undefined, action: Action) {
  return rentaExentaReducer(state, action);
}
