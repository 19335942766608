import { createReducer, on, Action } from '@ngrx/store';
import { InteresesPresuntosState, interesesPresuntosAdapter } from '@store/states';
import * as actionsIntPresuntos from '@store/actions/intereses-presuntos.actions';

export const initialState: InteresesPresuntosState = interesesPresuntosAdapter.getInitialState({
    list: []
});

const interesesPresuntosReducer = createReducer(
    initialState,
    // on(actionsIntPresuntos.setSharedolerSuccess, (state, { sharedolerSuccess }) => {
    //     state.ids = [];
    //     state.entities = {};
    //     return interesesPresuntosAdapter.upsertOne(sharedolerSuccess, state);
    // }),
    on(actionsIntPresuntos.addSharedoler, (state, { shareholderInfo }) => {
        let newData = !state?.list ? [] : [...state.list];
        newData.push(shareholderInfo);
        return {...state, list: newData}
    }),
    on(actionsIntPresuntos.setShareholder, (state, {shareholderInfo}) => {
        state.ids = [];
        state.entities = {};
        return interesesPresuntosAdapter.upsertOne({shareholderList: shareholderInfo}, state)
    })
);

export function reducer(state: InteresesPresuntosState | undefined, action: Action) {
    return interesesPresuntosReducer(state, action);
  }