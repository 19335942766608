<ng-container *ngIf="link else buttom">
  <p class="link_terms">
    <label (click)="this.openDialog()">{{message}}</label>
  </p>
</ng-container>

<ng-template #buttom>
  <app-button
  [icon]="'check_rounded'"
  [text]="message"
  [idButton]="'signUp'"
  [clase]="'btn_primary'"
  (clicker)="this.openDialog()">
  </app-button>

</ng-template>


