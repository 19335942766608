import { createReducer, on, Action } from '@ngrx/store';
import { getExcesosPresuntivaSuccess, setExcesosPresuntivaSuccess, setDeleteExcesosPresuntivaSuccess } from '../actions';
import { excesosPresuntivaAdapter, ExcesosPresuntivaState } from '../states';

export const initialState: ExcesosPresuntivaState = excesosPresuntivaAdapter.getInitialState({})

const excesosPresuntivaReducer = createReducer(
  initialState,

  on(getExcesosPresuntivaSuccess, (state, { excesosPresuntivaSuccess }) => {
    state.ids = [];
    state.entities = {};
    return excesosPresuntivaAdapter.upsertOne(excesosPresuntivaSuccess, { ...state })
  }),

  on(setExcesosPresuntivaSuccess, (state, { excesosPresuntivaForm }) => {
    state.ids = [];
    state.entities = {};
    return excesosPresuntivaAdapter.upsertOne(excesosPresuntivaForm, state)
  }),

  on(setDeleteExcesosPresuntivaSuccess, (state, { excesosPresuntiva }) => {
    return excesosPresuntivaAdapter.removeOne(excesosPresuntiva.idProject, state)
  }),
)

export function reducer(state: ExcesosPresuntivaState | undefined, action: Action) {
  return excesosPresuntivaReducer(state, action);
}
