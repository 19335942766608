import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EconomicActivityType } from '@app/pages/info-contribuyente/components/info-contribuyente/economic-activity';
import { Pregunta } from '@app/pages/info-contribuyente/components/info-contribuyente/pregunta';
import { Tarifa } from '@app/pages/info-contribuyente/components/info-contribuyente/tarifa';
import { Project } from '@proyectos/proyecto';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Type } from '../interfaces/type';
/**
 * Clase para el manejo de servicios de proyectos
 */
@Injectable({
  providedIn: 'root'
})
export class ListTypeService {
  /**
 * Lista de tipos (behavior)
 */
  private _tipos$ = new BehaviorSubject<Project[]>([]);
  /**
   * Lista de tipos (observable)
   */
  tipos$ = this._tipos$.asObservable();
  /**
   * Cliente para realizar llamados al backend
   * 
   * @param http Cliente Http para llamar a backend
   */
  constructor(private http: HttpClient) {

  }
  /**
   * Funcion para obtener lista de tipos
   * 
   * @param {string} type  tipo para obtener la lista
   * 
   * @returns Lista de tipos
   */
  getTipos(type: string): Observable<Type[]> {
    return this.http.get<Type[]>(`/options/${type}`);
  }
  /**
   * Funcion para obtener lista de actividades
   * 
   * @returns Lista de actividades
   */
   getActividades(): Observable<EconomicActivityType[]> {
    return this.http.get<EconomicActivityType[]>(`/options/economic_activity`);
  }
  /**
   * Funcion para obtener lista de preguntas
   * @param idProyecto id del proyecto
   * @returns Lista de pregunta
   */
   getPreguntas(idProyecto:number): Observable<Pregunta[]> {
    return this.http.get<Pregunta[]>(`/options/preguntas?id=${idProyecto}`);}
    /**
   * Funcion para obtener lista de tarifas
   * @param idProyecto id del proyecto
   * @returns Lista de tarifas
   */
   getTarifas(idProyecto:number): Observable<Tarifa[]> {
    return this.http.get<Tarifa[]>(`/options/tarifas?id=${idProyecto}`);
  }
}