import { get, forOwn, pick } from 'lodash';
import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, AbstractControl } from '@angular/forms';
// import { ES } from '../../helper/validations.es';
// import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-validation-errors',
  templateUrl: './validation-errors.component.html',
  styleUrls: ['./validation-errors.component.scss'],
  exportAs: 'validationErrors'
})
export class ValidationErrorsComponent implements OnInit {

  @Input() public reactiveForm: FormGroup;

  @Input() public controlName: string;

  @Input() public submitAttempt: boolean;

  /**
   * Cuando se forza un mensaje este entra como ng-content dentro
   * de la llamada al componente. Ejemplo:
   * <app-validation-errors
   *    [forceMessage]="true"
   *    [reactiveForm]="form"
   *    controlName="paymentMethods">
   *      Mensaje personalizado
   * </app-validation-errors>
   */

  @Input() public forceMessage: boolean = false;

  /**
   * Acepta un array de strings con nombres de los validadores a mostrar.
   * Ejemplo: showOnly = ['required', 'min']; Con estos parámetros  mostraremos
   * los mensajes de los validadores required y min.
   *
   */
  @Input() public showOnly: string[];

  @Input() public msgs: Object = {};

  @Input() public apiErrors: Object = {};

  /**
   * Mapa de nombres de controles de formulario reactivo. Usado para mostrar
   * mensajes como "El email es obligatorio", en vez de "Este campo es
   * obligatorio". Ejemplo:
   * {
   *    name: "Nombre",
   *    email: "Correo electrónico",
   * }
   */
  @Input() public controlsNamesMap: any;

  public constructor(
    //private translate: TranslateService
  ) { }

  public ngOnInit() { }

  /**
   * Get the translation key fot the given error.
   * @param string controlError
   */
  public getErrorString(controlError: string): string {
    // let msg: string = this.translate.instant(get(ES, 'VALIDATION.' + controlError, 'Invalid field'));
    let msg: string = 'VALIDATION.' + controlError + 'Invalid field';
    msg = this.replaceMsgParams(msg);

    return msg
  }

  /**
   * Reemplaza los parámetros de validación en en mensaje de error.
   * @param msg El mensaje de error al cual reemplazaremos los parámetros.
   */
  private replaceMsgParams(msg: string) {
    // reemplazo los parámetros de validación
    this.validationErrorsKeys.forEach(errorKey => {
      forOwn(get(this.validationErrors, errorKey), (param, key) => {
        msg = msg.replace('{{' + key + '}}', param);
      });
    });

    // reemplazo el parámetro {{field}} con el nombre correspondiente en el
    // mapa de nombres de controles en this.controlsNamesMap, si no hay nombre
    // disponible, pongo "Este campo "
    if (this.controlsNamesMap) {
      forOwn(this.controlsNamesMap, (name: string, control) => {
        if (this.controlName === control) {
          msg = msg.replace(`{{field}}`, 'Campo ' + name.toLowerCase());
        }
      });
    }

    return msg.includes('{{field}}')
      ? msg.replace('{{field}}', 'This field')
      : msg;
  }

  /**
   * Get the translation params based on the error control type;
   * @param string controlError
   */
  public getParams(controlError: string): any {
    const params = {};
    const errors = this.validationErrors;

    switch (controlError) {
      case 'minlength':
      case 'maxlength':
        params['requiredLength'] = errors[controlError].requiredLength;
        break;

      case 'pattern':
        params['requiredPattern'] = errors[controlError].requiredPattern;
        break;

      default:
        break;
    }

    params['value'] = this.controlValue;

    return params;
  }

  get control(): AbstractControl {
    if (this.reactiveForm && this.controlName) {
      return this.reactiveForm.get(this.controlName);
    }

    return this.reactiveForm && !this.controlName
      ? this.reactiveForm
      : new FormControl();
  }

  get controlValue(): any {
    return this.control ? this.control.value : null;
  }

  get showErrors(): boolean {
    return this.hasErrors && this.areErrorsDisplayable ? true : false;
  }

  get areErrorsDisplayable(): boolean {
    try {
      return this.control.dirty || this.control.touched ? true : false;
    } catch (error) {
      return false
      }
  }

  get hasErrors(): boolean {
    return this.hasValidationErrors || this.hasApiErrors ? true : false;
  }

  get hasValidationErrors(): boolean {
    return this.control && this.control.errors === null ? false : true;
  }

  get hasApiErrors(): boolean {
    return this.apiErrors && Object.keys(this.apiErrors).length > 0
      ? true
      : false;
  }

  get validationErrors(): any {
    return this.control
      ? this.showOnly
        ? pick(this.control.errors, this.showOnly)
        : this.control.errors
      : {};
  }

  get validationErrorsKeys(): Array<string> {
    return this.validationErrors ? Object.keys(this.validationErrors) : [];
  }

  get apiErrorsKeys(): Array<string> {
    return this.apiErrors ? Object.keys(this.apiErrors) : [];
  }

  get errorsMap(): any {
    const errors = {};

    this.validationErrorsKeys.forEach(error => {
      errors[error] = this.getErrorString(error);
    });

    return errors;
  }
}
