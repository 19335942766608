import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';

import { ConfirmationService } from 'primeng/api';

import { DepuracionService } from '@depuracion/services/depuracion.service';
import { ListProyectosService } from '@proyectos/services/list-proyectos.service';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { getDepuracion } from '@store/actions/depuracion.actions';
import { NotifyService } from '@app/shared/services/notify.service';
/**
 * Componente que muestra el dialogo de eleccion de nivel de depuracion
 */
@Component({
  selector: 'app-level-dialog',
  templateUrl: './level-dialog.component.html',
  styleUrls: ['./level-dialog.component.scss']
})
export class LevelDialogComponent implements OnInit {
  /**
   * Id de proyecto
   */
  projectId: number = 0;
  /**
   * Nivel seleccionado
   */
  @Output() selectedLevel = new EventEmitter<string>();
  /**
   * Evento que cierra el dialogo
   */
  @Output()
  cerrar = new EventEmitter<boolean>();
  /**
   * Si es verdadero muestra la lista de niveles, si es falso muestra depuracion
   */
  visibleNivel: boolean;
  /**
   * Muestra dialogo de depuracion
   */
  showDepDialog: boolean;
   /**
   * Lista de niveles para elegir para la depuracion
   */
  @Input() niveles:string[];
  /**
   * Formulario para el componente
   */
  form = this.fb.group({
    levelControl: ['', [Validators.required]]
  })
/**
   * Constructor
   * @param fb componente para cargar el formulario
   * @param depService hace llamado al backend para cargar la depuracion
   * @param confirmationService
   * @param toast muestra mensajes en pantalla
   * @param projectSrv hace llamado al backend para cargar los proyectos
   *
   */
  constructor(
    private fb: FormBuilder,
    private store: Store,
    private depService: DepuracionService,
    private confirmationService: ConfirmationService,
    private notify: NotifyService,
    private projectSrv: ListProyectosService
  ) { }
  /**
   * verifica si tiene un nivel elegido para mostrar la lista de niveles o depuracion
 */
  ngOnInit(): void {
    this.projectSrv.currentProject$.subscribe(
      proyecto => {
        if (proyecto) {
          this.projectId = proyecto.id

          const level = localStorage.getItem('level');
          const projectId = +localStorage.getItem('projectId');
          if (!level && this.projectId === projectId) {
            this.showDepDialog = true;
            this.visibleNivel = false;
          } else {
            this.visibleNivel = true;
            this.showDepDialog = false;
          }
        }
      }
    );
  }
/**
 * Realiza la confirmacion del nivel elegido por el usuario, verifica si cuadran las cuentas, si no muestra un mensaje de advertencia, y si da aceptar redirige a la ventana de depuracion
 */
  confirm() {
    if (this.form.valid) {
      const level = this.form.get('levelControl').value;

      this.depService.checkDepLevel(level).subscribe(
        resp => {
          const message = resp.balanceMatching ? `Seleccionó el nivel ${level}. ¿Desea continuar?` : `Las cuentas del nivel ${level} no coinciden. ¿Desea continuar?`;

          this.confirmationService.confirm({
            message,
            accept: () => {
              localStorage.setItem('level', level);

              this.store.dispatch(getDepuracion({projectId: this.projectId, level: level}));

              this.depService.getDepuracion(this.projectId, level).subscribe(
                () => {
                  this.visibleNivel = false;
                  this.showDepDialog = true;
                  const depUrl = environment.appUrl + `/proyecto/${this.projectId}/depuracion/dep`;
                  window.open(depUrl);

                  // this.toast.add({
                  //   severity: 'success',
                  //   summary: 'Nivel configurado',
                  //   detail: 'Se configuró el nivel ' + level + " correctamente"
                  // })

                  this.notify.success(
                    'Nivel configurado!',
                    `Se configuró el nivel ${level} correctamente`,
                    5000
                );

                },

                () =>

                // this.toast.add({
                //   severity: 'error',
                //   summary: 'Error al actualizar',
                //   detail: 'Ocurrió un error inesperado, no fue posible cargar el nivel de depuración'
                // })

                this.notify.error(
                  'Error al actualizar!',
                  'Ocurrió un error inesperado, no fue posible cargar el nivel de depuración',
                  5000
                )

              );
              this.cerrarDialogo();
            }
          });
        }
      )
    } else {
      alert('Debe seleccionar un nivel para continuar');
    }
  }
/**
 * Envia el evento para cerrar el dialogo
 */
  cerrarDialogo() {
    this.cerrar.emit(true);
  }
/**
 * Envia el nivel seleccionado por el usuario
 * @param level nivel elegido
 */
  selectLevel(level: string) {
    this.selectedLevel.emit(level);
  }

}
