import { createSelector } from '@ngrx/store';
import { getProjectsModuleState } from '../reducers/index';
import { toArray } from 'lodash';
import { InsolvenciaProjectStore } from '../models';


export const selectPerdidasCompensarInsolvenciaList = createSelector(
  getProjectsModuleState,
  state => state.perdidasCompensarInsolvencia.entities
);

export const selectPerdidasCompensarInsolvenciaById = (id: number) =>
  createSelector(getProjectsModuleState, state => {
  const data: any = state.perdidasCompensarInsolvencia.entities;
  if(data) {
    return toArray(data).find((item: InsolvenciaProjectStore) => {
      return item.projectId === id;
    });
  } else {
    return {};
  }
});
