import { Injectable } from '@angular/core';
import { ResponseLoading } from '@app/pages/info-contribuyente/services/balance.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  isLoading$ = new Subject<boolean>();
  isMessage$ = new Subject<string>();
  isPercent$ = new Subject<number>();
  isDeterminate$ = new Subject<string>();
  isLoader$ = new Subject<boolean>();

  public show(message: string = null): void {
    this.isLoading$.next(true);
    this.isLoader$.next(true);
    this.isMessage$.next(message);
  }

  showPercent(data: ResponseLoading): void {
    this.isLoader$.next(false);
    this.isLoading$.next(data.loading);
    this.isMessage$.next(data.msg);
    this.isPercent$.next(data.percent);
    this.isDeterminate$.next(data.determinate);
  }

  hide(): void {
    this.isLoading$.next(false);
  }

}
