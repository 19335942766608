import { createReducer, on, Action } from '@ngrx/store';
import { DepuracionState, depuracionAdapter } from '@store/states';
import { getDepuracionSuccess, setDeleteDepuracionSuccess,  setDepuracionInfoSuccess } from '@store/actions/depuracion.actions';

export const initialState: DepuracionState = depuracionAdapter.getInitialState({})

const depuracionReducer = createReducer(
  initialState,

  on(getDepuracionSuccess, (state, { depuracionSuccess }) => {
    state.ids = [];
    state.entities = {};
    return depuracionAdapter.upsertOne(depuracionSuccess, { ...state })
  }),

  on(setDepuracionInfoSuccess, (state, { depuracion }) => {
    state.ids = [];
    state.entities = {};
    return depuracionAdapter.upsertOne(depuracion, state)
  }),

  on(setDeleteDepuracionSuccess, (state, { depuracion }) => {
    return depuracionAdapter.removeOne(depuracion.id, state)
  }),
)

export function reducer(state: DepuracionState | undefined, action: Action) {
  return depuracionReducer(state, action);
}
