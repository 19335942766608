import { createSelector } from '@ngrx/store';
import { getProjectsModuleState } from '../reducers/index';
import { toArray } from 'lodash';

export const selectProyectList = createSelector(
  getProjectsModuleState,
  (state) => state.projects.entities
);
export const selectProjectById = (id: number) =>
  createSelector(getProjectsModuleState, (state) => {
    const data: any = state.projects.entities;
    if (data) {
      return toArray(data).find((item) => {
        return item.id === id;
      });
    } else {
      return {};
    }
  });

export const selectYearProject = createSelector(
  getProjectsModuleState,
  (state) => state.projects.id_year
);
export const selectYearTasaProject = createSelector(
  getProjectsModuleState,
  (state) => state.projects.tasa_anual
);
export const selectDayTasaProject = createSelector(
  getProjectsModuleState,
  (state) => state.projects.tasa_diaria
);

export const  selectProtyectId = createSelector(
  getProjectsModuleState,
  (state) => state.project_status.ids[0]
);

export const selectIdProject = createSelector(
  getProjectsModuleState,
  (state) => state.projects.idProject
);

export const selectNitProject = createSelector(
  getProjectsModuleState,
  (state) => state.projects.nit
);


export const selectUvtProject = createSelector(
  getProjectsModuleState,
  (state) => state.projects.uvt
);