<li *ngFor="let item of statusList; index as i">
    <div class="message-box">
        <p class="title-style" (click)="openStatusModal(item.status)">{{item.status}}</p>
        <div class="text-center">
            <p class="aux-text-style">{{item.annexAmount}}</p>
            <p class="aux-text-style fw-bold">Anexos</p>
        </div>
        <mat-icon svgIcon="circle" class="state_circle"
            [style]="'color: ' + item.color">
        </mat-icon>
    </div>
    <div class="header_line">
        <hr class="hr1">
        <hr class="hr2">
    </div>
    
</li>