import { createAction, props } from '@ngrx/store';
import { Seccion2GmfStore, Seccion2Tabla } from '../models/cuatrox-mil-gmf.model';

export const GET_SECCION2GMF = '[seccion2gmf] get seccion2gmf';
export const GET_SECCION2GMF_SUCCESS = '[seccion2gmf] get seccion2gmf success';
export const GET_SECCION2GMF_ERROR = '[seccion2gmf] get seccion2gmf with error';

export const SET_SECCION2GMF = '[seccion2gmf] set seccion2gmf save info';
export const SET_SECCION2GMF_SUCCESS = '[seccion2gmf] set seccion2gmf save info success';
export const SET_SECCION2GMF_ERROR = '[seccion2gmf] set seccion2gmf save info with error';

export const SET_DELETE_SECCION2GMF = '[seccion2gmf] set delete seccion2gmf';
export const SET_DELETE_SECCION2GMF_SUCCESS = '[seccion2gmf] set delete seccion2gmf success';
export const SET_DELETE_SECCION2GMF_ERROR = '[seccion2gmf] set delete seccion2gmf with error';

//get seccion2gmf
export const getSeccion2Gmf = createAction(
  GET_SECCION2GMF,
  props<{projectId: number}>()
);

export const getSeccion2GmfSuccess = createAction(
  GET_SECCION2GMF_SUCCESS,
  props<{ seccion2GmfSuccess: Seccion2GmfStore  }>()
);

export const getSeccion2GmfError = createAction(
  GET_SECCION2GMF_ERROR,
  props<{ error: string }>()
);

//create seccion2gmf
export const setSeccion2Gmf = createAction(
  SET_SECCION2GMF,
  props<{ seccion2GmfForm:Seccion2GmfStore, projectId: number }>()
);

export const setSeccion2GmfSuccess = createAction(
  SET_SECCION2GMF_SUCCESS,
  props<{ seccion2GmfForm:Seccion2GmfStore }>()
);

export const setSeccion2GmfError = createAction(
  SET_SECCION2GMF_ERROR,
  props<{ error: string }>()
);

//delete seccion2gmf
export const setDeleteSeccion2Gmf = createAction(
  SET_DELETE_SECCION2GMF,
  props<{ projectId: number, objSeccion2Gmf: Seccion2Tabla[] }>()
);

export const setDeleteSeccion2GmfSuccess = createAction(
  SET_DELETE_SECCION2GMF_SUCCESS,
  props<{ seccion2Gmf: any }>()
);

export const setDeleteSeccion2GmfError = createAction(
  SET_DELETE_SECCION2GMF_ERROR,
  props<{ error: string }>()
);
