<ng-container *ngIf="iconDir === 'der' else iconIzq">
    <div [class]="'btn ' + type" [id]="idButton" (click)="onEmit()" [style]="background !== '' ? 'background-color:' + background: ''">
        <div class="texto">
            {{text}}
        </div>
    
        <div  class="icono">
            <mat-icon [style]="color !== '' ? 'color:' + color: ''" [svgIcon]="icon"></mat-icon>
        </div>
    </div>
</ng-container>

<ng-template #iconIzq>
    <div [class]="'btn btn_' + type" [id]="idButton" (click)="onEmit()" [style]="background !== '' ? 'background-color:' + background: ''">
        <div *ngIf="icon" class="icono">
            <mat-icon [style]="color !== '' ? 'color:' + color: ''" [svgIcon]="icon"></mat-icon>
        </div>
        
        <div class="texto">
            {{text}}
        </div>
    
    </div>
</ng-template>