import { createSelector } from '@ngrx/store';
import { getProjectsModuleState } from '../reducers/index';
import { toArray } from 'lodash';


export const selectSeccion1GmfList = createSelector(
  getProjectsModuleState,
  state => state.seccion1Gmf.entities
);

export const selectSeccion1GmfById = (id: number) =>
  createSelector(getProjectsModuleState, state => {
  const data: any = state.seccion1Gmf.entities;
  if(data) {
    return toArray(data).find(item => {
      return item.id === id;
    });
  } else {
    return {};
  }
});
