import * as actionsProvisionImpuestoGanancia from '../actions/provision-impuesto-ganancia.actions';
import { NotifyService } from '@app/shared/services/notify.service';
import { ProvisionService } from '@app/pages/anex-informacion/services/provision.service';
import { map, switchMap, catchError } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { ProvisionImpuestoGananciaStore } from '../models/provision-impuesto-ganancia.model';
import { Provision } from '@app/pages/anex-informacion/model/provision';

@Injectable()
export class ProvisionImpuestoGananciaEffects {
  getProvison$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsProvisionImpuestoGanancia.getProvisionImpuestoGanancia),
      switchMap((action) => {
        return this.provisionService.getProvision().pipe(
          map((provision: Provision) => {
            const provisionStore: ProvisionImpuestoGananciaStore = {
              projectId: action.id,
              provision,
            };
            return actionsProvisionImpuestoGanancia.getProvisionImpuestoGananciaSuccess(
              {
                provision: provisionStore,
              }
            );
          }),
          catchError((error) => {
            this.notify.error(
              'Algo salio mal! ',
              'No pudimos cargar la información del contribuyente',
              4000
            );
            return of(
              actionsProvisionImpuestoGanancia.getProvisionImpuestoGananciaFail(
                { error }
              )
            );
          })
        );
      })
    )
  );

  setProvision$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsProvisionImpuestoGanancia.setProvisionImpuestoGanancia),
      switchMap((action) => {
        return this.provisionService.saveProvision(action.payload).pipe(
          map((provisionSaved: Provision) => {
            const provision: ProvisionImpuestoGananciaStore = {
              projectId: action.id,
              provision: provisionSaved,
            };
            return actionsProvisionImpuestoGanancia.setProvisionImpuestoGananciaSuccess(
              {
                provisionSaved: provision,
              }
            );
          }),
          catchError((error) => {
            this.notify.error(
              'Algo salio mal! ',
              'No pudimos guardar la infromación del contribuyente',
              4000
            );
            return of(
              actionsProvisionImpuestoGanancia.setProvisionImpuestoGananciaFail(
                { error }
              )
            );
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private notify: NotifyService,
    private provisionService: ProvisionService
  ) {}
}
