import { createSelector } from '@ngrx/store';
import { getProjectsModuleState } from '../reducers/index';
import { toArray } from 'lodash';


// export const selectProyectList = createSelector(
//   getProjectsModuleState,
//   state => state.projects.entities
// );

export const selectStatusProjectById = (id: number) =>
  createSelector(getProjectsModuleState, state => {
  const data: any = state.project_status.entities;
  if(data) {
    return toArray(data).find(item => {
      return item.id === id;
    });
  } else {
    return {};
  }
});
