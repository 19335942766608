import { createReducer, on, Action } from '@ngrx/store';
import { getProjectListSuccess, setProjectInfoSuccess, setDeleteProjectSuccess } from '@store/actions';
import { ExtrasState, extrasAdapter } from '@store/states';
import { getExtrasListSuccess } from '../actions/extras.actions';

export const initialState: ExtrasState = extrasAdapter.getInitialState({
  // pageInfo: {
  //   page: 0,
  //   total: 0,
  // }
})

const extrasReducer = createReducer(
  initialState,

  on(getExtrasListSuccess, (state, { extrasList }) => {
    return extrasAdapter.setAll(extrasList, { ...state })
  }),

  // on(setProjectInfoSuccess, (state, { project }) => {
  //   return extrasAdapter.upsertOne(project, state)
  // }),

  // on(setProjectInfoSuccess, (state, { project, action }) => {
  //   if (action === 'update') {
  //     return projectAdapter.upsertOne(project, state)
  //   }
  //   return projectAdapter.addOne(project, state)
  // }),

  // on(setDeleteProjectSuccess, (state, { project }) => {
  //   return extrasAdapter.removeOne(project.id, state)
  // }),
)

export function reducer(state: ExtrasState | undefined, action: Action) {
  return extrasReducer(state, action);
}
