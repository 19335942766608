import { Component, Input } from '@angular/core';

@Component({
  selector: 'msj-ajustes-fisc',
  templateUrl: 'msj-ajustes-fisc.component.html',
  styles: [`
    p {
      color: #043E77;
      font-size: 18px;
      font-weight: 600;
      margin: 0 .5rem ;
    }
  `]
})
export class MsjAjustesFiscComponent {
  @Input() showDepuracion: boolean = true;
}
