import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from './spinner.service';

@Injectable({
  providedIn: 'root'
})
export class SpinnerInterceptorService implements HttpInterceptor {

  public excludeRoutes: string[] = [
    'formato2516/ERI',
    'project_status',
    'auth/login',
    'settings/manage_users',
    'settings/profile',
    'plans/pricing',
    'depuracion/dep',

  ];


  constructor(
    private spinner: SpinnerService,
    ) { }

    public validatorExcludes(url: string): any {
      this.excludeRoutes.forEach((exc: string, i: number) => {
        if(url.includes(exc) && i < this.excludeRoutes.length){
          return true
        } else {
          return false
        }
      });

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if(this.validatorExcludes(req.url) || req.url.includes('/project_status')){
        return next.handle(req)
      } else {
        return next.handle(req)

        // this.spinner.show();
        // return next.handle(req).pipe(
        //   finalize(() => this.spinner.hide())
        // );
      }
    }

    
}
