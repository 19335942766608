export interface TipoCuentaGravable {
    id:          number,
    nameAccount: string,
}

export interface CuentaRentaGravable {
    id?: number;
    nameAccount:       string;
    account:           string;
    renglon:           string;
    e2516:             string;
    menorValorFiscal:  boolean;
    mayorValorFiscal:  boolean;
    convertionEnabled: boolean;
}

export interface RenglonRentaGravable {
    id: number,
    account: number,
    accountName: string,
    description: string,
    description2516: string,
    e2516: number,
    efectoConversion: number,
    fiscal: boolean,
    item: string,
    mayorValorFiscal: number,
    menorValorFiscal: number,
    renglon: number
}

export interface CuentaRentaGravableMore {
    account: RenglonDepuracion,
    more?: number
} 

export interface RenglonDepuracion {
    id:                      number,
    account:                 number | string,
    accountName:             string,
    description2516:         string,
    description:             string;
    e2516:                   string,
    efectoConversion:        number,
    item:                    string,
    menorValorFiscal:        number,
    mayorValorFiscal:        number,
    renglon:                 string,
    fiscal:                  boolean,
    valorContable:           number
}

export const OTRO : CuentaRentaGravable= {
    id: -1,
    nameAccount: '',
    account: '',
    renglon: '',
    e2516: '',
    convertionEnabled: true,
    menorValorFiscal: true,
    mayorValorFiscal: true
}

export const RENGLONES_DEP : RenglonDepuracion[] = [
    {
        id:                      1,
        account:                 1998,
        accountName:             'Activos reconocidos solamente para fines fiscales',
        description2516:         'Activos reconocidos solamente para fines fiscales',
        description:             'Description example',
        e2516:                   '143',
        efectoConversion:        0,
        item:                    '',
        menorValorFiscal:        12_454,
        mayorValorFiscal:        123_098_223,
        renglon:                 '43',
        fiscal:                  true,
        valorContable:           123_289_223_233
    },
    {
        id:                      2,
        account:                 2999,
        accountName:         'Pasivos reconocidos solamente para fines fiscales',
        description2516:         'Pasivos reconocidos solamente para fines fiscales',
        description:             'Es una cuenta pasiva',
        e2516:                   '204',
        efectoConversion:        0,
        item:                    'Item example',
        menorValorFiscal:        0,
        mayorValorFiscal:        456_875,
        renglon:                 '45',
        fiscal:                  false,
        valorContable:           879_234_020
    },
    {
        id:                      3,
        account:                 4999,
        accountName:         'Recuperación de deducciones sin incidencia contable',
        description2516:         'Recuperación de deducciones sin incidencia contable',
        description:             '',
        e2516:                   '143',
        efectoConversion:        0,
        item:                    '',
        menorValorFiscal:        12_454,
        mayorValorFiscal:        123_098_223,
        renglon:                 '43',
        fiscal:                  true,
        valorContable:           123_289_223_233
    },
    {
        id:                      3,
        account:                 4998,
        accountName:         'Mayor Ingreso - Precios de Transferencia',
        description2516:         'Mayor Ingreso - Precios de Transferencia',
        description:             '',
        e2516:                   '120',
        efectoConversion:        0,
        item:                    '',
        menorValorFiscal:        0,
        mayorValorFiscal:        0,
        renglon:                 '57',
        fiscal:                  true,
        valorContable:           0
    },
    {
        id:                      4,
        account:                 4997,
        accountName:         'Otros ingresos fiscales y no incluidos contablemente',
        description2516:         'Otros ingresos fiscales y no incluidos contablemente',
        description:             'Description example',
        e2516:                   '121',
        efectoConversion:        0,
        item:                    '',
        menorValorFiscal:        0,
        mayorValorFiscal:        0,
        renglon:                 '57',
        fiscal:                  false,
        valorContable:           0
    },
]