import { createAction, props } from '@ngrx/store';
import { InsolvenciaProject, InsolvenciaProjectStore } from '../models';

export const GET_PERDIDASCOMPENSAR_INSOLVENCIA= '[PerdidasCompensarInsolvencia] get PerdidasCompensar Insolvencia ';
export const GET_PERDIDASCOMPENSAR_INSOLVENCIA_SUCCESS = '[PerdidasCompensarInsolvencia] get PerdidasCompensar Insolvencia success';
export const GET_PERDIDASCOMPENSAR__INSOLVENCIA_ERROR = '[PerdidasCompensarInsolvencia] get PerdidasCompensar Insolvencia with error';

export const SET_PERDIDASCOMPENSAR_INSOLVENCIA = '[PerdidasCompensarInsolvencia] set PerdidasCompensar Insolvencia save info';
export const SET_PERDIDASCOMPENSAR_INSOLVENCIA_SUCCESS = '[PerdidasCompensarInsolvencia] set PerdidasCompensar Insolvencia save info success';
export const SET_PERDIDASCOMPENSAR_INSOLVENCIA_ERROR = '[PerdidasCompensarInsolvencia] set PerdidasCompensar Insolvencia save info with error';


//get PerdidasCompensar Insolvencia Seccion 3
export const getPerdidasCompensarInsolvencia = createAction(
  GET_PERDIDASCOMPENSAR_INSOLVENCIA,
  props<{projectId: number}>()
);

export const getPerdidasCompensarInsolvenciaSuccess = createAction(
  GET_PERDIDASCOMPENSAR_INSOLVENCIA_SUCCESS,
  props<{ insolvenciaCompensarSuccess: InsolvenciaProjectStore  }>()
);

export const getPerdidasCompensarInsolvenciaError = createAction(
  GET_PERDIDASCOMPENSAR__INSOLVENCIA_ERROR,
  props<{ error: string }>()
);

//create PerdidasCompensar Insolvencia Seccion 3
export const setPerdidasCompensarInsolvencia = createAction(
  SET_PERDIDASCOMPENSAR_INSOLVENCIA,
  props<{ perdidasCompensarForm: InsolvenciaProject, projectId: number }>()
);

export const setPerdidasCompensarInsolvenciaSuccess = createAction(
  SET_PERDIDASCOMPENSAR_INSOLVENCIA_SUCCESS,
  props<{ insolvenciaCompensarSuccess: InsolvenciaProjectStore }>()
);

export const setPerdidasCompensarInsolvenciaError = createAction(
  SET_PERDIDASCOMPENSAR_INSOLVENCIA_ERROR,
  props<{ error: string }>()
);

