import { createReducer, on, Action } from '@ngrx/store';
import { registerESFAdapter, RegistroESFState } from '../states';
import * as actionsRegistroESF from '@store/actions/esf.actions';

export const initialState: RegistroESFState = registerESFAdapter.getInitialState({
});

const registroESFReducer = createReducer(
  initialState,

  on(actionsRegistroESF.getEsfSuccess, (state, { resgiterEsf }) => {
    state.entities = {};
    state.ids = [];
    return registerESFAdapter.upsertOne(resgiterEsf, state)
  }),

  on(actionsRegistroESF.setEsfSuccess, (state, { resgiterEsfSaved }) => {
    state.entities = {};
    state.ids = [];
    return registerESFAdapter.upsertOne(resgiterEsfSaved, state)
  }),
);

export function reducer(state: RegistroESFState | undefined, action: Action) {
  return registroESFReducer(state, action);
}
