import { createReducer, on, Action } from '@ngrx/store';
import { getProjectListSuccess, setProjectInfoSuccess, setDeleteProjectSuccess, setYearProjectSuccess, setDayTasaProjectSuccess, setYearTasaProjectSuccess, setIdProjectSuccess, setNitProjectSuccess, setUvtProject } from '@store/actions/projects.actions';
import { ProjectState, projectAdapter } from '@store/states/project.state';

export const initialState: ProjectState = projectAdapter.getInitialState({
  id_year:0,
  tasa_diaria: 0,
  tasa_anual: 0,
  idProject: 0,
  nit: 0,
  uvt: 0
});

const { selectAll } = projectAdapter.getSelectors();

const projectReducer = createReducer(
  initialState,

  on(getProjectListSuccess, (state,  { projectList }) => {
    return projectAdapter.setAll(projectList, state)
  }),

  on(setProjectInfoSuccess, (state, { projectSuccess }) => {
    return projectAdapter.upsertOne(projectSuccess, state)
  }),

  on(setDeleteProjectSuccess, (state, { project }) => {
    return projectAdapter.removeOne(project.id, state)
  }),
  on(setYearProjectSuccess, (state, { year }) => {
    return {...state,id_year:year}
  }),
  on(setDayTasaProjectSuccess, (state, { diaria }) => {
    return {...state,tasa_diaria:diaria}
  }),
  on(setYearTasaProjectSuccess, (state, { anual }) => {
    return {...state,tasa_anual:anual}
  }),
  on(setIdProjectSuccess, (state, { idProject }) => {
    return {...state,idProject:idProject}
  }),
  on(setNitProjectSuccess, (state, { nit }) => {
    return {...state,nit:nit}
  }),
  on(setUvtProject, (state, { uvt }) => {
    return {...state,uvt};
  }),
)

export function reducer(state: ProjectState | undefined, action: Action) {
  return projectReducer(state, action);
}