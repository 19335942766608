<mat-dialog-content class="modalInput">
  <div class="modalInput_close">
    <mat-icon mat-dialog-close svgIcon="close_rounded"></mat-icon>
  </div>
  <form class="modalInput_contain" [formGroup]="formGroup">
    <label>{{ data.textOne }}</label>
    <input
      type="number"
      pattern="^[0-9]+"
      formControlName="renglones"
      class="form-control"
      [ngClass]="{ 'is-invalid': isInvalid('renglones') }"
    />
    <div class="modalInput_buttons">
      <app-button
        *ngFor="let but of data.buttons"
        [icon]="but.icon"
        [text]="but.text"
        [idButton]="but.id"
        [clase]="but.clase"
        [iconDir]="but.direction"
        (clicker)="this.emitingData($event)"
      >
      </app-button>
    </div>
  </form>
</mat-dialog-content>
