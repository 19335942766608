import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {BehaviorSubject, EMPTY, Observable, Subject, of} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {map,switchMap, tap} from 'rxjs/operators';
import {ListProyectosService} from '@proyectos/services/list-proyectos.service';
import {Project} from '@store/models';
import { CalculoDescuentoDTO, CalculoDescuentoDTOSave, CalculusDtResponseModel, Donacion, DonacionDTO, DonacionDTOSave, ImpuestoExterior, ImpuestoExteriorDTO, ImpuestoExteriorDTOSave, OTRO_CONCEPTO, Pais, PorcentajesDonacion, Tarifa, TotalDonacion, TotalImpuestoExterior } from '@app/store/models/descuento-tributario.model';

@Injectable({
  providedIn: 'root'
})
export class DescuentoTributarioService {

  currentDonaciones: Donacion[] = [];
  private currentListDonaciones$ = new BehaviorSubject<Donacion[]>([]);
  currentListDonacionesAction$ = this.currentListDonaciones$.asObservable();
  private currentProject: Project;
  private currentTotal$ = new Subject<TotalDonacion>();
  currentTotalAction$ = this.currentTotal$.asObservable();
  private currentConceptoList$ = new BehaviorSubject<PorcentajesDonacion[]>([]);
  currentConceptoListAction$ = this.currentConceptoList$.asObservable();
  private anexPendientes$ = new BehaviorSubject(false);
  anexPendientesAction$ = this.anexPendientes$.asObservable();

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private prjSrv: ListProyectosService
  ) {
  }

  save(donacion: DonacionDTOSave,id:number): Observable<DonacionDTO> {
    return this.http.post<DonacionDTO>(`/descuentos_tributarios/savePriSec?id=${id}`, donacion);
  }

  saveImpuestoExterior(impuestoExterior: ImpuestoExteriorDTOSave,id:number): Observable<ImpuestoExteriorDTO> {
    return this.http.post<ImpuestoExteriorDTO>(`/descuentos_tributarios/saveSegSec?id=${id}`, impuestoExterior);
  }

  saveImpuestoExteriorTotal(impuestoExterior: TotalImpuestoExterior,id:number): Observable<TotalImpuestoExterior> {
    return this.http.post<TotalImpuestoExterior>(`/descuentos_tributarios/saveTotalesSegSec?id=${id}`, impuestoExterior);
  }

  saveCalculoDescuento(calculoDescuento: CalculoDescuentoDTOSave,id:number): Observable<CalculoDescuentoDTO> {
    return this.http.post<CalculoDescuentoDTO>(`/descuentos_tributarios/saveTerSec?id=${id}`, calculoDescuento);
  }

  getDonaciones(id:number): Observable<DonacionDTO> {
          return this.http.get<DonacionDTO>(`/descuentos_tributarios/getPriSec?id=${id}`);
  }

  getImpuestosExterior(id:number): Observable<ImpuestoExteriorDTO> {
    return this.http.get<ImpuestoExteriorDTO>(`/descuentos_tributarios/getSegSec?id=${id}`);
}

getCalculoDescuento(id:number): Observable<CalculoDescuentoDTO> {
  return this.http.get<CalculoDescuentoDTO>(`/descuentos_tributarios/getTerSec?projectId=${id}`);
}

  getConceptos(): Observable<PorcentajesDonacion[]> {
    return this.http.get<PorcentajesDonacion[]>('/concepto_donaciones/donacion').pipe(
      tap(conceptos => this.currentConceptoList$.next(conceptos)),
      map(conceptos => {
        conceptos.unshift(OTRO_CONCEPTO);
        return conceptos;
      })
    );
  }

  getPaises(): Observable<Pais[]> {
    return this.http.get<Pais[]>('/descuentos_tributarios/paises');
  }

  getTarifas(id:number): Observable<Tarifa[]> {
    return this.http.get<Tarifa[]>('/descuentos_tributarios/getImpuestosRenta?id='+id);
  }

  calcularTotales(list:Donacion[],id:number):TotalDonacion{
    const totales: TotalDonacion = {
      conCertificacionDeLey: 0,
      noDescuentoTributario: 0,
      descuentoTributario: 0,
      sinCertificiacionDeLey: 0,
      valorTotalDescuentos: 0,
      valorTotalDonaciones: 0
    }
    let lista
    if(id>0){lista=list.filter(i=>i.id!=id);}
    else{
      lista=list;
    }
      //let lista=list.filter(i=>i.id!=id);
      let sumaValorDonaciones=0;
      let sumaValorDescuentos=0;
      let sumaconCertificacionDeLey=0;
      let sumasinCertificacionDeLey=0;
      let sumaconDescuento=0;
      let sumasinDescuento=0;
      lista.forEach(l=>{sumaValorDonaciones=sumaValorDonaciones+l.valorDonacion;
        sumaValorDescuentos=sumaValorDescuentos+l.valorDescuento;
        if(l.descripcion=='Con Certificados y Soportes exigidos de Ley')
        {
          sumaconCertificacionDeLey=sumaconCertificacionDeLey+l.valorDonacion;
        }
        if(l.descripcion=='Sin certificados ni Soportes de Ley')
        {
          sumasinCertificacionDeLey=sumasinCertificacionDeLey+l.valorDonacion;
        }
        if(l.posicionFiscal=='Descuento Tributario')
        {
          sumaconDescuento=sumaconDescuento+l.valorDescuento;
        }
        if(l.posicionFiscal=='No es Descuento tributario')
        {
          sumasinDescuento=sumasinDescuento+l.valorDescuento;
        }
      })
      totales.valorTotalDonaciones=sumaValorDonaciones;
      totales.valorTotalDescuentos=sumaValorDescuentos;
      totales.conCertificacionDeLey=sumaconCertificacionDeLey;
      totales.sinCertificiacionDeLey=sumasinCertificacionDeLey;
      totales.descuentoTributario=sumaconDescuento;
      totales.noDescuentoTributario=sumasinDescuento;
    return totales;
  }

  calcularTotalesImpuestoExterior(list:ImpuestoExterior[],id:number,totalGO:number):TotalImpuestoExterior{
    const totales: TotalImpuestoExterior = {
      descuentosTributarios:0,
      impuestosPagadosExterior:0,
      topeMaximoPagoColombia:0,
      totalAnexo:0,
      totalGO:0
    }
    let lista:ImpuestoExterior[];
    if(id>0){lista=list.filter(i=>i.id!=id);}
    else{
      lista=list;
    }
      let sumaValorColombia=0;
      let sumaValorDescuentos=0;
      let sumaValorExterior=0;
      lista.forEach(l=>{sumaValorColombia=sumaValorColombia+l.impuestoPagadoColombia;
        sumaValorExterior=sumaValorExterior+l.impuestoPagadoExterior;
        sumaValorDescuentos=sumaValorDescuentos+l.descuentoTributario;
      })
      totales.impuestosPagadosExterior=sumaValorExterior;
      totales.descuentosTributarios=sumaValorDescuentos;
      totales.topeMaximoPagoColombia=sumaValorColombia;
      totales.totalGO=totalGO;
      totales.totalAnexo=totales.descuentosTributarios - totalGO;
    return totales;
  }


  deleteDonacion(
    projectId: number,
    idDonacion: number,
    totales:TotalDonacion
  ): Observable<DonacionDTO> {
    return this.http.request<DonacionDTO>(
      'delete',
      `/descuentos_tributarios/delPriSec?idProject=${projectId}&idRow=${idDonacion}`,
      {body:totales}
    );
  }
  deleteImpuestoExterior(
    projectId: number,
    idImpuestoExterior: number,
    totales:TotalImpuestoExterior
  ): Observable<ImpuestoExteriorDTO> {
    return this.http.request<ImpuestoExteriorDTO>(
      'delete',
      `/descuentos_tributarios/delSegSec?idProject=${projectId}&idRow=${idImpuestoExterior}`,
      {body:totales}
    );
  }
  getAccountValueDepuracion(projectId: number, account: number): Observable<any> {
    return this.http.get(
      `/autorentenciones/accountValue?projectId=${projectId}&account=${account}`
    );
  }
  changeAnexPendiente(status: boolean): void {
    this.anexPendientes$.next(status);
  }

  getCalculusTable(idProject: number): Observable<object> {
    return this.http.get(`/descuentos_tributarios/descuentosTributariosTotal?projectId=${idProject}`);
  }

  putCalculusTable(idProject: number, data: CalculusDtResponseModel[]): Observable<object> {
    return this.http.put(`/descuentos_tributarios/descuentosTributariosTotal?projectId=${idProject}`, data);
  }

}
