import { Component, EventEmitter, Input, Output, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-modal-cabecera-grl',
  templateUrl: './modal-cabecera-grl.component.html',
  styleUrls: ['./modal-cabecera-grl.component.scss']
})

export class ModalCabeceraGrlComponent implements OnInit {
  @Input() iconTitle: string = '';
  @Input() title: string = '';
  //@Input() iconClose: any[] = [];
  @Output() clickerEvent: EventEmitter<string>  = new EventEmitter<string>();

  constructor(
  ) { }


  ngOnInit(){
  }

  public onEmit(event: any){
    this.clickerEvent.emit(event)
  }
}
