import { createReducer, Action } from '@ngrx/store';
import {
  afectacionFiscalAdapter,
  AfectacionFiscalState,
} from '../states/afectacion-fiscal.state';
export const initialState: AfectacionFiscalState =
  afectacionFiscalAdapter.getInitialState({});

const afectacionFiscalReducer = createReducer(initialState);

export function reducer(
  state: AfectacionFiscalState | undefined,
  action: Action
) {
  return afectacionFiscalReducer(state, action);
}
