import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface callToAction {
  idButton: string
  icon: string
  label: string
  iconDir: 'izq' | 'der'
}

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})

export class EmptyStateComponent {
  @Input() icon: string = '';
  @Input() message: string = '';
  @Input() button: callToAction;


  @Output() clicker: EventEmitter<string>  = new EventEmitter<string>();

  constructor(
  ) { }

  public onEmit(id: string){
    this.clicker.emit(id);
  }
}
