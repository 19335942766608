import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { map, switchMap, catchError, tap, debounceTime } from "rxjs/operators";
import { of } from "rxjs";
import { getExtrasList, getExtrasListSuccess, getExtrasListError } from "../actions/extras.actions";
import { ListTypeService } from "@app/shared/services/type-list.service";

@Injectable()
export class ExtrasEffects {

  getExtrasList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getExtrasList),
      switchMap(() => {
        return this.listTypeService.getActividades().pipe(
          map((extrasList) =>
          getExtrasListSuccess({ extrasList })
          ),
          catchError((error) => of(getExtrasListError({ error })))
        )
      }
      )
    )
  );

  public constructor(
    private actions$: Actions,
    private listTypeService: ListTypeService,
  ) {}
}
