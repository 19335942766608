import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent {
  @Input() link: boolean = false;
  @Input() message: string = 'Terminos y Condiciones';

  @Output() clicker: EventEmitter<string>  = new EventEmitter<string>();

  constructor(
    private dialog: MatDialog
  ) { }

  openDialog() {
    const dialogRef = this.dialog.open(DialogContent);

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(`Dialog result: ${result}`);
    // });
  }
}

@Component({
  selector: 'dialog-content',
  templateUrl: 'dialog.html',
  styleUrls: ['dialog.scss']
})
export class DialogContent {}
