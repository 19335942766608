<mat-dialog-content class="modalGrl">

    <div class="modalGrl_close">
      <mat-icon mat-dialog-close svgIcon="close_rounded"></mat-icon>
    </div>

    <div class="modalGrl_contain">
      <div class="modalGrl_contain_icon" *ngIf="data.icon">
        <mat-icon [svgIcon]="data.icon"></mat-icon>
      </div>

      <div class="modalGrl_contain_message">
        <p class="modalGrl_contain_message_title">
          {{data.textOne}} <b *ngIf="data.textFocus">{{data.textFocus}}</b>
        </p>
        <p class="modalGrl_contain_message_copy">
          {{data.textTwo}}
        </p>
      </div>
    </div>
    <div *ngIf="data.counts.length" class="modalGrl_contain_extra">

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="cuenta">
          <th mat-header-cell *matHeaderCellDef> No Cuenta </th>
          <td mat-cell *matCellDef="let element"> {{element.cuenta}} </td>
        </ng-container>

        <ng-container matColumnDef="repeticiones">
          <th mat-header-cell *matHeaderCellDef> Repeticiones </th>
          <td mat-cell *matCellDef="let element"> {{element.repeticiones}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[3, 5]"></mat-paginator>
  </div>

  <div class="modalGrl_buttons">
    <app-button *ngFor="let but of data.buttons"
    [icon]="but.icon"
    [text]="but.text"
    [idButton]="but.id"
    [clase]="but.clase"
    (clicker)="this.emitingData($event, 'button')"
    [iconDir]="but.direction"
    >
    </app-button>
  </div>
</mat-dialog-content>
