

<div class="close" (click)="this.closeSidenav.emit(true)">
    <mat-icon class="close_icon" svgIcon="close_rounded"></mat-icon>
</div>

<div class="menu">
    <div [class]="menu.label === 'salir' ? 'menu_btn exit' : 'menu_btn'" *ngFor="let menu of menuOptions" [routerLink]="menu.route">
        <mat-icon class="menu_btn_icon" [svgIcon]="menu.icon"></mat-icon>
        <span class="menu_btn_text">{{menu.label | uppercase}}</span>
    </div>
</div>

<div class="brand">
    <img class="brand_img" src="/assets/icons/elementos-03.png" alt="RentaApp">
    <small>V 1.0</small>
</div>