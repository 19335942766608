import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InfoContribuyente } from '../info-contribuyente';
/**
 * Servicio para manejo de informacion de contribuyente hacia backend
 */
@Injectable({
  providedIn: 'root'
})
export class InfoContribuyenteService {
  /**
   * Constructor
   */
  constructor(private http:HttpClient) { }

  guardarInformacion(info:InfoContribuyente){
    return this.http.post("/informacion_contribuyente",info);
  }

  obtenerInformacion(id:number){
    return this.http.get<InfoContribuyente>("/informacion_contribuyente?id="+id);
  }
}
