import { createSelector } from '@ngrx/store';
import { getProjectsModuleState } from '../reducers/index';
import { toArray } from 'lodash';

export const selectAfectacionFiscalList = createSelector(
  getProjectsModuleState,
  (state) => state.afectacionFiscal.entities
);

export const selectAfectacionFiscalById = (id: number) =>
  createSelector(getProjectsModuleState, (state) => {
    const data: any = state.afectacionFiscal.entities;
    if (data) return toArray(data).find((item) => item.projectId === id);
    else return {};
  });
