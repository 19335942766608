

<mat-sidenav-container class="content" autosize>
    
    <mat-sidenav #sidenav class="example-sidenav" mode="side" fixedInViewport="true" opened="true" (mouseenter)="mouseenter()" (mouseleave)="mouseleave()">

        <mat-nav-list class="list">
            <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent">
                <span class="full-width" *ngIf="isExpanded || isShowing"></span>
                <mat-icon mat-list-icon svgIcon="menu_bars"></mat-icon>
                Hello
                <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenu}" *ngIf="isExpanded || isShowing" svgIcon="arrow_down"></mat-icon>
            </mat-list-item>
            <div class="submenu" [ngClass]="{'expanded' : showSubmenu}" *ngIf="isShowing || isExpanded">
                <a mat-list-item href="">Submenu Item 1</a>
                <a mat-list-item href="">Submenu Item 2</a>
            </div>
        </mat-nav-list>
    </mat-sidenav>

    <div id="content">
        <router-outlet></router-outlet>
    </div>

</mat-sidenav-container>