import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { map, switchMap, catchError, tap, debounceTime, take } from "rxjs/operators";
import { of } from "rxjs";

import { NotifyService } from "@app/shared/services/notify.service";
import { DepuracionService } from "@app/shared/services/depuracion.service";
import {
  viewDepuracion,
  viewDepuracionSuccess,
  viewDepuracionError,
  getDepuracion,
  getDepuracionError,
  getDepuracionSuccess,
  setDepuracionInfo,
  setDepuracionInfoSuccess,
  setDepuracionInfoError
} from "@store/actions/depuracion.actions";
import { DepuracionStatus, DepuracionStore } from "../models";

@Injectable()
export class DepuracionEffects {

  public viewDepuracion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewDepuracion),
      //tap(() => console.log('entramos al effect de depuracion')),
      switchMap(({ projectId }) =>

        this.depuracionService.returnNivelDep(projectId).pipe(
          map((depuracionViewSuccess) => {

            // const depuracionViewSuccess: DepuracionStatus = {
            //   id: projectId,
            //   ...status,
            //   ...depuracion }
            console.log('la depuracionview == ', depuracionViewSuccess);
            return viewDepuracionSuccess({ depuracionViewSuccess });

          }),
          catchError((error) => {
            this.notify.error('[STORE]: No pudimos obtener nivel depuracion!',
            JSON.stringify(error), 5000,
          {
            text: 'Entendido',
            orientation: 'izq'
          });
            return of(viewDepuracionError({ error }));
          })
        )
      )
    )
  );


  // public getDepuracion$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(getDepuracion),
  //     //tap(() => console.log('entramos al effect de depuracion')),
  //     switchMap(({ projectId, level }) =>


  //       this.depuracionService.getDepuracion(projectId, level).pipe(
  //         map((depuracion) => {
  //           let status: any;

  //           this.depuracionService.returnNivelDep(projectId)
  //           .subscribe((state: any) => {
  //             status = state
  //             console.log('el status in obs= ', status)
  //           })

  //           console.log('el status out = ', status)

  //           const depuracionSuccess: DepuracionStore = {
  //             id: projectId,
  //             ...status,
  //             ...depuracion }

  //           return getDepuracionSuccess({ depuracionSuccess });

  //         }),
  //         catchError((error) => {
  //           this.notify.error('[STORE]: No pudimos obtener depuracion!',
  //           JSON.stringify(error), 5000,
  //         {
  //           text: 'Entendido',
  //           orientation: 'izq'
  //         });
  //           return of(getDepuracionError({ error }));
  //         })
  //       )
  //     )
  //   )
  // );

  public getDepuracion$ = createEffect(() =>
  this.actions$.pipe(
    ofType(getDepuracion),
    switchMap(({ projectId, level }) =>


      this.depuracionService.getDepuracion(projectId, level).pipe(
        map((depuracion) => {
          let status: any[] = [];

          this.depuracionService.returnNivelDep(projectId)
          .subscribe((state: any) => {
            status.push(state)
            console.log('el status in obs= ', status)
          })

          console.log('el status out = ', status)

          const depuracionSuccess: DepuracionStore = {
            id: projectId,
            ...status[0],
            ...depuracion }

          return getDepuracionSuccess({ depuracionSuccess });

        }),
        catchError((error) => {
          this.notify.error('[STORE]: No pudimos obtener depuracion!',
          JSON.stringify(error), 5000,
        {
          text: 'Entendido',
          orientation: 'izq'
        });
          return of(getDepuracionError({ error }));
        })
      )
    )
  )
);

  public setDepuracion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDepuracionInfo),
      switchMap(({ depuracionForm, projectId, dialog }) =>
        this.depuracionService.actualizarDepuracion(depuracionForm).pipe(
          map((depuracionRes) => {

            if(dialog){
              dialog.close();
            }

            const depuracion: DepuracionStore = {
              id: projectId,
              ...depuracionRes }
            return setDepuracionInfoSuccess({ depuracion });

          }),
          catchError((error) => {
            this.notify.error('[STORE]: No pudimos actualizar depuracion!',
            JSON.stringify(error), 5000,
          {
            text: 'Entendido',
            orientation: 'izq'
          });
            return of(setDepuracionInfoError({ error }));
          })
        )
      )
    )
  );

  public constructor(
    private actions$: Actions,
    private depuracionService: DepuracionService,
    private notify: NotifyService
  ) {}
}
