import { createReducer, on, Action } from '@ngrx/store';
import {
  getEriSuccess,
  updateOneEriSuccess,
  updateManyEriSuccess,
  setDeleteEriSuccess
} from '@store/actions';
import { ERIState, eriAdapter } from '@store/states';

export const initialState: ERIState = eriAdapter.getInitialState({
})

const eriReducer = createReducer(
  initialState,

  on(getEriSuccess, (state, { eriSuccess }) => {
    state.ids = [];
    state.entities = {};
    return eriAdapter.upsertOne( eriSuccess, { ...state })
  }),

  on(updateOneEriSuccess, (state, { eriOneSuccess }) => {
    return eriAdapter.upsertOne(eriOneSuccess, state)
  }),

  on(updateManyEriSuccess, (state, { eriManySuccess }) => {
    return eriAdapter.upsertOne(eriManySuccess, state)
  }),

  on(setDeleteEriSuccess, (state, { projectId }) => {
    return eriAdapter.removeOne(projectId, state)
  }),
)

export function reducer(state: ERIState | undefined, action: Action) {
  return eriReducer(state, action);
}
