import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SERVICES } from './services';

const routes: Routes = [
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    SERVICES
  ]
})
export class SharedRoutingModule { }