
<div class="bar_progress">
  <div class="bar_progress_message">
    <mat-icon class="icon" svgIcon="renta-isotipo"></mat-icon>
    <p>{{ message }}</p>
  </div>
  <p-progressBar
  [style]="{'height': '.7rem', 'width': '25rem', 'margin-bottom': '3rem'}"
    mode="indeterminate">
  </p-progressBar>

  <app-button
    *ngIf="btn_reload"
    [icon]="'reload'"
    [text]="'Actualizar'"
    [idButton]="'reload'"
    (clicker)="this.clicker.emit($event)"
    [clase]="'btn_primary'"
    class="me-2">
  </app-button>
</div>
