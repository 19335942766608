import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OptionMenu } from '@app/shared/models/menu';
import { AuthService } from '@auth/services/auth.service';

@Component({
  selector: 'app-user-profile-button',
  templateUrl: './user-profile-button.component.html',
  styleUrls: ['./user-profile-button.component.scss']
})
export class UserProfileButtonComponent {
  @Input() image: string = '';
  @Input() name: string = '';
  @Input() role: string = '';
  @Input() menuOptions: OptionMenu[];
  @Output() clicker = new EventEmitter<OptionMenu>()

  public clickArrow: boolean = false;
  //TODO: eliminar esto cuando se tenga el store
  currentUser$ = this.auth.getCurrentUser();

  constructor(
    private auth: AuthService,
  ) { }
}
