export interface MenuItem {
    name            : string;
    permissionsName  : string[];
    route           : string;
}

export interface OptionMenu {
  icon: string,
  label: string
  route: string
}

export interface PartidaDobleModel {
    1?: PartidaDobleValues;
    3?: PartidaDobleValues;
    4?: PartidaDobleValues;
    5?: PartidaDobleValues;
    6?: PartidaDobleValues;
    8?: PartidaDobleValues;
    aux?: PartidaDobleValues;
}

export interface PartidaDobleValues {
    balanced: boolean;
    valor: number;
}

export const PUBLIC_MENU_ITEMS: MenuItem[] = [
    {
        name: 'Home',
        permissionsName: ['public'],
        route: '/home'
    },
    {
        name: 'Profile',
        permissionsName: ['public'],
        route: '/profile/complete'
    },
    {
        name: 'Page not found',
        permissionsName: ['public'],
        route: '/404'
    },
];

export const USER_MENU_ITEMS : MenuItem[] = [
    {
        name: 'Listar proyectos',
        permissionsName: ['list_projects'],
        route: '/proyecto/list'
    },
    {
        name: 'Crear proyectos',
        permissionsName: ['create_project', 'list_projects'],
        route: '/proyecto/create'
    },
    {
        name: 'Info contribuyente',
        permissionsName: ['create_project', 'list_projects'],
        route: '/proyecto/1243/infoContribuyente'
    },
    {
        name: 'Listar usuarios',
        permissionsName: ['list_user'],
        route: '/user/list'
    },
    {
        name: 'Administrar usuarios',
        permissionsName: ['create_user','list_user' ],
        route: '/settings/manage_users'
    },
];
