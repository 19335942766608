import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@auth/services/auth.service';


@Component({
  selector: 'app-page-no-found',
  templateUrl: './page-no-found.component.html',
  styleUrls: ['./page-no-found.component.scss']
})
export class PageNoFoundComponent  implements OnInit{

  permissions: string[] = [];

  constructor(
    private auth: AuthService,
    private router: Router,
  ){}

  ngOnInit(): void {
  }

  signOut(): void {
    this.auth.logOut().subscribe(
      () => {
        localStorage.removeItem('token');
        this.router.navigate(['/auth/login']);
      }
    );
  }
}
