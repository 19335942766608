import { FormControl } from '@angular/forms';

export class NumberPositiveValidator {

  public static isValid(control: FormControl) {
    if (parseFloat(control.value) <= 0) {
      return { 'numberInvalid': true };
    }
    return null;
  }
}
