import { InfoContribuyente } from '@app/pages/info-contribuyente/info-contribuyente';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export interface InfoContribuyenteState
  extends EntityState<InfoContribuyente> {}

export const infoContribuyenteAdapter: EntityAdapter<InfoContribuyente> =
  createEntityAdapter<InfoContribuyente>({
    selectId: (infoContribuyente) => infoContribuyente.id,
  });
