<div class="wrapper">
    <h1>Confirma tu correo</h1>
    <span>Ingresa el correo electrónico donde recibiste la invitación</span>
    <input type="email" placeholder="Email" class="form-control mb-2 mt-2" [formControl]="confirmEmailControl" />
    <div *ngIf="visibleClave">
        <input class="form-control mb-2 mt-2" [type]="'password'" name="password" placeholder="Contraseña"
            [formControl]="password">
        <input class="form-control mb-2 mt-2" [type]="'password'" name="password" placeholder="Confirma contraseña"
            [formControl]="repeatPass">
    </div>
    <button (click)="confirmEmail()" class="renta-btn">Confirmar</button>
</div>