import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { map, switchMap, catchError, tap, debounceTime } from "rxjs/operators";
import { forkJoin, from, of } from "rxjs";
import { ListProyectosService } from "@proyectos/services/list-proyectos.service";

import { NotifyService } from "@app/shared/services/notify.service";
import { AnnexedStatus, ProjectStore, Project } from "@store/models";
import { StatusService } from "@app/shared/services/status-project.service";
import { Router } from "@angular/router";
import {
  getProjecStatusSuccess,
  getProjectStatus,
  getProjectStatusError,
  setStatusProject,
  setStatusProjectSuccess,
  setStatusProjectError,
  setStatusProjectInitialState,
  setStatusProjectInitialStateSuccess,
} from "@store/actions";
import { setBalanceSuccess } from "../actions";
import { ProjectStatus } from "../models/project-status.model";
import { DepuracionService } from "@app/shared/services/depuracion.service";

@Injectable()
export class ProjectStatusEffects {

  getProjectStatus$ = createEffect(() =>
  this.actions$.pipe(
    //esta accion se dispara en dos momentos distintos, el 1ro al seleccionar el proyecto, el 2do al cargar un balance
    ofType(getProjectStatus, setBalanceSuccess),
    switchMap((data) => {

      let anexed$: any = '';
      let depuracion$: any = '';
      let id: number = 0
;
      if(data.type === '[project status] get project status'){
        anexed$ = this.statusService.getStatusAnnexed(data.projectId);
        depuracion$ = this.depuracionService.getStateDepuracion(data.projectId);
        id = data.projectId
      }

      if(data.type === '[balance] set balance save info success'){
        anexed$ = this.statusService.getStatusAnnexed(data.balance.projectId);
        depuracion$ = this.depuracionService.getStateDepuracion(data.balance.projectId);
        id = data.balance.projectId
      }

      return forkJoin([anexed$, depuracion$]).pipe(
        map((response: any) => {

        let statusGrl: ProjectStatus = {

          id: id,
          PATRIMONIO: response[0].status.PATRIMONIO,
          DEPURACION: response[0].status.DEPURACION,
          INFORMACION: response[0].status.INFORMACION,
          CONCILIACION: response[0].status.CONCILIACION,
          nivel: response[1].nivel,
          balanceMatching: response[1].balanceMatching ,
          depuracionStatus:response[1].depuracionStatus
        }

        return getProjecStatusSuccess({ projectStatus: statusGrl });

      }),
        catchError((error) => {
          return of(getProjectStatusError({ error }))
        })
      )
  })));

  setProjectStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setStatusProject),
      switchMap(({ projectStatus }) => {

          const anexed$ = this.statusService.getStatusAnnexed(projectStatus.id);
          const depuracion$ = this.depuracionService.getStateDepuracion(projectStatus.id);

          return forkJoin([anexed$, depuracion$]).pipe((response) => {

            let statusGrl: ProjectStatus = {

              id: projectStatus.id,
              PATRIMONIO: response[0].status.PATRIMONIO,
              DEPURACION: response[0].status.DEPURACION,
              INFORMACION: response[0].status.INFORMACION,
              CONCILIACION: response[0].status.CONCILIACION,
              nivel: response[1].nivel,
              balanceMatching: response[1].balanceMatching ,
              depuracionStatus:response[1].depuracionStatus
            }

            return of(setStatusProjectSuccess({ projectStatus: statusGrl }))
          })
      }),
      catchError((error) => {
        return of(setStatusProjectError({ error }))
      })
    )
  );

  setProjectStatusInitialState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setStatusProjectInitialState),
      switchMap(({ projectId }) => {

        let statusGrl: ProjectStatus = {

          id: projectId,
          PATRIMONIO: "0.0",
          DEPURACION: "0.0",
          INFORMACION: "0.0",
          CONCILIACION: "0.0",
          nivel: null,
          balanceMatching: null,
          depuracionStatus: "PENDING"
        }

        return of(setStatusProjectInitialStateSuccess
          ({ projectStatus: statusGrl }))
      })
    )
  );


  public constructor(
    private actions$: Actions,
    private router: Router,
    private depuracionService: DepuracionService,
    private statusService: StatusService,
    private notify: NotifyService
  ) {}
}
