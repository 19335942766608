import { FormControl } from '@angular/forms';

export const isArrayRequired = (minLegth: number = 1, messageText: string = "El campo es inválido") => {
  return (control: FormControl) => {
    const arr = control.value;
    if (arr.length < minLegth) {
      return {
        arrayRequired: {
          text: messageText
        }
      };
    } else {
      return null;
    }
  };
};

export const isArrayMapDeletedRequired = (minLegth: number = 1, messageText: string = "El campo es inválido") => {
  return (control: FormControl) => {
    const arr = control.value.filter((item: any) => !item.deleted);
    if (arr.length < minLegth) {
      return {
        arrayRequired: {
          text: messageText
        }
      };
    } else {
      return null;
    }
  };
};
