import { Component, Input, OnInit,ViewChild } from '@angular/core';
import { NavigationStart, Router, Event, NavigationEnd} from '@angular/router';

import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

import { AuthService } from '@auth/services/auth.service';
import { ListProyectosService } from '@proyectos/services/list-proyectos.service';

import { Project } from '@app/pages/proyectos/proyecto';
import { MatSidenav } from '@angular/material/sidenav';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { toArray } from "lodash";
import { selectProyectList } from '@store/selectors';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;

  isExpanded = false;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;

  public menuOption = [
       {
         id: '1',
         title: 'Datos de de Información',
         icon: 'user_line',

          submenu: [
            {
            name: 'Información del Contribuyente',
            icon: 'user_line',
            route: ''
            },
            {
              name: 'Información del Contribuyente',
              icon: 'user_line',
              route: ''
            },

          ]
      },
      {
        id: '2',
        title: 'Datos de de Información',
        icon: 'user_line',

         submenu: [
           {
           name: 'Información del Contribuyente',
           icon: 'user_line',
           route: ''
           },
           {
             name: 'Información del Contribuyente',
             icon: 'user_line',
             route: ''
           },

         ]
        },
        {
          id: '3',
          title: 'Datos de de Información',
          icon: 'user_line',

            submenu: [
              {
              name: 'Información del Contribuyente',
              icon: 'user_line',
              route: ''
              },
              {
                name: 'Información del Contribuyente',
                icon: 'user_line',
                route: ''
              },

            ]
          },


  ];

  proyectos$:Observable<Project[]>;
  currentUser$ = this.auth.currentUser$;

  @Input() showProject: boolean = false;

  projectId: number;

  constructor(
    private auth: AuthService,
    private store: Store,
    private projectSrv: ListProyectosService,
    private router: Router
  ) {
    this.proyectos$ = this.store.pipe(
      select(selectProyectList),
      map((list) => toArray(list))
    );
    router.events.subscribe(
      event => this.updateMenu(event)
    )
   }

  ngOnInit(): void {
  }

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  logout() {
    this.auth.logOut().pipe(
      delay(1000)
    ).subscribe(
      () => {
        this.router.navigate(['/auth/login']);
      }
    );
  }

  updateMenu(event: Event) {

    if(event instanceof NavigationStart || event instanceof NavigationEnd){
      const url = event.url.split('/');
      this.projectId = +url[2];
    }
  }
}
