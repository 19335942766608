import { createReducer, on, Action } from '@ngrx/store';
import { Seccion1GmfState, seccion1GmfAdapter } from '@store/states';
import { getSeccion1GmfSuccess, setSeccion1GmfSuccess,  setDeleteSeccion1GmfSuccess } from '@store/actions/seccion1-gmf.actions';

export const initialState: Seccion1GmfState = seccion1GmfAdapter.getInitialState({})

const seccion1GmfReducer = createReducer(
  initialState,

  on(getSeccion1GmfSuccess, (state, { seccion1GmfSuccess }) => {
    state.ids = [];
    state.entities = {};
    return seccion1GmfAdapter.upsertOne(seccion1GmfSuccess, { ...state })
  }),

  on(setSeccion1GmfSuccess, (state, { seccion1GmfForm }) => {
    state.ids = [];
    state.entities = {};
    return seccion1GmfAdapter.upsertOne(seccion1GmfForm, state)
  }),

  on(setDeleteSeccion1GmfSuccess, (state, { seccion1Gmf }) => {
    return seccion1GmfAdapter.removeOne(seccion1Gmf.id, state)
  }),
)

export function reducer(state: Seccion1GmfState | undefined, action: Action) {
  return seccion1GmfReducer(state, action);
}
