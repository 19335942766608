import { createReducer, on, Action } from '@ngrx/store';
import {
  gananciasOcacionalesAdapter,
  GanaciasOcacionalesState,
} from '../states';
import * as actionsGananciasOcacionales from '@store/actions/ganancias-ocacionales.actions';

export const initialState: GanaciasOcacionalesState =
  gananciasOcacionalesAdapter.getInitialState({});

const gananciasOcacionalesReducer = createReducer(
  initialState,
  on(
    actionsGananciasOcacionales.getGanaciaOcacionalListaSuccess,
    (state, { gananciaOcacionalOtraLista }) => {
      state.entities = {};
      state.ids = [];
      return gananciasOcacionalesAdapter.upsertOne(
        gananciaOcacionalOtraLista,
        state
      );
    }
  )
);

export function reducer(
  state: GanaciasOcacionalesState | undefined,
  action: Action
) {
  return gananciasOcacionalesReducer(state, action);
}
