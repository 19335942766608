import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { StatusService } from '../services/status-project.service';

@Pipe({ name: 'getStatus' })

export class getStatus implements PipeTransform {

  constructor(
    private statusService: StatusService,
  ) { }

  public transform(id: number): Observable<number> {
    if (id) {
      return this.statusService.getStatusProject(id);
    } else {
        return of(0)
    }
  }
}