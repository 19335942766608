import { Action, createReducer, on } from "@ngrx/store";
import { getCalculoDescuentoSuccess, setCalculoDescuentoSuccess, setDeleteCalculoDescuentoSuccess } from "../actions/calculo-descuento.actions";
import { calculoDescuentoAdapter, CalculoDescuentoState } from "../states";

export const initialState: CalculoDescuentoState = calculoDescuentoAdapter.getInitialState({})

const calculoDescuentoReducer = createReducer(
  initialState,

  on(getCalculoDescuentoSuccess, (state, { calculoDescuentoSuccess }) => {
    state.ids = [];
    state.entities = {};
    return calculoDescuentoAdapter.upsertOne(calculoDescuentoSuccess, { ...state })
  }),

  on(setCalculoDescuentoSuccess, (state, { calculoDescuentoForm }) => {
    state.ids = [];
    state.entities = {};
    return calculoDescuentoAdapter.upsertOne(calculoDescuentoForm, {...state})
  }),

  on(setDeleteCalculoDescuentoSuccess, (state, { calculoDescuentoForm }) => {
    state.ids = [];
    state.entities = {};
    return calculoDescuentoAdapter.upsertOne(calculoDescuentoForm, state)
  }),
)

export function reducer(state: CalculoDescuentoState | undefined, action: Action) {
  return calculoDescuentoReducer(state, action);
}
