import { Component, EventEmitter, Input, Output, Inject, ViewChild, AfterViewInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ModalData, Counts } from './modal-models';
import { FormControl } from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-modal-upload-balance',
  templateUrl: './modal-upload-balance.component.html',
  styleUrls: ['./modal-upload-balance.component.scss']
})

export class ModalUploadBalanceComponent  implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() clickerEvent: EventEmitter<string>  = new EventEmitter<string>();
  public selectedData = new FormControl('');

  displayedColumns: string[] = ['cuenta', 'repeticiones'];
  dataSource: MatTableDataSource<Counts>;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ModalUploadBalanceComponent>,
    @Inject(MAT_DIALOG_DATA)
      public data: ModalData
  ) {
    this.dataSource = new MatTableDataSource(this.data.counts)
   }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  public emitingData(datos: any, emiter: string) {
    let info: any;

    info = {
      emiter: emiter,
      data: datos
    }
    this.dialogRef.close(info);
  }
}
