import { createReducer, on, Action } from '@ngrx/store';
import { LimiteBeneficioState, limiteBeneficoAdapter } from '@store/states';
import { setOneSuma, setTwoSuma } from '../actions/limite-beneficio.actions';

export const initialState: LimiteBeneficioState =
  limiteBeneficoAdapter.getInitialState({
    resultado: 0,
    resultadofila: 0,
  });

const limiteBeneficiosReducer = createReducer(
  initialState,

  on(setOneSuma, (state, { suma }) => {
    return { ...state, resultado: suma };
  }),
  on(setTwoSuma, (state, { suma }) => {
    return { ...state, resultadofila: suma };
  })
);

export function reducer(
  state: LimiteBeneficioState | undefined,
  action: Action
) {
  return limiteBeneficiosReducer(state, action);
}
