import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '@auth/services/auth.service';
import { getErrorMessage, emailErrorMessages, EMAIL_PATTERN } from '../../../shared/utils/form-validators';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent implements OnInit {

  resetPassForm = this.fb.group({
    email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
  });

  errorMessage = '';

  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder
  ){}

  ngOnInit(): void {
    const emailControl = this.resetPassForm.get('email');
    emailControl.valueChanges.pipe(debounceTime(500)).subscribe(
      _ => {
        this.errorMessage = getErrorMessage(emailControl, emailErrorMessages);
      }
    )
  }

  resetPassword() : void {
    if(this.resetPassForm.valid) {
      const { email } = this.resetPassForm.controls;
      this.authService.resetPassword(email.value).subscribe(
        () => {
         alert('Se ha enviado un email a ' + email.value);
         this.router.navigate(['']);
        },
        error => this.errorMessage = error
      )
    }else {
      this.errorMessage = 'Ingrese un email por favor'
    }
  }
}
