<div class="position-relative">
  <div class="d-flex flex-row">
    <div class="picture">
      <div class="picture_icon">
        <mat-icon svgIcon="user_line_bold"></mat-icon>
      </div>
    </div>
    <div class="profile">
      <div class="profile_arrow" [class]="clickArrow ? 'rotated' : ''">
        <mat-icon
          svgIcon="arrow_down"
          (click)="clickArrow = !clickArrow"
          [matMenuTriggerFor]="menu"
        >
        </mat-icon>
      </div>
    </div>
  </div>
</div>
<mat-menu
  #menu="matMenu"
  (closed)="clickArrow = !clickArrow"
  [class]="'custom_menu_user'"
>
  <button mat-menu-item disabled="true" style="height: 5rem">
    <span>
      <p class="pm" style="margin: 0 !important">
        {{ name | slice : 0 : 12 }}
      </p>
      <div class="profile_contain_role">
        <p class="pm" style="margin: 0 !important">
          {{ role }}
        </p>
      </div>
    </span>
  </button>
  <button
    mat-menu-item
    *ngFor="let option of menuOptions"
    (click)="this.clicker.emit(option)"
  >
    <mat-icon [svgIcon]="option.icon"></mat-icon>
    <span>{{ option.label }}</span>
  </button>
</mat-menu>
