import { createAction, props } from '@ngrx/store';
import { Seccion3GmfStore, Seccion3Tabla } from '../models/cuatrox-mil-gmf.model';

export const GET_SECCION3GMF = '[seccion3gmf] get seccion3gmf';
export const GET_SECCION3GMF_SUCCESS = '[seccion3gmf] get seccion3gmf success';
export const GET_SECCION3GMF_ERROR = '[seccion3gmf] get seccion3gmf with error';

export const SET_SECCION3GMF = '[seccion3gmf] set seccion3gmf save info';
export const SET_SECCION3GMF_SUCCESS = '[seccion3gmf] set seccion3gmf save info success';
export const SET_SECCION3GMF_ERROR = '[seccion3gmf] set seccion3gmf save info with error';

export const SET_DELETE_SECCION3GMF = '[seccion3gmf] set delete seccion3gmf';
export const SET_DELETE_SECCION3GMF_SUCCESS = '[seccion3gmf] set delete seccion3gmf success';
export const SET_DELETE_SECCION3GMF_ERROR = '[seccion3gmf] set delete seccion3gmf with error';

//get seccion3gmf
export const getSeccion3Gmf = createAction(
  GET_SECCION3GMF,
  props<{projectId: number}>()
);

export const getSeccion3GmfSuccess = createAction(
  GET_SECCION3GMF_SUCCESS,
  props<{ seccion3GmfSuccess: Seccion3GmfStore  }>()
);

export const getSeccion3GmfError = createAction(
  GET_SECCION3GMF_ERROR,
  props<{ error: string }>()
);

//create seccion3gmf
export const setSeccion3Gmf = createAction(
  SET_SECCION3GMF,
  props<{ seccion3GmfForm:Seccion3GmfStore, projectId: number }>()
);

export const setSeccion3GmfSuccess = createAction(
  SET_SECCION3GMF_SUCCESS,
  props<{ seccion3GmfForm:Seccion3GmfStore }>()
);

export const setSeccion3GmfError = createAction(
  SET_SECCION3GMF_ERROR,
  props<{ error: string }>()
);

//delete seccion3gmf
export const setDeleteSeccion3Gmf = createAction(
  SET_DELETE_SECCION3GMF,
  props<{ projectId: number, objSeccion3Gmf: Seccion3Tabla[] }>()
);

export const setDeleteSeccion3GmfSuccess = createAction(
  SET_DELETE_SECCION3GMF_SUCCESS,
  props<{ seccion3Gmf: any }>()
);

export const setDeleteSeccion3GmfError = createAction(
  SET_DELETE_SECCION3GMF_ERROR,
  props<{ error: string }>()
);
