import { createReducer, on, Action } from '@ngrx/store';
import { projectStatusAdapter, ProjectStatusState } from '@store/states';
import { 
  getProjecStatusSuccess, 
  setStatusProjectSuccess, 
  updateStatusProjectSuccess,
  setStatusProjectInitialStateSuccess
} from '@store/actions/project-status.actions';


export const initialState: ProjectStatusState = projectStatusAdapter.getInitialState({
});

const { selectAll } = projectStatusAdapter.getSelectors();

const projectStatusReducer = createReducer(
  initialState,

  on(getProjecStatusSuccess, (state,  { projectStatus }) => {
    state.ids = []
    state.entities = {}
    return projectStatusAdapter.upsertOne(projectStatus, state)
  }),

  on(setStatusProjectSuccess, (state,  { projectStatus }) => {
    state.ids = []
    state.entities = {}
    return projectStatusAdapter.upsertOne(projectStatus, state)
  }), 

  on(setStatusProjectInitialStateSuccess, (state,  { projectStatus }) => {
    state.ids = []
    state.entities = {}
    return projectStatusAdapter.upsertOne(projectStatus, state)
  }), 
)

export function reducer(state: ProjectStatusState | undefined, action: Action) {
  return projectStatusReducer(state, action);
}