import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { NotifyService } from '@app/shared/services/notify.service';
import { LastYearInfoService } from '@app/pages/info-contribuyente/services/last-year-info.service';
import { LastYearInfo } from '@app/pages/info-contribuyente/model/lastYearInfo';
import * as actionsLastYear from '../actions/last-year.actions';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class LastYearInfoEffects {
  getLastYearInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsLastYear.getInfoLastYear),
      switchMap((action) => {
        return this.lastYearInfoService.getInfoAnioAnterior(action.id).pipe(
          map((lastYearInfo: LastYearInfo) => {
            const projectId = lastYearInfo?.projectId
              ? lastYearInfo?.projectId
              : action?.id;
            return actionsLastYear.getInfoLastYearSuccess({
              lastyearInfo: { ...lastYearInfo, projectId },
            });
          }),
          catchError((error) => {
            this.notify.error(
              'Algo salio mal! ',
              'No pudimos cargar la información del contribuyente',
              4000
            );
            return of(actionsLastYear.getInfoLastYearFail({ error }));
          })
        );
      })
    )
  );

  setLastYearInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsLastYear.setInfoLastYear),
      switchMap((action) => {
        return this.lastYearInfoService.update(action.payload).pipe(
          map((lastYearInfoUpdated: LastYearInfo) => {
            console.log("effects",lastYearInfoUpdated );
            
            const projectId = lastYearInfoUpdated?.projectId
              ? lastYearInfoUpdated?.projectId
              : action?.id;
            return actionsLastYear.setInfoLastYearSuccess({
              lastYearUpdated: { ...lastYearInfoUpdated, projectId },
            });
          }),
          catchError((error) => {
            this.notify.error(
              'Algo salio mal! ',
              'No pudimos guardar la infromación del contribuyente',
              4000
            );
            return of(actionsLastYear.setInfoLastYearFail({ error }));
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private notify: NotifyService,
    private lastYearInfoService: LastYearInfoService
  ) {}
}
