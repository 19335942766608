import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  MSG_DURATION,
  NotifyService,
} from '@app/shared/services/notify.service';
import { AnticipoRentaService } from '@app/shared/services/anticipo-renta.service';
import { getRentaOpciones, getRentaOpcionesError, getRentaOpcionesSuccess } from '../actions';
import { AnticipoRentaOpciones } from '../models';

@Injectable()
export class RentaOpcionesEffects {
  getRentaOpciones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRentaOpciones),
      switchMap(({ projectId }) => {
        return this.anticipoRentaService.getRentaOpciones(projectId).pipe(
          map((rentaOpciones) => {
            let rentaOpcionesSuccess: AnticipoRentaOpciones = {
              idProject: projectId,
              opcion1: rentaOpciones[0],
              opcion2: rentaOpciones[1]
            };
            console.log(rentaOpciones);
            return getRentaOpcionesSuccess({ rentaOpcionesSuccess });
          }),
          catchError((error) => {
            this.notify.error('Algo salio mal!', JSON.stringify(error), MSG_DURATION, {
              text: 'Entendido',
              orientation: 'izq',
            });
            return of(getRentaOpcionesError({ error }));
          })
        );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private anticipoRentaService: AnticipoRentaService,
    private notify: NotifyService
  ) {}
}
