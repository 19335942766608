<div *ngFor="let but of buttonsArray" [class]="'div_' + but.idButton">

    <button
      [class]="but.idButton === butSelect ? but.class + ' ' + but.class + '_active': but.class"
      [id]="but.idButton"
      (click)="onEmit(but.idButton)"
      [pTooltip]="but.message"
      tooltipPosition="bottom"
      placeholder="Bottom">
      <mat-icon
      [svgIcon]="but.icon">
      </mat-icon>
    </button>

    <!-- <ng-container *ngIf="but.idButton !== 'state'; else btn_state">
      </ng-container> -->

  <!-- <ng-template  #btn_state>
    <app-select-status-annexed
      [but]="but"
      [eventStartStatus$]="eventStatusStart">
    </app-select-status-annexed>
  </ng-template> -->

</div>
