import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  MSG_DURATION,
  NotifyService,
} from '@app/shared/services/notify.service';
import {
  getSeccion2Gmf,
  getSeccion2GmfError,
  getSeccion2GmfSuccess,
  setDeleteSeccion2Gmf,
  setDeleteSeccion2GmfError,
  setDeleteSeccion2GmfSuccess,
  setSeccion2Gmf,
  setSeccion2GmfError,
  setSeccion2GmfSuccess,
} from '@store/actions/seccion2-gmf.actions';
import { CuatroxMilGmfService } from '@app/shared/services/cuatrox-mil-gmf.service';
import { Seccion2GmfStore } from '../models/cuatrox-mil-gmf.model';

@Injectable()
export class Seccion2GmfEffects {
  getSeccion2Gmf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSeccion2Gmf),
      switchMap(({ projectId }) => {
        return this.cuatroxMilGmfService.getSeccion2Gmf(projectId).pipe(
          map((seccion2Gmf) => {
            let seccion2GmfSuccess: Seccion2GmfStore = {
              idProject: projectId,
              ...seccion2Gmf,
            };
            return getSeccion2GmfSuccess({ seccion2GmfSuccess });
          }),
          catchError((error) => {
            this.notify.error('Algo salio mal!', JSON.stringify(error), 5000, {
              text: 'Entendido',
              orientation: 'izq',
            });
            return of(getSeccion2GmfError({ error }));
          })
        );
      })
    )
  );

  saveSeccion2Gmf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setSeccion2Gmf),
      switchMap(({ seccion2GmfForm, projectId }) => {
        return this.cuatroxMilGmfService
          .saveSeccion2Gmf(projectId, seccion2GmfForm)
          .pipe(
            map((seccion2Gmf) => {
              let seccion2GmfForm: Seccion2GmfStore = {
                idProject: projectId,
                ...seccion2Gmf,
              };
              this.notify.success(
                '',
                'Guardado de registros exitoso',
                MSG_DURATION
              );
              return setSeccion2GmfSuccess({ seccion2GmfForm });
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setSeccion2GmfError({ error }));
            })
          );
      })
    )
  );

  deleteSeccion2Gmf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDeleteSeccion2Gmf),
      switchMap(({ projectId, objSeccion2Gmf }) => {
        return this.cuatroxMilGmfService
          .deleteSeccion2Gmf(projectId, objSeccion2Gmf)
          .pipe(
            map((seccion2Gmf) => {
              this.notify.success(
                '',
                'Borrado de registros exitoso',
                MSG_DURATION
              );
              return setDeleteSeccion2GmfSuccess({ seccion2Gmf });
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setDeleteSeccion2GmfError({ error }));
            })
          );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private cuatroxMilGmfService: CuatroxMilGmfService,
    private notify: NotifyService
  ) {}
}
