import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentHistory } from '@app/pages/plans/models/plan';
import { FormGenerate } from '@app/shared/services/form.service';

@Component({
  selector: 'app-dialog-payments',
  templateUrl: './dialog-payments.component.html',
  styleUrls: ['./dialog-payments.component.scss'],
  encapsulation: ViewEncapsulation.None
})
/**
 * Componente para confirmar operaciones.
 */
export class DialogPaymentComponent implements OnInit{

  public form: FormGroup;

  constructor(
    public dialogo: MatDialogRef<DialogPaymentComponent>,
    private builder: FormBuilder,
    private genForm: FormGenerate,
    @Inject(MAT_DIALOG_DATA)
    public data: PaymentHistory,
  ) { }

  ngOnInit(): void {
    console.log('data llegando al modal = ', this.data);
  }

  close(): void {
    this.dialogo.close(false);
  }

  confirm(): void {
    this.dialogo.close(true)
   }

   setStatus(status: string): string{
    switch (status) {
      case 'NEW':
        return 'Nueva'
        break;

      case 'PENDING':
        return 'Pendiente'
        break;

      case 'DECLINED':
        return 'Abandonada'
        break;

      case 'APPROVED':
        return 'Aprobada'
        break;

      case 'ERROR':
        return 'Error'
        break;

      case 'CANCELLED':
        return 'Cancelada'
        break;

      case 'EXPIRED':
        return 'Expirada'
        break;

      default:
        return 'Pendiente'
        break;
    }
  }

}
