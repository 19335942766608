import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject, combineLatest, EMPTY } from 'rxjs';
import { tap, map, switchMap, retry, last, catchError } from 'rxjs/operators';

import { ListProyectosService } from '@proyectos/services/list-proyectos.service';

import { MathDepLevel, RegistroCompleto, RegistroDepuracion } from '@depuracion/models/registro-depuracion';
import { Renglon2516, RenglonRenta } from '@depuracion/models/renglon';
import { Resumen } from '@depuracion/models/resumen';
import { SimpleStatus } from '@app/store/models/project-status.model';
import { DepuracionStatus } from '@app/store/models';

@Injectable({
  providedIn: 'root'
})

export class DepuracionService {

  private _currentDepListSubj$ = new BehaviorSubject<RegistroDepuracion[]>([]);
  _currentDepList$ = this._currentDepListSubj$.asObservable();
  currentDepList : RegistroDepuracion[];
  efectEnable = false;

  private typeSelectedSubj$ = new BehaviorSubject<string>('AFECTACION_FISCAL');
  typeSelectedAction$ = this.typeSelectedSubj$.asObservable();

  set currentDepList$(registrosDep: RegistroDepuracion[])   {
    this.currentDepList = registrosDep;
    this._currentDepListSubj$.next(registrosDep);
  }

  filteredList$ = combineLatest([
    this.typeSelectedAction$,
    this._currentDepList$
  ]).pipe(
    map(([filter, registros]) => registros.filter( registro => registro.type === filter) )
  )

  constructor(
    private http: HttpClient,
    private projectSrv: ListProyectosService
  ) { }

  levels$ = (level: number)=> this.http.get<string[]>(`/depuracion/niveles?id=${ level }`);

  regDepuracion$ = (projectId:number) =>
  this.nivelActual$(projectId).pipe(
    switchMap(
      level => level ? this.getDepuracion(projectId, level.nivel) : EMPTY
    ),
    tap(
      depList => {
        this.efectEnable = depList.conversionEnabled;
        this.currentDepList = depList.list;
        this._currentDepListSubj$.next(depList.list);
      }
    )
  );

  getDepuracion = (projectId: string | number, level:string) =>this.http.get<RegistroCompleto>(`/depuracion?projectId=${projectId}&nivel=${ level }`);

  renglones$ = (clase: number, projectId: number)=> this.http.get<RenglonRenta[]>(`/options/accountClass?clase=${clase}&projectId=${projectId}`);

  renglones2516$ = (projectId: number, renglon: number, clase: number)=> this.http.get<Renglon2516[]>(`/options/renDesc?renglon=${renglon}&clase=${clase}&projectId=${projectId}`);

  actualizarDepuracion(registro:RegistroCompleto):Observable<any>{
    return this.http.post("/depuracion",registro);
  }

  resumen$ = (projectId:number)=> this.http.get<Resumen[]>(`/depuracion/summary?projectId=${projectId}`);

  onFilterChange(filter: string) {
    this.typeSelectedSubj$.next(filter);
  }

  deleteRenglon(renglonId: number) : Observable<any>{
    const registros = this.currentDepList.filter(registro => registro.id !== renglonId);
    this.currentDepList$ = registros;
    return this.http.delete<any>(`/afectacion_fiscal?id=${renglonId}`);
  }

  nivelActual$ = (projectId:number)=> this.http.get<MathDepLevel>(`/depuracion/project_nivel_depuracion?projectId=${projectId}`);


  returnNivelDep(projectId:number) {
    return this.http.get<any>(`/depuracion/project_nivel_depuracion?projectId=${projectId}`)};

  checkDepLevel(level: string): Observable<MathDepLevel> {
    return this.projectSrv.currentProject$.pipe(
      switchMap(
        project => this.http.get<MathDepLevel>(`/depuracion/nivel_depuracion?projectId=${project.id}&nivel=${level}`)
      )
    )
  }
  changeStatus(level: string): Observable<MathDepLevel> {
    return this.projectSrv.currentProject$.pipe(
      switchMap(
        project => this.http.post<MathDepLevel>(`/depuracion/update_project_level?projectId=${project.id}&nivel=${level}`,{})
      )
    )
  }



  checkDepReady(projectId) {
    const req = new HttpRequest('GET', '/depuracion/project_nivel_depuracion?projectId=' + projectId, {
      reportProgress: true
    });

    return this.http.request(req).pipe(
      retry(3),
      map(event => event),
      // tap(() => {
      // }),
      last(), // return last (completed) message to caller
      catchError(err => err)
    );


    // this.http.get<any>(`/depuracion/project_nivel_depuracion?projectId=${ projectId }`)
    // .pipe(map(data => { return true }
    // ))
    // ,(err:HttpErrorResponse)=> { return false}
  }

    //methods for new flow store
    getStateDepuracion(projectId: number) {
      return this.http.get<DepuracionStatus>(`/depuracion/project_nivel_depuracion?projectId=${projectId}`)
    }
}
