import {
  Component,
  Input,
  OnInit,
  ViewChild,
  HostListener,
  Inject,
  ViewChildren,
  QueryList,
  ElementRef,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';

import { FilterMetadata, FilterService } from 'primeng/api';
import { Table, TableHeaderCheckbox } from 'primeng/table';
import { interval, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DepuracionService } from '@shared/services/depuracion.service';
import {
  RegistroDepuracion,
  renglonesERIInvertido,
  renglonesESFInvertido,
  TarifaDepuracion,
} from '../../../pages/depuracion/models/registro-depuracion';
import { Resumen } from '../../../pages/depuracion/models/resumen';
import {
  Renglon2516,
  RenglonRenta,
} from '../../../pages/depuracion/models/renglon';

import { InfoContribuyenteService } from '@app/pages/info-contribuyente/services/info-contribuyente.service';
import { ListProyectosService } from '@proyectos/services/list-proyectos.service';

import { InfoContribuyente } from '@app/pages/info-contribuyente/info-contribuyente';
import { MatDialog } from '@angular/material/dialog';
import { DepuracionModalResumen } from '../depuracion-window-modal-resumen/depuracion-window-modal-resumen.component';
import { DepuracionModalEdit } from '../depuracion-window-modal-edit/depuracion-window-modal-edit.component';
import { NotifyService } from '@app/shared/services/notify.service';
import { ModalGrlComponent } from '../modal-grl/modal-grl.component';
import { Store, select } from '@ngrx/store';
import { selectCurrentRoute } from '@app/reducers';
import { DOCUMENT } from '@angular/common';
import { getProjectStatus } from '@app/store/actions';
import { Title } from '@angular/platform-browser';
import { depuracion } from '@app/shared/models/namewindows';
import { SpinnerService } from '@app/shared/services/spinner.service';

@Component({
  selector: 'app-depuracion-window',
  templateUrl: './depuracion-window.component.html',
  styleUrls: ['./depuracion-window.component.scss'],
})
export class DepuracionWindowComponent implements OnInit {
  public buttonsActions = [
    {
      id: 'reload',
      class: 'btn_icon_success',
      icon: 'arrow_circle',
      label: 'Actualizar',
    },
    {
      id: 'resume',
      class: 'btn_icon_primary',
      icon: 'paper',
      label: 'Resumen',
    },
    // {
    //   id: 'edit',
    //   class: 'btn_icon_primary',
    //   icon: 'edit',
    //   label: 'Editar varios'
    // },
  ];
  public routeNow$: Observable<any>;
  @Input() nivel: string | number;
  screenHeight: number;
  ActivateButtonEdit: boolean = false;
  public registroo: any[] = [];
  resumen$: Observable<Resumen[]>;
  cols: any[];
  clases: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  totalContable: number;
  totalEfectos: number;
  totalMayorFiscal: number;
  totalMenorFiscal: number;
  totalSaldoFiscal: number;
  projectId: number;
  declarante: InfoContribuyente;
  @ViewChild('dt') tabla: Table;
  displayDialog: boolean;
  displayDialogEdit: boolean;
  displayDialogDelete: boolean;
  renglonesRenta$: Observable<RenglonRenta[]>;
  renglones2516$: Observable<Renglon2516[]>;
  registro: any;
  registros: any[] = [];
  sinRenglon: boolean;
  minDecimals = 2;
  maxDecimals = 4;
  messageAlert: any;
  visibleEfConversion: boolean;
  form = this.fb.group({
    renta: ['', Validators.required],
    renglon2516: ['', Validators.required],
  });

  refreshingData = false;
  inputSeleccionado = false;

  public menuFilter: number = 0;
  public cuentaHidden: boolean = true;
  public NombreCuentaHidden: boolean = true;
  public renglonHidden: boolean = true;
  public descripcionHidden: boolean = true;
  public itemHidden: boolean = true;
  public valorFiscalHidden: boolean = true;
  public viewChangeData: boolean = false;
  categorias: any[] = [];
  registrosTabla: any[] = [];

  public descriptionTaxAdjustmentList: Array<string> = [
    'Anexo Ganancias Ocasionales',
    'GMF 4x1000',
    'AUTORRETENCIONES',
    'Renta exenta',
    'DESCUENTO TRIBUTARIO',
    'INCRGO',
    'Anexo de INCRGO',
    'DECLARACION ICA',
    'GASTOS DEL EXTERIOR',
    'RETENCIONES',
    'CUENTAS (VALORES) RENTA GRAVABLE',
    'CUENTAS (VALORES) AFECTACIÓN FISCAL',
    'Inter Presuntos',
    'Cartera Provision y Deterioro',
  ];

  public selectedRows: any[] = [];

  public currentFilter: string;

  constructor(
    private fb: FormBuilder,
    private decService: InfoContribuyenteService,
    private depService: DepuracionService,
    private filterService: FilterService,
    private routers: Router,
    private proyectSrv: ListProyectosService,
    private notifyService: NotifyService,
    private dialog: MatDialog,
    private store: Store<any>,
    private titulo: Title,
    private spinner: SpinnerService,
    private cdr: ChangeDetectorRef
  ) {
    this.routers.events.subscribe((event) => {
      this.autoSave(event);
    });
    titulo.setTitle(depuracion);
  }

  ngOnInit(): void {
    this.messageAlert = {
      type: 'success',
      title: 'Todo genial!',
      message: 'Tabla sin errores',
    };
    this.initDepuracion();
  }
  seleccionarTodo(event: Event) {
    if (event.target instanceof HTMLInputElement) {
      event.target.select();
    }
  }
  seleccionarTodo2(event: Event) {
    const keyboardEvent = event as KeyboardEvent;
    if (keyboardEvent.key === 'Escape') {
      this.refreshData();
    }
    if (keyboardEvent.key === 'Enter') {
      this.guardarDepuracion();
    }
  }

  autoSave(event: any) {
    if (event instanceof NavigationStart || event instanceof NavigationEnd) {
      const url = event.url.split('/');
      if (url[3] === 'depuracion' && url[4] === 'dep') {
        interval(300000).subscribe(() => this.guardarDepuracion());
      }
    }
  }
  changeData() {
    this.viewChangeData = !this.viewChangeData;
    this.cdr.detectChanges();
  }

  clickActions(event: any) {
    switch (event) {
      case 'save':
        this.guardarDepuracion();
        break;

      case 'reload':
        this.guardarDepuracion();
        this.refreshData2();
        break;
      case 'resume':
        this.mostrarResumen();
        break;
      case 'edit':
        this.editMultiple();
        break;
    }
  }

  public onFocusValue(value: number): number {
    switch (Math.sign(value)) {
      case 0:
        return null;
        break;
      case -1:
        return value;
        break;
      case 1:
        return value;
        break;
      default:
        return value;
    }
  }

  initDepuracion() {
    this.spinner.show();
    this.registrosTabla = [];
    this.refreshingData = true;
    this.proyectSrv.currentProject$.subscribe(
      (proyecto) => {
        if (proyecto) {
          this.projectId = proyecto.id;
          this.editStoreStatusDep();
          this.decService.obtenerInformacion(proyecto.id).subscribe((data) => {
            this.declarante = data;
            this.refreshingData = false;
          });

          this.depService.regDepuracion$(proyecto.id).subscribe((data) => {
            Object.keys(data.listByParent).forEach((categoria) => {
              // Agregar la categoría
              this.registrosTabla.push({
                isCategoria: true,
                content: categoria,
              });

              // Agregar los detalles de la categoría
              data.listByParent[categoria].forEach((item) => {
                this.registrosTabla.push({
                  isCategoria: false,
                  content: item,
                });
              });
            });

            let contentList = this.registrosTabla
              .filter((item) => !item.isCategoria)
              .map((item) => item.content);
            this.registros = contentList.map((r) => {
              if (
                ['2', '3', '4', '9'].includes(r.clase) &&
                r.mayorPositivo == false
              ) {
                r.valContable = r.valContable * -1;
                r.valFiscal = r.valFiscal * -1;
              }
              return r;
            });
            //Ordena segun cuenta
            // this.registros.sort(function (a, b) {
            //   if (a.account > b.account) {
            //     return 1;
            //   }
            //   if (a.account < b.account) {
            //     return -1;
            //   }
            //   return 0;
            // });

            this.visibleEfConversion = data.conversionEnabled;
            this.iniciarTarifas();
            this.calcularTotales();
          });
          this.filterService.register(
            'starts',
            (value: string, filter: string): boolean => {
              if (
                filter === undefined ||
                filter === null ||
                filter + ''.trim() === ''
              ) {
                return true;
              }

              if (value === undefined || value === null) {
                return false;
              }

              return value
                .toString()
                .toUpperCase()
                .startsWith(filter.toString().toUpperCase());
            }
          );

          this.spinner.hide();
        }
      },
      (error) => {
        this,
          this.notifyService.error(
            'Error en Proyecto',
            'No encontramos poyecto seleccionado',
            4000
          );
        this.spinner.hide();
      }
    );
  }
  initDepuracion2() {
    this.spinner.show();
    this.registrosTabla = [];
    this.refreshingData = true;
    this.proyectSrv.currentProject$.subscribe(
      (proyecto) => {
        if (proyecto) {
          this.projectId = proyecto.id;
          this.editStoreStatusDep();
          this.decService.obtenerInformacion(proyecto.id).subscribe((data) => {
            this.declarante = data;
            this.refreshingData = false;
          });

          this.depService.regDepuracion$(proyecto.id).subscribe((data) => {
            Object.keys(data.listByParent).forEach((categoria) => {
              // Agregar la categoría
              this.registrosTabla.push({
                isCategoria: true,
                content: categoria,
              });

              // Agregar los detalles de la categoría
              data.listByParent[categoria].forEach((item) => {
                this.registrosTabla.push({
                  isCategoria: false,
                  content: item,
                });
              });
            });

            let contentList = this.registrosTabla
              .filter((item) => !item.isCategoria)
              .map((item) => item.content);
            this.registros = contentList.map((r) => {
              if (
                ['2', '3', '4', '9'].includes(r.clase) &&
                r.mayorPositivo == false
              ) {
                r.valContable = r.valContable * -1;
                r.valFiscal = r.valFiscal * -1;
              }
              return r;
            });
            //Ordena segun cuenta
            // this.registros.sort(function (a, b) {
            //   if (a.account > b.account) {
            //     return 1;
            //   }
            //   if (a.account < b.account) {
            //     return -1;
            //   }
            //   return 0;
            // });

            this.visibleEfConversion = data.conversionEnabled;
            this.iniciarTarifas();
            this.calcularTotales();
          });
          this.filterService.register(
            'starts',
            (value: string, filter: string): boolean => {
              if (
                filter === undefined ||
                filter === null ||
                filter + ''.trim() === ''
              ) {
                return true;
              }

              if (value === undefined || value === null) {
                return false;
              }

              return value
                .toString()
                .toUpperCase()
                .startsWith(filter.toString().toUpperCase());
            }
          );

          this.spinner.hide();
        }
      },
      (error) => {
        this,
          this.notifyService.error(
            'Error en Proyecto',
            'No encontramos poyecto seleccionado',
            4000
          );
        this.spinner.hide();
      }
    );
  }

  calcularTotales() {
    this.sinRenglon = false;
    this.messageAlert = {
      type: 'success',
      title: 'Todo genial!',
      message: 'Tabla sin errores',
    };
    this.totalContable = 0;
    this.totalEfectos = 0;
    this.totalMayorFiscal = 0;
    this.totalMenorFiscal = 0;
    this.totalSaldoFiscal = 0;
    let lista = this.registros;

    if (this.tabla != null) {
      lista =
        this.tabla.filteredValue != null
          ? this.tabla.filteredValue.map((item) => item.content)
          : this.registros;
    }
    lista.map((l) => {
      if (l.renglon == 0 || l.renglon == null) {
        l.renglon = -1;
      }
      return l;
    });
    lista.forEach((reg) => {
      if ((reg.renglon < 0 || reg.renglon == null) && !this.sinRenglon) {
        this.sinRenglon = true;
        this.messageAlert = {
          type: 'alert',
          title: 'Existen cuentas sin asignacion',
          message:
            'Existen cuentas sin asignacion de renglon de renta, filtre en RENGLON por el valor -1 y asigne el que corresponda',
        };
      }
      this.totalContable = this.totalContable + +reg.valContable;
      this.totalEfectos = this.totalEfectos + +reg.efConversion;
      this.totalMayorFiscal = this.totalMayorFiscal + +reg.debitos;
      this.totalMenorFiscal = this.totalMenorFiscal + +reg.creditos;
      this.totalSaldoFiscal = this.totalSaldoFiscal + +reg.valFiscal;
    });
  }
  calcularSaldo(rowData: RegistroDepuracion) {
    rowData.creditos = rowData.creditos > 0 ? rowData.creditos : 0;
    rowData.debitos = rowData.debitos > 0 ? rowData.debitos : 0;
    rowData.efConversion = !isNaN(+rowData.efConversion)
      ? rowData.efConversion
      : 0;
    let saldoFiscal = 0;
    if (
      rowData.valContable < 0 &&
      ((rowData.renglon > 46 &&
        renglonesERIInvertido.includes(rowData.entity2516)) ||
        (rowData.renglon < 46 &&
          renglonesESFInvertido.includes(rowData.entity2516)))
    ) {
      saldoFiscal =
        rowData.valContable +
        +rowData.creditos -
        +rowData.debitos -
        +rowData.efConversion;
    } else {
      saldoFiscal =
        rowData.valContable -
        +rowData.creditos +
        +rowData.debitos +
        +rowData.efConversion;
    }
    if (
      this.registros.find((d) => d.id === rowData.id).valFiscal !== saldoFiscal
    ) {
      this.registros.find((d) => d.id === rowData.id).modificado = true;
      this.registros.find((d) => d.id === rowData.id).valFiscal = saldoFiscal;
      this.guardarDepuracion();
    }
    this.calcularTotales();
    this.iniciarTarifas();
    //this.guardarDepuracion();
  }
  calcularSaldo2(rowData: RegistroDepuracion) {
    rowData.creditos = rowData.creditos > 0 ? rowData.creditos : 0;
    rowData.debitos = rowData.debitos > 0 ? rowData.debitos : 0;
    rowData.efConversion = !isNaN(+rowData.efConversion)
      ? rowData.efConversion
      : 0;
    let saldoFiscal = 0;
    if (
      rowData.valContable < 0 &&
      ((rowData.renglon > 46 &&
        renglonesERIInvertido.includes(rowData.entity2516)) ||
        (rowData.renglon < 46 &&
          renglonesESFInvertido.includes(rowData.entity2516)))
    ) {
      saldoFiscal =
        rowData.valContable +
        +rowData.creditos -
        +rowData.debitos -
        +rowData.efConversion;
    } else {
      saldoFiscal =
        rowData.valContable -
        +rowData.creditos +
        +rowData.debitos +
        +rowData.efConversion;
    }
    if (
      this.registros.find((d) => d.id === rowData.id).valFiscal !== saldoFiscal
    ) {
      this.registros.find((d) => d.id === rowData.id).modificado = true;
      this.registros.find((d) => d.id === rowData.id).valFiscal = saldoFiscal;
    }
    this.calcularTotales();
    this.iniciarTarifas();
  }
  /**
   * Si un registro es modificado le pone una bandera para indicar que se modifico y posteriormente ser enviado al backend
   * @param rowData registro a marcar
   */

  guardarRegistro(event: any, rowData: RegistroDepuracion) {
    if (
      (event.key === 'Tab' || event.key === 'Enter') &&
      rowData.descAjusteFiscal !== ''
    ) {
      this.registros.find((d) => d.id === rowData.id).modificado = true;
      this.guardarDepuracion();
    }
  }

  mostrarResumen() {
    this.resumen$ = this.depService.resumen$(this.projectId).pipe(
      map((datos) => {
        datos.forEach((d) => {
          d.totalCredito =
            d.type == 2222 || d.type == 3333
              ? d.totalCredito
              : this.valorAbsoluto(d.totalCredito);
          d.totalDebito =
            d.type == 2222 || d.type == 3333
              ? d.totalDebito
              : this.valorAbsoluto(d.totalDebito);
          d.totalEfConversion =
            d.type == 2222 || d.type == 3333
              ? d.totalEfConversion
              : this.valorAbsoluto(d.totalEfConversion);
          d.totalValorContable =
            d.type == 2222 || d.type == 3333
              ? d.totalValorContable
              : this.valorAbsoluto(d.totalValorContable);
          d.totalValorFiscal =
            d.type == 2222 || d.type == 3333
              ? d.totalValorFiscal
              : this.valorAbsoluto(d.totalValorFiscal);
          let objeto: any = this.detalle(d.type);
          d.detalle = objeto.detalle;
          d.type = objeto.tipo;
        });
        return datos;
      })
    );

    this.dialog.open(DepuracionModalResumen, {
      data: this.resumen$,
    });
  }
  detalle(tipo: number): Object {
    let objeto: Object = {};
    switch (tipo) {
      case 1: {
        objeto['detalle'] = 'Total Activos';
        objeto['tipo'] = 1;
        break;
      }
      case 2: {
        objeto['detalle'] = 'Total Pasivos';
        objeto['tipo'] = 2;
        break;
      }
      case 3: {
        objeto['detalle'] = 'Total Patrimonio';
        objeto['tipo'] = 3;
        break;
      }
      case 4: {
        objeto['detalle'] = 'Total Ingresos';
        objeto['tipo'] = 4;
        break;
      }
      case 5: {
        objeto['detalle'] = 'Total Gastos';
        objeto['tipo'] = 5;
        break;
      }
      case 6: {
        objeto['detalle'] = 'Total Costos de ventas';
        objeto['tipo'] = 6;
        break;
      }
      case 7: {
        objeto['detalle'] = 'Total Costos de producción';
        objeto['tipo'] = 7;
        break;
      }
      case 8: {
        objeto['detalle'] = 'Total de Cuentas de orden deudoras';
        objeto['tipo'] = 8;
        break;
      }
      case 9: {
        objeto['detalle'] = 'Total de Cuentas de orden acreedoras';
        objeto['tipo'] = 9;
        break;
      }
      case 1111: {
        objeto['detalle'] = 'Total (Activo - Pasivo)';
        objeto['tipo'] = null;
        break;
      }
      case 2222: {
        objeto['detalle'] = 'Total P&G';
        objeto['tipo'] = null;
        break;
      }
      case 3333: {
        objeto['detalle'] = '(Activo - Pasivo - Total P&G)';
        objeto['tipo'] = null;
        break;
      }
    }
    return objeto;
  }
  iniciarTarifas() {
    this.registros.forEach((reg) => {
      if (reg.tarifasList.length > 0) {
        if (+reg.clase < 4) {
          reg.tarifasList.forEach((t) => {
            t.mayor = true;
            t.amount = 0;
          });
        } else {
          let total = 0;
          reg.tarifasList.sort(function (a, b) {
            return a.valorTarifa - b.valorTarifa;
          });
          reg.tarifasList.forEach((t) => (total = total + +t.amount));
          reg.tarifasList[reg.tarifasList.length - 1].amount =
            reg.valFiscal +
            +reg.tarifasList[reg.tarifasList.length - 1].amount -
            total;
          reg.tarifasList[reg.tarifasList.length - 1].mayor = true;
        }
      }
    });
  }
  toggleRow(rowData: any) {
    rowData.expanded = !rowData.expanded;
  }
  /**
   * verifica si el valor es positivo y si la suma de las tarifas es menor o igual al saldo fiscal del registro, si es asi
   * realiza el guardado para enviar al backend despues
   * @param t registro de tarifas
   * @param reg registro de depuracion
   * @returns si no cumple la validacion retorna cero, si cumple retorna el valor de la tarifa
   */

  validarTarifas(t: TarifaDepuracion, reg: RegistroDepuracion): number {
    if (reg.valFiscal > 0) {
      if (
        t.amount > 0 &&
        t.amount <= reg.valFiscal &&
        this.calcularTotalTarifas(reg) <= reg.valFiscal
      ) {
        reg.tarifasList.find((tar) => tar.id === t.id).amount = +t.amount;
        this.registros.find((d) => d.id === reg.id).modificado = true;
        this.iniciarTarifas();
        this.calcularTotales();
        return t.amount;
      } else {
        reg.tarifasList.find((tar) => tar.id === t.id).amount = 0;
        this.registros.find((d) => d.id === reg.id).tarifasList =
          reg.tarifasList;
        this.iniciarTarifas();
        return 0;
      }
    } else {
      if (
        t.amount < 0 &&
        t.amount >= reg.valFiscal &&
        this.calcularTotalTarifas(reg) >= reg.valFiscal
      ) {
        reg.tarifasList.find((tar) => tar.id === t.id).amount = +t.amount;
        this.registros.find((d) => d.id === reg.id).modificado = true;
        this.iniciarTarifas();
        this.calcularTotales();
        return t.amount;
      } else {
        reg.tarifasList.find((tar) => tar.id === t.id).amount = 0;
        this.registros.find((d) => d.id === reg.id).tarifasList =
          reg.tarifasList;
        this.iniciarTarifas();
        return 0;
      }
    }
  }

  calcularTotalTarifas(reg: RegistroDepuracion): number {
    let total = 0;
    reg.tarifasList.forEach((t) => (total = total + +t.amount));
    total = total - reg.tarifasList[reg.tarifasList.length - 1].amount;
    return total;
  }

  columnasTarifas(registros: RegistroDepuracion[]): TarifaDepuracion[] {
    if (registros != null && registros.length > 0) {
      let reg: RegistroDepuracion;
      let mayor: number = 0;
      registros.forEach((r) => {
        if (r.tarifasList.length > mayor) {
          reg = r;
          mayor = r.tarifasList.length;
        }
      });
      if (mayor > 0) {
        return reg.tarifasList;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  editar(reg: RegistroDepuracion) {
    this.registro = reg;
    const patch = {
      renta: reg.renglon,
      renglon2516: reg.entity2516,
    };
    if (reg.renglon === -1) {
      this.renglonesRenta$ = this.depService
        .renglones$(-1, this.projectId)
        .pipe(
          map((ren) => {
            ren.sort(function (a, b) {
              return a.value - b.value;
            });
            return ren;
          })
        );

      this.renglones2516$ = this.depService.renglones2516$(
        this.projectId,
        -1,
        +reg.clase
      );
    } else {
      this.renglonesRenta$ = this.depService
        .renglones$(+reg.clase, this.projectId)
        .pipe(
          map((ren) => {
            ren.sort(function (a, b) {
              return a.value - b.value;
            });
            return ren;
          })
        );

      if (reg.renglon > 0) {
        this.renglones2516$ = this.depService.renglones2516$(
          this.projectId,
          +reg.renglon,
          +reg.clase
        );
      }
    }

  
    const depuracionEdit = this.dialog.open(DepuracionModalEdit, {
      data: {
        projectId: this.projectId,
        patch: patch,
        registro: reg,
        renglonesRenta: this.renglonesRenta$,
      },
    });

    depuracionEdit.afterClosed().subscribe((click: any) => {
      this.guardar(click.data, +reg.clase);
      this.refreshData2();
    });
  }

  private guardar(form: any, clase: number) {
    this.registros.find((reg) => reg.id === this.registro.id).renglon =
      form.renta;
    this.renglones2516$ = this.depService.renglones2516$(
      this.projectId,
      +form.renta,
      clase
    );
    this.renglones2516$.subscribe((data: Renglon2516[]) => {
      this.registros.find(
        (reg) => reg.id === this.registro.id
      ).descripcion2516 = data.find(
        (r) => r.value === +form.renglon2516
      ).description;
      this.registros.find((reg) => reg.id === this.registro.id).entity2516 =
        data.find((r) => r.value === +form.renglon2516).value;
      if (data.find((r) => r.value === +form.renglon2516).sinEfFiscal) {
     
        this.notifyService
          .warning(
            '',
            'Para el renglon ' +
              form.renglon2516 +
              ' el valor fiscal se ajustara en 0',
            0,
            {
              text: 'Entendido',
              orientation: 'izq',
            }
          )
          .afterDismissed()
          .subscribe((l) => {
            if (l.dismissedByAction) {
              this.registros.find(
                (reg) => reg.id === this.registro.id
              ).modificado = true;
              this.registros.find(
                (reg) => reg.id === this.registro.id
              ).sinEfFiscal = true;
              let regDep = this.registros.find(
                (reg) => reg.id === this.registro.id
              );
              this.registros.find(
                (reg) => reg.id === this.registro.id
              ).creditos =
                regDep.valContable + regDep.efConversion + regDep.debitos;
              this.calcularSaldo2(regDep);
              this.calcularTotalTarifas(regDep);
              this.depService
                .actualizarDepuracion({
                  projectId: this.projectId,
                  list: [
                    this.registros.find((reg) => reg.id === this.registro.id),
                  ],
                })
                .subscribe(
                  (data) => {
                    this.notifyService.success(
                      'Guardado Exitoso!',
                      'Los registros se han guardado correctamente',
                      4000
                    );
                  },
                  (error) => {
                    this.notifyService.error(
                      'Error inesperado',
                      'Tenemos problemas para guardar',
                      4000
                    );
                  }
                );
            }
          });
      } else {
        this.registros.find((reg) => reg.id === this.registro.id).modificado =
          true;
        this.registros.find((reg) => reg.id === this.registro.id).sinEfFiscal =
          false;
        this.depService
          .actualizarDepuracion({
            projectId: this.projectId,
            list: [this.registros.find((reg) => reg.id === this.registro.id)],
          })
          .subscribe(
            (data) => {
              this.notifyService.success(
                'Guardado Exitoso!',
                'Los registros se han guardado correctamente',
                4000
              );
              this.store.dispatch(
                getProjectStatus({ projectId: this.projectId })
              );
            },
            (error) => {
              this.notifyService.error(
                'Error inesperado',
                'Tenemos problemas para guardar',
                4000
              );
            }
          );
      }
    });
  }

  editStoreStatusDep() {
    setTimeout(() => {
      const channel = new BroadcastChannel('depuracion-channel');
      channel.postMessage('ready');
    }, 2000);
  }

  visibilityFilter(event, filterName) {
    if (event.target.value !== '') {
      switch (filterName) {
        case 'cuenta':
          this.cuentaHidden = false;
          break;

        case 'nombre cuenta':
          this.NombreCuentaHidden = false;
          break;

        case 'renglon':
          this.renglonHidden = false;
          break;

        case 'descripcion':
          this.descripcionHidden = false;
          break;

        case 'item':
          this.itemHidden = false;
          break;

        case 'valor fiscal':
          this.valorFiscalHidden = false;
          break;
      }
    } else {
      switch (filterName) {
        case 'cuenta':
          this.cuentaHidden = true;
          break;

        case 'nombre cuenta':
          this.NombreCuentaHidden = true;
          break;

        case 'renglon':
          this.renglonHidden = true;
          break;

        case 'descripcion':
          this.descripcionHidden = true;
          break;

        case 'item':
          this.itemHidden = true;
          break;

        case 'valor fiscal':
          this.valorFiscalHidden = true;
          break;
      }
    }
  }

  public dialogoEliminar(reg: RegistroDepuracion) {
    const dialogDelete = this.dialog.open(ModalGrlComponent, {
      data: {
        icon: 'doubt',
        textOne: 'Estas a punto de ',
        textFocus: 'eliminar el registro?',
        textTwo: '¿Deseas Continuar?',
        buttons: [
          {
            icon: 'arrow_left',
            text: 'Regresar',
            id: 'back',
            clase: 'btn_primary',
          },
          {
            icon: 'trash',
            text: 'Si Borrar',
            id: 'delete',
            clase: 'btn_danger',
            direction: 'izq',
          },
        ],
      },
    });

    dialogDelete.afterClosed().subscribe((click: any) => {
      if (click.data === 'delete') {
        this.eliminar(reg);
      } else {
        dialogDelete.close();
      }
    });
  }

  private eliminar(reg: RegistroDepuracion) {
    this.depService.deleteRenglon(reg.id).subscribe(
      (data) => {
        this.depService.regDepuracion$(this.projectId).subscribe((data) => {
          this.registros = data.list;
          this.visibleEfConversion = data.conversionEnabled;
          this.displayDialogDelete = false;
          this.iniciarTarifas();
          this.calcularTotales();

          this.notifyService.success(
            'Registro eliminado!',
            'El registro se ha eliminado correctamente',
            4000
          );
        });
      },
      (error) =>
        this.notifyService.error(
          'Algo salio mal!',
          'El registro no pudo ser eliminado ',
          4000
        )
    );
  }
  valorAbsoluto(num: number): number {
    if (num < 0) {
      return num * -1;
    } else {
      return num;
    }
  }

  // collapsed(event) {
  //   this.collapse = event.collapsed;
  // }

  refreshData = () => {
    this.initDepuracion();
  };
  refreshData2 = () => {
    this.initDepuracion2();
  };
  guardarDepuracion() {
    let listaGuardado: RegistroDepuracion[] = [];
    this.registros
      .filter((reg) => reg.modificado === true)
      .forEach((r) => {
        let r1: RegistroDepuracion = {
          id: r.id,
          account: r.account,
          clase: r.clase,
          creditos: r.creditos,
          debitos: r.debitos,
          descAjusteFiscal: r.descAjusteFiscal,
          descripcion2516: r.descripcion2516,
          efConversion: r.efConversion,
          entity2516: r.entity2516,
          item: r.item,
          nameAccount: r.nameAccount,
          nivel: r.nivel,
          renglon: r.renglon,
          tarifasList: r.tarifasList,
          type: r.type,
          valContable: r.valContable,
          valFiscal: r.valFiscal,
          mayorPositivo: r.mayorPositivo,
          auxiliar: r.auxiliar,
          sinEfFiscal: false,
        };
        listaGuardado.push(r1);
      });
    this.depService
      .actualizarDepuracion({
        projectId: this.projectId,
        list: listaGuardado,
      })
      .subscribe(
        (data) => {
          this.notifyService.success(
            'Guardado Exitoso!',
            'Los registros se han guardado correctamente',
            4000
          );
          this.registros
            .filter((reg) => reg.modificado === true)
            .forEach((reg) => {
              const findIndex = this.registros.findIndex(
                (e) => e.id === reg.id
              );
              this.registros[findIndex].modificado = false;
            });
        },
        (error) => {
          this.notifyService.error(
            'Error inesperado',
            'Tenemos problemas para guardar',
            4000
          );
        }
      );
  }

  validateDescrTaxAdjLink(value: string): boolean {
    return this.descriptionTaxAdjustmentList.find(
      (v: string) => v === value
    ) !== undefined
      ? true
      : false;
  }
  getDescrTaxAdjLink(value: string): string {
    switch (value) {
      case 'Anexo Ganancias Ocasionales':
        return `/proyecto/${this.projectId}/anx-depuracion/ganancia_ocasional`;
      case 'GMF 4x1000':
        return `/proyecto/${this.projectId}/anx-depuracion/gmf`;
      case 'AUTORRETENCIONES':
        return `/proyecto/${this.projectId}/anx-depuracion/autorretenciones`;
      case 'Renta exenta':
        return `/proyecto/${this.projectId}/anx-depuracion/renta`;
      case 'DESCUENTO TRIBUTARIO':
        return `/proyecto/${this.projectId}/anx-depuracion/descuentos_tributarios`;
      case 'INCRGO':
        return `/proyecto/${this.projectId}/anx-depuracion/incrgo`;
      case 'Anexo de INCRGO':
        return `/proyecto/${this.projectId}/anx-depuracion/incrgo`;
      case 'DECLARACION ICA':
        return `/proyecto/${this.projectId}/anx-depuracion/industria_comercio`;
      case 'GASTOS DEL EXTERIOR':
        return `/proyecto/${this.projectId}/anx-depuracion/gasto_exterior`;
      case 'RETENCIONES':
        return `/proyecto/${this.projectId}/patrimonio/retefuente`;
      case 'CUENTAS (VALORES) AFECTACIÓN FISCAL':
        return `/proyecto/${this.projectId}/renta/fiscal`;
      case 'CUENTAS (VALORES) RENTA GRAVABLE':
        return `/proyecto/${this.projectId}/renta/gravable`;
      case 'Inter Presuntos':
        return `/proyecto/${this.projectId}/anx-depuracion/intereses_presuntivos`;
      case 'Cartera Provision y Deterioro':
        return `/proyecto/${this.projectId}/anx-depuracion/cartera-provision`;
      default:
        return '';
    }
  }

  public selectedClass: number;

  disableLines(rowData: RegistroDepuracion): void {
    if (!this.selectedRows.length) {
      this.selectedClass = +rowData.clase;
    }
  }
  cantidadSelect() {
    if (this.selectedRows.length > 1) {
      this.ActivateButtonEdit = true;
    } else {
      this.ActivateButtonEdit = false;
    }
  }

  isRowDisabled(rowData: RegistroDepuracion): boolean {
    return +rowData.clase !== this.selectedClass;
  }

  getCurrentFilter(event: { filters: { [s: string]: FilterMetadata } }): void {
    const filter = event.filters['account'];
    if (filter) {
      this.currentFilter = filter.value;
    }
  }

  editMultiple(): void {
    let rowsToEdit = this.selectedRows.map((item) => item.content);
    if (rowsToEdit.length > 0) {
      const patch = {
        renta: rowsToEdit[0].renglon,
        renglon2516: rowsToEdit[0].entity2516,
      };

      this.renglonesRenta$ = this.depService
        .renglones$(+rowsToEdit[0].clase, this.projectId)
        .pipe(
          map((ren) => {
            ren.sort(function (a, b) {
              return a.value - b.value;
            });
            return ren;
          })
        );
      const depuracionEdit = this.dialog.open(DepuracionModalEdit, {
        data: {
          projectId: this.projectId,
          patch: patch,
          registro: rowsToEdit[0],
          renglonesRenta: this.renglonesRenta$,
        },
      });

      depuracionEdit.afterClosed().subscribe((click: any) => {
        if (click.data) {
          let description: string;

          this.depService
            .renglones2516$(
              this.projectId,
              click.data.renta,
              +rowsToEdit[0].clase
            )
            .subscribe((res) => {
              description = res.find((row) => {
                return row.value === parseInt(click.data.renglon2516);
              }).description;

              rowsToEdit.forEach((_, index) => {
                rowsToEdit[index].renglon = click.data.renta;
                rowsToEdit[index].entity2516 = click.data.renglon2516;
                rowsToEdit[index].descripcion2516 = description;
              });

              this.depService
                .actualizarDepuracion({
                  projectId: this.projectId,
                  list: rowsToEdit,
                })
                .subscribe(
                  (data) => {
                    this.notifyService.success(
                      'Guardado Exitoso!',
                      'Los registros se han guardado correctamente',
                      4000
                    );
                    this.refreshData2();
                    this.selectedRows = [];
                    if (this.currentFilter)
                      this.tabla.filter(
                        this.currentFilter,
                        'renglon',
                        'equals'
                      );
                    this.ActivateButtonEdit = false;
                  },
                  (error) => {
                    this.notifyService.error(
                      'Error inesperado',
                      'Tenemos problemas para guardar',
                      4000
                    );
                  }
                );
            });
        }
      });
    } else {
      this.notifyService.info(
        'Selección de filas',
        'No has seleccionado filas para editar',
        4000
      );
    }
  }
}
