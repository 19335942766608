import { Component, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';
import { DialogTicketComponent } from '../dialog-ticket/dialog-ticket.component';

@Component({
  selector: 'app-button-ticket',
  templateUrl: './button-ticket.component.html',
  styleUrls: ['./button-ticket.component.scss']
})
export class ButtonTicketComponent implements OnInit {
  public clicked: boolean = false;

  constructor(
    public dialog: MatDialog,
    private renderer: Renderer2
  ) {}

  ngOnInit() {

    this.renderer.listen('window', 'click',(e:any)=>{
      // if((e.path[4].id) !== 'btn-menu-float' && this.clicked){
      //   document.getElementById("btn-help").click();
      // }
    })
  }

  helpClick(){
    const element = document.getElementById("changeToogle");
    element.classList.toggle("open");


  }

  tutoriales(){
    window.open('https://www.youtube.com/channel/UCGYLDQlnMP8FMJNYcnoxc2g', '_blank');

    const element = document.getElementById("btn-help");
    element.click();
  }

  reportarBug(){
    this.dialog.open(DialogTicketComponent, { disableClose: true });

    const element = document.getElementById("btn-help");
    element.click();

  }
}
