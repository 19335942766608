import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '@environment';
import { AuthModuleState } from '@auth/states';
import { reducer } from './auth.reducer';

export const reducers: ActionReducerMap<AuthModuleState> = {
  auth: reducer,
};

export const metaReducers: MetaReducer<AuthModuleState>[] = !environment.production ? [] : [];

export const getAuthModuleState = createFeatureSelector<AuthModuleState>(
  'auth-module'
);
