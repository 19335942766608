import { createReducer, on, Action } from '@ngrx/store';
import { autorretencionesAdapter, AutorretencionesState } from '@store/states';
import { getAutorretencionesSuccess, setAutorretencionesSuccess } from '../actions';

export const initialState: AutorretencionesState = autorretencionesAdapter.getInitialState({})

const autorretencionesReducer = createReducer(
  initialState,

  on(getAutorretencionesSuccess, (state, { autorretencionesSuccess }) => {
    state.ids = [];
    state.entities = {};
    return autorretencionesAdapter.upsertOne(autorretencionesSuccess, { ...state })
  }),

  on(setAutorretencionesSuccess, (state, { autorretencionesForm }) => {
    state.ids = [];
    state.entities = {};
    return autorretencionesAdapter.upsertOne(autorretencionesForm, state)
  }),
)

export function reducer(state: AutorretencionesState | undefined, action: Action) {
  return autorretencionesReducer(state, action);
}
