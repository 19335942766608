import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationStart, Router, Event, NavigationEnd} from '@angular/router';

import { Observable } from 'rxjs';
import { delay ,map } from 'rxjs/operators';
import { toArray } from "lodash";

import { AuthService } from '@auth/services/auth.service';
import { ListProyectosService } from '@proyectos/services/list-proyectos.service';

import { Project } from '@app/pages/proyectos/proyecto';
import { Store, select } from '@ngrx/store';
import { selectProyectList } from '@store/selectors';

@Component({
  selector: 'app-side-bar-mobile',
  templateUrl: './side-bar-mobile.component.html',
  styleUrls: ['./side-bar-mobile.component.scss']
})
export class SideBarMobileComponent implements OnInit {

  public menuOptions = [
    {
      label: 'panel principal',
      icon: 'menu_bars',
      route: '/home',
    },
    {
      label: 'datos de informacion',
      icon: 'db',
      route: '',
    },
    {
      label: 'anexos patrimoniales',
      icon: 'copy',
      route: '',
    },
    {
      label: 'anexos depuracion',
      icon: 'invoice',
      route: '',
    },
    {
      label: 'conciliaciones renta y verif',
      icon: 'check_rounded',
      route: '',
    },
    {
      label: 'formato 2516',
      icon: 'edit_pen',
      route: '',
    },
    {
      label: 'anexos de informacion',
      icon: 'paper',
      route: '',
    },
    {
      label: 'salir',
      icon: 'log_out',
      route: '',
    }
  ]

  proyectos$:Observable<Project[]>;
  currentUser$ = this.auth.currentUser$;

  @Input() showProject: boolean = false;
  @Output() closeSidenav = new EventEmitter();

  projectId: number;

  constructor(
    private auth: AuthService,
    private store: Store,
    private projectSrv: ListProyectosService,
    private router: Router
  ) {

    this.proyectos$ = this.store.pipe(
      select(selectProyectList),
      map((list) => toArray(list))
    );

    router.events.subscribe(
      event => this.updateMenu(event)
    )
   }

  ngOnInit(): void {
  }

  logout() {
    this.auth.logOut().pipe(
      delay(1000)
    ).subscribe(
      () => {
        this.router.navigate(['/auth/login']);
      }
    );
  }

  updateMenu(event: Event) {

    if(event instanceof NavigationStart || event instanceof NavigationEnd){
      const url = event.url.split('/');
      this.projectId = +url[2];
    }
  }

}
