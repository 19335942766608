<ng-container *ngIf="submitAttempt">
  <div *ngIf="showErrors"
    class="errors-wrapper">
    <span class="container_messages d-flex align-items-center flex-row gap-2"
      *ngIf="!forceMessage">
      <span *ngFor="let error of validationErrorsKeys"
        class="text-danger">
        {{ getErrorString(error)}}<br>
      </span>

      <ng-container *ngIf="apiErrors">
        <span *ngFor="let error of apiErrors[controlName]"
          class="text-danger">
          {{ error }}
        </span>
      </ng-container>
    </span>
    <div *ngIf="forceMessage"
      class="force_message">
      <p class="text-danger">
        <ng-content></ng-content>
      </p>
    </div>
  </div>
</ng-container>
