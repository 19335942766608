import { createAction, props } from '@ngrx/store';
import { Autorretenciones } from '../models/autorretenciones.model';

export const GET_AUTORRETENCIONES = '[autorretenciones] get autorretenciones';
export const GET_AUTORRETENCIONES_SUCCESS = '[autorretenciones] get autorretenciones success';
export const GET_AUTORRETENCIONES_ERROR = '[autorretenciones] get autorretenciones with error';

export const SET_AUTORRETENCIONES = '[autorretenciones] set autorretenciones save info';
export const SET_AUTORRETENCIONES_SUCCESS = '[autorretenciones] set autorretenciones save info success';
export const SET_AUTORRETENCIONES_ERROR = '[autorretenciones] set autorretenciones save info with error';


export const getAutorretenciones = createAction(
  GET_AUTORRETENCIONES,
  props<{projectId: number}>()
);

export const getAutorretencionesSuccess = createAction(
  GET_AUTORRETENCIONES_SUCCESS,
  props<{ autorretencionesSuccess: Autorretenciones  }>()
);

export const getAutorretencionesError = createAction(
  GET_AUTORRETENCIONES_ERROR,
  props<{ error: string }>()
);

export const setAutorretenciones = createAction(
  SET_AUTORRETENCIONES,
  props<{ autorretencionesForm: Autorretenciones, projectId: number }>()
);

export const setAutorretencionesSuccess = createAction(
  SET_AUTORRETENCIONES_SUCCESS,
  props<{ autorretencionesForm: Autorretenciones }>()
);

export const setAutorretencionesError = createAction(
  SET_AUTORRETENCIONES_ERROR,
  props<{ error: string }>()
);
