import { createSelector } from '@ngrx/store';
import { getProjectsModuleState } from '../reducers/index';
import { toArray } from 'lodash';
import { AnnexedStatus } from '../models';

export const selectAnnexedStatusList = createSelector(
  getProjectsModuleState,
  state => toArray(state.annexedStatus.entities)
);

export const selectAnnexedStatusById = (id: number) =>
  createSelector(getProjectsModuleState, state => {
  const data: any = state.annexedStatus.entities;
  if(data) {
    return toArray(data).find((item: AnnexedStatus) => {
      return item.id === id;
    });
  } else {
    return {};
  }
});


export const selectAnnexedStatusByAnnexed = (annexed: string) =>
  createSelector(getProjectsModuleState, state => {
  const data: any = state.annexedStatus.entities;
  if(data) {
    return toArray(data).find((item: AnnexedStatus) => {
      return item.annexed === annexed;
    });
  } else {
    return {};
  }
});

export const selectAnnexedStatusByTab = (tab: string) =>
  createSelector(getProjectsModuleState, state => {
  const data: any = state.annexedStatus.entities;
  if(data) {
    return toArray(data).find((item: AnnexedStatus) => {
      return item.tab === tab;
    });
  } else {
    return {};
  }
});