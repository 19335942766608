import { environment } from '@environment';
import {
  routerReducer, RouterReducerState
} from '@ngrx/router-store';
import { localStorageSync } from 'ngrx-store-localstorage';

import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';

import { forOwn, set } from 'lodash';
import { RouterStateUrl } from './custom-route-serializer';

export interface State {
  router: RouterReducerState<RouterStateUrl>;
}

export const reducers: ActionReducerMap<State> = {
  router: routerReducer
};

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return function (state: State, action: any): State {

    const payload = {};

    forOwn(action, (value, key) => set(payload, key, value));

    if (action.type.indexOf('@ngrx') < 0 && action.type.indexOf('[SYSTEM] extend the logout timer') < 0) {
  
    }

    return reducer(state, action);
  };
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      {
        projectModule: [
          'projects',
          'project_status',
          'balance',
          'depuracion',
          'eri',
          'seccion1Gmf',
          'seccion2Gmf',
          'seccion3Gmf',
          'rentaOpciones',
          'anticipoRenta',
          'donacion',
          'impuestoExterior',
          'annexedStatus',
          'calculoDescuento',
          'autorretenciones',
          'autorreTotales',
          'ajusteDepuracion',
          'relacionDeclaraciones',
          'ajusteDepuracionIca',
          'ajustePasivosIca',
          'perdidasCompensar',
          'perdidasCompensarInsolvencia',
          'excesosPresuntiva',
          'nomina',
          'interesesPresuntivos'
        ]
      },
      {
        patrimoniales: [
          'retefuente',
          'retefuenteDepuracion'
        ]
      },
      {
        conciliaciones: [
          'renta'
        ]
      }

    ],
    rehydrate: true
  })(reducer);
}

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [localStorageSyncReducer, logger]
  : [localStorageSyncReducer];

export const getRouterState = createFeatureSelector<RouterReducerState<RouterStateUrl>>(
  'router'
);

export const selectCurrentRoute = createSelector(
  getRouterState,
  route => route.state
)
