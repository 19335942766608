import { createAction, props } from '@ngrx/store';
import firebase from 'firebase/app'

export const GET_CURRENT_USER = '[auth] get current user';
export const GET_CURRENT_USER_SUCCESS = '[auth] get current user success';
export const GET_CURRENT_USER_ERROR = '[auth] get current user error';

export const SET_FORM_LOGIN = '[auth] set form login';
export const SET_FORM_LOGIN_SUCCESS = '[auth] set form login success';
export const SET_FORM_LOGIN_ERROR = '[auth] set form login error';

export const SET_FORM_LOGIN_GOOGLE = '[auth] set form login with google';
export const SET_FORM_LOGIN_GOOGLE_SUCCESS = '[auth] set form login with google success';
export const SET_FORM_LOGIN_GOOGLE_ERROR = '[auth] set form login with google error';

export const SET_FORM_REGISTER = '[auth] set form register';
export const SET_FORM_REGISTER_SUCCESS = '[auth] set form register success';
export const SET_FORM_REGISTER_ERROR = '[auth] set form register error';

export const SET_LOGOUT = '[auth] set form logout';
export const SET_LOGOUT_SUCCESS = '[auth] set form logout success';
export const SET_LOGOUT_ERROR = '[auth] set form logout error';

//currentUser
export const getCurrentUser = createAction(
  GET_CURRENT_USER,
)
export const getCurrentUserSuccess = createAction(
  GET_CURRENT_USER_SUCCESS,
  props<{ user: any }>()
)
export const getCurrentUserError = createAction(
  GET_CURRENT_USER_ERROR,
  props<{ error: string }>()
)
//FormLogin
export const setFormLogin = createAction(
  SET_FORM_LOGIN,
  props<{ userForm, form }>()
)
export const setFormLoginSuccess = createAction(
  SET_FORM_LOGIN_SUCCESS,
  props<{ user }>()
)
export const setFormLoginError = createAction(
  SET_FORM_LOGIN_ERROR,
  props<{ error: string }>()
)
//googleLogin
export const setFormLoginGoogle = createAction(
  SET_FORM_LOGIN_GOOGLE,
  props<{ userForm, form }>()
)
export const setFormLoginGoogleSuccess = createAction(
  SET_FORM_LOGIN_GOOGLE_SUCCESS,
  props<{ user }>()
)
export const setFormLoginGoogleError = createAction(
  SET_FORM_LOGIN_GOOGLE_ERROR,
  props<{ error: string }>()
)
//FormRegister
export const setFormRegister = createAction(
  SET_FORM_REGISTER,
  props<{ userForm }>()
)
export const setFormRegisterSuccess = createAction(
  SET_FORM_REGISTER_SUCCESS,
  props<{ user }>()
)
export const setFormRegisterError = createAction(
  SET_FORM_REGISTER_ERROR,
  props<{ error: string }>()
)
//Logout
export const setLogout = createAction(
  SET_LOGOUT,
)
export const setLogoutSuccess = createAction(
  SET_LOGOUT_SUCCESS,
)
export const setLogoutError = createAction(
  SET_LOGOUT_ERROR,
  props<{ error: string }>()
)
