import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { NotifyService } from '@app/shared/services/notify.service';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ImpuestoDiferidoService } from '@app/pages/formato2516/services/impuesto-diferido.service';
import * as actionsImpuestoDiferido from '../actions/impuesto-diferido.actions';
import { RegistroTotalID } from '@app/pages/formato2516/components/impuesto-diferido/model/impuesto-diferido';
import { ImpuestoDiferidoStore } from '../models';
import { RegistroESF } from '@app/pages/formato2516/components/esfpatrimonio/model/esf';

@Injectable()
export class ImpuestoDiferidoEffects {
  getImpuestoDiferido$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsImpuestoDiferido.getImpuestoDiferido),
      switchMap((action) => {
        return this.impuestoDiferidoService
          .getImpuestoDiferido(action.projectId)
          .pipe(
            map((registroTotal: RegistroTotalID) => {
              const impuestoStore: ImpuestoDiferidoStore = {
                projectId: action.projectId,
                impuestoDiferidoRegistro: registroTotal,
              };
              return actionsImpuestoDiferido.getImpuestoDiferidoSuccess({
                registroTotal: impuestoStore,
              });
            }),
            catchError((error) => {
              this.notify.error(
                'Algo salio mal! ',
                'No pudimos cargar la información del contribuyente',
                4000
              );
              return of(
                actionsImpuestoDiferido.getImpuestoDiferidoFail({ error })
              );
            })
          );
      })
    )
  );

  guardarImpuestoDiferido$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsImpuestoDiferido.setImpuestoDiferido),
      switchMap((action) => {
        return this.impuestoDiferidoService
          .guardarImpuestoDiferido(action.projectId, action.payload)
          .pipe(
            map((newRegistroTotal: RegistroTotalID) => {
              const impuestoStore: ImpuestoDiferidoStore = {
                projectId: action.projectId,
                newImpuestoDiferidoRegistro: newRegistroTotal,
              };
              return actionsImpuestoDiferido.setImpuestoDiferidoSuccess({
                registroTotal: impuestoStore,
              });
            }),
            catchError((error) => {
              this.notify.error(
                'Algo salio mal! ',
                'No pudimos cargar la información del contribuyente',
                4000
              );
              return of(
                actionsImpuestoDiferido.setImpuestoDiferidoFail({ error })
              );
            })
          );
      })
    )
  );

  getValorRenglon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsImpuestoDiferido.getValorRenglon),
      switchMap((action) => {
        return this.impuestoDiferidoService
          .getValorRenglon(action.projectId, action.renglon)
          .pipe(
            map((registros: RegistroESF[]) => {
              const impuestoStore: ImpuestoDiferidoStore = {
                projectId: action.projectId,
                registrosRenglones: registros,
              };
              return actionsImpuestoDiferido.getValorRenglonSuccess({
                resgistrosESF: impuestoStore,
              });
            }),
            catchError((error) => {
              this.notify.error(
                'Algo salio mal! ',
                'No pudimos cargar la información del contribuyente',
                4000
              );
              return of(actionsImpuestoDiferido.getValorRenglonFail({ error }));
            })
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private notify: NotifyService,
    private impuestoDiferidoService: ImpuestoDiferidoService
  ) {}
}
