<h2 mat-dialog-title>Requerimientos mínimos</h2>

<mat-dialog-content class="mat-typography">
  <!-- <h3>Develop across all platforms</h3> -->
  <p>Para  que  Appi-renta  funcione  en  óptimas  condiciones  se  recomienda  tener
    presentes las siguientes recomendaciones</p>

  <div class="table">
    <div class="table_hardware">
      <div class="table_hardware_title">
        <p>
          Hardware
        </p>
      </div>
      <div class="table_hardware_seccion">
        <p>
          Memoria RAM
        </p>
        <p>
          2Gb minimo - 4Gb o más Recomendado
        </p>
      </div>
      <div class="table_hardware_seccion">
        <p>
          Resolución
        </p>
        <p>
          1280 x 768 o full HD
        </p>
      </div>
      <div class="table_hardware_seccion">
        <p>
        Velocidad del
        Procesador mínimo
        Intel - Pentium
        </p>
        <p>
          1 - 2 GHz
        </p>
      </div>
    </div>

    <div class="table_software">
      <div class="table_software_title">
        <p>
          Exploradores Soportados
        </p>
      </div>

      <div class="table_software_seccion">
        <div class="table_software_seccion_browser">
          <mat-icon svgIcon="edge">
          </mat-icon>
          <p>
            Microsot Edge
          </p>
        </div>
        <p>
          <b>Version: </b> 99.x o superior
        </p>
      </div>

      <div class="table_software_seccion">
        <div class="table_software_seccion_browser">
          <mat-icon svgIcon="firefox">
          </mat-icon>
          <p>
            Mozilla Firefox
          </p>
        </div>
        <p>
          <b>Version: </b> 98.x o superior
        </p>
      </div>

      <div class="table_software_seccion">
        <div class="table_software_seccion_browser">
          <mat-icon svgIcon="chrome">
          </mat-icon>
          <p>
            Google Chrome
          </p>
        </div>
        <p>
          <b>Version: </b> 99.x o superior
        </p>
      </div>

      <div class="table_software_seccion">
        <div class="table_software_seccion_browser">
          <mat-icon svgIcon="safari">
          </mat-icon>
          <p>
            Safari
          </p>
        </div>
        <p>
          <b class="not-support">No soportado </b>
        </p>
      </div>

      <div class="table_software_seccion">
        <div class="table_software_seccion_browser">
          <mat-icon svgIcon="iexplorer">
          </mat-icon>
          <p>
            Internet Explorer
          </p>
        </div>
        <p>
          <b class="not-support">No soportado </b>
        </p>
      </div>

    </div>
  </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <app-button
  [icon]="'check_rounded'"
  [text]="'Entiendo'"
  [idButton]="'signUp'"
  [clase]="'btn_primary'"
  [mat-dialog-close]="true"
  >
  </app-button>
</mat-dialog-actions>
