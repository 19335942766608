<div class="button_state"
    [matMenuTriggerFor]="stateMenu"
    [pTooltip]="stateText(this.annexedStatus$  | async)"
    tooltipPosition="bottom"
    placeholder="Bottom"
    >

    <mat-icon svgIcon="circle" class="state_circle"
      [style]="'color: ' + stateColor((this.annexedStatus$  | async))">
    </mat-icon>
      <span class="button_state_text">
        {{ stateText(this.annexedStatus$  | async) }}
      </span>
      <mat-icon svgIcon="arrow_down" class="button_state_icon">
      </mat-icon>

      <mat-menu #stateMenu="matMenu" yPosition="above" [class]="'custom_menu_state'">
        <button *ngFor="let state of statesGroup" mat-menu-item
          [class]="state.class"  (click)="setStatusAnnexed(state.value)">
          <mat-icon svgIcon="circle"></mat-icon>
          <span>{{state.name}}</span>
        </button>
      </mat-menu>

</div>
