<div class="container" appDnd
  (fileDropped)="onFileDropped($event)"
  (dragoverStatus)="dragoverStatusHandler($event)"
  [pTooltip]="'Archivos admitidos: ' + this.filesCorrects" tooltipPosition="bottom">
	<input type="file" #fileDropRef id="fileDropRef" multiple
  (change)="fileBrowseHandler($event)"
  [disabled]="this.files.length === this.maxFilesNumber" />

	  <h6 class="title_input">
      <mat-icon svgIcon="drag_drop"></mat-icon>
      {{message}}
    </h6>

	<label for="fileDropRef">Click para subir</label>
</div>
<div class="small_text">
  <small *ngIf="this.files.length < this.maxFilesNumber">
    Has subido <b>{{this.files.length}}</b>  de un maximo de <b>{{this.maxFilesNumber}}</b> archivos
  </small>
  <small *ngIf="this.files.length >= this.maxFilesNumber" class="small_alert">
    Llegaste al maximo de <b>{{this.maxFilesNumber}}</b>  archivos
  </small>
</div>
<div class="files-list">
	<div class="single-file" *ngFor="let file of files; let i = index">
		<div class="file-icon" style="width: 50px">
      <mat-icon svgIcon="file_upload"></mat-icon>
		</div>
		<div class="info">
			<h4 class="name">
				{{ file?.file?.name }}
			</h4>
			<p class="size">
				{{ formatBytes(file?.file?.size) }}
			</p>
			<app-progress-dropzone [progress]="file?.progress">
      </app-progress-dropzone>
		</div>

		<div class="delete" (click)="deleteFile(i)">
      <mat-icon svgIcon="trash"></mat-icon>
		</div>
	</div>
</div>
