import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { RegistroERI } from '@app/pages/formato2516/components/eri-renta-liquida/eri';


@Injectable({
  providedIn: 'root'
})

export class ERIService {

  constructor(private http: HttpClient) { }

    eri$ = (id: number)=> this.http.get<RegistroERI[]>(`/renta_liquida?id=${ id }`);

    guardarERI(eri:RegistroERI[]):Observable<RegistroERI[]>{
      return this.http.post<RegistroERI[]>(`/renta_liquida`,eri);
    }

    //new services to use in effects
    getEriData(id: number) {
      return this.http.get<RegistroERI[]>(`/renta_liquida?id=${ id }`)
    }

    updateEri(eri:RegistroERI[],id:number):Observable<RegistroERI[]>{
      return this.http.post<RegistroERI[]>(`/renta_liquida?id=${ id }`,eri);
    }
}
