<app-modal-cabecera-grl
  [title]="'Estado: ' + ' ' + data?.status"
  [iconTitle]="'indicator'"
>
</app-modal-cabecera-grl>
<mat-dialog-content class="modalGrl mt-3">
  <div *ngIf="currentStatus?.length; else noAnnexes" class="results">
    <p-table [value]="currentStatus">
      <ng-template pTemplate="header">
        <tr>
          <th class="text-start">
            <div
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <p style="margin: 0">Anexo</p>
              <div style="width: 200px">
                <p-columnFilter
                  [placeholder]="'Buscar anexo'"
                  type="text"
                  field="name"
                  [showMenu]="false"
                >
                </p-columnFilter>
              </div>
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template let-row pTemplate="body">
        <tr>
          <td>
            <a [routerLink]="[row.link]" target="_blank" class="link-style">
              {{ row.name }}
            </a>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td style="text-align: center; padding: 2%">
            <img
              src="assets/img/no-result.png"
              style="width: 3.5em; height: auto"
            />
            <span class="p-text-bold p-ml-2"
              >No encontramos resultados con esta busqueda
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <ng-template #noAnnexes>
    <div class="no-results">
      <p>No hay anexos para el estado de "{{ data?.status }}"</p>
    </div>
  </ng-template>
</mat-dialog-content>
