import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { selectCurrentRoute } from '@app/reducers';

interface responseLoading {
  msg: string;
  percent: number;
  total?: number;
  data?: {} | [];
  loading: boolean;
  determinate?: string;
}

interface routeSpec {
  project: number;
  route: string;
}

@Injectable({
  providedIn: 'root'
})

export class LoadingMessageService {

  public sizeBalance = 0;
  public routeReady$: Observable<any>;
  /**
   * Constructor
   */

  constructor(
    private store: Store
  ) {}

  public connectToRoute(): string {
  let message: string;
  this.routeReady$ = this.store.pipe(
      select(selectCurrentRoute),
      map((route) => route.url)
    ).pipe(map((ruta) => {
      const url = ruta.split('/');
      const data: routeSpec = {
        project: Number(url[2]) || null,
        route: ruta
      };
      return this.getRoute(data);
    }));

  this.routeReady$.subscribe((datos: string) => {
      message = datos;
    });

  return message;
  }

  getNameUrl(name: string, routeData: string): boolean{
    return routeData.includes(name);
  }

  getRoute(routeData: routeSpec): string {
    let routeMessage: string;

    if (routeData.project) {
      if (this.getNameUrl('balance', routeData.route)) {
        routeMessage = ' Estamos procesando tu Balance';
      }
      else if (this.getNameUrl('depuracion', routeData.route)) {
        routeMessage = ' Estamos procesando depuración';
      }
      else if (this.getNameUrl('infoContribuyente', routeData.route)) {
        routeMessage = ' Estamos procesando tu información';
      }
      else if (this.getNameUrl('ganancia_ocasional', routeData.route)) {
        routeMessage = ' Estamos procesando los anexos';
      }
      else if (this.getNameUrl('ESF', routeData.route)) {
        routeMessage = ' Estamos procesando ESF';
      }
      else if (this.getNameUrl('anio_anterior', routeData.route)) {
        routeMessage = ' Estamos procesando el año anterior';
      }
      else if (this.getNameUrl('gasto_exterior', routeData.route)) {
        routeMessage = ' Estamos procesando gastos en el exterior';
      }

      else {
        routeMessage = 'Estamos Procesando tu información...';
      }



    } else {
      routeMessage = 'Estamos Procesando tu Proyecto...';
    }

    return routeMessage;

  }

  public getEventMessage(event: HttpEvent<any>) {

    let response: responseLoading;
    const messageRoute: string = this.connectToRoute();



    switch (event.type) {
      case HttpEventType.Sent:

        response = {
          msg: messageRoute,
          percent: null,
          data: null,
          loading: true,
          determinate: 'indeterminate'
        };
        return response;

      case HttpEventType.UploadProgress:

        if (event.total !== 0) {
          this.sizeBalance = event.total;
        }

        const percentDone = Math.floor(100 * event.loaded / this.sizeBalance ) || 0;

        response = {
          msg: messageRoute + `...esta ${percentDone}% cargado.`,
          percent: percentDone,
          data: null,
          loading: true,
          determinate: 'determinate'
        };

        return response;

      case HttpEventType.DownloadProgress:

        response = {
          msg: messageRoute,
          percent: null,
          data: null,
          loading: true,
          determinate: 'indeterminate'
        };

        return response;

      case HttpEventType.Response:

        response = {
          msg: messageRoute + `... ha subido correctamente!.`,
          percent: 99,
          data: event.body,
          loading: false,
          determinate: 'indeterminate'
        };

        return response;

      default:

        response = {
          msg: messageRoute + `, Seguimos procesando...`,
          percent: null,
          data: null,
          loading: true,
          determinate: 'indeterminate'
        };

        return response;
    }
  }



}
