import { createAction, props } from '@ngrx/store';
import { AnnexedStatus } from '@store/models/project.model';

export const GET_ALL_ANNEXED_STATUS = '[annexed status] get all annexed status';
export const GET_ALL_ANNEXED_STATUS_SUCCESS = '[annexed status] get all annexed status success';
export const GET_ALL_ANNEXED_STATUS_ERROR = '[annexed status] get all annexed status with error';

export const GET_ANNEXED_STATUS = '[annexed status] get annexed status';
export const GET_ANNEXED_STATUS_SUCCESS = '[annexed status] get annexed status success';
export const GET_ANNEXED_STATUS_ERROR = '[annexed status] get annexed status with error';

export const SET_ANNEXED_STATUS = '[annexed status] set annexed status list';
export const SET_ANNEXED_STATUS_SUCCESS = '[annexed status] set annexed status list success';
export const SET_ANNEXED_STATUS_ERROR = '[annexed status] set annexed status list with error';

export const RESET_ANNEXED_STATUS = '[annexed status] reset annexed status list';
export const RESET_ANNEXED_STATUS_SUCCESS = '[annexed status] reset annexed status list success';
export const RESET_ANNEXED_STATUS_ERROR = '[annexed status] reset annexed status list with error';

//get Alls proyect status
export const getAllAnnexedStatus = createAction(
  GET_ALL_ANNEXED_STATUS,
  props<{ projectId: number }>()
);

export const getAllAnnexedStatusSuccess = createAction(
  GET_ALL_ANNEXED_STATUS_SUCCESS,
  props<{ annexedStatusGroup: AnnexedStatus[] }>()
);

export const getAllAnnexedStatusError = createAction(
  GET_ALL_ANNEXED_STATUS_ERROR,
  props<{ error: string }>()
);

//set project status
export const setAnnexedStatus = createAction(
  SET_ANNEXED_STATUS,
  props<{ annexedStatus: AnnexedStatus }>()
);

export const setAnnexedStatusSuccess = createAction(
  SET_ANNEXED_STATUS_SUCCESS,
  props<{ annexedStatus: AnnexedStatus }>()
);

export const setAnnexedStatusError = createAction(
  SET_ANNEXED_STATUS_ERROR,
  props<{ error: string }>()
);
//reset all status
export const resetAnnexedStatus = createAction(
  RESET_ANNEXED_STATUS,
  props<{ projectId: number, newStatus: string }>()
);

export const resetAnnexedStatusSuccess = createAction(
  RESET_ANNEXED_STATUS_SUCCESS,
  props<{ annexedStatusGroup: AnnexedStatus[] }>()
);

export const resetAnnexedStatusError = createAction(
  RESET_ANNEXED_STATUS_ERROR,
  props<{ error: string }>()
);
