import { Action, createReducer, on } from "@ngrx/store";
import { getDonacionSuccess, setDeleteDonacionSuccess, setDonacionSuccess } from "../actions/donacion.actions";
import { DonacionState, donacionAdapter } from "../states/donacion.state";

export const initialState: DonacionState = donacionAdapter.getInitialState({})

const donacionReducer = createReducer(
  initialState,

  on(getDonacionSuccess, (state, { donacionSuccess }) => {
    state.ids = [];
    state.entities = {};
    return donacionAdapter.upsertOne(donacionSuccess, { ...state })
  }),

  on(setDonacionSuccess, (state, { donacionForm }) => {
    state.ids = [];
    state.entities = {};
    return donacionAdapter.upsertOne(donacionForm, {...state})
  }),

  on(setDeleteDonacionSuccess, (state, { donacionForm }) => {
    state.ids = [];
    state.entities = {};
    return donacionAdapter.upsertOne(donacionForm, state)
  }),
)

export function reducer(state: DonacionState | undefined, action: Action) {
  return donacionReducer(state, action);
}
