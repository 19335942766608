import { createReducer, on, Action } from '@ngrx/store';
import { Seccion2GmfState, seccion2GmfAdapter } from '@store/states';
import { getSeccion2GmfSuccess, setSeccion2GmfSuccess,  setDeleteSeccion2GmfSuccess } from '@store/actions/seccion2-gmf.actions';

export const initialState: Seccion2GmfState = seccion2GmfAdapter.getInitialState({})

const seccion2GmfReducer = createReducer(
  initialState,

  on(getSeccion2GmfSuccess, (state, { seccion2GmfSuccess }) => {
    state.ids = [];
    state.entities = {};
    return seccion2GmfAdapter.upsertOne(seccion2GmfSuccess, { ...state })
  }),

  on(setSeccion2GmfSuccess, (state, { seccion2GmfForm }) => {
    state.ids = [];
    state.entities = {};
    return seccion2GmfAdapter.upsertOne(seccion2GmfForm, state)
  }),

  on(setDeleteSeccion2GmfSuccess, (state, { seccion2Gmf }) => {
    return seccion2GmfAdapter.removeOne(seccion2Gmf.id, state)
  }),
)

export function reducer(state: Seccion2GmfState | undefined, action: Action) {
  return seccion2GmfReducer(state, action);
}
