import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnnexedStatusResponse, AnnexesListStatus, StatusListModel } from '@app/store/models/project-status.model';
import { AnnexedStatus, Project } from '@store/models';

import { Observable, of } from 'rxjs';
import { ROUTES_ANEX } from '../components/select-status-annexed/annexed';


//Ejemplo de post para cambio de estado
// {
//   "projectId": 322,
//   "tab": "CONTRIBUYENTE",
//   "annexed": "INFORMACION",
//   "status": "TERMINADO"
// }

interface status {
  CONCILIACION: string
  DEPURACION: string
  INFORMACION: string
  PATRIMONIO: string
}

@Injectable({
  providedIn: 'root'
})

export class StatusService {

  constructor(private http: HttpClient) { }

    getStatusProject(id: number) {
      return this.http.get<number>(`/project_status?projectId=${ id }`)
    }

    updateStatusProject(status:AnnexedStatus):Observable<any>{
      return this.http.post<any>(`/project_status`,status);
    }

    updateAllStatusProjects(projectId: number, status: String):Observable<any>{
      return this.http.put<AnnexedStatus[]>(`/project_status?projectId=${projectId}&status=${status}`, {});
    }

    getStatusAnnexed(id: number) {
      return this.http.get<status | any>(`/project_status/annexes?projectId=${ id }`)
    }

    getAllStatusTabAnnexed(projectId: number){
      return this.http.get<AnnexedStatus[]>(`/project_status/getAnnexes?projectId=${projectId}`)
    }

    getStatusSubannexed(projectId: number, subannex: string){
      return this.http.get<any>(`/project_status/subannexe?projectId=${projectId}&subAnnexe=${subannex}`)
    }

    getStatusProyectList(projecList: Project[]): Observable<Project[]>{

      let list:Project[] = [];
      projecList.map((project: Project) => {
        this.getStatusProject(project.id)
        .subscribe((status: number) => {
          list.push(
            {
              ...project,
              percentStatus: status
            }
          );
        })
      })

      return of(list)
    }

    getStatusListProject(idProject: number): Observable<object> {
      return this.http.get(`/ws/anexo/total/estado/${idProject}?projectId=${idProject}`);
    }

    formatAnnexesListData(data: Array<any>): StatusListModel[] {
      return data.map(val => {
        
        let color: string;
  
        if (val.status === "Terminado") {
          color = "#19BF71";
        } else if (val.status === "Por Tramitar") {
          color = "#a6a6a6";
        } else if (val.status === "No Aplica") {
          color = "#000"
        } else {
          color = "#F2CF3B"
        }
  
        return {
          annexAmount: val.annexAmount,
          status: val.status,
          color: color
        } 
      })
    }

    getAnnexesByStatus(status: string, idProject: number): Observable<AnnexedStatusResponse[]> {
      return this.http.get<AnnexedStatusResponse[]>(`/ws/anexo/estado/${status}/project/${idProject}`);
    }

    formatAnnexesList(data: AnnexedStatusResponse[], idProject: number): AnnexesListStatus[] {
      let dataFormated = data.map(val => {
        let  findRoute = ROUTES_ANEX.find(element => element.tab === val.tab);
        return {
          name: val.tab,
          link: `/proyecto/${idProject}/${findRoute.route}`
        }
      });
      return dataFormated;
    }
}
