<div>
  <ejs-uploader
    #defaultupload
    [cssClass]="'carga'"
    id="defaultfileupload"
    allowedExtensions=".xls,.csv,.xlsx"
    [buttons]="buttons"
    [autoUpload]="false"
    (selected)="onFileRemove($event)"
    (failure)="onUploadFailure($event)"
    (change)="onSuccess($event)"
  ></ejs-uploader>
</div>
