export const environment = {
  apiBaseUrl: 'https://hp3uilqrv5mx57cbuwwj6v6lfe.apigateway.sa-bogota-1.oci.customer-oci.com/v1',
  appName: 'RentaAppi',
  apiUrl: 'https://hp3uilqrv5mx57cbuwwj6v6lfe.apigateway.sa-bogota-1.oci.customer-oci.com/v1',
  appUrl: 'https://hp3uilqrv5mx57cbuwwj6v6lfe.apigateway.sa-bogota-1.oci.customer-oci.com/v1',
  mailSupport: 'soporte_rentappi@grupompr.com',
  syncfusionUrl: 'https://services.syncfusion.com/angular/production/api/spreadsheet/save',
  nodeEnv: 'undefined',
  firebaseConf: {
    apiKey: "AIzaSyCuOQftwSOJcK2PmSXSmOi5JRtuiLwTp6I",
    authDomain: "rentaapi.firebaseapp.com",
    projectId: "rentaapi",
    storageBucket: "rentaapi.appspot.com",
    messagingSenderId: "659876149006",
    appId: "1:659876149006:web:6c5f498a3d855597c9895f",
  },
  flags: {
    useNewHeader: true,
  },
  log: true,
  production: false,
  version: '1.0',
};