
<div class="notify">
    <div class="icon">
        <mat-icon [svgIcon]="data.icon"></mat-icon>
    </div>

    <div class="content">
        <div class="content_title">
            {{data.title}}
        </div>
        <div class="content_msg">
            {{data.message}}
        </div>
    </div>

    <div class="buttons">
        <div class="buttons_close">
            <mat-icon svgIcon="close_rounded" 
                (click)="this.snackBarRef.dismiss()">
            </mat-icon>
        </div>
        <div *ngIf="data.button" class="buttons_action">
            <app-button-alerts
                [idButton]="data.button.type"
                [type]="data.button.type"
                [icon]="data.button.icon"
                [text]="data.button.text"
                [iconDir]="data.button.orientation"
                [background]="data.button.background"
                [color]="data.button.color"
                [idButton]="data.button.type"
                (clicker)="onClick($event)">
            </app-button-alerts>
        </div>
        
    </div>
</div>