import { createAction, props } from "@ngrx/store";
import { ImpuestoExterior, ImpuestoExteriorDTO, ImpuestoExteriorDTOSave, TotalImpuestoExterior } from "../models/descuento-tributario.model";


export const GET_IMPUESTO_EXTERIOR = '[impuestoExterior] get impuestoExterior';
export const GET_IMPUESTO_EXTERIOR_SUCCESS = '[impuestoExterior] get impuestoExterior success';
export const GET_IMPUESTO_EXTERIOR_ERROR = '[impuestoExterior] get impuestoExterior with error';

export const SET_IMPUESTO_EXTERIOR = '[impuestoExterior] set impuestoExterior save info';
export const SET_IMPUESTO_EXTERIOR_SUCCESS = '[impuestoExterior] set impuestoExterior save info success';
export const SET_IMPUESTO_EXTERIOR_ERROR = '[impuestoExterior] set impuestoExterior save info with error';

export const SET_DELETE_IMPUESTO_EXTERIOR = '[impuestoExterior] set delete impuestoExterior';
export const SET_DELETE_IMPUESTO_EXTERIOR_SUCCESS = '[impuestoExterior] set delete impuestoExterior success';
export const SET_DELETE_IMPUESTO_EXTERIOR_ERROR = '[impuestoExterior] set delete impuestoExterior with error';

//get impuestoExterior
export const getImpuestoExterior = createAction(
  GET_IMPUESTO_EXTERIOR,
  props<{projectId: number}>()
);

export const getImpuestoExteriorSuccess = createAction(
  GET_IMPUESTO_EXTERIOR_SUCCESS,
  props<{ impuestoExteriorSuccess: ImpuestoExteriorDTO  }>()
);

export const getImpuestoExteriorError = createAction(
  GET_IMPUESTO_EXTERIOR_ERROR,
  props<{ error: string }>()
);

//create impuestoExterior
export const setImpuestoExterior = createAction(
  SET_IMPUESTO_EXTERIOR,
  props<{ impuestoExteriorForm:ImpuestoExteriorDTOSave, projectId: number }>()
);

export const setImpuestoExteriorSuccess = createAction(
  SET_IMPUESTO_EXTERIOR_SUCCESS,
  props<{ impuestoExteriorForm:ImpuestoExteriorDTO }>()
);

export const setImpuestoExteriorError = createAction(
  SET_IMPUESTO_EXTERIOR_ERROR,
  props<{ error: string }>()
);

//delete impuestoExterior
export const setDeleteImpuestoExterior = createAction(
  SET_DELETE_IMPUESTO_EXTERIOR,
  props<{ projectId: number, idImpuestoExterior: number,totales:TotalImpuestoExterior }>()
);

export const setDeleteImpuestoExteriorSuccess = createAction(
  SET_DELETE_IMPUESTO_EXTERIOR_SUCCESS,
  props<{ impuestoExteriorForm:ImpuestoExteriorDTO }>()
);

export const setDeleteImpuestoExteriorError = createAction(
  SET_DELETE_IMPUESTO_EXTERIOR_ERROR,
  props<{ error: string }>()
);
