import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  MSG_DURATION,
  NotifyService,
} from '@app/shared/services/notify.service';
import { IndustriaComercioService } from '@app/shared/services/industria-comercio.service';
import { getAjustePasivosIca, getAjustePasivosIcaError, getAjustePasivosIcaSuccess, setAjustePasivosIca, setAjustePasivosIcaError, setAjustePasivosIcaSuccess, setDeleteAjustePasivosIca, setDeleteAjustePasivosIcaError, setDeleteAjustePasivosIcaSuccess } from '../actions';
import { PasivosIcaStore } from '../models';


@Injectable()
export class AjustePasivosIcaEffects {
  getAjustePasivosIca$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAjustePasivosIca),
      switchMap(({ projectId }) => {
        return this.industriaComercioService.getAjustePasivosIca(projectId).pipe(
          map((ajustePasivosIca) => {
            let ajustePasivosIcaSuccess: PasivosIcaStore = {
              idProject: projectId,
              ...ajustePasivosIca,
            };
            return getAjustePasivosIcaSuccess({ ajustePasivosIcaSuccess });
          }),
          catchError((error) => {
            this.notify.error('Algo salio mal!', JSON.stringify(error), 5000, {
              text: 'Entendido',
              orientation: 'izq',
            });
            return of(getAjustePasivosIcaError({ error }));
          })
        );
      })
    )
  );

  saveAjustePasivosIca$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setAjustePasivosIca),
      switchMap(({ ajustePasivosIcaForm, projectId }) => {
        return this.industriaComercioService
          .saveAjustePasivosIca(projectId, ajustePasivosIcaForm)
          .pipe(
            map((ajustePasivosIca) => {
               let ajustePasivosIcaForm: PasivosIcaStore = {
              idProject: projectId,
              ...ajustePasivosIca,
            };
              this.notify.success(
                '',
                'Guardado de registros exitoso',
                MSG_DURATION
              );
              return setAjustePasivosIcaSuccess({ ajustePasivosIcaForm });
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setAjustePasivosIcaError({ error }));
            })
          );
      })
    )
  );

  deleteAjustePasivosIca$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDeleteAjustePasivosIca),
      switchMap(({ projectId, objAjustePasivosIca }) => {
        return this.industriaComercioService
          .deleteAjustePasivosIca(projectId, objAjustePasivosIca)
          .pipe(
            map((ajustePasivosIca) => {
              this.notify.success(
                '',
                'Borrado de registros exitoso',
                MSG_DURATION
              );
              return setDeleteAjustePasivosIcaSuccess({ ajustePasivosIca });
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setDeleteAjustePasivosIcaError({ error }));
            })
          );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private industriaComercioService: IndustriaComercioService,
    private notify: NotifyService
  ) {}
}
