import { Injectable } from "@angular/core";
import { environment } from '@environment';

const APP_PREFIX = 'rentaApp-';

@Injectable()
export class LocalStorageService {

  public static loadInitialState() {
    return Object.keys(localStorage).reduce((state: any, storageKey) => {
      if (storageKey.includes(APP_PREFIX)) {
        const stateKeys = storageKey
          .replace(APP_PREFIX, '')
          .toLowerCase()
          .split('.')
          .map(key =>
            key
              .split('-')
              .map(
                (token, index) =>
                  index === 0
                    ? token
                    : token.charAt(0).toUpperCase() + token.slice(1)
              )
              .join('')
          );
        let currentStateRef = state;
        stateKeys.forEach((key, index) => {
          if (index === stateKeys.length - 1) {
            currentStateRef[key] = JSON.parse(localStorage.getItem(storageKey));
            return;
          }
          currentStateRef[key] = currentStateRef[key] || {};
          currentStateRef = currentStateRef[key];
        });
      }
      return state;
    }, {});
  }

  public static setItem(key: string, value: any) {
    localStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value));
  }

  public static getItem(key: string) {
    return JSON.parse(localStorage.getItem(`${APP_PREFIX}${key}`));
  }

  public static removeItem(key: string) {
    localStorage.removeItem(`${APP_PREFIX}${key}`);
  }

  /**
   * Set item on localStorage.
   */
  public set(key: string, item: any) {
    return localStorage.setItem(key, JSON.stringify(item));
  }

  /**
   * Get item from localStorage.
   */
  public get(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }

  /**
   * Remove item from localStorage.
   */
  public remove(key: string): void {
    localStorage.removeItem(key);
  }

  /**
   * Set user data on localStorage.
   */
  // public setUser(user: any) {
  //   if (user) {
  //     localStorage.setItem('user', JSON.stringify(user));
  //   }
  // }

  // /**
  //  * Get the user from local storage.
  //  */
  // public getUser(): any {
  //   return Object.assign({}, JSON.parse(localStorage.getItem('user')));
  // }

  /**
   * Remove the user data from localStorage.
   */
  // public removeUser(): boolean {
  //   localStorage.removeItem('token');
  //   localStorage.removeItem('user');

  //   return true;
  // }

  public setTokens(tokenData: any) {
    this.set('token', JSON.stringify(tokenData));
    //localStorage.setItem('api_token', JSON.stringify(tokenData.api_token));
  }

  /**
   * Get the auth token.
   */
  public getToken(): string {
    return localStorage.getItem('token');
  }

  // public getClient(): string {
  //   return JSON.parse(localStorage.getItem(environment.keyClient));
  // }

}
