import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CurrencyMaskConfig, CurrencyMaskModule,CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';

import { PrimeNgModule } from '@third-party/prime-ng.module';
import { MaterialNgModule } from '@third-party/material-module';
import { SyncfusionModule } from '@third-party/syncfusion.module';

import { BaseLayoutComponent } from './base-layout/base-layout.component';

import { MsjAjustesFiscComponent } from './components/msj-ajustes-fisc/msj-ajustes-fisc.component';
import { SharedRoutingModule } from './shared.router';
import { COMPONENTS } from './components';
import { GUARDS } from './guard';
import { PIPES } from './pipes';
import { DIRECTIVES } from './directives';
import { ModalGastoExteriorErrorsComponent } from './components/modal-gasto-exterior-errors/modal-gasto-exterior-errors.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { AnnexesDropdownComponent } from './components/annexes-dropdown/annexes-dropdown.component';
import { StatusModalComponent } from './components/status-modal/status-modal.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ConfirmationExcelModalComponent } from './components/confirmation-excel-modal/confirmation-excel-modal.component';
import { ConfirmationDesicionExcelModalComponent } from './components/confirmation-desicion-excel-modal/confirmation-desicion-excel-modal.component'

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  decimal: ",",
  precision: 0,
  prefix: "$",
  suffix: "",
  thousands: ","
};
const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [
    BaseLayoutComponent,
    ...COMPONENTS,
    ...PIPES,
    ...DIRECTIVES,
    MsjAjustesFiscComponent,
    ModalGastoExteriorErrorsComponent,
    NotificationsComponent,
    AnnexesDropdownComponent,
    StatusModalComponent,
    ConfirmationExcelModalComponent,
    ConfirmationDesicionExcelModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedRoutingModule,
    FormsModule,
    MaterialNgModule,
    PrimeNgModule,
    SyncfusionModule,
    ReactiveFormsModule,
    CurrencyMaskModule,
    NgxMaskModule.forRoot(maskConfig),

    
  ],
  exports: [
    ... COMPONENTS,
    ...PIPES,
    ...DIRECTIVES,
    CurrencyMaskModule
  ],
  providers: [
    ...GUARDS,
    ...PIPES,
    ...DIRECTIVES,
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }

  ]
})
export class SharedModule { }
