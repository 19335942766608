import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExcesosPresuntivaSaveOne, ExcesosPresuntivaStore, ExcesosPresuntivaTabla, InsolvenciaProject, InsolvenciaProjectStore, PerdidasCompensarSaveOne, PerdidasCompensarStore, PerdidasCompensarTabla, RegimenTransicionSave, TotalesExcesosPresuntiva, TotalesPerdidasCompensar } from '@app/store/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompensacionPerdidasService {

  constructor(private http: HttpClient) {}

  getAnioDeclaracionRenta(projectId: number): Observable<any> {
    return this.http.get(
      `/compensacion_perdidas_fiscales/getAnio?id=${projectId}`
    );
  }

  //sección1
  getPerdidasCompensar(
    projectId: number
  ): Observable<PerdidasCompensarStore> {
    return this.http.get<PerdidasCompensarStore>(
      `/compensacion_perdidas_fiscales/getPriSec?id=${projectId}`
    );
  }

  deletePerdidasCompensar(
    projectId: number,
    idRow: number,
    perdidasCompensar: TotalesPerdidasCompensar
  ): Observable<any> {
    return this.http.request<any>(
      'delete',
      `/compensacion_perdidas_fiscales/deletePriSec?id=${projectId}&idRow=${idRow}`,
      { body: perdidasCompensar }
    );
  }

  savePerdidasCompensar(
    projectId: number,
    objPerdidasCompensar: PerdidasCompensarSaveOne
  ): Observable<PerdidasCompensarStore> {
    return this.http.post<PerdidasCompensarStore>(
      `/compensacion_perdidas_fiscales/savePriSec?id=${projectId}`,
      objPerdidasCompensar
    );
  }

  //sección2
  getExcesosPresuntiva(
    projectId: number
  ): Observable<ExcesosPresuntivaStore> {
    return this.http.get<ExcesosPresuntivaStore>(
      `/compensacion_perdidas_fiscales/getSegSec?id=${projectId}`
    );
  }

  deleteExcesosPresuntiva(
    projectId: number,
    idRow: number,
    excesosPresuntiva: TotalesExcesosPresuntiva
  ): Observable<any> {
    return this.http.request<any>(
      'delete',
      `/compensacion_perdidas_fiscales/deleteSegSec?id=${projectId}&idRow=${idRow}`,
      { body: excesosPresuntiva }
    );
  }

  saveExcesosPresuntiva(
    projectId: number,
    objExcesosPresuntiva: ExcesosPresuntivaSaveOne
  ): Observable<ExcesosPresuntivaStore> {
    return this.http.post<ExcesosPresuntivaStore>(
      `/compensacion_perdidas_fiscales/saveSegSec?id=${projectId}`,
      objExcesosPresuntiva
    );
  }

  saveRegimenTransicion(
    projectId: number,
    objRegimenTransicion: RegimenTransicionSave
  ): Observable<ExcesosPresuntivaStore> {
    return this.http.post<ExcesosPresuntivaStore>(
      `/compensacion_perdidas_fiscales/saveProcesoMatematicoSegSec?id=${projectId}`,
      objRegimenTransicion
    );
  }

  calcularTotalesPerdidasCompensar(perdidasCompensarList: PerdidasCompensarTabla[]):TotalesPerdidasCompensar{
    const totales: TotalesPerdidasCompensar = {
      totalPerdida : 0,
      totalesCompensados : 0,
      totalesCaducados : 0,
      totalesACompensar : 0,
      impuestoRentaHasta2016 : 0,
      impuestoRentaDesde2017 : 0,
      total : 0,
      impuestoEquidadCREE : 0
    }

    perdidasCompensarList.forEach((data) => {
      totales.totalPerdida += +data.valTotalPerdida;
      totales.totalesCompensados += +data.valYaCompensado;
      totales.totalesCaducados += +data.valCaducados;
      totales.totalesACompensar += +data.valACompensar;
      if (data.anio <= 2016 && data.impuesto === 'IMPUESTO DE RENTA') {
        totales.impuestoRentaHasta2016 += +data.valACompensar;
      }
      if (data.anio >= 2017 && data.impuesto === 'IMPUESTO DE RENTA') {
        totales.impuestoRentaDesde2017 += +data.valACompensar;
      }
      if (data.impuesto === 'IMPUESTO PARA LA EQUIDAD CREE') {
        totales.impuestoEquidadCREE += +data.valACompensar;
      }
    });
    totales.total = totales.impuestoRentaHasta2016 + totales.impuestoRentaDesde2017;

    return totales;
  }

  calcularTotalesExcesosPresuntiva(excesosPresuntivaList: ExcesosPresuntivaTabla[]):TotalesExcesosPresuntiva{
    const totales: TotalesExcesosPresuntiva = {
      totalesExcesoPresuntiva : 0,
      totalesCompensados : 0,
      totalesCaducados : 0,
      totalesACompensar : 0,
      impuestoHasta2016 : 0,
      impuestoDesde2017 : 0,
      totalPorCompensar : 0,
      impuestoEquidadCREE : 0
    }

    excesosPresuntivaList.forEach((data) => {
      totales.totalesExcesoPresuntiva += +data.totalRentaPresuntiva;
      totales.totalesCompensados += +data.valCompensado;
      totales.totalesCaducados += +data.valNoCompensado;
      totales.totalesACompensar += +data.valACompensar;
      if (data.anio <= 2016 && data.impuesto === 'IMPUESTO DE RENTA') {
        totales.impuestoHasta2016 += +data.valACompensar;
      }
      if (data.anio >= 2017 && data.impuesto === 'IMPUESTO DE RENTA') {
        totales.impuestoDesde2017 += +data.valACompensar;
      }
      if (data.impuesto === 'IMPUESTO PARA LA EQUIDAD CREE') {
        totales.impuestoEquidadCREE += +data.valACompensar;
      }
    });
    totales.totalPorCompensar = totales.impuestoHasta2016 + totales.impuestoDesde2017;

    return totales;
  }

  //seccion 3
  getInsolvencia(
    projectId: number
  ): Observable<InsolvenciaProject> {
    return this.http.get<InsolvenciaProject>(
      `/compensacion_perdidas_fiscales/getTerSec?id=${projectId}`
    );
  }

  saveInsolvencia(
    projectId: number,
    insolvenciaProject: InsolvenciaProject
  ): Observable<InsolvenciaProject> {
    return this.http.post<InsolvenciaProject>(
      `/compensacion_perdidas_fiscales/saveTerSec?id=${projectId}`,
      insolvenciaProject
    );
  }

}
