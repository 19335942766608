import { EconomicActivityType } from '@app/pages/info-contribuyente/components/info-contribuyente/economic-activity';
import { createAction, props } from '@ngrx/store';

export const GET_EXTRAS_LIST = '[extras] get extras list';
export const GET_EXTRAS_LIST_SUCCESS = '[extras] get extras list success';
export const GET_EXTRAS_LIST_ERROR = '[extras] get extras list with error';

//get list
export const getExtrasList = createAction(
  GET_EXTRAS_LIST,
);

export const getExtrasListSuccess = createAction(
  GET_EXTRAS_LIST_SUCCESS,
  props<{ extrasList: EconomicActivityType[] }>()
);

export const getExtrasListError = createAction(
  GET_EXTRAS_LIST_ERROR,
  props<{ error: string }>()
);
