import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { map, switchMap, catchError } from "rxjs/operators";
import { of } from "rxjs";

import { NotifyService } from "@app/shared/services/notify.service";

import {
  getEri,
  getEriError,
  getEriSuccess,
  updateManyEri,
  updateManyEriError,
  updateManyEriSuccess,
  updateOneEri,
  updateOneEriError,
  updateOneEriSuccess,

} from "../actions";

import { ERIService } from "@app/shared/services/eri.service";
import { ERIstore, RegistroERI } from "@app/pages/formato2516/components/eri-renta-liquida/eri";

@Injectable()
export class EriEffects {

  public getEri$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEri),
      switchMap(({ projectId }) =>
        this.eriService.getEriData(projectId).pipe(
          map((eriResponse: RegistroERI[] ) => {
            if(eriResponse){
              let eriSuccess: ERIstore = {
                id: projectId,
                registros: eriResponse
              }
              return getEriSuccess({ eriSuccess: eriSuccess });
            }else {
              this.notify.error('[STORE]: Algo salio mal',
              'No pudimos obtener ERI!', 4000,)
              return getEriError({ error: 'No pudimos tarer ERI' });
            }
          }),
          catchError((error) => {
            this.notify.error('[STORE]: No pudimos obtener ERI!',
            JSON.stringify(error), 4000,
          {
            text: 'Entendido',
            orientation: 'izq'
          });
            return of(getEriError({ error }));
          })
        )
      )
    )
  );

  public setOneEri$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOneEri),
      switchMap(({ eriOneForm,id }) => {
        const dataToUpdate = eriOneForm.filter(er => er.modificado == true);

        return this.eriService.updateEri(dataToUpdate,id).pipe(
          map((eriOne: RegistroERI[]) => {
            const eriOneSuccess: ERIstore = {
              id: id,
              registros: eriOneForm
            }
            this.notify.success(
              'Guardado Exitoso!',
              'Datos guardados correctamente',
              4000);

            return updateOneEriSuccess({ eriOneSuccess: eriOneSuccess })
          }),
          catchError((error) => {
            this.notify.error(
              '[STORE]: No pudimos Guardar ERI',
              'Sufrimos un error inesperado',
              4000,
          {
            text: 'Entendido',
            orientation: 'izq'
          });
            return of(updateOneEriError({ error }));
          })
        )
      }
      )
    )
  );

  public setManyEri$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateManyEri),
      switchMap(({ eriManyForm,id }) => {
        const dataToUpdate = eriManyForm.filter(er => er.modificado == true);
        return this.eriService.updateEri(dataToUpdate,id).pipe(
          map((eriManyResponse: RegistroERI[]) => {
            this.notify.success(
              'Guardado Exitoso!',
              'Datos guardados correctamente',
              4000)
              const eriManySuccess: ERIstore = {
                id: id,
                registros: eriManyForm
              }
            return updateManyEriSuccess({
              eriManySuccess:  eriManySuccess
            })
          }),
          catchError((error) => {
            this.notify.error(
              '[STORE]: No pudimos Guardar ERI',
              'Sufrimos un error inesperado',
              4000,
          {
            text: 'Entendido',
            orientation: 'izq'
          });
            return of(updateManyEriError({ error }));
          })
        )
      }
      )
    )
  );

  public constructor(
    private actions$: Actions,
    private eriService: ERIService,
    private notify: NotifyService,
  ) {}
}
