import { ProvisionImpuestoGananciaStore } from '../models/provision-impuesto-ganancia.model';
import { createAction, props } from '@ngrx/store';


export const GET_PROVISION_IMPUESTO_GANANCIA =
  '[Provision Impuesto Ganancia] get provision';
export const GET_PROVISION_IMPUESTO_GANANCIA_SUCCESS =
  '[Provision Impuesto Ganancia] get provision success';
export const GET_PROVISION_IMPUESTO_GANANCIA_FAIL =
  '[Provision Impuesto Ganancia] get provision fail';

export const SET_PROVISION_IMPUESTO_GANANCIA =
  '[Provision Impuesto Ganancia] set provision';
export const SET_PROVISION_IMPUESTO_GANANCIA_SUCCESS =
  '[Provision Impuesto Ganancia] set provision success';
export const SET_PROVISION_IMPUESTO_GANANCIA_FAIL =
  '[Provision Impuesto Ganancia] set provision fail';

export const getProvisionImpuestoGanancia = createAction(
  GET_PROVISION_IMPUESTO_GANANCIA,
  props<{ id: number }>()
);

export const getProvisionImpuestoGananciaSuccess = createAction(
  GET_PROVISION_IMPUESTO_GANANCIA_SUCCESS,
  props<{ provision: ProvisionImpuestoGananciaStore }>()
);

export const getProvisionImpuestoGananciaFail = createAction(
  GET_PROVISION_IMPUESTO_GANANCIA_FAIL,
  props<{ error: any }>()
);

export const setProvisionImpuestoGanancia = createAction(
  SET_PROVISION_IMPUESTO_GANANCIA,
  props<{ payload: any, id: number }>()
);

export const setProvisionImpuestoGananciaSuccess = createAction(
  SET_PROVISION_IMPUESTO_GANANCIA_SUCCESS,
  props<{ provisionSaved: ProvisionImpuestoGananciaStore }>()
);

export const setProvisionImpuestoGananciaFail = createAction(
  SET_PROVISION_IMPUESTO_GANANCIA_FAIL,
  props<{ error: any }>()
);
