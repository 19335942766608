import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StatusService } from '@app/shared/services/status-project.service';
import { AnnexesListStatus } from '@app/store/models/project-status.model';

@Component({
  selector: 'app-status-modal',
  templateUrl: './status-modal.component.html',
  styleUrls: ['./status-modal.component.scss']
})
export class StatusModalComponent implements OnInit {

  public currentStatus: AnnexesListStatus[];
  private statusFormated: string;


  constructor(
    public dialogRef: MatDialogRef<StatusModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private statusService: StatusService
  ) { }

  ngOnInit(): void {
    this.getCurrentStatus(this.data.status);
  }

  getCurrentStatus(status: string) {
    if (status === 'Por Tramitar') {
      this.statusFormated = "TRAMITAR";
    } else if (status === "Terminado") {
      this.statusFormated = "TERMINADO";
    } else if (status === "No Aplica") {
      this.statusFormated = "NO_APLICA"
    } else {
      this.statusFormated = "PROCESO";
    }
    this.statusService.getAnnexesByStatus(this.statusFormated, this.data.idProject).subscribe(response => {
      this.currentStatus = this.statusService.formatAnnexesList(response, this.data.idProject);
    })
  }
  
}

