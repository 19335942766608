<app-modal-cabecera-grl
[title]="'Remplazar o añadir datos'"
[iconTitle]="'paper_status'">
</app-modal-cabecera-grl>

<mat-dialog-content class="modalGrl"> 
    <p class="text-style">¿Desea añadir la información del Excel a los datos actuales o desea remplazarlos?</p>
</mat-dialog-content>

<mat-dialog-actions align="end" class="modalGrl_buttons">
    <app-button
        [disabling]="false"
        [icon]="'edit_pen'"
        [text]="'Remplazar'"
        [idButton]="'replace'"
        [clase]="'btn_danger'"
        (clicker)="actionDialog('replace')"
    >
    </app-button>
    <app-button
        [disabling]="false"
        [icon]="'plus_bold'"
        [text]="'Añadir'"
        [idButton]="'add'"
        [clase]="'btn_success'"
        (clicker)="actionDialog('add')"
    >
    </app-button>
</mat-dialog-actions>
