import { createReducer, on, Action } from '@ngrx/store';
import { BalanceState, balanceAdapter } from '@store/states';
import {
  getBalanceSuccess,
  setBalanceSuccess,
  setDeleteBalanceSuccess
 } from '@store/actions/balance.actions';
import { getProjectStatus } from '../actions/project-status.actions';

export const initialState: BalanceState = balanceAdapter.getInitialState({})

const balanceReducer = createReducer(
  initialState,

  on(getBalanceSuccess, (state, { balanceSuccess }) => {
    state.entities = {};
    state.ids = [];
    return balanceAdapter.upsertOne(balanceSuccess, { ...state })
  }),

  on(setBalanceSuccess, (state, { balance }) => {
    state.entities = {};
    state.ids = [];
    return balanceAdapter.upsertOne(balance, state)
  }),

  on(setDeleteBalanceSuccess, (state, { balanceDeleted }) => {
    return balanceAdapter.removeOne(balanceDeleted.projectId, state)
  }),
)

export function reducer(state: BalanceState | undefined, action: Action) {
  return balanceReducer(state, action);
}
