import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fab-btn',
  templateUrl: './fab.component.html',
  styleUrls: ['./fab.component.scss']
})
export class FabComponent implements OnInit {

  @Input() toolTip = '';
  @Input() loading = false;
  @Input() iconClass = 'fas fa-sync';
  @Input() callBackFunction : (args: any) => void;

  @Output() clicked : EventEmitter<string>  = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  emitBtn(){
    this.clicked.emit('emited')
  }

}
