import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validator, Validators } from '@angular/forms';
import { isArrayRequired } from '@shared/validators/array';
import { NumberPositiveValidator } from '@shared/validators/number-positive';
import { UrlValidator } from '@shared/validators/url';
import { HexaValidator } from '../validators/hexa';
import { JsonValidator } from '../validators/json';
import { LocationValidator } from '../validators/location';

@Injectable()
export class FormGenerate {

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  public genGroupToggle(control: string, form: FormGroup, modules: any) {
    const newModules: any = {};
    modules.forEach((module: number) => {
      newModules[module] = new FormControl(false);
    });

    form.addControl(
      control,
      this.formBuilder.group(newModules)
    );
  }

  public genInputString(
    control: string,
    form: FormGroup,
    isRequired: boolean = false,
    defaultValue: string | null = null,
    validators = []
  ) {
    form.addControl(
      control,
      new FormControl(
        defaultValue ? defaultValue : '', Validators.compose([isRequired ? Validators.required : Validators.nullValidator, ...validators])
      )
    );
  }

  public genInputUrl(
    control: string,
    form: FormGroup,
    isRequired: boolean = false,
    defaultValue: string | null = null,
    validators: any[] = []
  ) {
    validators = [...validators, UrlValidator.isValid]
    form.addControl(
      control,
      new FormControl(
        defaultValue ? defaultValue : '', Validators.compose([isRequired ? Validators.required : Validators.nullValidator, ...validators])
      )
    );
  }

  public genInputArray(
    control: string,
    form: FormGroup,
    isRequired: boolean = false,
    defaultValue: any = [],
    validators = []
  ) {
    form.addControl(
      control,
      new FormControl(
        defaultValue,
        Validators.compose([isRequired ? isArrayRequired() : Validators.nullValidator, ...validators]),
      )
    );
  }

  public genInputFiles(
    control: string,
    form: FormGroup,
    isRequired: boolean = false,
    defaultValue: any = [],
    validators = []
  ) {
    form.addControl(
      control,
      new FormControl(
        defaultValue,
        Validators.compose([
          isRequired ? isArrayRequired() : Validators.nullValidator,
          ...validators
        ])
      )
    );
  }

  public genInputJSON(
    control: string,
    form: FormGroup,
    isRequired: boolean = false,
    defaultValue: any = {},
    validators: Validator[] | any[] = []
  ) {
    form.addControl(
      control,
      new FormControl(
        defaultValue,
        Validators.compose([
          isRequired ? JsonValidator.isJsonRequired : Validators.nullValidator,
          ...validators
        ]),
      )
    );
  }

  public genInputEmail(
    control: string,
    form: FormGroup,
    isRequired: boolean = false,
    defaultValue: string | null = null,
    validators = [],
  ) {
    form.addControl(
      control,
      new FormControl(
        defaultValue ? defaultValue : '',
        Validators.compose([
          Validators.email,
          isRequired ? Validators.required : Validators.nullValidator,
          ...validators,
        ])
      )
    );
  }

  public genInputLatitude(
    control: string,
    form: FormGroup,
    isRequired: boolean = false,
    defaultValue: string | null = null,
    validators: Validator[] | any[] = []
  ) {
    form.addControl(
      control,
      new FormControl(
        defaultValue,
        Validators.compose([
          LocationValidator.latitude,
          isRequired ? Validators.required : Validators.nullValidator,
          ...validators
        ])
      )
    );
  }

  public genInputLongitude(
    control: string,
    form: FormGroup,
    isRequired: boolean = false,
    defaultValue: string | null = null,
    validators: Validator[] | any[] = []
  ) {
    form.addControl(
      control,
      new FormControl(
        defaultValue ? defaultValue : '',
        Validators.compose([
          LocationValidator.longitude,
          isRequired ? Validators.required : Validators.nullValidator,
          ...validators
        ])
      )
    );
  }

  public genInputHexa(
    control: string,
    form: FormGroup,
    isRequired: boolean = false,
    validators: Validator[] | any[] = []) {
    form.addControl(
      control,
      new FormControl(
        '',
        Validators.compose([
          HexaValidator.isHexa,
          isRequired ? Validators.required : null,
          ...validators
        ])
      )
    );
  }

  public genToggle(control: string,
    form: FormGroup,
    isRequired: boolean = false,
    defaultValue = false,
    validators = []
    ){
    form.addControl(
      control,
      new FormControl(
        defaultValue,
        Validators.compose([
          isRequired ? Validators.requiredTrue : Validators.nullValidator,
          ...validators
        ])
      )
    );
  }

  public genInputNumber(
    control: string,
    form: FormGroup,
    isRequired: boolean = false,
    defaultValue: number | null = null,
    validators = []
  ) {
    form.addControl(
      control,
      new FormControl(
        (defaultValue !== null) ? defaultValue : '',
        Validators.compose([
          isRequired ? Validators.required : Validators.nullValidator,
          ...validators
        ])
      )
    );
  }

  public genInputNumberPositive(
    control: string,
    form: FormGroup,
    isRequired: boolean = false,
    defaultValue: number | null = null,
    validators = []
  ) {
    form.addControl(
      control,
      new FormControl(
        (defaultValue !== null) ? defaultValue : '',
        Validators.compose([
          isRequired ? Validators.required : Validators.nullValidator,
          NumberPositiveValidator.isValid,
          ...validators
        ])
      )
    );
  }

  public genInputDate(control: string, form: FormGroup, isRequired: boolean = false, validators = []) {
    form.addControl(
      control,
      new FormControl(
        '',
        Validators.compose([
          isRequired ? Validators.required : Validators.nullValidator,
          ...validators,
        ])
      )
    );
  }
}
