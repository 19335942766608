import {CostosContable, Option, CostosContableEce} from './ganacia-ocasional';

export interface RentaExenta {
    id?: number;
    pais: string;
    concepto: string;
    otroConcepto?: string;
    ingresos?: CostosContable[];
    costos?: CostosContable[];
    gastos?: CostosContable[];
    convenio: string;
}
export interface RentaExenta2 {
    id?: number;
    pais: string;
    concepto: string;
    tarifa:string;
    otroConcepto?: string;
    ingresos?: CostosContable[];
    costos?: CostosContable[];
    gastos?: CostosContable[];
    convenio: string;
}

export interface EceModel {
  id?: number | null;
  pais: string;
  concepto: string;
  valorTarifa:string;
  ingresos?: CostosContableEce[];
  costos?: CostosContableEce[];
  gastos?: CostosContableEce[];
  totalCosto: number;
  totalGasto: number;
  totalIngreso: number;
}

export interface RentaList {
    id: number;
    concepto: string;
    otroConcepto: string;
    pais: string;
    totalCostos: number;
    totalGastos: number;
    totalIngresos: number;
    totalIngresoNeto: number;
}

export interface RentaExentaMore {
    renta: RentaExenta;
    addMore: boolean;
}

export const INIT_RENTA_EXENTA: RentaExenta = {
    id: null,
    pais: '',
    concepto: '',
    convenio: ''
};
export const INIT_RENTA_EXTERIOR: RentaExenta2 = {
    id: null,
    pais: '',
    concepto: '',
    convenio: '',
    tarifa :'',
};

export const INIT_ECE_FORM: EceModel = {
  id: null,
  pais: '',
  concepto: '',
  valorTarifa :'',
  totalCosto: 0,
  totalGasto: 0,
  totalIngreso: 0,
};

export interface ConvenioExentaList {
  can: RentaTotal;
  convenios: RentaTotal;
  otros: RentaTotal;
  totalCostos: number;
  totalExentas: number;
  totalGastos: number;
  totalIngresos: number;
}

export interface ConvenioExentaResponse {
  totalCostos: number;
  totalExentas: number;
  totalGastos: number;
  totalIngresos: number;
  totalesByConvenio: ConvenioModel[]
}

export interface ConvenioModel {
  concepto?: string;
  convenio: string;
  id?: string;
  otroConcepto?: string;
  pais?: string;
  totalCostos: number;
  totalGastos: number;
  totalIngresoNeto: number;
  totalIngresos: number;
}

export interface RentaExentaList {
  pactoAndino: RentaTotal;
  operacionColombia: RentaTotal;
  companiasHoldingColombianas: RentaTotal;
  totalExentas: number;
  rentasATomar: number;
  limiteRenta: number;
}

export interface RentaTotal {
  id: number;
  concepto: string;
  otroConcepto?: string;
  pais: string;
  totalCostos: number;
  totalGastos: number;
  totalIngresos: number;
  totalIngresoNeto: number;
}

export const DEFAULT_CONCEPTO: Option = {name: 'TOTAL_CONCEP', description: 'Todos los conceptos'};

export const INIT_RENTA_TOTAL: RentaTotal = {
  id: null,
  concepto: DEFAULT_CONCEPTO.name,
  pais: '',
  totalIngresos: 0,
  totalGastos: 0,
  totalCostos: 0,
  totalIngresoNeto: 0
};

export const TOTALES_RENTA: RentaExentaList = {
  pactoAndino: INIT_RENTA_TOTAL,
  operacionColombia: INIT_RENTA_TOTAL,
  companiasHoldingColombianas: INIT_RENTA_TOTAL,
  limiteRenta: 0,
  rentasATomar: 0,
  totalExentas: 0
};

export interface ConvenioOptions {
  id: number;
  name: number;
}

export interface TotalesChcModel {
  totalCostos: number;
  totalExentas: number;
  totalGastos: number;
  totalIngresos: number;
  totalIngresoNeto: number;
}

export interface TableCalculusModel {
  totalA: number;
  totalB: number;
  totalC: number;
  total: number;
}

export interface CalculusResponseModel {
  id: number;
  tipo: string;
  totalRenta: number;
  calculoAplicativo: number;
  calculoUsuario: number;
  status: boolean;
  faltaAplicativo: number;
  faltaUsuario: number;
  aplicativo: boolean;
}