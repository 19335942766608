<ng-container *ngIf="isProject; else folderClose">
  <div >
    <div class="row">
      <div class="col-4">
        <div class="folder_contain">
          <div class="folder_contain_row_1">
            <p class="parrafo">
              {{ name | uppercase }}
            </p>
          </div>
          <div class="folder_contain_row_2">
            <p class="folder_contain_row_2_nit">NIT: {{ nit }}</p>
          </div>
        </div>
      </div>
      <div class="col-1">
        <div class="folder_contain_row_3 dropdown">
          <button
            id="dropdown-annexes"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            (click)="openDropdown($event)"
            style="
              background-color: transparent;
              border: none;
              width: 7rem;
              height: 4rem;
            "
          >
            <p-chart
              type="doughnut"
              [data]="data"
              height="4rem"
              width="7rem"
            ></p-chart>
            <p class="folder_contain_row_3_porc">{{ valorProgreso }}%</p>
          </button>
          <ul
            class="dropdown-menu dropdown-menu-custom"
            aria-labelledby="dropdown-annexes"
          >
            <app-annexes-dropdown [statusList]="statusList" [idProject]="id_proyec">
            </app-annexes-dropdown>
          </ul>
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-xl-6 col-xll-4  marL">
        <div class="d-flex flex-column ">
          <div class="input-group" >
            <input
              #searchInput
              type="text"
              class="form-control"
              [(ngModel)]="searchTerm"
              (input)="performSearch()"
              placeholder="Buscador de anexos"
              (keydown)="onInputKeyDown($event)"
            />

            <span class="input-group-text" id="basic-addon2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                />
              </svg>
            </span>
          </div>
          <ul
            class="list-group"
            *ngIf="searchResults.length > 0"
            (keydown)="onListKeyDown($event)"
            tabindex="0"
            #resultsList
          >
            <li
              class="list-group-item"
              style="cursor: pointer;"
              *ngFor="let result of searchResults; let i = index"
              [class.active]="i === selectedProjectIndex && classActivate"
              (click)="onResultClick(i)"
            >
              {{ result.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #folderClose>
  <div class="folder">
    <div class="folder_contain">
      <div class="folder_contain_row_1">
        <p>MIS PROYECTOS</p>
      </div>
      <div class="folder_contain_row_2">
        <div class="folder_contain_row_2_badge">
          <span class="circle"></span>
          <p>{{ (proyectos$ | async)?.length }} Activos</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
