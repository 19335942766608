import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { RegistroESF } from '../components/esfpatrimonio/model/esf';


@Injectable({
  providedIn: 'root'
})
export class EsfService {

  constructor(private  http: HttpClient) { }

  getEsf(idProject:number): Observable<RegistroESF[]>{
    return this.http.get<RegistroESF[]>("/patrimonio_esf?id="+idProject);
  }
  guardarESF(esf:RegistroESF[],idProject:number):Observable<RegistroESF[]>{
    return this.http.post<RegistroESF[]>("/patrimonio_esf?projectId="+idProject,esf);
  }
}
