<h2 mat-dialog-title>COMPACTUM S.A.S</h2>

<h2 mat-dialog-title>CONDICIONES DEL USO COMO SERVICIO DEL SOFTWARE DE COMPACTUM SAS PARA DECLARACIONES DE RENTA</h2>

<mat-dialog-content class="mat-typography">
  <p>
    Bienvenidos, al software para la declaración de renta de COMPACTUM SAS que está alojado
    en <b>www.rentappi.com</b> de aquí en adelante mencionado en este documento como <b>“EL
      SOFTWARE”</b>, el cual proporciona un servicio de manejo, gestión y administración de
    declaraciones de Renta en línea (Software en la Nube), diseñado para las empresas del
    sector real, Financiero, Solidario, salud o cualquier otro sector dispuesto por nuestra
    normatividad Fiscal, <b>“EL SOFTWARE”</b> está estructurado para reconocer de manera
    automática el sector a que su compañía pertenece
  </p>

  <p>
    Como Usuario debe leer detenidamente este documento, entenderlo y aceptarlo para
    poder usar el software como servicio en la nube, pues son condiciones vinculantes y aplican
    desde el momento en que COMPACTUM S.A.S., le proporciona acceso al mismo. Al
    registrarse para utilizar el software y acceder a las funcionalidades que le presta el mismo,
    EL USUARIO reconoce que ha leído y comprendido estos términos y que tiene la autoridad
    para actuar en nombre de la persona para la cual está utilizando el software, de manera
    que exonera a COMPACTUM S.A.S, en caso de no haber leído las presente condiciones del
    servicio y/o de no contar con la autoridad suficiente para aceptar estas condiciones.
  </p>
  <p>
    Además, como las condiciones pueden variar o ser modificadas a lo largo del tiempo, es
    obligación del USUARIO asegurarse de revisar y aceptar las condiciones más recientes
    disponibles en el sitio web de “EL SOFTWARE”
    <a href="https://www.rentappi.com/auth/login" target="_blank">https://www.rentappi.com/auth/login</a> o
    incluidas en El Software para su conocimiento y consulta.
  </p>
  <p>
    Salvo estipulación en contrario, estas condiciones entrarán en vigencia inmediatamente se
    publiquen.
  </p>
  <h3>
    1.- DEFINICIONES
  </h3>
  <p>
    <b>"Acuerdo":</b> Significa estas condiciones de uso del Servicio. O sea, este mismo documento.
  </p>
  <ol>
    <li>
      <b>“Aplicativos COMPACTUM”:</b> : Significa <b>“EL SOFTWARE”</b> según la modalidad que
      usted suscriba como USUARIO. También se usa en singular, cuando se refiere a uno
      solo de los aplicativos.
    </li>
    <li>
      <b>“Cargo o Tarifas”:</b> : Se refiere a la tarifa (sin incluir impuestos y tasas) pagadera por
      el USUARIO de acuerdo con la modalidad que usted suscriba como USUARIO y el
      Programa de tarifas que se reflejará en la facturación, para tener acceso al servicio.
    </li>
    <li>
      <b>"Datos":</b> Significa cualquier información ingresada por usted o con su autoridad en
        el Software. También se usa la expresión “Data”.
    </li>
    <li>
      <b>“Derecho de Propiedad Intelectual”:</b> Significa cualquier patente, marca de
      productos o de servicios, nombre de dominio, nombre comercial, derechos de autor
      (derecho patrimonial o derecho moral), modelo o diseño, know-how y cualquier
      otro derecho de propiedad intelectual (de propiedad industrial o derechos de
      autor), reconocido legalmente, independientemente que esté registrado o no.
    </li>
    <li>
      <b>“El Software”:</b> Herramienta en la nube, suministrada por COMPACTUM S.A.S, para
      el servicio objeto de este contrato. Es sinónimo de “Aplicativo” o “Aplicativo de
      COMPACTUM”.
    </li>
    <li>
      <b>"Información confidencial":</b> incluye toda la información de relevancia contable,
      tributaria, comercial, estratégica, técnica o legal, intercambiada entre las partes de
      este Acuerdo, ya sea por escrito o en forma electrónica u oral (en este último caso,
      siempre que se confirme en un soporte físico), sin incluir información que es, o
      llegue a ser públicamente disponible, que no sea a través de la divulgación no
      autorizada por la otra parte. La información confidencial debe ser anunciada como
      tal al momento de ser remitida por una de las partes a la otra.
    </li>
    <li>
      <b>"Servicio":</b> significa la prestación a cargo de COMPACTUM S.A.S para que los
      suscriptores accedan al uso de los Aplicativos o Aplicativos COMPACTUM en línea, a
      través del sitio web.
    </li>
    <li>
      <b>“COMPACTUM S.A.S..” o “COMPACTUM”:</b> Sociedad comercial legalmente
      existente y constituida, con domicilio principal en la ciudad de Bogotá, Colombia,
      con NIT 901522303-5; matrícula mercantil No. 03430802 de la Cámara de Comercio
      de Bogotá, significa la empresa que provee el servicio, responsable del software.
      También se usa en este documento como “nosotros”.
    </li>
    <li>
      <b>"Sitios webs":</b> significa los o el sitio(s) de Internet en el dominio
      <a href="http://compactum.com.co" target="_blank">compactum.com.co</a>
      o/y en el dominio <a href="https://www.rentappi.com" target="_blank">rentappi.com</a>,
      o cualquier otro sitio operado por COMPACTUM
      S.A.S para la prestación de los servicios de que trata este Acuerdo.
    </li>
    <li>
      <b>”Suscripción”:</b> Significa su decisión expresa o tácita como USUARIO de adquirir o
      usar los servicios de “EL SOFTWARE”. El uso que usted haga como USUARIO del
      servicio de los aplicativos de COMPACTUM S.A.S., implica un acto de suscripción del
      servicio.
    </li>
    <li>
      <b>“Suscriptor”:</b>  Se refiere al USUARIO del software o del servicio. Salvo acuerdo
      expreso en contrario la facturación por el servicio será hecha a nombre del
      Suscriptor.
    </li>
    <li>
      <b>"Usted":</b> significa el Suscriptor o USUARIO del Servicio. El USUARIO está siempre
      identificado plenamente en la factura de venta. También se usa “tu” o “su”.
    </li>
    <li>
      <b>“Usuarios”:</b> significa bien el USUARIO, en general toda persona que dentro de las
      presentes condiciones tiene acceso a los aplicativos o al Servicio.
    </li>
  </ol>
  <h3>2.- USO DE SOFTWARE</h3>
  <p>
    COMPACTUM S.A.S le otorga el derecho de acceder y utilizar el Servicio a través del sitio
    web con las funciones de usuario disponibles para usted. Este derecho no es exclusivo, es
    intransferible, y está limitado y sujeto a lo prescrito en este documento y a sus adendas o
    modificaciones. Usted reconoce y acepta que es el responsable del uso del Servicio, y el
    responsable de todos los Usuarios configurados en su cuenta administradora. Como
    Suscriptor usted decidirá qué acceso o nivel de acceso (permisos) tendrán sus diferentes usuarios,
    y deberá asegurarse de que todas las personas que usan el aplicativo conocen,
    cumplen y aceptan estos términos.
  </p>
  <p>
    El presente Acuerdo entre el Suscriptor y COMPACTUM S.A.S., describe los términos de uso
    y los alcances, condiciones y limitaciones de los servicios de El Software, prestados bajo la
    modalidad “software como servicio” (SaaS) de acuerdo con su tipo de suscripción.
  </p>
  <p>
    El tipo de servicio puede ser bajo diferentes modalidades, bien para que El Usuario lleve su
    propia información contable y tributaria, o bien para que los contadores lleven la
    información mencionada de sus clientes, o bien para otras funcionalidades o modalidades
    definidas actualmente o que se definan en el futuro.
  </p>
  <p>
    Si el servicio se adquiere para proporcionar servicios a otros, usted debe asegurarse de que
    está autorizado de hacerlo y que todas las personas para quienes se prestan servicios
    conocen todos los términos de este documento, sus adendas o modificaciones, para efectos
    de su cumplimiento.
  </p>
  <p>
    Por la modalidad de contratación (SaaS) EL USUARIO no tendrá instalado el software en sus
    máquinas, sino que tendrá acceso al servicio suministrado por COMPACTUM S.A.S., bajo el
    tipo de suscripción definido por EL USUARIO. EL USUARIO tendrá acceso al servicio en la
    medida que mantenga el contrato vigente y a paz y salvo. En cualquier caso, que EL
    USUARIO no continúe o no quiera continuar con los servicios podrá bajar su información a
    archivos Excel para no perder la información, ya que la misma no puede migrarse a otro
    software o plataforma y COMPACTUM S.A.S., no puede hacerse responsable
    indefinidamente de la misma.
  </p>
  <p>
    A cada USUARIO se le asigna su correo electrónico como identificador usuario único y estará
    asociado a su empresa, con el cual podrá acceder a los módulos y funcionalidades
    adquiridas y solo podrán usar el número de usuarios y/o módulos y/o empresas autorizadas
    expresamente por COMPACTUM S.A.S., y sus características y descripción se encuentran
    detalladas en la página web de <a href="https://www.rentappi.com" target="_blank">rentappi.com</a>,
    EL USUARIO reconoce haber comprendido las características y funcionalidades del aplicativo contratado
    y que este, siendo genérico, satisface las necesidades para las cuales suscribió el servicio pues se adecúa
    aceptablemente a las necesidades y procesos propios de su negocio.
  </p>
  <h3>
    3.- ACEPTACIÓN DE TÉRMINOS:
  </h3>
  <p>
    Para la utilización de los servicios de “EL SOFTWARE”, EL USUARIO debe aceptar expresa o
    tácitamente los términos y condiciones del presente documento y que también se
    encuentran en la página web <a href="https://www.rentappi.com" target="_blank">rentappi.com</a>,
    pero puede cambiar en alguna actualización de
    este, de modo que para la utilización del servicio cada vez que se produzca una actualización
    de El Software, EL USUARIO debe leer, comprender y aceptar los nuevos términos y
    condiciones: se entenderá que EL USUARIO ha aceptado los términos de este contrato si hace uso
    de los servicios de cualquiera de los aplicativos o funcionalidades de “EL SOFTWARE” y/o con el pago de la factura.
  </p>
  <p>
    En caso de no aceptar los términos y condiciones del servicio, EL USUARIO no debe hacer
    uso de los aplicativos o funcionalidades. COMPACTUM S.A.S., no aceptará la modificación
    de estas condiciones generales, en la medida que son uniformes para todos los usuarios de
    los servicios. En el evento que el USUARIO no acepte las nuevas condiciones en caso que
    estas cambien, el USUARIO no podrá hacer uso del servicio
  </p>
  <p>
    Por lo mismo, las condiciones de este documento, sus adendas o modificaciones estarán
    vigentes respecto de la versión de El Software habilitada al usuario. En caso de una
    actualización de versión o un ajuste de esta, cambio de software o de empaquetamiento, o
    de sistema operativo, o de plataforma, adición de módulos, funcionalidades o usuarios
    autorizado, es probable que estas condiciones cambien.
  </p>
  <h3>4.- OBLIGACIONES DE LAS PARTES:</h3>
  <p>
    <b>4.1 -</b> Como USUARIO del servicio usted se obliga a:
  </p>
  <ol class="alpha">
    <li>
      Entregar información adecuada y veraz en el momento de suscripción de los servicios.
    </li>
    <li>
      Tener la facultad de celebrar este contrato en representación de la persona jurídica o
      natural que utiliza los servicios de “EL SOFTWARE”
    </li>
    <li>
      No permitir el uso de los aplicativos o del servicio a personas distintas del USUARIO, o a
      un USUARIO distinto al que adquiere el servicio, y no venderá, ni revenderá, ni arrendará
      los servicios del aplicativo licenciado, ya que son para uso exclusivamente del USUARIO.
    </li>
    <li>
      Mantener actualizada la información, modificando cuando sea necesario cualquier
      cambio de estado de alguno de los datos proporcionados en la inscripción inicial.
    </li>
    <li>
      No entregar información que corresponda a terceros, ni entregar información
      inadecuada, o que afecte las disposiciones sobre datos personales, o que viole derechos de
      autor o de terceros, marcas u otros derechos de propiedad intelectual, convenios de
      confidencialidad, ni usar los servicios “EL SOFTWARE” en actividades que tengan que ver
      con la explotación sexual, pornografía, proxenetismo, el turismo sexual y demás formas de
      abuso sexual, imágenes o videos con menores de edad, material que haga apología a la
      violencia, proselitismo armado o actividades de personas o grupos al margen de la ley, o
      que atenten contra los usos honestos. En caso contrario COMPACTUM S.A.S. podrá decidir
      unilateralmente la terminación del servicio de manera permanente.
    </li>
    <li>
      Responsabilizarse por la clave de acceso y/o la administración de las claves que asigne y
      mantenerlas bajo confidencialidad y notificar a COMPACTUM S.A.S de inmediato si se produce acceso
      o uso no autorizado del servicio. Usted es responsable por todas las
      actividades que se realicen con su cuenta de usuario.
    </li>
    <li>
      Pagar el precio pactado a COMPACTUM S.A.S., por los servicios del aplicativo adquirido,
      así como todos aquellos cargos que se desprendan de la ejecución del presente contrato o
      de requerimientos del USUARIO. EL USUARIO no podrá excusarse del pago de las sumas a
      su cargo con el pretexto de no estar usando el aplicativo ya que las tarifas se basan en los
      servicios adquiridos por el tiempo contratado y no en su uso real; tampoco podrá excusarse
      cuando haya incumplido cualquiera de las obligaciones a su cargo y/o con el argumento de
      supeditar el pago al avance de la implementación de “EL SOFTWARE”, pues esta depende
      del mismo USUARIO. Los reportes de calidad no exoneran del pago pactado al USUARIO,
      pues están comprendidos dentro de los servicios y la garantía a cargo de COMPACTUM
      S.A.S.
    </li>
    <li>
      Asegurarse de haber leído, comprendido y aceptado los términos más recientes
      disponibles en el sitio web o al acceder al servicio. Al registrarse para utilizar el software o
      el servicio, EL USUARIO reconoce que ha leído y comprendido estos términos y que tiene la
      autoridad para actuar en nombre de cualquier persona para la que esté utilizando el
      software o el servicio. En caso que el USUARIO no las acepte o las rechace expresamente,
      no estará obligado a contratar la nueva vigencia del servicio, pero COMPACTUM S.A.S
      tampoco estará obligado a prestar los servicios contratados, ni a prestar servicio alguno, y
      la garantía solo se mantendrá vigente hasta el vencimiento del último periodo renovado.
    </li>
    <li>
      Contar con mecanismos de protección y detección de virus, e información no deseada, ya
      que COMPACTUM S.A.S no responderá por daños o perjuicios derivados de unos u otra.
    </li>
    <li>
      EL USUARIO debe poseer los dispositivos de cómputo requeridos y su respectiva conexión
      a Internet (según requerimientos técnicos mínimos recomendados por COMPACTUM S.A.S.,
      en la página web rentappi.com, aunque estos requerimientos técnicos pueden ser
      modificados en cualquier momento por cambios tecnológicos, actualización de versiones,
      nuevas funcionalidades, etc. EL USUARIO es responsable por la adquisición y
      mantenimiento de dichos equipos y de asegurar la conectividad adecuada a internet para
      lograr un correcto funcionamiento del servicio.
    </li>
    <li>
      No infringir los derechos propiedad intelectual de COMPACTUM S.A.S sobre las marcas,
      nombres y los aplicativos. En este orden de ideas, usted no podrá, a título enunciativo, más
      no limitativo:
      <ol class="roman">
        <li>
          intentar socavar la seguridad o la integridad de los sistemas o redes informáticos
          de COMPACTUM S.A.S o, cuando los Servicios están alojados por un tercero, los
          sistemas informáticos y las redes de terceros;
        </li>
        <li>
          utilizar los Servicios de manera que pueda afectar la funcionalidad de los mismos
          o el Sitio web u otros sistemas utilizados para prestar los Servicios, o afectar la
          capacidad de cualquier otro usuario para utilizar los Servicios o el Sitio web;
        </li>
        <li>
          intentar obtener acceso no autorizado a ningún material que no sea el que le ha
          dado permiso expreso para acceder o al sistema informático en el que se alojan los
          Servicios.
        </li>
        <li>
          transmitir o ingresar al sitio web archivos que puedan dañar los
          dispositivos informáticos o software de otra persona, contenido o material o Datos
          en violación de cualquier ley (incluidos datos u otro material protegido por habeas
          data, derechos de autor o secretos comerciales) que no tiene el derecho de usar); o
        </li>
        <li>
          intentar modificar, copiar, adaptar, reproducir, desensamblar, descompilar o
          aplicar ingeniería inversa a El Software o los programas informáticos utilizados para
          prestar los Servicios u operar el Sitio web, salvo que sea estrictamente necesario
          para utilizar cualquiera de ellos para el funcionamiento normal.
        </li>
      </ol>
    </li>
    <li>
      Contar con planes de contingencia que le permitan afrontar cualquier inconveniente que
      se pueda presentar con la información, independientemente que el inconveniente sea o no
      de la responsabilidad de COMPACTUM S.A.S.
    </li>
    <li>
      Las demás que se desprendan de las anteriores o que se consignen en este documento
      o en cualquier adenda o modificación al mismo.
    </li>
  </ol>
  <p>
    <b>4.2.-</b> - COMPACTUM S.A.S, por su parte se obliga a:
  </p>
  <ol class="alpha">
    <li>
      Habilitar el servicio contratado, para operar bajo la modalidad de Software como Servicio
      (SaaS), de acuerdo al tipo de Servicio y con acceso al número limitado de usuarios, número
      de proyectos y en general de las prestaciones adquiridas y expresamente facturadas y
      canceladas por el USUARIO al momento de la suscripción del servicio.
    </li>
    <li>
      Asignar acceso al servicio, a traves de la cuenta de correo configurada para tal fin por
      parte del USUARIO. EL USUARIO deberá administrar los niveles de acceso a sus funcionarios,
      de forma segura para los servicios.
    </li>
    <li>
      Poner a disposición las guías y/o videos de uso de El Software a través del portal de
      clientes en la página web de COMPACTUM S.A.S. <a href="https://www.rentappi.com" target="_blank">rentappi.com</a> y/o en la página
      web <a href="http://compactum.com.co" target="_blank">compactum.com.co</a>, o en nuestro canal de YouTube.
    </li>
    <li>
      Garantizar la funcionalidad del software en los términos y condiciones que se expresan
      en este documento.
    </li>
    <li>
      Hacer permanentemente monitoreo en la red, sistemas y elementos de comunicación y
      trabajo de los aplicativos de COMPACTUM S.A.S., para efectos del servicio, de la medición
      de la usabilidad del mismo y/o con fines estadísticos o para efectos internos.
    </li>
    <li>
      Recibir las experiencias con “EL SOFTWARE”, comentarios, sugerencias o ideas sobre El
      software y sobre el servicio o mejoras de uno y otro que le envíen los usuarios, aunque
      COMPACTUM S.A.S no está obligado a aceptarlos o incluirlos en El Software o en el Servicio.
      No obstante, COMPACTUM S.A.S se reserva el derecho de usar libremente, de cualquier manera,
      incluso en futuras modificaciones de los productos o servicios, publicidad o
      materiales de marketing, las experiencias de los usuarios con El Software o El Servicio, los
      comentarios, sugerencias e ideas que EL USUARIO proporcione en desarrollo o con ocasión
      del uso de El Software o aplicativo o funcionalidad objeto del servicio, sin reconocer
      derecho, compensación o propiedad alguna al USUARIO que los envía sobre unos y otra.
      <br>
      Si EL USUARIO envía dichos comentarios, sugerencias o ideas a COMPACTUM S.A.S, se
      entiende que con ello otorga permiso a COMPACTUM S.A.S para usarlos, así como la
      información sobre su experiencia con “EL SOFTWARE” para ayudarnos a brindarle y mejorar
      los servicios a que hace referencia este documento.
    </li>
    <li>
      Brindar a los usuarios activos y a paz y salvo servicios de soporte, mantenimiento y
      garantía de manera virtual, sobre El Software y sobre el Servicio, a través del correo
      soporte@compatum.com
    </li>
    <li>
      La responsabilidad de COMPACTUM S.A.S es de medio y no de resultados.
    </li>
  </ol>
  <h3>
    5.- EXCLUSIONES O LIMITACIONES
  </h3>
  <p>
    Además de todas aquellas otras circunstancias que se definen en el texto de este
    documento, sus adendas y modificaciones, COMPACTUM S.A.S excluye del servicio o de sus
    responsabilidades lo siguiente:
  </p>
  <ol class="alpha">
    <li>
      COMPACTUM S.A.S no brinda servicios presenciales. Todos los servicios que se brindan
      son virtuales o remotos, a través de los distintos canales de comunicación que provee a LOS
      USUARIOS y de la conexión de internet con que cuenta EL USUARIO.
    </li>
    <li>
      COMPACTUM S.A.S no da soporte, ni asesoría en redes, sistemas operacionales, internet,
      hardware y/o software distinto a los aplicativos de COMPACTUM S.A.S.
    </li>
    <li>
      COMPACTUM S.A.S no proporciona servicios o asesoría contable, de impuestos,
      financiera, administrativa, legal, u otros servicios profesionales. Estos servicios deberán ser
      adquiridos a través de un profesional competente, bajo la total y exclusiva responsabilidad
      de EL USUARIO, ya que EL USUARIO es el único responsable de cumplir con todas las leyes
      contables, impositivas y de otro tipo aplicables a su negocio u objeto social.
      <br>
      También será responsabilidad de EL USUARIO verificar los datos, los resultados obtenidos y procesos
      ejecutados a través del software, ya que el servicio ofrecido por COMPACTUM S.A.S es de
      medio y no de resultados y por ende es del usuario la total responsabilidad de los
      resultados. COMPACTUM S.A.S., no acepta ni autoriza las recomendaciones que en sentido
      contrario a esta cláusula hagan sus empleados y/o funcionarios.
    </li>
    <li>
      COMPACTUM S.A.S. se reserva el derecho de no renovar los servicios al vencimiento del
      periodo contratado, o modificar o inhabilitar algunas funcionalidades o servicios en
      cualquier momento, cambiar o descontinuar productos o servicios, en todo o en parte, sin
      que haya lugar a pago de indemnización o contraprestación alguna. Si por la modificación
      introducida EL USUARIO desiste de seguir usando el servicio, podrá manifestarlo así a
      COMPACTUM S.A.S.
    </li>
    <li>
      En ningún caso COMPACTUM S.A.S., prestará o habilitará el servicio para el USUARIO que
      tenga en mora el pago de la factura o, en general, el pago del servicio. EL USUARIO en
      consecuencia, acepta esta condición, y exonera a COMPACTUM S.A.S., en caso de
      inconvenientes o afectaciones por esta causa.
    </li>
    <li>
      Las obligaciones que COMPACTUM S.A.S., adquiere con EL USUARIO serán siempre de
      medio y no de resultado. COMPACTUM S.A.S no acepta una condición diferente a esta, ni
      promesa alguna de cualquiera de sus funcionarios que garantice al USUARIO un resultado
      especifico a través del servicio a que hace referencia este documento.
    </li>
    <li>
      COMPACTUM S.A.S., provee un servicio idóneo y de calidad, pero la responsabilidad con
      el ingreso, administración, interpretación o uso de la información es del USUARIO, así como
      la administración de claves de usuarios, los sistemas operativos, la red, las aplicaciones o
      el(los) software desarrollado(s) por terceros que puedan interactuar o no con “EL
      SOFTWARE”, las bases de datos, el hardware, la conexión a internet y en general los equipos
      o software del USUARIO.
      <br>
      EL USUARIO dispone de herramientas y funcionalidades en El
      Software que debe usar o administrar bajo sus propios criterios, sin hacer responsable a
      COMPACTUM S.A.S por el uso, el no uso, o el abuso que le den sus propios usuarios a
      aquellas o a la información almacenada en El Software. En algunos casos los usuarios
      deberán correr procesos paralelos, de verificación o complementarios en herramientas
      distintas al El Software para validar o incorporar el resultado en el mismo.
    </li>
    <li>
      COMPACTUM S.A.S., se reserva el derecho de cancelar en cualquier momento el servicio
      a aquellos usuarios que a su criterio estén realizando operaciones peligrosas, ilegales o
      inadecuadas, en contraindicación de lo estipulado en este documento, sus adendas o
      modificaciones, y que pongan o puedan poner en riesgo la operación de la plataforma o la
      información de los demás usuarios del sistema, y/o de terceros.
    </li>
    <li>
      COMPACTUM S.A.S. se reserva también el derecho de cancelar en cualquier momento el
      servicio a aquellos usuarios que se vean relacionados en lista Clinton, listas de sanciones de
      la OFAC o UIAF o que no respeten las disposiciones del SARLAFT o similares, o en reportes
      a centrales de riesgo, o afectados con alguna sanción penal, administrativa o fiscal, cuando
      a juicio de COMPACTUM S.A.S., cualquiera de estos reportes o situaciones puedan afectarla
      directa o indirectamente.
    </li>
  </ol>
  <h3>6.- TARIFAS Y CARGOS:</h3>
  <p>
    Los servicios ofrecidos por COMPACTUM S.A.S. son prestados en diferentes planes o
    modalidades. Las suscripciones pueden ser cargadas a su tarjeta de crédito, tarjetas débitos
    canceladas por internet o pagadas a través de los medios electrónicos habilitados por COMPACTUM S.A.S.,
    para tal fin, de acuerdo al plan, la cantidad de usuarios solicitados y el periodo establecido.
  </p>
  <p>
    El precio total pactado, los factores del mismo y la forma de pago son los que se determinan
    en la factura de venta que emitirá COMPACTUM S.A.S., y remitirá al USUARIO, la cual se
    entiende aceptada si no es devuelta u objetada por EL USUARIO dentro de los 10 días
    calendario siguientes a su recibo y de todas maneras, se entiende aceptada si se recibe el
    pago o algún abono sobre ésta. La factura reflejará los servicios suscritos por EL USUARIO
    y habilitados por COMPACTUM S.A.S, de manera que, al existir un error entre los servicios
    suscritos y habilitados y la factura, prevalecerá lo primero y se deberá ajustar la factura
  </p>
  <p>
    En caso que las partes acuerden que EL USUARIO cancele el valor del servicio mediante
    sistema de “pagos recurrentes”, la facturación se hará por anualidades anticipadas y/o con
    la periodicidad y monto según la modalidad acordada. EL USUARIO podrá cancelar en
    cualquier momento la autorización a su entidad financiera comunicándose con el área
    administrativa de COMPACTUM S.A.S, EL USUARIO también puede utilizar un medio de
    pago diferente previo acuerdo con COMPACTUM S.A.S., En cualquier caso, que EL USUARIO
    no haga el pago dentro de la oportunidad pactada o incumpla cualquier obligación a su
    cargo en virtud de estas especificaciones, o de las disposiciones legales vigentes,
    COMPACTUM S.A.S., suspenderá el servicio y EL USUARIO no podrá acceder al software,
    hasta que el USUARIO se ponga nuevamente al día.
  </p>
  <p>
    Las tarifas se basan en los servicios adquiridos por el tiempo contratado y no en el uso real,
    de manera que salvo acuerdo expreso en contrario, COMPACTUM S.A.S no proporcionará
    ningún reembolso por el período que EL USUARIO no haya usado el servicio ni por el periodo
    restante por una suscripción prepaga, en caso que EL USUARIO no desee seguir usando los
    servicios. De todos modos, las obligaciones de pago por el periodo contratado son no
    revocables unilateralmente por el usuario y las tarifas abonadas no son rembolsables.
  </p>
  <p>
    Después de efectuada la compra inicial del software “EL SOFTWARE” en la modalidad de
    SaaS (Software como Servicio) COMPACTUM S.A.S., continuará facturándole la suscripción
    según la periodicidad elegida por El Usuario, de acuerdo con las tarifas vigentes hasta que
    se rescinda este servicio de acuerdo con este documento. La suscripción al servicio podrá
    tener incrementos de año en año. Todas las facturas de COMPACTUM S.A.S., se le enviarán
    a usted, o a un contacto de facturación cuyos detalles proporcione usted. El pago de todos
    los montos especificados en una factura debe hacerse de acuerdo con lo facturado. Los
    pagos se realizarán a través del botón de pagos dispuesto en la factura, o medios
    electrónicos, no se contemplan pagos en efectivo o consignaciones bancarias
  </p>
  <p>
    En caso de mora EL USUARIO reconocerá y pagará a COMPACTUM S.A.S el máximo interés
    moratorio admitido de acuerdo con el artículo 884 del Código de Comercio y demás
    disposiciones legales, sobre las sumas adeudadas según los plazos estipulados en la factura,
    pero COMPACTUM S.A.S podrá dar la obligación de plazo vencido y exigir el pago de la totalidad
    de la obligación, en el evento que la mora se presente en una o más cuotas. De
    todas maneras, la mora implica la suspensión de todo servicio.
  </p>
  <p>
    Dentro de la tarifa pactada con el USUARIO se incluye el uso de un espacio de
    almacenamiento razonable para las operaciones e información contable y administrativa
    del mismo que se tasa en 1Gigabyte; sin embargo, en la medida que la información
    almacenada por el usuario supere este límite, COMPACTUM S.A.S., procederá a efectuar el
    cobro adicional por el espacio en exceso utilizado.
  </p>
  <p>
    Es posible que existan situaciones donde COMPACTUM S.A.S., ofrezca precios
    preferenciales o descuentos por los productos o servicios, como resultado de planes
    promocionales, precios de introducción, precios u ofertas de temporada, ofertas masivas,
    ofertas espontáneas, etc. La elegibilidad de dichos precios preferenciales o descuentos está
    condicionada a los términos de cada promoción, y salvo disposición en contrario, no
    tendrán vigencia mayor a treinta (30) días calendario desde su publicación. COMPACTUM
    S.A.S., se reserva el derecho de suspender o finalizar la promoción o descuento en el caso
    de que las facturas de esos cargos no se paguen en su totalidad de acuerdo con los plazos y
    condiciones pactadas.
  </p>
  <p>
    En el caso que COMPACTUM S.A.S., defina un servicio no facturable, por ejemplo
    dependiendo el tipo de servicio contratado y la cantidad de clientes que tengan el servicio,
    etc., estas condiciones comerciales se entenderán temporales y no obligan indefinidamente
    a COMPACTUM S.A.S., ya que pueden cambiar o se pueden suspender en cualquier
    momento, caso en el cual COMPACTUM S.A.S., notificará al USUARIO las nuevas
    condiciones que se definan, directamente o a través de los portales
    <a href="http://compactum.com.co" target="_blank">compactum.com.co</a>
    y/o <a href="https://www.rentappi.com" target="_blank">rentappi.com</a> , y
    las mismas empezarán a regir en la fecha que se notifique en la
    comunicación. Tratándose de algún servicio sin contraprestación económica alguna,
    COMPACTUM S.A.S., se reserva el derecho a eliminar EL USUARIO del software si observa
    que después de tres (3) meses, EL USUARIO no está haciendo USO del servicio o de la
    plataforma. En este caso, la información ingresada por EL USUARIO también puede ser
    eliminada por COMPACTUM S.A.S.
  </p>
  <p>
    COMPACTUM S.A.S se reserva también el derecho de cambiar las tarifas, planes y periodos
    de cobro publicados en cualquier momento, pero estos cambios no tendrán vigencia
    retroactiva.
  </p>
  <h3>7.- ACTIVACIÓN, RENOVACIÓN, SUSPENSIÓN Y CANCELACIÓN DEL SERVICIO:</h3>
  <p>
    Los servicios, aun en el evento que la forma de pago se pacte mediante modalidad de pago
    recurrente, tendrán una vigencia de un año contado a partir de la activación del aplicativo
    “EL SOFTWARE” adquirido por el USUARIO. Al vencimiento del término pactado, el contrato
    se renovará de manera automática, salvo manifestación expresa y escrito de alguna de las
    partes con antelación a un mes al vencimiento del contrato o de cualquiera de sus
    prorrogas. La renovación del contrato se hará por períodos sucesivos e iguales de doce (12)
    meses, con un incremento para EL USUARIO de acuerdo al empaquetamiento, o
    funcionalidades adicionales y número de usuarios habilitados con que cuente para este
    momento el USUARIO. La renovación de este contrato se cobrará año anticipado, en un solo
    contado. La vigencia del contrato no se corre en el caso que el USUARIO no use el servicio,
    tampoco por cambio de factura o suspensión de servicio por mora en el pago por parte del
    USUARIO.
  </p>
  <p>
    Una vez sea verificado el pago exitoso de los servicios suscritos, COMPACTUM S.A.S.,
    habilitará los servicios adquiridos. Si COMPACTUM S.A.S., no recibe el pago completo o no
    puede confirmar el éxito de una transacción, para una compra nueva, una adición o
    modificación, o un nuevo periodo de renovación, usted no dispondrá del acceso a los
    servicios contratados. Es decir, en caso que no se paguen o no se renueven los servicios, se
    procederá a inhabilitar las cuentas de acceso al mismo. En estos casos COMPACTUM S.A.S.,
    no se obliga a mantener indefinidamente la información registrada por los usuarios,
    reservándose el derecho de eliminar del sistema los recursos aprovisionados por el usuario
    y cualquier información allí almacenada, sin responsabilidad alguna de COMPACTUM S.A.S.,
    situación que expresamente declara entender y aceptar el USUARIO, liberando a
    COMPACTUM S.A.S., de cualquier responsabilidad que pudiera endilgársele por este hecho
  </p>
  <p>
    COMPACTUM S.A.S., se reserva el derecho de no renovar los servicios al vencimiento del
    periodo contratado, o de modificar o inhabilitar algunas funcionalidades o servicios en
    cualquier momento, cambiar o descontinuar productos, funcionalidades o servicios, en
    todo o en parte, sin que haya lugar a pago de indemnización o contraprestación alguna. Si
    por la modificación introducida EL USUARIO desiste de seguir usando el servicio, podrá
    manifestarlo así a COMPACTUM S.A.S. En caso de no renovación y/o que usted desee
    cancelar definitivamente un servicio y desee una copia de la información almacenada, usted
    tendrá hasta treinta (30) días para solicitar a COMPACTUM S.A.S le habilite
    provisionalmente (hasta por tres días) el acceso solo para exportar su información a Excel,
    sin responsabilidad o intervención de COMPACTUM S.A.S.
  </p>
  <p>
    La falta de renovación del presente contrato libera a COMPACTUM S.A.S de toda obligación
    de prestar los servicios del aplicativo, así como de la garantía sobre el software, y hace
    responsable al USUARIO de la información ingresada al aplicativo.
  </p>
  <h3>
    8.- USO DEL SERVICIO Y MARCAS:
  </h3>
  <p>
    Al contratar el servicio usted adquiere el derecho de uso del mismo a título personal o de
    su organización, según sea el caso. El uso de logos, nombres y marcas de COMPACTUM
    S.A.S. o de terceros, involucradas en el servicio o links a los portales de COMPACTUM S.A.S.,
    queda totalmente restringido. Ni usted ni ningún tercero podrá hacer uso de ellos sin previa
    autorización expresa y por escrito del titular legítimo de las mismas.
  </p>
  <p>
    COMPACTUM S.A.S. puede enviar información a los usuarios sobre productos o servicios
    propios y/o de terceros, incluso a través del software o del servicio. También puede ofrecer
    productos y servicios en nombre de terceros y/o proporcionar acceso o enlaces a sitios web
    propios o de terceros. Si usted decide utilizar productos o servicios de terceros o acceder a
    sitios de terceros, usted es el único responsable de su selección, revisión de términos de
    productos por separado, términos del sitio web y políticas de privacidad COMPACTUM
    S.A.S., no respalda productos o servicios de terceros.
  </p>
  <h3>
    9.- VERSIONES DE PRUEBA Y OBSEQUIOS
  </h3>
  <p>
    Por regla general los servicios COMPACTUM S.A.S no se entregan en prueba o sin
    contraprestación económica alguna. No obstante, si COMPACTUM S.A.S., permite que un
    usuario suscriba un Servicio en prueba o sin contraprestación económica, esta condición
    será por una sola vez, por el tiempo expresamente acordado y en defecto de acuerdo, por
    un periodo no mayor a treinta (30) días calendario, al final de los cuales, si usted sigue
    haciendo uso del Servicio, este le será facturado y aplicarán las demás condiciones de este
    documento. En este caso, las versiones en prueba o gratuitas en ningún momento están
    diseñadas para ser servicios de misión crítica y soportar procesos de negocio de su
    organización. Tanto los servicios definidos como pruebas o “trials” y aquellos gratuitos no
    poseen ningún tipo de nivel de servicio en el soporte; sin embargo, usted podrá escalar
    incidentes mediante el correo electrónico, chat o el medio virtual que defina COMPACTUM
    S.A.S., según se describe en la cotización o en la página de soporte de COMPACTUM S.A.S.,
    para obtener respuesta de acuerdo a la disponibilidad.
  </p>
  <p>
    COMPACTUM S.A.S., se reserva el derecho de descontinuar sin previo aviso cualquiera de
    los servicios gratuitos o de prueba en el sistema o de cambiar las condiciones de los mismos,
    o de exigir contraprestación económica en cualquier momento. Cualquier dato que
    ingresen en el software y cualquier personalización efectuada a los servicios por o para
    usted durante el período de prueba o gratuito, se perderán permanentemente, a menos
    que adquiera (a más tardar dentro del periodo de prueba y de acuerdo con el aparte
    activación, renovación, suspensión y cancelación del servicio) una suscripción a los mismos
    servicios que aquellos contemplados por la prueba o gratuitos. De todos modos, vencido el
    periodo de prueba o gratuito o excedido el número de empresas operadas o las condiciones
    previas, si usted, como usuario decide seguir usando el software, aplicarán las presentes
    condiciones de uso, tarifas y el pago correspondiente.
  </p>
  <p>
    En algunas ocasiones COMPACTUM S.A.S., obsequia algunos de sus productos de manera
    ilimitada sin que esto se trate de una prueba gratuita. En caso tal que COMPACTUM S.A.S
    decida empezar a efectuar un cobro sobre los productos que alguna vez fueron
    obsequiados, COMPACTUM S.A.S dará aviso al Usuario con la debida antelación acerca de
    dicho cambio y dará un plazo extendido antes de suspender el uso del software. Si el usuario
    decide no continuar con el servicio por este motivo, el Usuario tiene 30 dias para descargar
    su información a fin de poderla migrar hacia otra plataforma o poderla conservar.
    COMPACTUM S.A.S no se hace responsable de la información que el Usuario tenga
    almacenada después de los 30 dias que estipula este acuerdo
  </p>
  <h3>10.- GARANTIA:</h3>
  <p>
    Sobre el entendido que ningún servicio, software o aplicativo de computador es infalible, ni
    está 100% libre de defectos, COMPACTUM S.A.S otorga una garantía de funcionamiento
    sobre el servicio y sobre El Software, que da derecho al USUARIO a la corrección de errores
    e inconvenientes que se presenten con relación al servicio y al aplicativo contratado por el
    USUARIO.
  </p>
  <p>
    Por defectos se entenderán todos los errores del servicio y fallos reproducibles posibles que
    se den en el aplicativo y todos los fallos sustanciales de los mismos para funcionar de la
    manera descrita en la documentación correspondiente y que no tengan que ver con
    circunstancias externas al software o al manejo del usuario. En estos casos el USUARIO
    comunicará inmediatamente a COMPACTUM S.A.S., la existencia de un error, junto con
    toda la información del diagnóstico y demás que resulte relevante relacionada con el error,
    a fin de permitir a COMPACTUM S.A.S., identificar, aislar y reproducir el error para su
    corrección.
    <br><br><b> Dentro de la garantía COMPACTUM S.A.S se compromete a:</b>
  </p>
  <ol class="alpha">
    <li>
      Efectuar las reparaciones adecuadas y oportunas en caso de fallas del aplicativo que el
      usuario haya contratado por EL USUARIO.
    </li>
    <li>
      Tramitar y responder en forma diligente, las quejas, peticiones, reclamaciones y recursos
      presentados por el usuario.
    </li>
    <li>
      En general, realizar las actividades adecuadas para el desarrollo exitoso del presente
      contrato, desde su órbita de competencia.
    </li>
  </ol>
  <h3>11.CONDICIONES DE LA GARANTIA.</h3>
  <p>
    <b>11.1-</b> Para la efectividad de la garantía el USUARIO deberá cumplir con las siguientes
    condiciones:
  </p>
  <ol class="alpha">
    <li>
      No alterar ni ingresar al aplicativo objeto del servicio contratado, por medios diferentes
      a los propios del sistema o a los realizados directamente por COMPACTUM S.A.S, ni dañar
      ni alterar sus archivos de datos.
    </li>
    <li>
      Mantener en buen estado de funcionamiento el equipo de cómputo (hardware) para
      acceder al aplicativo y tener un buen rendimiento (performance) de los equipos, sistemas,
      internet, etc., para la correcta conectividad y/o para evitar daños de los archivos de datos
      ingresados al aplicativo
    </li>
    <li>
      Permitir al personal de COMPACTUM S.A.S la realización de las actividades propias de
      los servicios de seguimiento, mantenimiento o garantía, aun si es necesario suspendiendo
      temporalmente el Servicio.
    </li>
    <li>
      Mantener vigente y a paz y salvo el contrato.
    </li>
    <li>
      Mantener una conexión de internet de banda ancha que permita una buena conectividad con el aplicativo
    </li>
    <li>
      No violar los derechos de autor sobre el aplicativo adquirido o sobre los sistemas operativos necesarios para su uso.
    </li>
    <li>
      No incumplir las demás condiciones del Servicio aquí definidos, o en sus adendas o
      modificaciones, en cuanto afecten o puedan afectar el Servicio, así como cualquier otro
      evento previsto como exclusión de garantía del Software o del Servicio.
    </li>
  </ol>
  <p>
    En caso de incumplimiento total o parcial de lo previsto en este numeral, COMPACTUM
    S.A.S puede dar por terminada la garantía y no estará obligado a prestar ningún servicio.
  </p>
  <p>
    COMPACTUM S.A.S garantiza únicamente la idoneidad y calidad de El Software y del
    Servicio, ya que no son objeto de su responsabilidad ni la parametrización, ni el manejo,
    administración, interpretación, uso de la información, ni la administración de claves de
    usuarios, ni los sistemas operativos, ni la red, ni las aplicaciones o el(los) software
    desarrollado(s) por terceros que puedan interactuar o no con “EL SOFTWARE”, ni las bases
    de datos, ni el hardware, el proveedor de internet, ni en general los equipos o software del
    USUARIO, ya que ellos son responsabilidad exclusiva del USUARIO. EL USUARIO dispone de
    herramientas y funcionalidades que debe usar o administrar bajo sus propios criterios, sin
    hacer responsable a COMPACTUM S.A.S por el uso, el no uso, o el abuso que les den sus
    propios usuarios
  </p>
  <p>
    Los aplicativos “EL SOFTWARE” y el servicio que presta COMPACTUM S.A.S son genéricos y
    estandarizados, en la medida que aplican a una universalidad de necesidades, de manera
    que COMPACTUM S.A.S no está obligado a hacer desarrollos especiales o cambios en el
    esquema de servicios que brinda a los usuarios, por lo tanto las funcionalidades adicionales
    que no contenga el aplicativo contratado, aun estando en la Ley vigente o en cambios
    legislativos, aunque se pueden considerar para futuros desarrollos, no se considerarán un
    defecto de El Software, y se entienden excluidas de la garantía. En algunos casos el USUARIO
    deberá correr procesos en herramientas distintas al El Software o validar mediante cruces
    y/o auditorias la información para incorporar y/o validar el resultado en el mismo.
  </p>
  <p>
    Los parámetros que por defecto traen los aplicativos y las herramientas adicionales que se
    incluyan en los aplicativos tales como formatos o ayudas, pueden o no ser usados por EL
    USUARIO, pero no tienen garantía y no exoneran al USUARIO de la definición de sus propios
    formatos o validación de resultados, o a asumir un costo adicional por ellos, a la vez que no
    obligan a COMPACTUM S.A.S a mantenerlos, de manera que pueden ser retirados del software en
    cualquier momento, sin previo aviso.
  </p>
  <p>
    <b>11.2.-</b> En desarrollo de la garantía sobre el servicio COMPACTUM S.A.S declara que:
  </p>
  <ol class="roman">
    <li>
      No modificaremos sus datos.
    </li>
    <li>
      Tendremos acceso a estadísticas básicas de uso del servicio de los aplicativos, así como
      al conteo de variables del sistema y toda la información pertinente para obtener
      información de usabilidad y de almacenamiento.
    </li>
    <li>
      En los aplicativos usted podrá encontrar información del software y de nuestros
      productos o servicios, además, en las notificaciones enviadas vía correo electrónico a través
      de “EL SOFTWARE” a usted, o por su conducto a sus clientes. Al usar las funcionalidades
      como facturación por internet, estados de cuenta, o cualquier otra funcionalidad o
      herramienta de COMPACTUM S.A.S donde exista un proceso de comunicación con el
      sistema, podrá recibir información del software y de nuestros productos o servicios. EL
      USUARIO es responsable de la administración y transmisión de los datos personales frente
      a quien replica esta información o cualquier información o datos incluidos en el aplicativo
      o recibidos a través de este servicio.
    </li>
    <li>
      COMPACTUM S.A.S no garantiza que los Servicios cumplirán con sus expectativas o que
      serán adecuados para un propósito particular del usuario. Para evitar dudas, se excluyen
      todas las condiciones o garantías implícitas en la medida en que lo permita la ley, incluidas
      (sin limitación) las garantías de resultados económicos, comerciales, financieros o
      tributarios, garantías por pérdidas que se quieran imputar al servicio (incluida la pérdida de
      información, datos, ganancias y ahorros) o daños resultantes, directa o indirectamente, de
      cualquier uso o dependencia del Servicio o sitio web.
    </li>
    <li>
      Si no está satisfecho con el Servicio, su único y exclusivo recurso es rescindir estos
      términos de conformidad con la Cláusula 7.
    </li>
  </ol>
  <h3>12.- LIMITES DE RESPONSABILIDAD:</h3>
  <p>
    Usted acepta que COMPACTUM S.A.S., no es responsable por daños directos o indirectos
    (incluyendo pérdida de utilidades o de oportunidades de negocio o cualquier tipo de
    responsabilidad por data errónea incompleta o incorrecta), errores u otros agentes nocivos
    que puedan afectar el servicio, pérdidas o interrupción de operaciones presentados en su
    empresa o en su sistema personal que pudieran causarse por el uso, abuso o incapacidad
    de uso de cualquiera de los servicios contratados, incluso si se hubiese notificado a
    COMPACTUM S.A.S. la posibilidad de dichos daños o pérdidas.
  </p>
  <p>
    En caso de cualquier disputa relacionada con los Servicios adquiridos, el valor máximo que
    COMPACTUM S.A.S., reconocerá a usted, no podrá exceder el valor efectivamente pagado por
    usted en el último año por los mismos servicios. COMPACTUM S.A.S., ofrece unos
    niveles de servicio diseñados para satisfacer las expectativas de un servicio idóneo y ha
    diseñado todas sus operaciones con la tecnología, siguiendo los procesos y empleando el
    personal adecuado para su correcto funcionamiento. Sin embargo, COMPACTUM S.A.S., no
    garantiza que en algún momento no se puedan presentar interrupciones del servicio, que
    el sistema estará totalmente libre de virus o posibles ataques de seguridad, ni tampoco por
    fallas en telecomunicaciones, internet, comunicaciones electrónicas, corrupción de
    archivos, pérdida o robo de datos, virus, spyware; entre otras afectaciones, porque el
    funcionamiento y la disponibilidad de los sistemas utilizados para acceder al software o al
    servicio, pueden ser impredecibles y pueden interferir ocasionalmente o impedir el acceso
    a los productos o servicios, tampoco garantiza que el aplicativo o el servicio esta ciento por
    ciento libre de errores, lo que obliga a los usuarios a contar con planes de contingencia
    frente a cualquier interrupción o afectación del servicio, o cualquier evento severo pueda
    afectar o poner en peligro la continuidad del servicio, y deben contar con programas de
    protección del software y equipos, tales como antivirus, vacunas, firewalls, etc., que además
    prevengan riesgos de intrusión y de códigos maliciosos.
  </p>
  <p>
    También, es responsabilidad del USUARIO validar la información y el resultado de las
    operaciones e informes, mediante la verificación de soportes, las auditorías periódicas y/o
    cruces de información que minimicen cualquier error del sistema y/o del USUARIO.
  </p>
  <p>
    El servicio será ofrecido de conformidad con las posibilidades que ofrecen los aplicativos,
    según sea el caso, aplicativos genéricos que declara conocer y aceptar en toda su integridad
    el USUARIO.
  </p>
  <h3>13.- DISPONIBILIDAD</h3>
  <p>
    COMPACTUM S.A.S. y sus asociados proveen sus servicios utilizando tecnologías y
    procedimientos de alta calidad que permiten entregar unos estándares de talla mundial
    para los mismos. Como parte del acuerdo de los servicios suscritos y pagos, usted recibirá
    una disponibilidad de 99%, entendiéndose esta disponibilidad como el tiempo que el
    servicio estará habilitado para el uso general por parte de los usuarios.
  </p>
  <p>
    Son excluidos del cálculo de disponibilidad y de cualquier tipo de garantía los tiempos en
    que el sistema este por fuera de línea debido a:
  </p>
  <ol class="roman">
    <li>
      Suspensiones por fuerza mayor o que hayan sido planeadas e informadas con antelación;
    </li>
    <li>
      Factores ajenos al control de COMPACTUM S.A.S., o sus asociados;
    </li>
    <li>
      Fallas en su hardware, software, servicio de internet, equipos o canal de comunicaciones;
    </li>
    <li>
      Acciones de sus empleados o terceros con su cuenta de acceso y clave, que generen
      alguna indisponibilidad del servicio.
    </li>
    <li>
      Acciones de hackers, malware, virus, cookies, virus defectuosos, etc.;
    </li>
    <li>
      Una mala configuración por su parte del servicio al no seguir las instrucciones provistas por COMPACTUM S.A.S.
    </li>
    <li>
      Interrupciones planificadas (que notificaremos en lo posible con por lo menos 8 horas
      de anticipación a través de los servicios adquiridos), para realizar labores de
      mantenimiento, actualización y/o soporte de los aplicativos o de los servicios, en lo posible
      en horas de la noche o de poco tráfico o días no hábiles;
    </li>
    <li>
      Los Servicios pueden estar sujetos a otras limitaciones como, por ejemplo, límites en
      el espacio de almacenamiento adquirido, volúmenes de transacciones o documentos
      mensuales, caídas del sistema por parte de nuestros proveedores de infraestructura o
      hosting y otros casos no expresamente señalados ahora. En estos casos, si usted requiere,
      puede adquirir un espacio mayor al que habían adquirido inicialmente, asumiendo un costo
      adicional, según las tarifas.
    </li>
  </ol>
  <p>
    Si por algún motivo COMPACTUM S.A.S., tiene que interrumpir los servicios por períodos
    más largos de lo que normalmente esperaría, COMPACTUM S.A.S., hará todos los esfuerzos
    razonables para publicar por adelantado o inmediatamente se presente la causa que nos
    obliga a la suspensión, con los detalles de dicha actividad en el sitio web.
  </p>
  <p>
    A través de nuestros proveedores de hosting mantendremos defensas administrativas,
    físicas y técnicas adecuadas para la protección de la seguridad, confidencialidad e integridad
    de toda la información ingresada a El Software. No obstante, ello no releva al USUARIO de
    contar con sus propios sistemas o dispositivos de seguridad.
  </p>
  <h3>14.- MANTENIMIENTO Y SOPORTE:</h3>
  <p>
    COMPACTUM S.A.S., brindará mantenimiento sobre los aplicativos y soporte sobre estos y
    sobre los servicios adquiridos, en vigencia del periodo contratado, sin costo adicional.
    Brindará en caso de ser adquirido por el usuario, horas de acompañamiento virtual en las
    que el usuario decide el plan de capacitación e implementación a seguir en la puesta en
    marcha del servicio, con cargo al usuario de acuerdo a las tarifas vigentes. Los terceros
    habilitados para ofrecer soporte funcional podrán prestar dichos servicios a los clientes de
    manera independiente, pero en ningún momento podrán hacer actualizaciones o
    intervenciones en los aplicativos, y de todas maneras COMPACTUM S.A.S. no asume
    responsabilidad alguna por los mismos ni por la legalidad de las aplicaciones que usen.
  </p>
  <p>
    Todos los servicios que ofrece COMPACTUM S.A.S se brindan dentro de los horarios del call
    center (lunes a viernes hábiles de 8:30 a.m. a 5:00 pm), de manera virtual o remota.
  </p>
  <p>
    Los usuarios contarán además con acceso al portal de clientes, a través de la página web
    del aplicativo (<a href="https://www.rentappi.com" target="_blank">rentappi.com</a>), por los que podrá acceder a capacitaciones en línea,
    videos tutoriales, etc.
  </p>
  <p>
    Tanto los horarios de servicio como las funcionalidades del portal de clientes podrán ser
    modificados en cualquier momento, sin previo aviso, pero COMPACTUM S.A.S hará su
    mejor esfuerzo para que los usuarios tengan acceso a la información de estas
    modificaciones.
  </p>
  <h3>15.- ACTUALIZACIONES:</h3>
  <p>
    COMPACTUM S.A.S., no se obliga a hacer actualizaciones, o mejoras sobre los aplicativos,
    de manera que el servicio se prestará dentro de los alcances y limitaciones que ofrece
    actualmente. Las sugerencias que usted haga serán tenidas en cuenta, pero no obligan a
    COMPACTUM S.A.S. a adoptarlas. Los cambios de ley, en cuanto apliquen a la generalidad
    de USUARIOS podrán incorporarse a los aplicativos, pero dentro de los plazos que defina
    COMPACTUM S.A.S según sus planes de desarrollo de software genérico.
  </p>
  <p>
    En cualquier actualización de El Software o del Servicio, COMPACTUM S.A.S., podrá
    introducir herramientas, utilidades, mejoras, aplicaciones de terceros o actualizaciones
    generales para mejorarlos o estandarizarlos.
  </p>
  <h3>16.- CESION:</h3>
  <p>
    La suscripción de los servicios se hace en consideración a la naturaleza misma del USUARIO,
    por lo tanto, no podrán ser cedidos, vendidos o transferidos a cualquier título, sin la previa
    autorización expresa y por escrito de COMPACTUM S.A.S., qué con todo, no está obligado.
    El Aplicativo, por ser un software y por ello un bien amparado por la Ley de derechos de
    autor es para uso exclusivo del USUARIO y tampoco será lícita su enajenación, transferencia,
    préstamo o cesión a cualquier título en favor de un tercero.
  </p>
  <p>
    COMPACTUM S.A.S., no tiene ninguna obligación con ninguna persona que no sea EL
    USUARIO y nada en este documento confiere, o pretende conferir, un beneficio a ninguna
    persona que no sea EL USUARIO. Si EL USUARIO o un tercero, con autorización de este,
    utiliza el software o los servicios, EL USUARIO es el único responsable del acceso que le
    brinde y del acceso de estos a la información o datos vinculados al Software o al servicio,
    así como de la eventual infracción al presente contrato y las consecuencias de la misma.
  </p>
  <h3>17.- CONFIDENCIALIDAD</h3>
  <p>Cada parte conservará la confidencialidad de toda la Información de la otra obtenida en
    relación con estos términos. Ninguna de las partes, sin el previo consentimiento por escrito
    de la otra parte, divulgará o pondrá a disposición de ninguna persona información
    confidencial, ni la utilizará para su propio beneficio, salvo lo previsto en estos Términos.
  </p>
  <p>
    La información que usted provea a COMPACTUM S.A.S., en el momento de la suscripción y
    aquella que se ingrese a los aplicativos quedará almacenada en el sistema y será tratada
    como información confidencial, sin perjuicio de la posibilidad de uso por parte de
    COMPACTUM S.A.S., de dicha información conforme se prevé en este mismo contrato. La
    utilización de la información por parte de COMPACTUM S.A.S, en los términos previstos en
    este contrato, no será considerada como una transgresión a la obligación de
    confidencialidad a cargo de COMPACTUM S.A.S., por lo que el USUARIO no tendrá ninguna
    acción o derecho de reclamación en su contra por tal concepto. Sin embargo, usted es
    responsable de mantener la privacidad de su cuenta de usuario incluyendo la contraseña y
    claves de acceso, y de proveer los mecanismos de seguridad de la información en sus
    propios equipos o dispositivos
  </p>
  <p>
    Las obligaciones de cada parte bajo esta cláusula sobrevivirán a la terminación de los
    servicios adquiridos y hasta por un (1) año más.
  </p>
  <h3>18.- NOTIFICACIONES</h3>
  <p>
    Para todos los efectos relacionados con el presente documento y/o con el servicio, para las
    notificaciones y/o comunicaciones las partes acuerdan señalar como dirección la que
    aparece en el certificado de existencia y representación legal de la oficina principal, y en
    defecto de este por parte de EL USUARIO la dirección de facturación, salvo que se informe
    un cambio de dirección o correo electrónico diferente por EL USUARIO. Para todos los
    efectos legales, el domicilio contractual será la ciudad de Bogotá, D.C.
  </p>
  <h3>19.- DATOS PERSONALES</h3>
  <p>
    La información que el USUARIO puede ingresar a El Software puede corresponder a
    información corporativa, y eventualmente, a datos personales respecto de los cuales el
    USUARIO actúa como responsable del tratamiento, y sobre los cuales COMPACTUM S.A.S.,
    actuará como encargado en los términos de las condiciones de transmisión que se regulan
    más adelante. El USUARIO es el único propietario de los datos personales que ingrese o
    genere en El Software. En consecuencia, el USUARIO tendrá entera responsabilidad de
    administrar, publicar, eliminar o mantener los datos personales según sus propios criterios
    y políticas y en ningún momento COMPACTUM S.A.S., actuará como responsable de esos
    datos personales almacenados en El Software.
  </p>
  <p>
    Los USUARIOS de El Software deberán contar
    con las autorizaciones necesarias para tratar de cualquier modo y transmitir a COMPACTUM
    S.A.S., los datos personales de terceros que ingrese en El Software para efectos de la
    ejecución y el cumplimiento de este contrato. COMPACTUM S.A.S., guardará la
    confidencialidad de los datos personales de LOS USUARIOS y no los compartirá ni entregará
    a terceros. Con respecto a la información corporativa que el USUARIO ingrese a El Software,
    y que no corresponda a datos personales, el USUARIO expresamente autoriza a COMPACTUM S.A.S.,
    para que pueda conocer, efectuar análisis estadísticos y de mercado y
    eventualmente compartir con terceros dicha información corporativa para que
    COMPACTUM S.A.S., o esos terceros puedan ofrecer servicios propios a el USUARIO. Lo
    anterior aplicará incluso en los eventos en que el USUARIO no haya adquirido los servicios
    de mantenimiento, soporte y actualizaciones respecto de El Software. El USUARIO autoriza
    de manera permanente e irrevocable a COMPACTUM S.A.S., o a quien represente sus
    derechos o sucesores a cualquier título, y/o a quien ostente la calidad de acreedor del
    USUARIO, para qué con fines financieros, crediticios y comerciales, consulte, mantenga y
    reporte a cualquier Operador de bases de datos de información financiera, crediticia y
    comercial, tales como CIFIN y DATACREDITO, la información relativa al comportamiento
    comercial, financiero y crediticio del USUARIO.
  </p>
  <h3>20.- UNIDAD</h3>
  <p>
    El presente es el único contrato válido y de común acuerdo otorgado entre las partes y deja
    sin efecto cualquier acuerdo anterior expreso o tácito, verbal o escrito. No obstante, en
    caso de adición de usuarios, módulos, conversiones, y renovaciones, no será necesario el
    otorgamiento de un nuevo contrato u OTROSI. Bastará con la factura correspondiente, la
    cual se sujetará a los términos del presente contrato, salvo que haya vencido la vigencia por
    la cual se pactó, caso en el cual regirán las condiciones que obran en el contrato publicado
    en las páginas web de COMPACTUM S.A.S, <a href="http://compactum.com.co" target="_blank">compactum.com.co</a> y/o <a href="https://www.rentappi.com" target="_blank">rentappi.com</a>.
  </p>
  <p>
    COMPACTUM S.A.S., hace constar que este mismo texto está incluido en la página web
    <a href="http://compactum.com.co" target="_blank">compactum.com.co</a>, donde el USUARIO efectuó su compra, al igual que en el aplicativo al
    momento de habilitación del servicio, teniendo en cuenta que, como ya quedó dicho, para
    la utilización de El Software y acceder al servicio EL USUARIO debe aceptar los términos y
    condiciones del presente documento; razón por la cual usted debe revisar estos términos
    minuciosamente antes de dar la aceptación. Se entenderá que EL USUARIO ha aceptado los
    términos de este contrato si hace uso de los servicios de “EL SOFTWARE”. En caso de no
    aceptar los términos y condiciones del servicio, EL USUARIO no debe hacer uso de los
    aplicativos ni de los servicios. Si celebra este contrato en representación de una persona
    natural o jurídica distinta a quien da la aceptación, COMPACTUM S.A.S de buena fe
    entenderá que quien da la aceptación tiene la facultad de obligar a dicha persona o entidad,
    y que se aceptan estos términos y condiciones sin reserva. Si no tiene dicha facultad o si no
    está de acuerdo con estos términos y condiciones, no debe aceptar este contrato y no debe
    utilizar los servicios de COMPACTUM S.A.S. De la misma manera se deberá proceder si la
    persona que dio la aceptación no está facultada. Los usuarios que tengan acceso a los
    aplicativos o al servicio también tienen que aceptar previamente las presentes condiciones
    sus adendas y modificaciones, para tener acceso a los aplicativos o al servicio. En uno u otro
    caso, la falta de aviso, supone la vinculación de las partes en relación con estas condiciones.
  </p>
  <h3>21. CONDICIONES DE LA TRANSMISIÓN DE DATOS</h3>
  <p>
    En virtud del presente contrato y a la normativa aplicable, el USUARIO, en calidad de responsable
    del tratamiento, transmitirá datos personales a COMPACTUM S.A.S., quien, para efectos de esos datos personales,
    actuará como Encargado del tratamiento. El alcance de la operación de transmisión será el
    cumplimiento de este contrato y las obligaciones legales y contractuales que resulten a
    partir de la relación que existe entre el USUARIO y COMPACTUM S.A.S., Las actividades que
    realizará el Encargado por cuenta del Responsable serán:
  </p>
  <ol class="roman">
    <li>aquellas dispuestas en este contrato,</li>
    <li>
      cumplir con gestiones administrativas, operativas, comerciales, técnicas a
      cargo del COMPACTUM S.A.S. sobre los Datos Personales del Responsable que están a cargo
      del Encargado
    </li>
    <li>
      operaciones necesarias para el cumplimiento de cualquier otra finalidad
      relacionada con el objeto o las obligaciones establecidas para las partes, y
    </li>
    <li>
      para el cumplimiento de obligaciones legales que pudiesen ocasionarse en virtud de
      la relación entre las partes.
    </li>
  </ol>
  <p>
    <b>PARÁGRAFO PRIMERO.</b> COMPACTUM S.A.S., está obligado frente al
    titular y el Responsable de cumplir con sus obligaciones como Encargado del tratamiento,
    definidas en la Ley 1581 de 2012 y normas complementarias. Así mismo, el Encargado se
    comprometerá a dar aplicación a las obligaciones que resulten aplicables de acuerdo con la
    Política de Tratamiento de la información fijada por el responsable, situación que está
    sujeta a que el responsable entregue al Encargado copia de dicha Política.
  </p>
  <p>
    El Encargado se compromete a realizar el Tratamiento de datos de acuerdo con la finalidad que los Titulares
    hayan autorizado, cuando aplique, y con las leyes aplicables.
  </p>
  <p>
    <b>PARÁGRAFO SEGUNDO.</b> COMPACTUM S.A.S., se compromete a dar Tratamiento, a nombre del Responsable, a los
    datos personales conforme a los principios que los tutelan y salvaguardar la seguridad de
    las bases de datos en los que se contengan datos personales.
  </p>
  <p>
    <b>PARÁGRAFO TERCERO.</b> - El Usuario se comprometerá a:
  </p>
  <ol class="roman">
    <li>
      haber obtenido, cuando hubiese lugar para ello,
      autorización de los titulares para el tratamiento de sus datos personales de acuerdo a los
      requisitos exigidos por la normativa aplicable
    </li>
    <li>
      garantizar a los Titulares, mediante los
      mecanismos dispuestos legalmente para tal fin, el ejercicio de los derechos que les
      corresponden como Titulares de Datos Personales,
    </li>
    <li>
      informar al Encargado de cualquier evento que pudiese afectar la operación de tratamiento
      a la que se refiere el presente contrato
    </li>
    <li>
      actualizar en el Software los datos personales que sean objeto de tratamiento,
      o en su defecto informar de forma oportuna al Encargado de cualquier cambio o ajuste que
      deba hacerse sobre la información contenida en las bases de datos necesarias para ejecutar
      el presente contrato, y
    </li>
    <li>
      cumplir y acatar en su integridad las respectivas obligaciones de acuerdo al mencionado rol de Responsable
      conforme a la Ley 1581.
    </li>
  </ol>
  <p><b>Contrato Vigente a partir de: 15 de marzo de 2022.</b></p>

<!--
  <a href="http://compactum.com.co" target="_blank">compactum.com.co</a>
  <a href="https://www.rentappi.com" target="_blank">rentappi.com</a>
-->




</mat-dialog-content>

<mat-dialog-actions align="end">
  <app-button
  [icon]="'check_rounded'"
  [text]="'Entiendo'"
  [idButton]="'signUp'"
  [clase]="'btn_primary'"
  [mat-dialog-close]="true"
  >
  </app-button>
</mat-dialog-actions>
