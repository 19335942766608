import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { NotifyService } from '@app/shared/services/notify.service';
import { EsfService } from '@app/pages/formato2516/services/esf.service';
import * as actionsRegistroESF from '../actions/esf.actions';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { RegistroESF } from '@app/pages/formato2516/components/esfpatrimonio/model/esf';
import { ESFRegisterStore } from '../models/esf.register.model';

@Injectable()
export class RegistroESFEffects {
  getRegistroESF$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsRegistroESF.getEsf),
      switchMap((action) => {
        return this.esfService.getEsf(action.id).pipe(
          map((esfRegistro: RegistroESF[]) => {
            const registroESFEntitySaved: ESFRegisterStore = {
              resgistrosESF: esfRegistro,
              projectId: action.id
            }
            return actionsRegistroESF.getEsfSuccess({
             resgiterEsf: registroESFEntitySaved
            });
          }),
          catchError((error) => {
            this.notify.error(
              'Algo salio mal! ',
              'No pudimos cargar la información del contribuyente',
              4000
            );
            return of(actionsRegistroESF.getEsfFail({ error }));
          })
        );
      })
    )
  );

  setRegistroESF$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsRegistroESF.setEsf),
      switchMap((action) => {
        return this.esfService.guardarESF(action.payload, action.id).pipe(
          map((resgiterEsfSaved: RegistroESF[]) => {
            const registroESFEntitySaved: ESFRegisterStore = {
              resgistrosESF: resgiterEsfSaved,
              projectId: action.id
            }
            return actionsRegistroESF.setEsfSuccess({
              resgiterEsfSaved: registroESFEntitySaved
            });
          }),
          catchError((error) => {
            this.notify.error(
              'Algo salio mal! ',
              'No pudimos guardar la infromación del contribuyente',
              4000
            );
            return of(actionsRegistroESF.setEsfFail({ error }));
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private notify: NotifyService,
    private esfService: EsfService
  ) {}
}
