import { createReducer, on, Action } from '@ngrx/store';
import { getCurrentUser, getCurrentUserSuccess, getCurrentUserError, setFormLogin, setFormLoginSuccess, setFormLoginError, setFormRegister, setFormRegisterSuccess, setFormRegisterError, setLogoutSuccess} from '@auth/actions';
import { AuthState } from '@auth/states';

export const initialState: AuthState = ({
  isLoading: true,
  isLogged: null,
  payload: null,
  client: null,
  error: null,
  //globalTpm: null
});

const authReducer = createReducer(
  initialState,
//currentUser
  on(getCurrentUser, (state, { }) => {
    return { ...state, isLoading: true };
  }),
  on(getCurrentUserSuccess, (state, { user }) => {
    return { ...state, payload: null, client: user, isLogged: true, isLoading: false };
  }),
  on(getCurrentUserError, (state, { error }) => {
    return { ...state, payload: null, client: null, isLogged: false, isLoading: false, error };
  }),
//formLogin
  on(setFormLogin, (state, { }) => {
    return { ...state, isLoading: true };
  }),
  on(setFormLoginSuccess, (state, { user }) => {
    return { ...state, isLoading: false, payload: user.data, isLogged: true, error: null };
  }),
  on(setFormLoginError, (state, { error }) => {
    return { ...state, payload: null, client: null, isLogged: false, isLoading: false, error };
  }),
//formRegister
  on(setFormRegister, (state, { }) => {
    return { ...state, isLoading: true };
  }),
  on(setFormRegisterSuccess, (state, { user }) => {
    return { ...state, isLoading: false, payload: user.data, isLogged: true, error: null };
  }),
  on(setFormRegisterError, (state, { error }) => {
    return { ...state, payload: null, client: null, isLogged: false, isLoading: false, error };
  }),

  //logout
  on(setLogoutSuccess, (state, { }) => {
    return { ...state, payload: null, client: null, isLogged: false, isLoading: false };
  }),
);

export function reducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}
