import { TarifaDepuracion } from "@app/pages/depuracion/models/registro-depuracion";
import { CellStyleModel } from "@syncfusion/ej2-angular-spreadsheet";
import { NumberFormat } from "xlsx/types";
export interface RentaLiquida {
    id: number
    renglon?: number;
    entity2516?: number;
    entity2516Dian?: number;
    description: string;
    descGroup?: string;
    type: string;
    parentId?: number;
    total?: boolean;
    totalId?: number;
}

export interface RegistroERI {
    id: NumberFormat | number
    efConversion?: number;
    valorContable?: number;
    mayorValor?: number;
    menorValor?: number;
    saldoFiscal?: number;
    negEfConversion?: boolean;
    negValorContable?: boolean;
    negMayorValor?: boolean;
    negMenorValor?: boolean;
    negSaldoFiscal?: boolean;
    renta: RentaLiquida;
    tarifas?: TarifaDepuracion[];
    modificado?: boolean
}

export interface ERIstore {
  id: number,
  registros: RegistroERI[]
}

export interface CabecerasTarifas {
  value: string;
  style: CellStyleModel,
  isLocked: boolean
}

export function vector(inicio: number, fin: number) {
  let vector: number[] = []
  while (inicio <= fin) {
      vector.push(inicio);
      inicio++;
  }
  return vector;
}

export const SUMAS: number[] = [
  ...vector(13, 34), 36, 38,
  ...vector(40, 46),
  ...vector(50, 57), ,
  ...vector(59, 63),
  ...vector(65, 72),
  ...vector(74, 81),
  ...vector(83, 94),
  ...vector(96, 102),
  ...vector(104, 107),
  ...vector(109, 114),
  ...vector(126, 141),
  ...vector(143, 146),
  ...vector(148, 171),
  ...vector(173, 183),
  ...vector(190, 193),
  ...vector(195, 214),
  ...vector(216, 239),
  ...vector(242, 245),
  ...vector(247, 269),
  ...vector(271, 294),
  ...vector(296, 306),
  ...vector(311, 315),
  ...vector(316, 325),
  ...vector(327,333),
  ...vector(335,338),
  ...vector(466,472),
  ...vector(474,475)];

export const SIN_FISCAL: number[] = [
  48,49,115,308,309,340,386,387,359,348,349,347,
  ...vector(562,567)
];

export const SIN_CONTABLE: number[] = [
  184,185,339,341,
  ...vector(350,390),347,348,349,481,482,
  ...vector(485,544)
];

export const PRINCIPALES_TARIFAS=[497,496,469,470,451,452,334,335];
