import { BalanceCompleto } from '@app/pages/info-contribuyente/components/balance/balance-completo';
import { createAction, props } from '@ngrx/store';

import { ProjectStatus } from '../models/project-status.model';

export const GET_PROJECT_STATUS = '[project status] get project status';
export const GET_PROJECT_STATUS_SUCCESS = '[project status] get project status success';
export const GET_PROJECT_STATUS_ERROR = '[project status] get project status with error';

export const SET_PROJECT_STATUS = '[project status] set status project status';
export const SET_PROJECT_STATUS_SUCCESS = '[project status] set status project status success';
export const SET_PROJECT_STATUS_ERROR = '[project status] set status project status with error';

export const SET_PROJECT_STATUS_INITIAL_STATE = '[project status] set status project initial state';
export const SET_PROJECT_STATUS_INITIAL_STATE_SUCCESS = '[project status] set status project initial state success';
export const SET_PROJECT_STATUS_INITIAL_STATE_ERROR = '[project status] set status project initial state with error';

export const UPDATE_PROJECT_STATUS = '[project status] update project status';
export const UPDATE_PROJECT_STATUS_SUCCESS = '[project status] update project status success';
export const UPDATE_PROJECT_STATUS_ERROR = '[project status] update project status with error';

//get status project
export const getProjectStatus = createAction(
  GET_PROJECT_STATUS,
  props<{ projectId?: number, balance?: BalanceCompleto  }>()
);

export const getProjecStatusSuccess = createAction(
  GET_PROJECT_STATUS_SUCCESS,
  props<{ projectStatus: ProjectStatus }>()
);

export const getProjectStatusError = createAction(
  GET_PROJECT_STATUS_ERROR,
  props<{ error: string }>()
);

//set status project
export const setStatusProject = createAction(
  SET_PROJECT_STATUS,
  props<{ projectStatus: ProjectStatus }>()
);

export const setStatusProjectSuccess = createAction(
  SET_PROJECT_STATUS_SUCCESS,
  props<{ projectStatus: ProjectStatus }>()
);

export const setStatusProjectError = createAction(
  SET_PROJECT_STATUS_ERROR,
  props<{ error: string }>()
);

//set initial state project
export const setStatusProjectInitialState = createAction(
  SET_PROJECT_STATUS_INITIAL_STATE,
  props<{ projectId: number }>()
);

export const setStatusProjectInitialStateSuccess = createAction(
  SET_PROJECT_STATUS_INITIAL_STATE_SUCCESS,
  props<{ projectStatus: ProjectStatus }>()
);

export const setStatusProjectInitialStateError = createAction(
  SET_PROJECT_STATUS_INITIAL_STATE_ERROR,
  props<{ error: string }>()
);

//update status project
export const updateStatusProject = createAction(
  UPDATE_PROJECT_STATUS,
  props<{ projectStatus: ProjectStatus }>()
);

export const updateStatusProjectSuccess = createAction(
  UPDATE_PROJECT_STATUS_SUCCESS,
  props<{ projectStatus: ProjectStatus }>()
);

export const updateStatusProjectError = createAction(
  UPDATE_PROJECT_STATUS_ERROR,
  props<{ error: string }>()
);


