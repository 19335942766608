import { Component, OnInit } from '@angular/core';
import { selectCurrentRoute } from '@app/reducers';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { routesHelp, ROUTES_HELP } from './help_list';

export interface ButtonTool {
  idButton: string;
  class: string;
  icon: string;
  message: string;
}

@Component({
  selector: 'app-help-buttom',
  templateUrl: './help-buttom.component.html',
  styleUrls: ['./help-buttom.component.scss'],
})
export class HelpButtomComponent implements OnInit {
  public projectId: number = 0;
  public showHelp: boolean;
  public urlVideo: string = '';

  public routeSubs$: Observable<any>;
  public route: string;

  constructor(private store: Store<any>) {}

  ngOnInit(): void {
    this.connectToRoute();
  }

  private connectToRoute() {
    this.routeSubs$ = this.store.pipe(
      select(selectCurrentRoute),
      map((route) => route.url)
    );

    this.routeSubs$.subscribe((route) => {
      const url = route.split('/');

      if (url[1] === 'proyecto') {
        if (url[3] === 'f110') {
          this.setVideoHelp('f110');
        } else {
          this.route = url[3] + url[4];
          const urlFilter = url[3] + '/' + url[4];
          this.projectId = Number(url[2]);
          this.setVideoHelp(urlFilter);
        }
      } else {
        if (url[1] === 'home') {
          this.route = url[1];
          const urlFilter = '/' + url[1];
          this.projectId = 0;
          this.setVideoHelp(urlFilter);
        } else {
          this.route = url[1] + url[2];
          const urlFilter = url[1] + '/' + url[2];
          this.projectId = 0;
          this.setVideoHelp(urlFilter);
        }
      }
    });
  }

  setVideoHelp(url: string) {
    const urlHelp: routesHelp = ROUTES_HELP.find(
      (routing: routesHelp) => routing.route === url
    );
    this.showHelp = urlHelp.showHelp;
    this.urlVideo = urlHelp.urlVideo;
  }

  help() {
    if (this.urlVideo !== '') {
      window.open(this.urlVideo, '_blank');
    }
  }
}
