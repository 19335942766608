import { createReducer, on, Action } from '@ngrx/store';
import { Seccion3GmfState, seccion3GmfAdapter } from '@store/states';
import { getSeccion3GmfSuccess, setSeccion3GmfSuccess,  setDeleteSeccion3GmfSuccess } from '@store/actions/seccion3-gmf.actions';

export const initialState: Seccion3GmfState = seccion3GmfAdapter.getInitialState({})

const seccion3GmfReducer = createReducer(
  initialState,

  on(getSeccion3GmfSuccess, (state, { seccion3GmfSuccess }) => {
    state.ids = [];
    state.entities = {};
    return seccion3GmfAdapter.upsertOne(seccion3GmfSuccess, { ...state })
  }),

  on(setSeccion3GmfSuccess, (state, { seccion3GmfForm }) => {
    state.ids = [];
    state.entities = {};
    return seccion3GmfAdapter.upsertOne(seccion3GmfForm, state)
  }),

  on(setDeleteSeccion3GmfSuccess, (state, { seccion3Gmf }) => {
    return seccion3GmfAdapter.removeOne(seccion3Gmf.id, state)
  }),
)

export function reducer(state: Seccion3GmfState | undefined, action: Action) {
  return seccion3GmfReducer(state, action);
}
