import { createAction, props } from '@ngrx/store';
import { AnticipoRenta } from '../models';

export const GET_ANTICIPORENTA = '[anticipoRenta] get anticipoRenta';
export const GET_ANTICIPORENTA_SUCCESS = '[anticipoRenta] get anticipoRenta success';
export const GET_ANTICIPORENTA_ERROR = '[anticipoRenta] get anticipoRenta with error';

export const SET_ANTICIPORENTA = '[anticipoRenta] set anticipoRenta save info';
export const SET_ANTICIPORENTA_SUCCESS = '[anticipoRenta] set anticipoRenta save info success';
export const SET_ANTICIPORENTA_ERROR = '[anticipoRenta] set anticipoRenta save info with error';


export const getAnticipoRenta = createAction(
  GET_ANTICIPORENTA,
  props<{projectId: number}>()
);

export const getAnticipoRentaSuccess = createAction(
  GET_ANTICIPORENTA_SUCCESS,
  props<{ anticipoRentaSuccess: AnticipoRenta  }>()
);

export const getAnticipoRentaError = createAction(
  GET_ANTICIPORENTA_ERROR,
  props<{ error: string }>()
);

export const setAnticipoRenta = createAction(
  SET_ANTICIPORENTA,
  props<{ anticipoRentaForm: AnticipoRenta, projectId: number }>()
);

export const setAnticipoRentaSuccess = createAction(
  SET_ANTICIPORENTA_SUCCESS,
  props<{ anticipoRentaForm: AnticipoRenta }>()
);

export const setAnticipoRentaError = createAction(
  SET_ANTICIPORENTA_ERROR,
  props<{ error: string }>()
);
