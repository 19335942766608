import { Injectable } from "@angular/core";
import { DescuentoTributarioService } from "@app/pages/anex-depuracion/services/descuento-tributario.service";
import { MSG_DURATION, NotifyService } from "@app/shared/services/notify.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { getCalculoDescuento } from "../actions/calculo-descuento.actions";
import { getImpuestoExterior, getImpuestoExteriorError, getImpuestoExteriorSuccess, setDeleteImpuestoExterior, setDeleteImpuestoExteriorError, setDeleteImpuestoExteriorSuccess, setImpuestoExterior, setImpuestoExteriorSuccess } from "../actions/impuesto-exterior.actions";
import { ImpuestoExteriorDTO } from "../models/descuento-tributario.model";

@Injectable()
export class ImpuestoExteriorEffects {
  getImpuestoExterior$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getImpuestoExterior),
      switchMap(({ projectId }) => {
        return this.descTService.getImpuestosExterior(projectId).pipe(
          map((impuestoExterior) => {
            let impuestoExteriorSuccess: ImpuestoExteriorDTO = {
              idProject: projectId,
              ...impuestoExterior,
            };
            return getImpuestoExteriorSuccess({ impuestoExteriorSuccess });
          }),
          catchError((error) => {
            this.notify.error('Algo salio mal!', JSON.stringify(error), 5000, {
              text: 'Entendido',
              orientation: 'izq',
            });
            return of(getImpuestoExteriorError({ error }));
          })
        );
      })
    )
  );

  saveImpuestoExterior$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setImpuestoExterior),
      switchMap(({ impuestoExteriorForm, projectId }) => {
        return this.descTService
          .saveImpuestoExterior(impuestoExteriorForm,projectId)
          .pipe(
            map((impuestoExterior:ImpuestoExteriorDTO) => {
               let impuestoExteriorForm: ImpuestoExteriorDTO = {
              idProject: projectId,
              ...impuestoExterior
            };
              this.notify.success(
                '',
                'Guardado de registros exitoso',
                MSG_DURATION
              );
              this.store.dispatch(getCalculoDescuento({projectId}));
              return setImpuestoExteriorSuccess({ impuestoExteriorForm:impuestoExteriorForm });
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setDeleteImpuestoExteriorError({ error }));
            })
          );
      })
    )
  );

  deleteImpuestoExterior$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDeleteImpuestoExterior),
      switchMap(({ projectId, idImpuestoExterior,totales }) => {
        return this.descTService
          .deleteImpuestoExterior(projectId, idImpuestoExterior,totales)
          .pipe(
            map((impuestoExteriorForm) => {
              this.notify.success(
                '',
                'Borrado de registros exitoso',
                MSG_DURATION
              );
              this.store.dispatch(getCalculoDescuento({projectId}));
              return setDeleteImpuestoExteriorSuccess({impuestoExteriorForm} );
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setDeleteImpuestoExteriorError({ error }));
            })
          );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private descTService: DescuentoTributarioService,
    private store: Store<any>,
    private notify: NotifyService
  ) {}
}
