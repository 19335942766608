import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../services/spinner.service';
@Component({
  selector: 'app-spinner',
  templateUrl : './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit  {

  isLoader$ = this.spinner.isLoader$; //true es loader, false is percent

  isLoading$ = this.spinner.isLoading$;
  isMessage$ = this.spinner.isMessage$;
  isPercent = this.spinner.isPercent$;
  isDeterminate$ = this.spinner.isDeterminate$;


  constructor(
    private spinner: SpinnerService
  ) {}
ngOnInit(): void {
  
}
}
