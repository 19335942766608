import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AjustesDepuracion,
  AjustesDepuracionProject,
  AjustesDepuracionTotales,
  ResponseDeleteAJusteDepuracion,
  Retenciones,
  RetencionesProject,
  TotalRetenciones
} from '@patrimoniales/store/models/retefuente.models';

@Injectable({
  providedIn: 'root'
})

export class RetencionesService {

  constructor(private http: HttpClient) { }
    //GET
    getSector(projectId: number) {
      return this.http.get<string>(`/retenciones/getSector?id=${ projectId }`)
    }

    getRetenciones(projectId: number) {
      return this.http.get<RetencionesProject>(`/retenciones/getRetenciones?id=${ projectId }`)
    }

    getAjustesDepuracion(projectId: number) {
      return this.http.get<AjustesDepuracionProject>(`/retenciones/getAjustes?id=${ projectId }`)
    }
    //POST
    saveRetenciones(
      projectId: number,
      retenciones: Retenciones[],
      totales: TotalRetenciones){

      const retencionPost = {
        retenciones: retenciones,
        totalRetenciones: totales
      }

      return this.http.post<RetencionesProject>(`/retenciones/saveRetenciones?id=${ projectId }`, retencionPost)
    }

    saveAjustesDepuracion(
      projectId: number,
      ajustesDep: AjustesDepuracion[],
      totales: AjustesDepuracionTotales){

      const depuracionPost = {
        ajustesDepuracion: ajustesDep,
        totales: totales
      }

      return this.http.post<AjustesDepuracionProject>(`/retenciones/saveAjustes?id=${ projectId }`, depuracionPost)
    }

    //DELETE
    deleteRetenciones(
      projectId: number,
      retenciones: Retenciones[],
      totales: TotalRetenciones){

      const retencionPost = {
        retenciones: retenciones,
        totalRetenciones: totales
      }

      return this.http.request<RetencionesProject>(
        'delete',
        `/retenciones/deleteRetenciones?id=${ projectId }`,
        { body: retencionPost }
      );
    }

    deleteAjusteDepuracion(
      projectId: number,
      ajuste: AjustesDepuracionProject
    ){
      return this.http.request<AjustesDepuracionProject>(
        'delete',
        `/retenciones/deleteAjuste?id=${projectId}`,
        { body: ajuste }
      );
    }


    deleteAll(id: number,){
      return this.http.delete<{}>(`/retenciones/deleteAll?id=${ id }`)
    }
}
