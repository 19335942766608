import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {BehaviorSubject, combineLatest, EMPTY, Observable} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';

import {ListProyectosService} from '@proyectos/services/list-proyectos.service';

import {MathDepLevel, RegistroCompleto, RegistroDepuracion} from '../models/registro-depuracion';
import {Renglon2516, RenglonRenta} from '../models/renglon';
import {Resumen} from '../models/resumen';
import { SimpleStatus } from '@app/store/models/project-status.model';

@Injectable({
  providedIn: 'root'
})
export class DepuracionService {

  private currentDepListSubj$ = new BehaviorSubject<RegistroDepuracion[]>([]);
  currentDepListAction$ = this.currentDepListSubj$.asObservable();

  currentDepList: RegistroDepuracion[];
  efectEnable = false;

  private typeSelectedSubj$ = new BehaviorSubject<string>('AFECTACION_FISCAL');
  typeSelectedAction$ = this.typeSelectedSubj$.asObservable();

  set currentDepList$(registrosDep: RegistroDepuracion[])   {
    this.currentDepList = registrosDep;
    this.currentDepListSubj$.next(registrosDep);
  }

  filteredList$ = combineLatest([
    this.typeSelectedAction$,
    this.currentDepListAction$
  ]).pipe(
    map(([filter, registros]) => registros.filter( registro => registro.type === filter) )
  );

  constructor(
    private http: HttpClient,
    private projectSrv: ListProyectosService
  ) { }

  levels$ = (level: number) => this.http.get<string[]>(`/depuracion/niveles?id=${ level }`);

  regDepuracion$ = (projectId: number) => this.nivelActual$(projectId).pipe(
    switchMap(
      level => level ? this.getDepuracion(projectId, level.nivel) : EMPTY
    ),
    tap(
      depList => {
        this.efectEnable = depList.conversionEnabled;
        this.currentDepList = depList.list;
        this.currentDepListSubj$.next(depList.list);
      }
    )
  )

  getDepuracion = (projectId: string | number, level: string) => this.http.get<RegistroCompleto>(`/depuracion?projectId=${projectId}&nivel=${ level }`);

  renglones$ = (clase: number, projectId: number) => this.http.get<RenglonRenta[]>(`/options/accountClass?clase=${clase}&projectId=${projectId}`);

  renglones2516$ = (renglon: number, projectId: number, clase: number) => this.http.get<Renglon2516[]>(`/options/renDesc?renglon=${renglon}&clase=${clase}&projectId=${projectId}`);

  actualizarDepuracion(registro: RegistroCompleto): Observable<any> {
    return this.http.post('/depuracion', registro);
  }

  resumen$ = (projectId: number) => this.http.get<Resumen[]>(`/depuracion/summary?projectId=${projectId}`);

  onFilterChange(filter: string): void {
    this.typeSelectedSubj$.next(filter);
  }

  deleteRenglon(renglonId: number,id:number): Observable<any>{
    this.currentDepList$ = this.currentDepList.filter(registro => registro.id !== renglonId);
    return this.http.delete<any>(`/afectacion_fiscal?id=${renglonId}&project=${id}`);
        
  }

  nivelActual$ = (projectId: number) => this.http.get<MathDepLevel>(`/depuracion/project_nivel_depuracion?projectId=${projectId}`);

  checkDepLevel(level: string): Observable<MathDepLevel> {
    return this.projectSrv.currentProject$.pipe(
      switchMap(
        project => this.http.get<MathDepLevel>(`/depuracion/nivel_depuracion?projectId=${project.id}&nivel=${level}`)
      )
    );
  }

}
