<div class="dropdown dropdown-custom-styles">
    
    <button 
        class="button-notification position-relative" 
        id="drop-down-notifications"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        >
        <i 
            class="fas fa-bell" 
            style="color: blue;"
        ></i>
        <span
            style="font-size: 9px;" 
            class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
            >1
            <span class="visually-hidden">Notificaciones sin leer</span>
        </span>
    </button>
    
    <ul 
        class="dropdown-menu dropdown-menu-custom" 
        aria-labelledby="drop-down-notifications"
    >
        <li class="message-box" *ngFor="let message of messages; index as i">
            <div>
                <div *ngIf="message.link !== ''; else noLink">
                    <a 
                        class="link-style text" 
                        *ngIf="message.link" 
                        [href]="message.link"
                        target="_blank"    
                    >{{message.message}}</a>
                    <p style="font-size: 10px;" class="text" *ngIf="message.createdAt">{{message.createdAt}}</p>
                </div>
                <ng-template #noLink>
                    <p class="text">{{message.message}}</p>
                    <p style="font-size: 10px;" class="text" *ngIf="message.createdAt">{{message.createdAt}}</p>
                </ng-template>
            </div>
            <div class="d-flex justify-content-start align-items-center">
                <button (click)="deleteMessage(i)" class="close-button">X</button>
            </div>
        </li>
    </ul>
</div>
