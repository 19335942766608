import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ElementRef,
  ViewChild,
  HostListener,
} from '@angular/core';
import { Project } from '@app/pages/proyectos/proyecto';
import { selectProjectById, selectProyectList } from '@store/selectors';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { toArray } from 'lodash';
import { Observable } from 'rxjs';
import { selectCurrentRoute } from '@app/reducers';
import { ProjectStore } from '@store/models';
import { StatusService } from '@app/shared/services/status-project.service';
import { MenusLinks } from './menusLinks';
import { Router } from '@angular/router';
import {
  getAjusteDepuracion,
  getAjusteDepuracionIca,
  getAjustePasivosIca,
  getAnticipoRenta,
  getAutorretenciones,
  getEri,
  getExcesosPresuntiva,
  getImpuestoExterior,
  getNomina,
  getPerdidasCompensar,
  getPerdidasCompensarInsolvencia,
  getRelacionDeclaraciones,
  getRentaOpciones,
  getSeccion1Gmf,
  getSeccion2Gmf,
  getSeccion3Gmf,
  getTotalesAutorretenciones,
} from '@app/store/actions';
import { getDonacion } from '@app/store/actions/donacion.actions';
import { getCalculoDescuento } from '@app/store/actions/calculo-descuento.actions';
import { StatusListModel } from '@app/store/models/project-status.model';

interface actionsForDispatchs {
  route: string;
  dispatchs: any[];
}

@Component({
  selector: 'app-folder-projects',
  templateUrl: './folder-projects.component.html',
  styleUrls: ['./folder-projects.component.scss'],
})
export class FolderProjectsComponent implements OnInit, OnChanges {
  @ViewChild('searchInput') searchInput: ElementRef; // Obtener una referencia al input
  @ViewChild('resultsList') resultsList: ElementRef; // Obtener una referencia a la lista

  proyectos$: Observable<Project[]>;
  routeNow$: Observable<any>;
  public isProject: boolean = false;
  public menuOption: any = MenusLinks;
  valorProgreso = 0;
  public name: string = '';
  public nit: string = '';
  data: any;
  options: any;
  id_proyec: number = 0;
  showResults = false;
  filteredItems: any[] = [];
  selectedItem: any = null;
  searchTerm = '';
  projects: any = MenusLinks;
  selectedProjectIndex = -1;
  selectedProjectName = '';
  selectRouter = '';
  searchResults: any[] = [];
  classActivate: boolean = false;

  public statusList: StatusListModel[];

  constructor(
    private store: Store,
    private statusService: StatusService,
    private router: Router,
  ) {
    // this.proyectos$ = this.store.pipe(
    //   select(selectProyectList),
    //   map((list) => toArray(list))
    // );
  }

  ngOnInit() {
    this.connectToRoute();
  }
  ngAfterViewInit() {
    if (this.searchInput && this.resultsList) {
    }
  }
  ngOnChanges(simple: SimpleChanges) {
    if (simple) {
      this.connectToRoute();
    }
  }

  connectToRoute() {
    this.proyectos$ = this.store.pipe(
      select(selectProyectList),
      map((list) => toArray(list))
    );

    this.store
      .pipe(
        select(selectCurrentRoute),
        map((route) => route.url)
      )
      .subscribe((ruta: string) => {
        if (ruta) {
          const url = ruta.split('/');
          const section = url[1];
          const projectId = Number(url[2]) || 0;
          if (section === 'proyecto' && projectId !== 0) {
            this.isProject = true;
            this.store
              .pipe(
                select(selectProjectById(projectId)),
                map((projectFull: ProjectStore) => projectFull)
              )
              .subscribe((data) => {
                if (data) {
                  this.name = data.name;
                  this.nit = data.nit;
                  this.id_proyec = data.id;
                  this.statusService
                    .getStatusProject(this.id_proyec)
                    .subscribe((data) => {
                      this.valorProgreso = Math.trunc(data);
                      this.createBarChart();
                    });
                }
              });
          } else if (section === 'home') {
            this.isProject = false;
            this.name = '';
            this.nit = '';
            this.id_proyec = 0;
          }
        }
      });
  }
  createBarChart() {
    this.data = {
      datasets: [
        {
          data: [this.valorProgreso, 100 - this.valorProgreso],
          backgroundColor: [
            this.valorProgreso === 0
              ? '#a6a6a6'
              : this.valorProgreso === 100
              ? '#19BF71'
              : '#F2CF3B',
            '#a6a6a6',
          ],
          options: {
            cutoutPercentage: 60,
            responsive: true,
            maintainAspectRatio: false,
          },
        },
      ],
    };
  }

  performSearch() {
    if (!this.searchTerm) {
      this.searchResults = [];
    } else {
      const searchTerm = this.searchTerm.toLowerCase();
      this.searchResults = this.projects.filter((project) =>
        project.name.toLowerCase().includes(searchTerm)
      );
    }
  }
  onInputKeyDown(event: KeyboardEvent) {
    if (event.key === 'ArrowDown') {
      this.resultsList.nativeElement.focus();
    }
  }
  // @HostListener('window:keydown', ['$event'])
  onKeydown(event: any) {
    event = KeyboardEvent;
    event.preventDefault();
    if (this.filteredItems.length === 0) return;
    if (!this.selectedItem) this.selectedItem = this.filteredItems[0];
    else {
      const currentIndex = this.filteredItems.indexOf(this.selectedItem);
      const nextIndex =
        currentIndex === this.filteredItems.length - 1 ? 0 : currentIndex + 1;
      this.selectedItem = this.filteredItems[nextIndex];
    }
  }

  onListKeyDown(event: KeyboardEvent) {
    const list = this.resultsList.nativeElement;
    if (list === document.activeElement) {
      if (event.key === 'ArrowUp') {
        if (this.selectedProjectIndex > 0) {
          this.classActivate = true;
          this.selectedProjectIndex--;
          list.scrollTop -= list.children[0].clientHeight;
        }
      } else if (event.key === 'ArrowDown') {
        this.classActivate = true;
        if (this.selectedProjectIndex < this.searchResults.length - 1) {
          this.selectedProjectIndex++;
          list.scrollTop += list.children[0].clientHeight;
        }
      } else if (event.key === 'Enter' && this.selectedProjectIndex >= 0) {
        this.selectedProjectName =
          this.searchResults[this.selectedProjectIndex].name;
        this.searchInput.nativeElement.focus();
        this.onResultClick(this.selectedProjectIndex);
      }
      event.preventDefault();
    }
  }

  onResultClick(index: number) {
    this.classActivate = false;
    this.selectedProjectName = this.searchResults[index].name;
    this.selectRouter = this.searchResults[index].route;
    this.searchInput.nativeElement.focus();
    this.dispatchActionsInRoute(this.selectRouter);
    setTimeout(() => {
      this.router.navigate([`proyecto/${this.id_proyec}${this.selectRouter}`]);
    }, 1100);
    this.searchTerm = '';
    this.searchResults = [];
  }
  dispatchActionsInRoute(route: string): number {
    const actionsList: actionsForDispatchs[] = [
      {
        route: '/anx-depuracion/anticipo_renta',
        dispatchs: [
          getAnticipoRenta({ projectId: this.id_proyec }),
          getRentaOpciones({ projectId: this.id_proyec }),
        ],
      },
      {
        route: '/anx-depuracion/gmf',
        dispatchs: [
          getSeccion1Gmf({ projectId: this.id_proyec }),
          getSeccion2Gmf({ projectId: this.id_proyec }),
          getSeccion3Gmf({ projectId: this.id_proyec }),
        ],
      },
      {
        route: '/anx-depuracion/descuentos_tributarios',
        dispatchs: [
          getDonacion({ projectId: this.id_proyec }),
          getImpuestoExterior({ projectId: this.id_proyec }),
          getCalculoDescuento({ projectId: this.id_proyec }),
        ],
      },
      // {
      //   route: '/formato2516/ERI',
      //   dispatchs: [
      //     getEri({ projectId: this.id_proyec })
      //   ],
      // },
      {
        route: '/anx-depuracion/autorretenciones',
        dispatchs: [
          getAutorretenciones({ projectId: this.id_proyec }),
          getTotalesAutorretenciones({ projectId: this.id_proyec }),
          getAjusteDepuracion({ projectId: this.id_proyec }),
        ],
      },
      {
        route: '/anx-depuracion/industria_comercio',
        dispatchs: [
          getRelacionDeclaraciones({ projectId: this.id_proyec }),
          getAjusteDepuracionIca({ projectId: this.id_proyec }),
          getAjustePasivosIca({ projectId: this.id_proyec }),
        ],
      },
      {
        route: '/anx-depuracion/compensacion_perdidas',
        dispatchs: [
          getPerdidasCompensar({ projectId: this.id_proyec }),
          getExcesosPresuntiva({ projectId: this.id_proyec }),
          getPerdidasCompensarInsolvencia({ projectId: this.id_proyec }),
        ],
      },
      {
        route: '/anx-depuracion/gasto_personal',
        dispatchs: [getNomina({ projectId: this.id_proyec })],
      },
    ];

    actionsList.map((action: actionsForDispatchs) => {
      if (action.route === route) {
        action.dispatchs.map((dispatch) => {
          this.store.dispatch(dispatch);
        });
      }
    });
    return 1;
  }

  openDropdown(event: any): void {
    this.getStatusListData(this.id_proyec);
  }

  getStatusListData(idProject: number): void {
    this.statusService.getStatusListProject(idProject).subscribe({
      next: (response: any) => {
        this.statusList = this.statusService.formatAnnexesListData(response);
      }
    })
  }
}
