<div class="body-login">
    <p-toast position="top-center"></p-toast>
    <main id="main-login" [formGroup]="signUpForm">
        <div class="left-container">
            <img src="/assets/icons/logo-white.png" class="registro-img-responsive" alt="">
            <div class="login" *ngIf="!accountCreated">
                <h2 class="login-text">Datos básicos</h2>

                <div class="login-email">
                    <img src="/assets/icons/email.png" style="margin-right: 10px;" alt="">
                    <input type="email" name="e-mail" placeholder="E-mail" formControlName="email">
                </div>

                <span class="error">{{ emailErrorMessage }}</span>

                <div class="login-password">
                    <img src="/assets/icons/key.png" style="margin-right: 10px;" alt="">
                    <input type="password" name="password" placeholder="Contraseña" formControlName="password">
                </div>

                <span class="error">{{ passwordErrorMessage }}</span>

                <div class="login-password">
                    <img src="/assets/icons/key.png" style="margin-right: 10px;" alt="">
                    <input type="password" name="password-confirmacion" placeholder="Confirma contraseña"
                        formControlName="repeatPass">
                </div>

                <span class="error">{{ pass2ErrorMessage }}</span>

                <span class="error" *ngIf="signUpForm.get('password')?.errors?.noEquals || signUpForm.get('repeatPass')?.errors?.noEquals">
                    Las contraseñas deben coincidir
                </span>

                <span class="error">{{ errorMessage }}</span>

                <div class="login-button">
                    <button (click)="signUp()">
                        <img src="/assets/icons/check-green.png" alt="">
                        <div>Registrarme</div>
                    </button>
                </div>
            </div>

            <div class="login" *ngIf="accountCreated">
                <h3>
                    Cuenta creada éxitosamente
                </h3>

                <h4>Por favor valide su cuenta de correo para finalizar el proceso de registro</h4>

                <div class="login-button">
                    <button (click)="aceptar()">
                        <img src="/assets/icons/check-green.png" alt="">
                        <div>Aceptar</div>
                    </button>
                </div>
            </div>
        </div>
        <div class="right-container">
            <div class="registro">
                <img src="/assets/icons/logo-white.png" class="registro-img" alt="">
                <h3>Formulario de Registro</h3>
            </div>
        </div>
    </main>
</div>
