import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  MSG_DURATION,
  NotifyService,
} from '@app/shared/services/notify.service';
import { getAnticipoRenta, getAnticipoRentaError, getAnticipoRentaSuccess, setAnticipoRenta, setAnticipoRentaError, setAnticipoRentaSuccess } from '../actions';
import { AnticipoRentaService } from '@app/shared/services/anticipo-renta.service';
import { AnticipoRenta } from '../models';

@Injectable()
export class AnticipoRentaEffects {
  getAnticipoRenta$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAnticipoRenta),
      switchMap(({ projectId }) => {
        return this.anticipoRentaService.getAnticipoRenta(projectId).pipe(
          map((anticipoRenta) => {
            let anticipoRentaSuccess: AnticipoRenta = {
              idProject: projectId,
              ...anticipoRenta,
            };
            return getAnticipoRentaSuccess({ anticipoRentaSuccess });
          }),
          catchError((error) => {
            this.notify.error('Algo salio mal!', JSON.stringify(error), MSG_DURATION, {
              text: 'Entendido',
              orientation: 'izq',
            });
            return of(getAnticipoRentaError({ error }));
          })
        );
      })
    )
  );

  saveAnticipoRenta$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setAnticipoRenta),
      switchMap(({ anticipoRentaForm, projectId }) => {
        return this.anticipoRentaService
          .saveAnticipoRenta(projectId, anticipoRentaForm)
          .pipe(
            map((anticipoRenta) => {
              let anticipoRentaForm: AnticipoRenta = {
              idProject: projectId,
              ...anticipoRenta,
            };
              this.notify.success(
                '',
                'Guardado de registros exitoso',
                MSG_DURATION
              );
              return setAnticipoRentaSuccess({ anticipoRentaForm });
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                MSG_DURATION,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setAnticipoRentaError({ error }));
            })
          );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private anticipoRentaService: AnticipoRentaService,
    private notify: NotifyService
  ) {}
}
