import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button-alerts',
  templateUrl: './button-alerts.component.html',
  styleUrls: ['./button-alerts.component.scss']
})
export class ButtonAlertComponent {
  @Input() type: "info" | "error" | "success" | "warning" | "custom" = "info";
  @Input() icon: string = null;
  @Input() text: string = '';
  @Input() idButton: string = '';
  @Input() color: string = '';
  @Input() background: string = '';
  @Input() iconDir:'izq' | 'der' = 'izq';

  @Output() clicker: EventEmitter<string>  = new EventEmitter<string>();

  constructor(
  ) { }
  
  public onEmit(){
    this.clicker.emit(this.idButton)
  }
}
