import { createAction, props } from "@ngrx/store";
import { CalculoDescuentoDTO, CalculoDescuentoDTOSave, TotalCalculoDescuento } from "../models/descuento-tributario.model";

export const GET_CALCULO_DESCUENTO = '[calculoDescuento] get calculoDescuento';
export const GET_CALCULO_DESCUENTO_SUCCESS = '[calculoDescuento] get calculoDescuento success';
export const GET_CALCULO_DESCUENTO_ERROR = '[calculoDescuento] get calculoDescuento with error';

export const SET_CALCULO_DESCUENTO = '[calculoDescuento] set calculoDescuento save info';
export const SET_CALCULO_DESCUENTO_SUCCESS = '[calculoDescuento] set calculoDescuento save info success';
export const SET_CALCULO_DESCUENTO_ERROR = '[calculoDescuento] set calculoDescuento save info with error';

export const SET_DELETE_CALCULO_DESCUENTO = '[calculoDescuento] set delete calculoDescuento';
export const SET_DELETE_CALCULO_DESCUENTO_SUCCESS = '[calculoDescuento] set delete calculoDescuento success';
export const SET_DELETE_CALCULO_DESCUENTO_ERROR = '[calculoDescuento] set delete calculoDescuento with error';

//get calculoDescuento
export const getCalculoDescuento = createAction(
  GET_CALCULO_DESCUENTO,
  props<{projectId: number}>()
);

export const getCalculoDescuentoSuccess = createAction(
  GET_CALCULO_DESCUENTO_SUCCESS,
  props<{ calculoDescuentoSuccess: CalculoDescuentoDTO  }>()
);

export const getCalculoDescuentoError = createAction(
  GET_CALCULO_DESCUENTO_ERROR,
  props<{ error: string }>()
);

//create calculoDescuento
export const setCalculoDescuento = createAction(
  SET_CALCULO_DESCUENTO,
  props<{ calculoDescuentoForm:CalculoDescuentoDTOSave, projectId: number }>()
);

export const setCalculoDescuentoSuccess = createAction(
  SET_CALCULO_DESCUENTO_SUCCESS,
  props<{ calculoDescuentoForm:CalculoDescuentoDTO }>()
);

export const setCalculoDescuentoError = createAction(
  SET_CALCULO_DESCUENTO_ERROR,
  props<{ error: string }>()
);

//delete calculoDescuento
export const setDeleteCalculoDescuento = createAction(
  SET_DELETE_CALCULO_DESCUENTO,
  props<{ projectId: number, idCalculoDescuento: number,totales:TotalCalculoDescuento }>()
);

export const setDeleteCalculoDescuentoSuccess = createAction(
  SET_DELETE_CALCULO_DESCUENTO_SUCCESS,
  props<{ calculoDescuentoForm:CalculoDescuentoDTO }>()
);

export const setDeleteCalculoDescuentoError = createAction(
  SET_DELETE_CALCULO_DESCUENTO_ERROR,
  props<{ error: string }>()
);
