import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  MSG_DURATION,
  NotifyService,
} from '@app/shared/services/notify.service';
import { getExcesosPresuntiva, getExcesosPresuntivaError, getExcesosPresuntivaSuccess, setDeleteExcesosPresuntiva, setDeleteExcesosPresuntivaError, setDeleteExcesosPresuntivaSuccess, setExcesosPresuntiva, setExcesosPresuntivaError, setExcesosPresuntivaSuccess } from '../actions';
import { ExcesosPresuntivaStore } from '../models';
import { CompensacionPerdidasService } from '@app/shared/services/compensacion-perdidas.service';

@Injectable()
export class ExcesosPresuntivaEffects {
  getExcesosPresuntiva$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getExcesosPresuntiva),
      switchMap(({ projectId }) => {
        return this.compensacionPerdidasService.getExcesosPresuntiva(projectId).pipe(
          map((excesosPresuntiva) => {
            let excesosPresuntivaSuccess: ExcesosPresuntivaStore = {
              idProject: projectId,
              ...excesosPresuntiva,
            };
            return getExcesosPresuntivaSuccess({ excesosPresuntivaSuccess });
          }),
          catchError((error) => {
            this.notify.error('Algo salio mal!', JSON.stringify(error), 5000, {
              text: 'Entendido',
              orientation: 'izq',
            });
            return of(getExcesosPresuntivaError({ error }));
          })
        );
      })
    )
  );

  saveExcesosPresuntiva$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setExcesosPresuntiva),
      switchMap(({ excesosPresuntivaForm, projectId }) => {
        return this.compensacionPerdidasService
          .saveExcesosPresuntiva(projectId, excesosPresuntivaForm)
          .pipe(
            map((excesosPresuntiva) => {
               let excesosPresuntivaForm: ExcesosPresuntivaStore = {
              idProject: projectId,
              ...excesosPresuntiva,
            };
              this.notify.success(
                '',
                'Guardado de registro exitoso',
                MSG_DURATION
              );
              return setExcesosPresuntivaSuccess({ excesosPresuntivaForm });
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setExcesosPresuntivaError({ error }));
            })
          );
      })
    )
  );

  deleteExcesosPresuntiva$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDeleteExcesosPresuntiva),
      switchMap(({ projectId, idRow, objExcesosPresuntiva }) => {
        return this.compensacionPerdidasService
          .deleteExcesosPresuntiva(projectId, idRow, objExcesosPresuntiva)
          .pipe(
            map((excesosPresuntiva) => {
              this.notify.success(
                '',
                'Borrado de registro exitoso',
                MSG_DURATION
              );
              return setDeleteExcesosPresuntivaSuccess({ excesosPresuntiva });
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setDeleteExcesosPresuntivaError({ error }));
            })
          );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private compensacionPerdidasService: CompensacionPerdidasService,
    private notify: NotifyService
  ) {}
}
