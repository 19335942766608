<div class="header_title">
  <div class="header_title_group">
   <div class="header_title_group_icon">
    <mat-icon class="header_title_icon" 
      [svgIcon]="iconTitle"></mat-icon>
   </div>
   <div class="header_title_group_text">
    {{title}}
   </div>
  </div>
  <div class="header_title_buttons">
    <mat-icon mat-dialog-close svgIcon="close_rounded">
    </mat-icon>
  </div>
</div>
<div class="header_line">
  <hr class="hr1">
  <hr class="hr2">
</div>