import { FormControl } from '@angular/forms';

export class LocationValidator {

  public static latitude(control: FormControl): any {
    if (control.value) {
      const expre = new RegExp(/^(?=.)-?((8[0-5]?)|([0-7]?[0-9]))?(?:\.[0-9]{1,20})?$/);
      if (!expre.exec(control.value)) {
        return { 'invalid': true };
      }
    }

    return null;
  }

  public static longitude(control: FormControl): any {
    if (control.value) {
      const expre = new RegExp(/^(?=.)-?((0?[8-9][0-9])|180|([0-1]?[0-7]?[0-9]))?(?:\.[0-9]{1,20})?$/);
      if (!expre.exec(control.value)) {
        return { 'invalid': true };
      }
    }

    return null;
  }
}
