import { Component, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Router, UrlSegment } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { MatIconRegistry } from '@angular/material/icon';

import { delay, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';
import { toArray } from "lodash";

import { AuthService } from '@auth/services/auth.service';
import { ListProyectosService } from '@proyectos/services/list-proyectos.service';

import { ICONS } from '../utils/icons';
import { MENU, URLS_LOCKED } from './menu';

import { selectIdProject, selectProyectList } from '@store/selectors';
import { selectCurrentRoute } from '@app/reducers';
import { selectBalanceById } from '@store/selectors/balance.selectors';

import {
  getAjusteDepuracion,
  getAjusteDepuracionIca,
  getAjustePasivosIca,
  getAllAnnexedStatus,
  getAnticipoRenta,
  getAutorretenciones,
  getBalance,
  getEri,
  getExcesosPresuntiva,
  getImpuestoExterior,
  getNomina,
  getPerdidasCompensar,
  getPerdidasCompensarInsolvencia,
  getProjectStatus,
  getRelacionDeclaraciones,
  getRentaOpciones,
  getSeccion1Gmf,
  getSeccion2Gmf,
  getSeccion3Gmf,
  getTotalesAutorretenciones
} from '@app/store/actions';
import { selectStatusProjectById } from '@app/store/selectors/project-status.selectors';
import { NotifyService } from '../services/notify.service';
import { getDonacion } from '@app/store/actions/donacion.actions';
import { getCalculoDescuento } from '@app/store/actions/calculo-descuento.actions';
import { PlanService } from '@app/pages/plans/services/plan.service';
import { CurrentPlan } from '@app/pages/plans/models/plan';
import { selectAnnexedStatusByTab } from '@app/store/selectors/annexed-status.selectors';
import { AnnexedStatus } from '@app/store/models/project-status.model';

export const SECTIONS = {
  'proyecto': 'Proyecto',
  'home': 'Home',
  'settings': 'Configuraciones',
  'plans': 'Planes',
}

interface actionsForDispatchs {
  route: string
  dispatchs: any[]
}

interface lockUrl {
  name: string
  url: string
}

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss']
})

export class BaseLayoutComponent implements OnInit {
  /**
   * sidenav permite seleccionar el Matsidenav para los efectos ejecutados del menu azul
   */
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('sideNav2') sidenav2: MatSidenav;
  /**
   * @deprecated Antigua orma de comunicar la ventana de depuracion y realizar validaciones
   */
  @Output() depurAutoSave = new EventEmitter();

  /**
   * valida si menu lateral azul esta abiero
   */
  isExpanded: boolean = false;
  /**
   * valida si menu lateral azul muestra submenus
   */
  showSubmenu: boolean = false;
  /**
   * Muestra el menu y entrega ligado como efecto hover sobre el paso del pointer del menu
   */
  isShowing: boolean = false;
  /**
   * Muestra el menu alojado dentro de un submenu
   */
  showSubSubMenu: boolean = false;
  /**
   * Reune el menu exportado del MENU, archivo en donde se crean y editan el menu y sus rutas
   */
  public menuOption: any = MENU;
  /**
   * Emmiter para captura y comunicacion de tamaño actual de la pantalla
   */
  public sizeDisplay = new EventEmitter();
  /**
   * Captura el valor actual de la pantalla
   */
  public display: string;
  /**
   * Muestra la barra de carga
   */
  showProgress: boolean = false;

  //showSidebar: boolean = false;
    /**
   * Guarda el numero de proyecto actual
   */
  projectId: number;
    /**
   * Nos dice si hay un balance previo cargado para habilitar otras opciones
   */
  isBalance: boolean = false;

  //depurationReady$: Observable<any>;
  /**
   * @deprecated Esta funcionalidad se realiza mejor con uso del Store y un selector de proyectos, se eliminara en proximas versiones de la App
   * Nos muestra la Informacion del projecto actual
   */
  currentProject$ = this.proyecSrv.currentProject$;
  /**
   * Observable que carga la lista de proyectos de la que dispone el usuario
   */
  proyectos$: Observable<any>;
  /**
   * La Ruta Actual tomada del store de ruta
   */
  routeNow$: Observable<any>;
  /**
   * Observable que toma del Store informacion del Balance cargado del proyecto actual, retornando un true si existe un balance creado y si este tiene registros en su interior
   */
  isBalance$: Observable<boolean>;
  /**
   * Observable que toma del Store el estado de Depuracion  del balance del proyecto actual, si el estado es 'COMPLETED' retorna un true
   */
  isDepuracion$: Observable<boolean>;
  /**
   * String de la ruta actual
   */
  routeUrl: string;
  /**
   * Conteo de proyectos que posee el usuario
   */
  proyectsLength: number;
  /**
   * Evalua si nos encontramos dentro de la ventana de depuracion y elimina este baseLayout y deja la ventana sin menu lateral y sin menu superior
   */
  inDepuracion = false;
  /**
   * Nos dice si existe un plan ligado al usario
   */
  planActivo: boolean;
  /**
   * Captura el plan actual ligado al usuario
   */
  plan: CurrentPlan;
  //public depurationExist: boolean = false;

  /**
   * Constructor
   * @param auth Servicio para login y registro de usuarios
   * @param router Servicio de manejo de rutas propio de Angular
   * @param proyecSrv Servicio de conexion con proyectos
   * @param store Utilizado para llamar la data precargada
   * @param breakpointObserver Servicio de Angular que permite escuchar resoluciones y tamaños de pantalla
   * @param planService Servicio para consultar la información del plan relacionado con el usuario
   * @param notify Servicio para mostrar mensajes toast
   * @param matIconRegistry Servicio de ANgular material para registrar archivos SVG locales como iconos para usar en la tag de mat-icons
   */
  constructor(
    private auth: AuthService,
    private router: Router,
    private proyecSrv: ListProyectosService,
    private store: Store<any>,
    public breakpointObserver: BreakpointObserver,
    private planService: PlanService,
    private notify: NotifyService,
    private matIconRegistry: MatIconRegistry,
  ) {

    this.isBalance = false;

    this.routeNow$ = this.store.pipe(
      select(selectCurrentRoute),
      map((route) => route)
    );

    this.proyectos$ = this.store.pipe(
      select(selectProyectList),
      map((list) => toArray(list))
    );

    this.setProjectstoMenu();


    this.mediaQuery();

    this.sizeDisplay.subscribe((displayed: string) => {
      this.display = displayed;
    })

  }

  ngOnInit() {
    this.getIcons();

    this.store.pipe(select(selectIdProject)).subscribe(result => {
      this.projectId = result;
    });

    this.routeNow$.subscribe((ruta: any) => {

      this.updateMenu(ruta.url);
      this.routeUrl = ruta.url;
      this.activatedMenuBalance(ruta.url);
      this.lockMenuInUrl(ruta.url);

      this.listenDepuracionCahnnel();
    });

    this.planService.actualPlan().subscribe(pl => {
      let diferencia = (Date.now() - pl.creationDate) / (3600 * 24000);
      this.planActivo = pl.status == 'ACTIVE' && ((diferencia < 365 && pl.memberType != 'DEMO') || ((diferencia * 24) < 6 && pl.memberType == 'DEMO'));
      this.plan = pl;
    })

  }
  /**
   * Crea una escucha del evento enviado por la ventana de depuracion usando BroadcastChannel, en caso de recibir un mensaje disara la accion para traer el status del proyecto y asi desbloquear las opciones del menu de todos los anexos del proyecto
   */
  listenDepuracionCahnnel() {
    const channel = new BroadcastChannel('depuracion-channel');
    channel.addEventListener('message', (event) => {
      
      this.store.dispatch(getProjectStatus({ projectId: this.projectId }));
    });
  }

  /**
   *
   * @param { string } ruta string con la url a analizar
   * Se evalua si existe un balance y si este balance posee registros, al tiempo que se valida si existe una depuracion para este balance, en c aso de que todo sea correcto, se habilitara el menu completo, en caso de que falte balance y depuracion se bloqueara el menu, si hace falta depuacion se disparar un toast indicando que se realice una depuracion
   */

  activatedMenuBalance(ruta: string) {

    const url = ruta.split('/');
    //this.projectId = +url[2] || 0;
    const rutaFilter = url[4]

    this.isBalance$ = this.store.pipe(
      select(selectBalanceById(this.projectId)),
      map((balance) => {
        if (balance && Object.keys(balance).length > 0) {
          if (balance.projectId === this.projectId && balance.registroDTO.length > 0) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      })
    );


    this.isDepuracion$ = this.store.pipe(
      select(selectStatusProjectById(this.projectId)),
      map((depuracion: any) => {
        if (depuracion?.depuracionStatus === 'COMPLETED') {
          return true
        } else {
          return false
        }
      })
    );

    this.isBalance$.subscribe((balance: boolean) => {

      let depuracionReady: boolean = false;
      //mostramos el menu completo con anexos solo si ya hay balance cargado
      this.isDepuracion$.subscribe((depuracion) => {
        depuracionReady = depuracion;

        if (!depuracion && rutaFilter?.includes('balance')) {
          this.notify.info('Tu navegación esta incompleta', 'Necesitas realizar una depuracion a tu balance para acceder a todos los anexos', 4500)
        }

        if (balance && depuracionReady && this.proyectsLength > 0 && this.projectId !== 0) {
          this.menuOption.map((option: any) => {
            if (option.id !== '1' && option.id !== 'logout') {
              option.visible = true;
            }
          })
        } else {

          this.menuOption.map((option: any) => {
            if (option.id !== '1' && option.id !== '2' && option.id !== 'logout') {
              option.visible = false;
            }
          })
        }
      })

    })
  }

  /**
   * Se mapea el listado de proyectos desde el observable que los contiene, de manera dinamica se crea cada proyecto a manera de submenu con un nombre, una ruta y un array de dispatchs de actions que se disparan al hacer click sobre cada proyecto
   */
  setProjectstoMenu() {
    this.proyectos$.subscribe((projects: any) => {
      this.proyectsLength = projects.length;
      projects.map((project: any) => {
        this.menuOption.map((options: any) => {
          if (options.id === '1') {

            let obj = {
              id: project.id,
              name: project.name,
              route: `proyecto/${project.id}/infoContribuyente/info`,
              icon: 'paper_clip',
              active: false,
              dispatchs:[
                getBalance({ projectId: project.id}),
                getProjectStatus({ projectId: project.id }),
                getAllAnnexedStatus({ projectId: project.id})
              ]
            };
            let validate = options.submenu.find(
              (item: any) => item.id === obj.id);

            if (validate) {
              return
            } else {
              options.submenu.push(obj)
            }
          }
        })
      })
    });
  }

  /**
   * Metodo que llama los iconos del matIconRegistry para evitar errores en el renderizado dentro de la App
   */
  getIcons() {
    ICONS.map((icon: string) => {
      this.matIconRegistry.getNamedSvgIcon(icon).pipe(take(1)).subscribe();
    });
  }

  /**
   * Al ingresar  el cursor al menu lateral hace que este se despliegue
   */
  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }

    this.menuOption.map((menu: any) => {
      if (menu.active === true) {
        menu.showSub = true;
      }
    })

  }

   /**
   * Al sacar  el cursor del menu lateral hace que este se contraiga
   */
  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }

    this.menuOption.map((option: any) => {
      option.showSub = false;
    })
  }

  /**
   *
   * @param {string} id numero de identificacion de cada menu y submenu dentro del arreglo de opciones
   * Despliega las opciones de submenu que posea un menu o categoria exceptuando a logout
   */
  public goSubmenu(id: string) {
    this.menuOption.map((menu: any) => {
      if (menu.id === id) {
        menu.active = true
      } else {
        menu.active = false
      }

    })

    if (id !== 'logout') {
      this.menuOption.map((menu: any) => {
        if (menu.id === id) {
          menu.showSub = !menu.showSub;
        } else {
          menu.showSub = false;
        }
      })
    } else {
      this.logout();
    }

  }
  /**
   *
   * @param {string} url la URL actual a evaluar para definir si hay que bloquear
   * Se busca en el listado de urls que no deben poseer menu lateral habilitado y si la url actual pertenece al grupo se desaparecen las opciones de Menu
   */
  public lockMenuInUrl(url: string){
    URLS_LOCKED.map((lock: lockUrl) => {
      if(url.includes(lock.url)){

        this.menuOption.map((option: any) => {
          if (option.id !== '1' && option.id !== 'logout') {
            option.visible = false;
          }
        })

      }
    })
  }

  /**
   *
   * @param id numero de identificacion de cada menu y submenu dentro del arreglo de opciones
   * @param submenu Objeto que contiene el submenu y sus opciones
   * @param sublinks links que puede tener un submenu internamente
   * Metodo que define el flujo de acciones a realizar cuando el usuario selecciona algo en el menu lateral:
   *
   * Si lo seleccionado es un proyecto se mapean las acciones dentro del submenu y se disparan, para despues enrutar usando el id del proyecto dentro de la url
   *
   * Si lo seleccionado es un menu desplegable habilita el parametro showSubLinks que despliega las opciones
   *
   * Se valida si hay plan activo o es cuenta demo
   *
   * Se genera un bloqueo de vistas para los planes DEMO
   */
  public goToRoute(id: string, submenu: any, sublinks: any = null) {
    if (id === '1') {
      //dispatch actions de precarga de info de cada proyecto
      submenu.dispatchs.map((item: any) => {
        this.store.dispatch(item)
      })
    }
    
    if (sublinks) {
      //si es un link dentro de un submenu
      this.dispatchRoute(sublinks.route);

      this.menuOption.map((menu: any) => {
        if (menu.id === id) {
          menu.submenu.map((sub: any) => {
            if (sub?.id === submenu.id && sub.showSubLinks) {
              //sub.showSubLinks = true
              sub.menuLinks.map((link: any) => {
                if (link.route === sublinks.route) {
                  //link.active = true
                } else {
                  //link.active = false
                }
              })
            } else {
              //
            }
          })
        }
      })

    } else {
      //filtramos si es link de ruta o contiene opciones de menu
      if (submenu.menuLinks?.length) {
        //es un array de links
        this.menuOption.map((menu: any) => {
          if (menu.id === id) {
            menu.submenu.map((sub: any) => {
              if (sub?.id === submenu.id && !sub.showSubLinks) {
                sub.showSubLinks = true
              } else {
                sub.showSubLinks = false
              }
            })
          }
        })

      } else {
        //es una ruta
        switch (id) {
          //menu inicial sin escoger proyecto
          case '1':
            //se selecciona proyecto actual y se envia la data por la ruta hacia el state
            this.currentProject$.subscribe((proj: any) => {

              this.router.navigate([submenu.route], { state: { data: { proj } } });
              this.sidenav2.toggle();
              this.menuOption.map((menu: any) => {
                menu.visible = true;
                if (menu.id === '1') {
                  menu.submenu.map((sub: any) => {
                    if (sub.route === submenu.route) {
                      //sub.active = true
                    } else {
                      //sub.active = false
                    }
                  })
                }
              });


            })

            break;

          default:
            //todas las rutas de submenu entran
            if (submenu.route !== '') {
              //seteamos el estado active en false a todas las rutas de submenu
              this.menuOption.map((menu: any) => {
                if (menu.id !== '1' && menu.id !== 'logout')
                  menu.submenu.map((sub: any) => {
                    //sub.active = false
                    if (sub.showSubLinks) {
                      sub.showSubLinks = false;
                    }
                  })
              })
              //disparamos la ruta
              if (!this.planActivo) {
                this.router.navigate(['/home']);
                this.sidenav2.toggle();
                break;
              }
              if (submenu.route.includes("/anx-depuracion/gasto_personal")){
                this.store
                .pipe(
                  select(selectAnnexedStatusByTab("GST_PERSONAL"))
                )
                .subscribe((data:AnnexedStatus) => {
                  if (data.status=="TRAMITAR") {
                    this.notify.warning("","Señor usuario, para el correcto diligenciamiento de este anexo tenga en cuenta que el anexo de depuración de renta debe estar finalizado, de lo contrario y al hacer cambios en depuración Renta deberá revisarlo nuevamente",0,{text:"Entendido",orientation:"izq"});
                  }
                }).unsubscribe();
              }
              if (submenu.route.includes("formato2516")) {
                let ruta: string = submenu.route.split("formato2516/")[1];
                if (this.plan.memberType == "DEMO" && this.anexoVisto(ruta)) {
                  this.notify.warning("limite de vistas", "Solo se puede ver una vez "+ruta+" en este plan")
                  this.router.navigate(['/plans/pricing']);
                  this.sidenav2.toggle();
                  break;
                }
                else {
                  if (this.plan.memberType == "DEMO" && !this.anexoVisto(ruta)) {
                    this.planService.ver2516(ruta).subscribe(_ => { });
                    this.verAnexo(ruta);
                  }
                }
              }
              this.dispatchActionsInRoute(submenu.route);
              this.router.navigate(['proyecto/' + this.projectId + submenu.route]);
              this.sidenav2.toggle();
              //seteamos active al boton de submenu de la ruta seleccionada
              this.menuOption.map((menu: any) => {
                if (menu.id === id) {
                  menu.submenu.map((sub: any) => {
                    if (sub.route === submenu.route) {
                      //sub.active = true
                      if (sub.showSubLinks) {
                        sub.showSubLinks = false;
                      }
                    }
                  })
                }
              })
            }
            break;
        }
      }
    }
  }

  /**
   *
   * @param {string} anexo nombre del anexo a evaluar
   * @returns retorna un boolean que es true si la ruta ya fue visitada
   *
   * Evalua si el anexo ya fue visitado
   */
  anexoVisto(anexo: string): boolean {
    switch (anexo) {
      case "ERI": {
        return this.plan.viewERI;
      }
      case "ESF": {
        return this.plan.viewESF;
      }
      case "caratula": {
        return this.plan.viewCaratula;
      }
      case "impuesto": {
        return this.plan.viewImpuesto;
      }
      default: {
        return true;
      }
    }
  }

  /**
   * @param anexo nombre del anexo a visitar
   *
   * Redirige al anexo que se desea visitar y coloca un contador +1 de visita al anexo determinado, usado para validar el numero de vistas a los anexos de las cuentan DEMO
   */
  verAnexo(anexo: string) {
    switch (anexo) {
      case "ERI": {
         this.plan.viewERI=true;
         break;
      }
      case "ESF": {
         this.plan.viewESF=true;
         break;
      }
      case "caratula": {
         this.plan.viewCaratula=true;
         break;
      }
      case "impuesto": {
         this.plan.viewImpuesto=true;
         break;
      }
    }
  }

  /**
   *
   * @param {string} route string que completa la URL de la ruta
   *
   * Metodo que dispara las rutas hacia los anexos del proyecto, y las acciones de las rutas.
   *
   * IMPORTANTE: el timeOut de espera es para lograr que el store termine los flujos de Effect -> Reducer -> Store y asi ya existan datos para poder colgarse al selector
   */

  dispatchRoute(route: string) {
    this.dispatchActionsInRoute(route);
    setTimeout(() => {
      this.router.navigate(['proyecto/' + this.projectId + route]);
      this.sidenav2.toggle();
    }, 1100);
  }

  /**
   * @param route string URL del anexo al que se redirigira
   * @returns Retorna un numero
   *
   * Metodo que posee el array de Acciones a disparar para cada ruta de anexo.
   *
   * Evalua la ruta y dispara las acciones conectadas a esa ruta
   */

  dispatchActionsInRoute(route: string): number {

    const actionsList: actionsForDispatchs[] = [
      {
        route: '/anx-depuracion/anticipo_renta',
        dispatchs: [
          getAnticipoRenta({ projectId: this.projectId }),
          getRentaOpciones({ projectId: this.projectId })
        ]
      },
      {
        route: '/anx-depuracion/gmf',
        dispatchs: [
          getSeccion1Gmf({ projectId: this.projectId }),
          getSeccion2Gmf({ projectId: this.projectId }),
          getSeccion3Gmf({ projectId: this.projectId })
        ]
      },
      {
        route: '/anx-depuracion/descuentos_tributarios',
        dispatchs: [
          getDonacion({ projectId: this.projectId }),
          getImpuestoExterior({ projectId: this.projectId }),
          getCalculoDescuento({ projectId: this.projectId }),]
      },
      // {
      //   route: '/formato2516/ERI',
      //   dispatchs: [
      //     getEri({ projectId: this.projectId }),
      //   ]
      // },
      // {
      //   route: '/patrimonio/retefuente',
      //   dispatchs: [
      //     getRetenciones({ projectId: this.projectId }),
      //     getAjusteDepuracionRetefuente({ projectId: this.projectId })
      //   ]
      // },
      {
        route: '/anx-depuracion/autorretenciones',
        dispatchs: [
          getAutorretenciones({ projectId: this.projectId }),
          getTotalesAutorretenciones({ projectId: this.projectId }),
          getAjusteDepuracion({ projectId: this.projectId })
        ]
      },
      {
        route: '/anx-depuracion/industria_comercio',
        dispatchs: [
          getRelacionDeclaraciones({ projectId: this.projectId }),
          getAjusteDepuracionIca({ projectId: this.projectId }),
          getAjustePasivosIca({ projectId: this.projectId }),
        ]
      },
      {
        route: '/anx-depuracion/compensacion_perdidas',
        dispatchs: [
          getPerdidasCompensar({ projectId: this.projectId }),
          getExcesosPresuntiva({ projectId: this.projectId }),
          getPerdidasCompensarInsolvencia({ projectId: this.projectId })
        ]
      },
      {
        route: '/anx-depuracion/gasto_personal',
        dispatchs: [
          getNomina({ projectId: this.projectId })
        ]
      }
    ];

    actionsList.map((action: actionsForDispatchs) => {
      if (action.route === route) {
        action.dispatchs.map((dispatch) => {
          this.store.dispatch(dispatch)
        })
      }
    })
    return 1;
  }

  /**
   *Metodo que emite un Observable de tipo string que contiene la resolucion de pantalla actual.
   *
   * Usa el metodo propio de Angular breakpointObserver
   */
  public mediaQuery() {
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Tablet])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.sizeDisplay.emit('phone');
        }
      });

    this.breakpointObserver
      .observe(Breakpoints.Web)
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.sizeDisplay.emit('web');
        }
      });
  }

  /**
   *
   * @param urlMenu string de ruta URL
   * @returns retorna un boolean
   * @deprecated Metodo en desuso, se eliminara en futuras versiones
   */
  public checker(urlMenu: string): boolean {
    return this.routeUrl.includes(urlMenu)
  }

  /**
   *
   * @param ruta string de ruta URL
   *
   *Actualiza las opciones del menu lateral escuchando la ruta actual y separando las rutas entre las que estan dentro de un proyecto y las que no
   *
   */
  updateMenu(ruta: string) {

    const url = ruta.split('/');

    const storeSection = url[url.length - 1];
    sessionStorage.setItem('title', storeSection);
    sessionStorage.setItem('section', storeSection);

    if (storeSection === 'dep') {
      this.inDepuracion = true;
    } else {
      this.inDepuracion = false;
    }

    const section = url[1]; // proyecto - settings...etc
    this.projectId = +url[2] || 0;

    switch (section) {
      case 'home':

        if (this.proyectsLength > 0 && this.projectId !== 0) {
          this.menuOption.map((option: any) => {
            if (option.id !== '1' && option.id !== 'logout') {
              option.visible = true;
            }
          })
        } else {
          this.menuOption.map((option: any) => {
            if (option.id !== '1' && option.id !== 'logout') {
              option.visible = false;
            }
          })
        }
        break;

      case 'proyecto':

        if (this.projectId) {

          this.menuOption.map((option: any) => {
            if (option.id !== '1' && option.id !== 'logout') {
              option.visible = true;
            }
          })
          //menu reactivo segun ruta
          const statesMenu: any[] = [];

          this.menuOption.map((menu: any) => {

            let obj = {
              id: menu.id,
              active: menu.active
            }

            if (menu.id !== '1' && menu.id !== 'logout') {
              menu.submenu.map((sub: any) => {
                if (typeof (sub.route) === 'string' && sub.route !== '') {
                  //ingresamos a opciones de submenu
                  if (ruta.includes(sub.route)) {
                    sub.active = true;
                    obj.active = true;
                  } else {
                    sub.active = false;
                  }
                } else {
                  if (sub.menuLinks?.length) {
                    //ingresamos a los links del submenu
                    sub.menuLinks.map((link: any) => {
                      const urlNow = `/${section}/${this.projectId}${link.route}`
                      if (ruta === urlNow) {
                        sub.showSubLinks = true;
                        //sub.active = true;
                        link.active = true;
                        obj.active = true;

                      } else {
                        link.active = false
                      }
                    })
                    //sub.showSubLinks = true;
                  }
                }
              })
            }
            statesMenu.push(obj)
          })
          //se modifica el active de los menus para activarlos
          this.menuOption.map((option: any) => {
            statesMenu.map((state: any) => {
              if (option.id === state.id) {
                option.active = state.active
              }
            })
          })

        } else {
          this.menuOption.map((option: any) => {
            if (option.id !== '1' && option.id !== 'logout') {
              option.visible = false;
            }
          })
        }

        this.auth.currentUser$.subscribe(
          user => {
            if (user) {
              this.currentProject$.subscribe(
                project => {
                  if (!project) {
                    this.proyecSrv.getProyectos(0).pipe(
                      map(projects => projects.find(p => p.id === this.projectId))
                    ).subscribe(
                      projectFound => {
                        if (projectFound) {
                          this.projectId = projectFound.id;
                          this.proyecSrv.currentProject = projectFound;
                        }
                      }
                    )
                  } else {
                    this.projectId = project.id;
                  }
                }
              );
            }
          }
        )

        break;

      default:
        this.menuOption.map((option: any) => {
          if (option.id !== '1' && option.id !== 'logout') {
            option.visible = true;
          }
        })
        break

    }
  }

  /**
   *Elimina la sesion del usuario Actual y lo redirige al Login
  */

  logout(): void {
    this.showProgress = true;
    this.auth.logOut().pipe(
      delay(1000)
    ).subscribe(
      () => {
        this.router.navigate(['/auth/login']);
        this.sidenav2.toggle();
        this.showProgress = false;
      }
    );
  }
}
