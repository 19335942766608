import { InfoContribuyente } from '@app/pages/info-contribuyente/info-contribuyente';
import { createAction, props } from '@ngrx/store';

export const GET_INFO_CONTRIBUYENTE =
  '[Info Contribuyente] get info contribuyente';
export const GET_INFO_CONTRIBUYENTE_SUCCESS =
  '[Info Contribuyente] get info contribuyente success';
export const GET_INFO_CONTRIBUYENTE_FAIL =
  '[Info Contribuyente] get info contribuyente fail';

export const SET_INFO_CONTRIBUYENTE =
  '[Info Contribuyente] set info contribuyente';
export const SET_INFO_CONTRIBUYENTE_SUCCESS =
  '[Info Contribuyente] set info contribuyente success';
export const SET_INFO_CONTRIBUYENTE_FAIL =
  '[Info Contribuyente] set info contribuyente fail';

export const getInfoContribuyente = createAction(
  GET_INFO_CONTRIBUYENTE,
  props<{ id: number }>()
);

export const getInfoContribuyenteSuccess = createAction(
  GET_INFO_CONTRIBUYENTE_SUCCESS,
  props<{ infoContribuyente: InfoContribuyente }>()
);

export const getInfoContribuyenteFail = createAction(
  GET_INFO_CONTRIBUYENTE_FAIL,
  props<{ error: any }>()
);

export const setInfoContribuyente = createAction(
  SET_INFO_CONTRIBUYENTE,
  props<{ payload: any, id: number }>()
);

export const setInfoContribuyenteSuccess = createAction(
  SET_INFO_CONTRIBUYENTE_SUCCESS,
  props<{ infoContribuyenteSaved: any }>()
);

export const setInfoContribuyenteFail = createAction(
  SET_INFO_CONTRIBUYENTE_FAIL,
  props<{ error: any }>()
);
