import { createReducer, on, Action } from '@ngrx/store';
import { impuestoDiferidoAdapter, ImpuestoDiferidoState, } from '../states';
import * as actionsImpuestoDiferido from '@store/actions/impuesto-diferido.actions';

export const initialState: ImpuestoDiferidoState = impuestoDiferidoAdapter.getInitialState({
});

const impuestoDiferidoReducer = createReducer(
  initialState,

  on(actionsImpuestoDiferido.getImpuestoDiferidoSuccess, (state, { registroTotal }) => {
    return impuestoDiferidoAdapter.upsertOne(registroTotal, state)
  }),

  on(actionsImpuestoDiferido.getValorRenglonSuccess, (state, { resgistrosESF }) => {
    return impuestoDiferidoAdapter.upsertOne(resgistrosESF, state)
  }),

  on(actionsImpuestoDiferido.setImpuestoDiferidoSuccess, (state, { registroTotal }) => {
    return impuestoDiferidoAdapter.upsertOne(registroTotal, state)
  }),


);

export function reducer(state: ImpuestoDiferidoState | undefined, action: Action) {
  return impuestoDiferidoReducer(state, action);
}
