import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Nomina, NominaStore, NominaStoreSave } from '@app/store/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GastoPersonalService {
  constructor(private http: HttpClient) {}

  //Seccion1
  getNomina(projectId: number): Observable<NominaStore> {
    return this.http.get<NominaStore>(
      `/nomina?id=${projectId}`
    );
  }

  deleteNomina(
    projectId: number,
    seccion1Tabla: Nomina[]
  ): Observable<any> {
    return this.http.request<any>(
      'delete',
      `/nomina?id=${projectId}`,
      { body: [{listNominaDatosRenglonesDTO:seccion1Tabla}] }
    );
  }

  saveNomina(
    projectId: number,
    objNomina: NominaStoreSave
  ): Observable<NominaStore> {
    return this.http.post<NominaStore>(
      `/nomina?id=${projectId}`,
      [objNomina]
    );
  }
}
