import { createAction, props } from '@ngrx/store';
import { RelacionDeclaracionesStore } from '../models';

export const GET_RELACIONDECLARACIONES = '[relacionDeclaraciones] get relacionDeclaraciones';
export const GET_RELACIONDECLARACIONES_SUCCESS = '[relacionDeclaraciones] get relacionDeclaraciones success';
export const GET_RELACIONDECLARACIONES_ERROR = '[relacionDeclaraciones] get relacionDeclaraciones with error';

export const SET_RELACIONDECLARACIONES = '[relacionDeclaraciones] set relacionDeclaraciones save info';
export const SET_RELACIONDECLARACIONES_SUCCESS = '[relacionDeclaraciones] set relacionDeclaraciones save info success';
export const SET_RELACIONDECLARACIONES_ERROR = '[relacionDeclaraciones] set relacionDeclaraciones save info with error';

export const SET_DELETE_RELACIONDECLARACIONES = '[relacionDeclaraciones] set delete relacionDeclaraciones';
export const SET_DELETE_RELACIONDECLARACIONES_SUCCESS = '[relacionDeclaraciones] set delete relacionDeclaraciones success';
export const SET_DELETE_RELACIONDECLARACIONES_ERROR = '[relacionDeclaraciones] set delete relacionDeclaraciones with error';


//get RelacionDeclaraciones
export const getRelacionDeclaraciones = createAction(
  GET_RELACIONDECLARACIONES,
  props<{projectId: number}>()
);

export const getRelacionDeclaracionesSuccess = createAction(
  GET_RELACIONDECLARACIONES_SUCCESS,
  props<{ relacionDeclaracionesSuccess: RelacionDeclaracionesStore  }>()
);

export const getRelacionDeclaracionesError = createAction(
  GET_RELACIONDECLARACIONES_ERROR,
  props<{ error: string }>()
);

//create RelacionDeclaraciones
export const setRelacionDeclaraciones = createAction(
  SET_RELACIONDECLARACIONES,
  props<{ relacionDeclaracionesForm: RelacionDeclaracionesStore, projectId: number }>()
);

export const setRelacionDeclaracionesSuccess = createAction(
  SET_RELACIONDECLARACIONES_SUCCESS,
  props<{ relacionDeclaracionesForm: RelacionDeclaracionesStore }>()
);

export const setRelacionDeclaracionesError = createAction(
  SET_RELACIONDECLARACIONES_ERROR,
  props<{ error: string }>()
);

//delete RelacionDeclaraciones
export const setDeleteRelacionDeclaraciones = createAction(
  SET_DELETE_RELACIONDECLARACIONES,
  props<{ projectId: number, objRelacionDeclaraciones: RelacionDeclaracionesStore }>()
);

export const setDeleteRelacionDeclaracionesSuccess = createAction(
  SET_DELETE_RELACIONDECLARACIONES_SUCCESS,
  props<{ relacionDeclaraciones: RelacionDeclaracionesStore }>()
);

export const setDeleteRelacionDeclaracionesError = createAction(
  SET_DELETE_RELACIONDECLARACIONES_ERROR,
  props<{ error: string }>()
);
