import { createReducer, on, Action } from '@ngrx/store';
import { getPerdidasCompensarSuccess, setPerdidasCompensarSuccess, setDeletePerdidasCompensarSuccess } from '../actions';
import { perdidasCompensarAdapter, PerdidasCompensarState } from '../states';

export const initialState: PerdidasCompensarState = perdidasCompensarAdapter.getInitialState({})

const perdidasCompensarReducer = createReducer(
  initialState,

  on(getPerdidasCompensarSuccess, (state, { perdidasCompensarSuccess }) => {
    state.ids = [];
    state.entities = {};
    return perdidasCompensarAdapter.upsertOne(perdidasCompensarSuccess, { ...state })
  }),

  on(setPerdidasCompensarSuccess, (state, { perdidasCompensarForm }) => {
    state.ids = [];
    state.entities = {};
    return perdidasCompensarAdapter.upsertOne(perdidasCompensarForm, state)
  }),

  on(setDeletePerdidasCompensarSuccess, (state, { perdidasCompensar }) => {
    return perdidasCompensarAdapter.removeOne(perdidasCompensar.idProject, state)
  }),
)

export function reducer(state: PerdidasCompensarState | undefined, action: Action) {
  return perdidasCompensarReducer(state, action);
}
