import { Component, EventEmitter, Output, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ModalData } from './modal-models';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-modal-grl',
  templateUrl: './modal-grl.component.html',
  styleUrls: ['./modal-grl.component.scss'],
})
export class ModalGrlComponent implements OnInit {
  @Output() clickerEvent: EventEmitter<string> = new EventEmitter<string>();
  public selectedData = new FormControl('');
  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ModalGrlComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ModalData
  ) {}

  ngOnInit() {
  }

  public emitingData(datos: any, emiter: string) {
    let info: any;

    if (this.data.select) {
      //emite solo para selects
      switch (datos) {
        case 'next':
          info = {
            emiter: 'select',
            data: this.selectedData.value,
          };
          break;

        case 'back':
          info = {
            emiter: 'back',
            data: '',
          };
          break;
      }
    } else {
      //emite para todos los botones
      info = {
        emiter: emiter,
        data: datos,
      };
    }

    this.dialogRef.close(info);
  }
}
