import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  MSG_DURATION,
  NotifyService,
} from '@app/shared/services/notify.service';
import { IndustriaComercioService } from '@app/shared/services/industria-comercio.service';
import { getAjusteDepuracionIca, getAjusteDepuracionIcaError, getAjusteDepuracionIcaSuccess, setDeleteAjusteDepuracionIca, setDeleteAjusteDepuracionIcaError, setDeleteAjusteDepuracionIcaSuccess, setAjusteDepuracionIca, setAjusteDepuracionIcaError, setAjusteDepuracionIcaSuccess } from '../actions';
import { AjusteDepuracionIcaStore } from '../models';

@Injectable()
export class AjusteDepuracionIcaEffects {
  getAjusteDepuracionIca$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAjusteDepuracionIca),
      switchMap(({ projectId }) => {
        return this.industriaComercioService.getAjusteDepuracionIca(projectId).pipe(
          map((ajusteDepuracionIca) => {
            let ajusteDepuracionIcaSuccess: AjusteDepuracionIcaStore = {
              idProject: projectId,
              ...ajusteDepuracionIca,
            };
            return getAjusteDepuracionIcaSuccess({ ajusteDepuracionIcaSuccess });
          }),
          catchError((error) => {
            this.notify.error('Algo salio mal!', JSON.stringify(error), 5000, {
              text: 'Entendido',
              orientation: 'izq',
            });
            return of(getAjusteDepuracionIcaError({ error }));
          })
        );
      })
    )
  );

  saveAjusteDepuracionIca$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setAjusteDepuracionIca),
      switchMap(({ ajusteDepuracionIcaForm, projectId }) => {
        return this.industriaComercioService
          .saveAjusteDepuracionIca(projectId, ajusteDepuracionIcaForm)
          .pipe(
            map((ajusteDepuracionIca) => {
               let ajusteDepuracionIcaForm: AjusteDepuracionIcaStore = {
              idProject: projectId,
              ...ajusteDepuracionIca,
            };
              this.notify.success(
                '',
                'Guardado de registros exitoso',
                MSG_DURATION
              );
              return setAjusteDepuracionIcaSuccess({ ajusteDepuracionIcaForm });
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setAjusteDepuracionIcaError({ error }));
            })
          );
      })
    )
  );

  deleteAjusteDepuracionIca$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDeleteAjusteDepuracionIca),
      switchMap(({ projectId, objAjusteDepuracionIca }) => {
        return this.industriaComercioService
          .deleteAjusteDepuracionIca(projectId, objAjusteDepuracionIca)
          .pipe(
            map((ajusteDepuracionIca) => {
              this.notify.success(
                '',
                'Borrado de registros exitoso',
                MSG_DURATION
              );
              return setDeleteAjusteDepuracionIcaSuccess({ ajusteDepuracionIca });
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setDeleteAjusteDepuracionIcaError({ error }));
            })
          );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private industriaComercioService: IndustriaComercioService,
    private notify: NotifyService
  ) {}
}
