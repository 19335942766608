import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of, EMPTY } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ListProyectosService } from '@proyectos/services/list-proyectos.service';
import { Provision } from '../model/provision';

@Injectable({
  providedIn: 'root'
})
export class ProvisionService {

  constructor(
    private http: HttpClient,
    private projService: ListProyectosService
  ) {}

  getProvision() : Observable<Provision>{
    
    return this.projService.currentProject$.pipe(
      switchMap( project =>{
        if(project) return this.http.get<Provision>(`/provision_impuesto_ganancias?projectId=${project.id}`)
        return EMPTY
      }
      )
    ) 
  }

  saveProvision(provision: Provision) {
    return this.projService.currentProject$.pipe(
      switchMap(
        project =>{
          const provId = {...provision, projectId: project.id};
          return this.http.post<Provision>('/provision_impuesto_ganancias', provId )
        }
      )
    )
  }

}
