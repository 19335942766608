import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { LastYearInfo } from '@infoContribuyente/model/lastYearInfo';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LastYearInfoService {
    
  lastYearInfo: LastYearInfo;

  constructor(private http: HttpClient) { }

  getInfoAnioAnterior(projectId: number) : Observable<LastYearInfo>{
    return this.http.get<LastYearInfo>(`/informacion_anio_anterior?id=${projectId}`).pipe(
      tap(resp => this.lastYearInfo = resp)
    );
  }

  update(lastYearJson: LastYearInfo) : Observable<LastYearInfo>{
    return this.http.post<LastYearInfo>('/informacion_anio_anterior',  lastYearJson)
  }

}
