import { Injectable } from '@angular/core';
import { SpinnerService } from '@app/shared/services/spinner.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  getCurrentUser,
  getCurrentUserError,
  getCurrentUserSuccess,
} from '@auth/actions';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';

@Injectable()
export class AuthEffects {
  private getCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCurrentUser),
      tap(() => {
        this.spinner.hide();
      }),
      switchMap(() =>
        this.afAuth.authState.pipe(
          map((user: firebase.User): any => {
            if (user) {
              this.spinner.hide();
              return getCurrentUserSuccess({ user });
            }
          }),
          catchError((error) => {
            this.spinner.hide();
            return of(getCurrentUserError({ error }));
          })
        )
      )
    )
  );
  public constructor(
    private afAuth: AngularFireAuth,
    private actions$: Actions,
    private spinner: SpinnerService

  ) {}
}
