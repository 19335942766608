<ng-container *ngIf="isLoading$ | async">
  <ng-container *ngIf="isLoader$ | async; else percent">
    <div class="overlay">
      <div class="bar_progress">
        <div class="bar_progress_message">
          <mat-icon class="icon" svgIcon="renta-isotipo"></mat-icon>
          <p>{{ isMessage$ | async }}</p>
        </div>

        <p-progressBar [mode]="'indeterminate'"> </p-progressBar>
      </div>
    </div>
  </ng-container>
  <ng-template #percent>
    <div class="overlay">
      <div class="bar_progress">
        <div class="bar_progress_message">
          <mat-icon class="icon" svgIcon="renta-isotipo"></mat-icon>
          <p>{{ isMessage$ | async }}</p>
        </div>
        <p-progressBar
          [value]="isPercent | async"
          [mode]="(isDeterminate$ | async) || 'indeterminate'"
        >
        </p-progressBar>
      </div>
    </div>
  </ng-template>
</ng-container>