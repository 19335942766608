import { createReducer, on, Action } from '@ngrx/store';
import { ProvisionImpuestoGananciaState,  provisionImpuestoGananciaAdapter } from '../states';
import * as actionsProvisionImpuestoGanancia from '@store/actions/provision-impuesto-ganancia.actions';

export const initialState: ProvisionImpuestoGananciaState =
  provisionImpuestoGananciaAdapter.getInitialState({});

const provisionImpuestogananciaReducer = createReducer(
  initialState,

  on(actionsProvisionImpuestoGanancia.getProvisionImpuestoGananciaSuccess, (state, { provision }) => {
    state.entities = {};
    state.ids = [];
    return provisionImpuestoGananciaAdapter.upsertOne(provision, state);
  }),

  on(actionsProvisionImpuestoGanancia.setProvisionImpuestoGananciaSuccess, (state, { provisionSaved }) => {
    state.entities = {};
    state.ids = [];
    return provisionImpuestoGananciaAdapter.upsertOne(provisionSaved, state);
  }),
);

export function reducer(state: ProvisionImpuestoGananciaState | undefined, action: Action) {
  return provisionImpuestogananciaReducer(state, action);
}
