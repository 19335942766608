import { createReducer, on, Action } from '@ngrx/store';
import { getRelacionDeclaracionesSuccess, setRelacionDeclaracionesSuccess, setDeleteRelacionDeclaracionesSuccess } from '../actions';
import { relacionDeclaracionesAdapter, RelacionDeclaracionesState } from '../states';

export const initialState: RelacionDeclaracionesState = relacionDeclaracionesAdapter.getInitialState({})

const relacionDeclaracionesReducer = createReducer(
  initialState,

  on(getRelacionDeclaracionesSuccess, (state, { relacionDeclaracionesSuccess }) => {
    state.ids = [];
    state.entities = {};
    return relacionDeclaracionesAdapter.upsertOne(relacionDeclaracionesSuccess, { ...state })
  }),

  on(setRelacionDeclaracionesSuccess, (state, { relacionDeclaracionesForm }) => {
    state.ids = [];
    state.entities = {};
    return relacionDeclaracionesAdapter.upsertOne(relacionDeclaracionesForm, state)
  }),

  on(setDeleteRelacionDeclaracionesSuccess, (state, { relacionDeclaraciones }) => {
    return relacionDeclaracionesAdapter.removeOne(relacionDeclaraciones.idProject, state)
  }),
)

export function reducer(state: RelacionDeclaracionesState | undefined, action: Action) {
  return relacionDeclaracionesReducer(state, action);
}
