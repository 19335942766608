import { createReducer, on, Action } from '@ngrx/store';
import { AnticipoRentaState, anticipoRentaAdapter } from '@store/states';
import { getAnticipoRentaSuccess, setAnticipoRentaSuccess } from '../actions';

export const initialState: AnticipoRentaState = anticipoRentaAdapter.getInitialState({})

const anticipoRentaReducer = createReducer(
  initialState,

  on(getAnticipoRentaSuccess, (state, { anticipoRentaSuccess }) => {
    state.ids = [];
    state.entities = {};
    return anticipoRentaAdapter.upsertOne(anticipoRentaSuccess, { ...state })
  }),

  on(setAnticipoRentaSuccess, (state, { anticipoRentaForm }) => {
    state.ids = [];
    state.entities = {};
    return anticipoRentaAdapter.upsertOne(anticipoRentaForm, state)
  }),
)

export function reducer(state: AnticipoRentaState | undefined, action: Action) {
  return anticipoRentaReducer(state, action);
}
