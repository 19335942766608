import { AbstractControl, ValidationErrors, FormGroup } from '@angular/forms';

export const EMAIL_PATTERN: string = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
export const NAME_PATTERN: string = "^[a-zA-Z ]+$";

export const emailErrorMessages = {
    required: 'Este campo es obligatorio',
    email: 'Ingrese un correo válido',
    pattern: 'Ingrese un correo válido',
    minlength: 'La constraseña debe contener al menos 6 caractéres'
}

export function getErrorMessage(c: AbstractControl, errorMessage: any): string {
    let emailMessage = '';
    if ((c.touched || c.dirty) && c.errors) {
        const key = Object.keys(c.errors).find(
            key => errorMessage[key]
        );
        emailMessage = errorMessage[key];
    }
    return emailMessage;
}

export function compareValues(controlName1: string, controlName2: string) {
    return (formGroup: AbstractControl): ValidationErrors | null => {
        const control1 = formGroup.get(controlName1);
        const control2 = formGroup.get(controlName2);

        if ((control1?.value !== control2?.value) && (control1.dirty && control2.dirty)) {
            control1.setErrors({ ...control1.errors, noEquals: true })
            control2.setErrors({ ...control2.errors, noEquals: true })
            return { noEquals: true }
        }

        const errors = control1.errors;
        if (errors) {
            delete errors['noEquals'];
            Object.keys(errors).length > 0 ? control1.setErrors(errors) : control1.setErrors(null);
        }

        const errors2 = control2.errors;
        if (errors2) {
            delete errors2['noEquals'];
            Object.keys(errors2).length > 0 ? control2.setErrors(errors2) : control2.setErrors(null);
        }

        return null;
    }
}

export function isInvalid(controlName: string, form: FormGroup): boolean {
    return form.get(controlName).errors && form.get(controlName).touched;
}

function deleteError(control: AbstractControl, errorsName: string[]) : ValidationErrors | null {
    const errors = control.errors;
    if (errors) {
        errorsName.forEach(
            errorName => delete errors[errorName]
        );

        return Object.keys(errors).length > 0 ? errors: null;
    }
    
    return null;
}

export function compareDate(startDate: string, finishDate: string) {
    return (formControl: AbstractControl): ValidationErrors | null => {

        const initDate = formControl.get(startDate);
        const endDate = formControl.get(finishDate);

        if (initDate && endDate) {
            const start = new Date(initDate.value);
            const finish = new Date(endDate.value);

            if(start > finish){
                return { finishLess: 'La fecha de compra no puede ser mayor a la fecha de venta' };
            }
        }

        initDate.setErrors(deleteError(initDate, ['finishLess']));
        initDate.setErrors(deleteError(endDate, ['finishLess']));
        return null;
    }
}
