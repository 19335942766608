import { createReducer, on, Action } from '@ngrx/store';
import { RentaOpcionesState, rentaOpcionesAdapter } from '@store/states';
import { getRentaOpcionesSuccess } from '../actions';

export const initialState: RentaOpcionesState = rentaOpcionesAdapter.getInitialState({})

const rentaOpcionesReducer = createReducer(
  initialState,

  on(getRentaOpcionesSuccess, (state, { rentaOpcionesSuccess }) => {
    state.ids = [];
    state.entities = {};
    return rentaOpcionesAdapter.upsertOne(rentaOpcionesSuccess, { ...state })
  }),
)

export function reducer(state: RentaOpcionesState | undefined, action: Action) {
  return rentaOpcionesReducer(state, action);
}
