import { createAction, props } from '@ngrx/store';
import { AnticipoRentaOpciones } from '../models';

export const GET_RENTAOPCIONES = '[rentaOpciones] get rentaOpciones';
export const GET_RENTAOPCIONES_SUCCESS = '[rentaOpciones] get rentaOpciones success';
export const GET_RENTAOPCIONES_ERROR = '[rentaOpciones] get rentaOpciones with error';


//get RentaOpciones
export const getRentaOpciones = createAction(
  GET_RENTAOPCIONES,
  props<{projectId: number}>()
);

export const getRentaOpcionesSuccess = createAction(
  GET_RENTAOPCIONES_SUCCESS,
  props<{ rentaOpcionesSuccess: AnticipoRentaOpciones  }>()
);

export const getRentaOpcionesError = createAction(
  GET_RENTAOPCIONES_ERROR,
  props<{ error: string }>()
);
