<section>
  <div class="depu_header">
    <div class="depu_header_logo">
      <mat-icon svgIcon="renta-isotipo"></mat-icon>
    </div>

    <div class="depu_header_totals">
      <div class="depu_header_totals_card">
        <p class="depu_header_totals_card_title">Total valor contable</p>
        <p class="depu_header_totals_card_number">
          {{ totalContable | currency }}
        </p>
      </div>
      <div class="depu_header_totals_card">
        <p class="depu_header_totals_card_title">Efectos de conversión</p>
        <p class="depu_header_totals_card_number">
          {{ totalEfectos | currency }}
        </p>
      </div>
      <div class="depu_header_totals_card">
        <p class="depu_header_totals_card_title">Total menor valor fiscal</p>
        <p class="depu_header_totals_card_number">
          {{ totalMenorFiscal | currency }}
        </p>
      </div>
      <div class="depu_header_totals_card">
        <p class="depu_header_totals_card_title">Total mayor valor fiscal</p>
        <p class="depu_header_totals_card_number">
          {{ totalMayorFiscal | currency }}
        </p>
      </div>
      <div class="depu_header_totals_card">
        <p class="depu_header_totals_card_title">Total fiscal</p>
        <p class="depu_header_totals_card_number">
          {{ totalSaldoFiscal | currency }}
        </p>
      </div>
    </div>

    <div class="depu_header_buttons">
      <app-button-icon
        *ngFor="let but of buttonsActions"
        (clicker)="clickActions($event)"
        [clase]="but.class"
        [icon]="but.icon"
        [message]="but.label"
        [idButton]="but.id"
        [disabledLine]="false"
      >
      </app-button-icon>
      <app-button-icon
        (clicker)="clickActions($event)"
        [clase]="ActivateButtonEdit ? 'btn_icon_primary2' : 'btn_icon_primary'"
        [icon]="'edit'"
        [message]="'Editar varios'"
        [idButton]="'edit'"
        [disabledLine]="false"
      >
      </app-button-icon>
      <!-- plus -->
      <app-button-icon
        (clicker)="changeData()"
        [clase]="'btn_icon_primary'"
        [icon]="viewChangeData ? 'plus' : 'menos'"
        [message]="viewChangeData ? 'Expandir' : 'Ocultar'"
        [idButton]="'edit'"
        [disabledLine]="false"
      >
      </app-button-icon>

      <app-help-buttom></app-help-buttom>
    </div>
  </div>
  <div class="depu_table">
    <p-table
      #dt
      (onFilter)="calcularTotales()"
      [value]="registrosTabla"
      [resizableColumns]="true"
      [scrollable]="true"
      scrollDirection="both"
      [scrollHeight]="83 + 'vh'"
      [virtualScroll]="false"
      (onFilter)="getCurrentFilter($event)"
      [(selection)]="selectedRows"
    >
      <ng-template pTemplate="colgroup">
        <colgroup>
          <col style="width: 100px" *appToggleVisibility="!viewChangeData" />
          <col style="width: 100px" *appToggleVisibility="!viewChangeData" />
          <col style="width: 300px" *appToggleVisibility="!viewChangeData" />
          <col style="width: 115px" />
          <col style="width: 300px" />
          <col style="width: 200px" />
          <col *ngIf="visibleEfConversion" style="width: 200px" />
          <col style="width: 200px" />
          <col style="width: 200px" />
          <col style="width: 200px" />
          <col
            *ngFor="let tarifa of columnasTarifas(registros)"
            style="width: 200px"
          />
          <col style="width: 700px" />
        </colgroup>
      </ng-template>

      <ng-template pTemplate="header">
        <tr class="depu_table">
          <th
            scope="col"
            [ngClass]="'filtro'"
            pResizableColumn
            *appToggleVisibility="!viewChangeData"
          >
            <p-columnFilter
              class="filter_start center_icon"
              field="content.account"
              matchMode="starts"
              [showMenu]="false"
            >
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <p-tableHeaderCheckbox
                  (click)="cantidadSelect()"
                ></p-tableHeaderCheckbox>
                <ng-container
                  *ngIf="menuFilter > 0; else menuFiltering"
                  style="display: flex"
                >
                  <button
                    class="btn btn-close butAc"
                    (click)="filter(''); this.menuFilter = 0"
                  ></button>
                </ng-container>

                <ng-template #menuFiltering>
                  <mat-icon
                    [matMenuTriggerFor]="menu"
                    svgIcon="list_number"
                  ></mat-icon>
                  <mat-menu
                    #menu="matMenu"
                    [class]="''"
                    style="min-width: none !important"
                  >
                    <button
                      mat-menu-item
                      *ngFor="let clas of clases"
                      (click)="filter(clas); this.menuFilter = clas"
                    >
                      <span>{{ clas }}</span>
                    </button>
                  </mat-menu>
                </ng-template>
              </ng-template>
            </p-columnFilter>
          </th>
          <th
            pResizableColumn
            class="search_click"
            (click)="renglonHidden = false"
            *appToggleVisibility="!viewChangeData"
          >
            <ng-container *ngIf="renglonHidden; else renglonShow">
              Renglon
              <mat-icon svgIcon="search"> </mat-icon>
            </ng-container>
            <ng-template #renglonShow>
              <p-columnFilter
                (mouseout)="visibilityFilter($event, 'renglon')"
                (input)="visibilityFilter($event, 'renglon')"
                type="text"
                placeholder="Renglon"
                [showMenu]="false"
                field="content.renglon"
                matchMode="contains"
              >
              </p-columnFilter>
            </ng-template>
          </th>
          <th
            pResizableColumn
            class="search_click"
            (click)="descripcionHidden = false"
            *appToggleVisibility="!viewChangeData"
          >
            <ng-container *ngIf="descripcionHidden; else descripcionShow">
              Descripcion
              <mat-icon svgIcon="search"> </mat-icon>
            </ng-container>
            <ng-template #descripcionShow>
              <p-columnFilter
                type="text"
                (mouseout)="visibilityFilter($event, 'descripcion')"
                (input)="visibilityFilter($event, 'descripcion')"
                placeholder="descripcion 2516"
                [showMenu]="false"
                matchMode="starts"
                field="content.descripcion2516"
              >
              </p-columnFilter>
            </ng-template>
          </th>
          <th
            pResizableColumn
            class="search_click"
            (click)="cuentaHidden = false"
          >
            <ng-container *ngIf="cuentaHidden; else cuentaShow">
              Cuenta
              <mat-icon svgIcon="search"> </mat-icon>
            </ng-container>
            <ng-template #cuentaShow>
              <p-columnFilter
                type="text"
                (mouseout)="visibilityFilter($event, 'cuenta')"
                (input)="visibilityFilter($event, 'cuenta')"
                placeholder="Cuenta"
                [showMenu]="false"
                [showClearButton]="true"
                field="content.account"
                matchMode="starts"
              >
              </p-columnFilter>
            </ng-template>
          </th>
          <th
            pResizableColumn
            class="search_click"
            (click)="NombreCuentaHidden = false"
          >
            <ng-container *ngIf="NombreCuentaHidden; else nombreCuentaShow">
              Nombre Cuenta
              <mat-icon svgIcon="search"> </mat-icon>
            </ng-container>
            <ng-template #nombreCuentaShow>
              <p-columnFilter
                type="text"
                (mouseout)="visibilityFilter($event, 'nombre cuenta')"
                (input)="visibilityFilter($event, 'nombre cuenta')"
                placeholder="Nombre Cuenta"
                [showMenu]="false"
                field="content.nameAccount"
                matchMode="starts"
              >
              </p-columnFilter>
            </ng-template>
          </th>
          <th pResizableColumn>Valor contable</th>
          <th pResizableColumn *ngIf="visibleEfConversion">
            Efectos de conversion
          </th>
          <th pResizableColumn>Menor fiscal</th>
          <th pResizableColumn>Mayor fiscal</th>
          <th pResizableColumn>Saldo fiscal</th>
          <th
            pResizableColumn
            *ngFor="let tarifa of columnasTarifas(registros)"
          >
            Tarifa {{ tarifa.valorTarifa }} %
          </th>
          <th
            pResizableColumn
            class="search_click description_ajuste"
            (click)="valorFiscalHidden = false"
          >
            <ng-container *ngIf="valorFiscalHidden; else valorFiscalShow">
              Descripcion Ajuste fiscal
              <mat-icon svgIcon="search"> </mat-icon>
            </ng-container>
            <ng-template #valorFiscalShow>
              <p-columnFilter
                type="text"
                (mouseout)="visibilityFilter($event, 'valor fiscal')"
                (input)="visibilityFilter($event, 'valor fiscal')"
                placeholder="Descripcion Ajuste fiscal"
                [showMenu]="false"
                field="content.descAjusteFiscal"
                matchMode="contains"
              >
              </p-columnFilter>
            </ng-template>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-index="index">
        <tr *ngIf="rowData.isCategoria" class="especifico2">
          <td colspan="20">
            <ng-container>
              {{ rowData.content }}
            </ng-container>
          </td>
        </tr>
        <tr
          *ngIf="!rowData.isCategoria"
          [ngClass]="{
            'no-renglon':
              rowData.content.renglon < 0 || rowData.content.renglon == null,
            otros: rowData.content.type != 'BALANCE'
          }"
          [pSelectableRow]="rowData"
        >
          <td class="icons_actions" *appToggleVisibility="!viewChangeData">
            <ng-container *ngIf="rowData.content.type === 'BALANCE'">
              <p-tableCheckbox
                [disabled]="+rowData.content.clase !== selectedClass"
                [style]="
                  rowData.content.type === 'BALANCE'
                    ? 'padding-right: 10px;'
                    : 'padding-right: 22px;'
                "
                (mouseover)="disableLines(rowData.content)"
                (click)="cantidadSelect()"
                [value]="rowData"
              ></p-tableCheckbox>
              <mat-icon
                svgIcon="edit"
                (click)="editar(rowData.content)"
                [tabindex]="index"
              ></mat-icon>
              <mat-icon
                svgIcon="trash"
                *ngIf="rowData.content.type != 'BALANCE'"
                (click)="dialogoEliminar(rowData.content)"
                [tabindex]="index"
              ></mat-icon>
            </ng-container>

            <ng-template #menuDots>
              <mat-icon
                [matMenuTriggerFor]="dotsMenu"
                svgIcon="dots_menu"
              ></mat-icon>

              <mat-menu
                #dotsMenu="matMenu"
                class="menu_dots"
                xPosition="after"
                [class]="'custom_menu'"
              >
                <button mat-menu-item (click)="editar(rowData.content)">
                  <mat-icon svgIcon="edit"></mat-icon>
                  <span>Editar</span>
                </button>
                <button
                  mat-menu-item
                  *ngIf="
                    !['RENTAS_EXENTAS', 'INCRGO'].includes(rowData.content.type)
                  "
                  class="delete"
                  (click)="dialogoEliminar(rowData.content)"
                >
                  <mat-icon svgIcon="trash"></mat-icon>
                  <span>Borrar</span>
                </button>
              </mat-menu>
            </ng-template>
          </td>
          <td
            class="renglon"
            pEditableColumn
            [tabindex]="index"
            *appToggleVisibility="!viewChangeData"
          >
            <p-cellEditor>
              <ng-template pTemplate="input" let-row="rowData">
                <input
                  readonly
                  class="input-disable"
                  pInputText
                  type="text"
                  [(ngModel)]="rowData.content.renglon"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.content.renglon }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td
            pTooltip="{{ rowData.content.descripcion2516 }}"
            tooltipPosition="top"
            pEditableColumn
            [tabindex]="index"
            *appToggleVisibility="!viewChangeData"
          >
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  readonly
                  class="input-disable"
                  pInputText
                  type="text"
                  [(ngModel)]="rowData.content.descripcion2516"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.content.descripcion2516 }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn [tabindex]="index">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  readonly
                  class="input-disable"
                  pInputText
                  type="text"
                  [(ngModel)]="rowData.content.account"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.content.account }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn [tabindex]="index">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  readonly
                  class="input-disable"
                  pInputText
                  type="text"
                  [(ngModel)]="rowData.content.nameAccount"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.content.nameAccount }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td style="text-align: right" pEditableColumn [tabindex]="index">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  readonly
                  class="input-disable"
                  pInputText
                  type="text"
                  [(ngModel)]="rowData.content.valContable"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.content.valContable | currency }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td
            *ngIf="
              visibleEfConversion &&
              rowData.content.type == 'BALANCE' &&
              !rowData.content.sinEfFiscal
            "
            pEditableColumn
            [tabindex]="index"
            style="text-align: right"
          >
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  type="text"
                  mask="separator.2"
                  thousandSeparator=","
                  (onFocus)="this.onFocusValue(rowData.content.efConversion)"
                  [(ngModel)]="rowData.content.efConversion"
                  [allowNegativeNumbers]="true"
                  (keydown.enter)="calcularSaldo(rowData.content)"
                  (blur)="calcularSaldo(rowData.content)"
                  [tabindex]="index"
                  (click)="seleccionarTodo($event)"
                  (focus)="seleccionarTodo($event)"
                  (keydown)="seleccionarTodo2($event)"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.content.efConversion | currency }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td
            *ngIf="
              visibleEfConversion &&
              (rowData.content.type != 'BALANCE' || rowData.content.sinEfFiscal)
            "
            style="text-align: right"
            pEditableColumn
            [tabindex]="index"
          >
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  readonly
                  class="input-disable"
                  pInputText
                  type="text"
                  [(ngModel)]="rowData.content.efConversion"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.content.efConversion | currency }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td
            *ngIf="
              rowData.content.type == 'BALANCE' && !rowData.content.sinEfFiscal
            "
            pEditableColumn
            [tabindex]="index"
            style="text-align: right"
          >
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  type="text"
                  mask="separator.2"
                  thousandSeparator=","
                  (onFocus)="this.onFocusValue(rowData.content.creditos)"
                  [(ngModel)]="rowData.content.creditos"
                  (keydown.enter)="calcularSaldo(rowData.content)"
                  (blur)="calcularSaldo(rowData.content)"
                  [tabindex]="index"
                  (click)="seleccionarTodo($event)"
                  (focus)="seleccionarTodo($event)"
                  (keydown)="seleccionarTodo2($event)"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.content.creditos | currency }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td
            *ngIf="
              rowData.content.type != 'BALANCE' || rowData.content.sinEfFiscal
            "
            style="text-align: right"
            pEditableColumn
            [tabindex]="index"
          >
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  readonly
                  class="input-disable"
                  pInputText
                  type="text"
                  [(ngModel)]="rowData.content.creditos"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.content.creditos | currency }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td
            *ngIf="
              rowData.content.type == 'BALANCE' && !rowData.content.sinEfFiscal
            "
            pEditableColumn
            [tabindex]="index"
            style="text-align: right"
          >
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  type="text"
                  mask="separator.2"
                  thousandSeparator=","
                  [(ngModel)]="rowData.content.debitos"
                  (onFocus)="this.onFocusValue(rowData.content.debitos)"
                  (blur)="calcularSaldo(rowData.content)"
                  (keydown.enter)="calcularSaldo(rowData.content)"
                  [tabindex]="index"
                  (click)="seleccionarTodo($event)"
                  (focus)="seleccionarTodo($event)"
                  (keydown)="seleccionarTodo2($event)"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.content.debitos | currency }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td
            *ngIf="
              rowData.content.type != 'BALANCE' || rowData.content.sinEfFiscal
            "
            style="text-align: right"
            pEditableColumn
            [tabindex]="index"
          >
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  readonly
                  class="input-disable"
                  pInputText
                  type="text"
                  [(ngModel)]="rowData.content.debitos"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.content.debitos | currency }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td style="text-align: right" pEditableColumn [tabindex]="index">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  readonly
                  class="input-disable"
                  pInputText
                  type="text"
                  [(ngModel)]="rowData.content.valFiscal"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.content.valFiscal | currency }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td
            pEditableColumn
            *ngFor="let tarifa of rowData.content.tarifasList"
            pEditableColumn
            [tabindex]="index"
            style="text-align: right"
          >
            <p-cellEditor>
              <ng-template
                *ngIf="!tarifa.mayor && !rowData.content.sinEfFiscal"
                pTemplate="input"
              >
                <input
                  type="text"
                  mask="separator.2"
                  thousandSeparator=","
                  (onFocus)="this.onFocusValue(tarifa.amount)"
                  [(ngModel)]="tarifa.amount"
                  (blur)="
                    tarifa.amount = validarTarifas(tarifa, rowData.content)
                  "
                  (keydown.enter)="
                    tarifa.amount = validarTarifas(tarifa, rowData.content)
                  "
                  (click)="seleccionarTodo($event)"
                  (focus)="seleccionarTodo($event)"
                  (keydown)="seleccionarTodo2($event)"
                />
              </ng-template>
              <ng-template
                pTemplate="input"
                *ngIf="tarifa.mayor || rowData.content.sinEfFiscal"
              >
                <input
                  readonly
                  class="input-disable"
                  pInputText
                  type="text"
                  [(ngModel)]="tarifa.amount"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ tarifa.amount | currency }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td
            *ngIf="rowData.content.type == 'BALANCE'"
            pEditableColumn
            [tabindex]="index"
          >
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  pInputText
                  style="width: 700px"
                  [(ngModel)]="rowData.content.descAjusteFiscal"
                  (keydown)="guardarRegistro($event, rowData.content)"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.content.descAjusteFiscal }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td
            *ngIf="rowData.content.type != 'BALANCE'"
            class="description_ajuste"
            pEditableColumn
            [tabindex]="index"
          >
            <p-cellEditor>
              <ng-template pTemplate="input">
                <ng-container
                  *ngIf="
                    validateDescrTaxAdjLink(rowData.content.descAjusteFiscal);
                    else noLink
                  "
                >
                  <a
                    [routerLink]="[
                      getDescrTaxAdjLink(rowData.content.descAjusteFiscal)
                    ]"
                    target="_blank"
                    >{{ rowData.content.descAjusteFiscal }}</a
                  >
                </ng-container>
                <ng-template #noLink>
                  {{ rowData.content.descAjusteFiscal }}
                </ng-template>
              </ng-template>
              <ng-template pTemplate="output">
                <ng-container
                  *ngIf="
                    validateDescrTaxAdjLink(rowData.content.descAjusteFiscal);
                    else noLink
                  "
                >
                  <a
                    [routerLink]="[
                      getDescrTaxAdjLink(rowData.content.descAjusteFiscal)
                    ]"
                    target="_blank"
                    >{{ rowData.content.descAjusteFiscal }}</a
                  >
                </ng-container>
                <ng-template #noLink>
                  {{ rowData.content.descAjusteFiscal }}
                </ng-template>
              </ng-template>
            </p-cellEditor>
          </td>
        </tr>

        <tr pRowGroupHeader *ngIf="!registros.length">
          <td colspan="10" class="not_found">
            <img
              src="assets/img/no-result.png"
              style="vertical-align: middle"
            />
            <span class="p-text-bold p-ml-2">No encontramos nada</span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <ng-container *ngIf="registros.length === 0; else notFound">
          <tr>
            <td>
              <p-skeleton></p-skeleton>
            </td>
            <td>
              <p-skeleton></p-skeleton>
            </td>
            <td>
              <p-skeleton></p-skeleton>
            </td>
            <td>
              <p-skeleton></p-skeleton>
            </td>
            <td>
              <p-skeleton></p-skeleton>
            </td>
            <td>
              <p-skeleton></p-skeleton>
            </td>
            <td>
              <p-skeleton></p-skeleton>
            </td>
            <td>
              <p-skeleton></p-skeleton>
            </td>
            <td>
              <p-skeleton></p-skeleton>
            </td>
          </tr>
          <tr>
            <td>
              <p-skeleton></p-skeleton>
            </td>
            <td>
              <p-skeleton></p-skeleton>
            </td>
            <td>
              <p-skeleton></p-skeleton>
            </td>
            <td>
              <p-skeleton></p-skeleton>
            </td>
            <td>
              <p-skeleton></p-skeleton>
            </td>
            <td>
              <p-skeleton></p-skeleton>
            </td>
            <td>
              <p-skeleton></p-skeleton>
            </td>
            <td>
              <p-skeleton></p-skeleton>
            </td>
            <td>
              <p-skeleton></p-skeleton>
            </td>
          </tr>
          <tr>
            <td>
              <p-skeleton></p-skeleton>
            </td>
            <td>
              <p-skeleton></p-skeleton>
            </td>
            <td>
              <p-skeleton></p-skeleton>
            </td>
            <td>
              <p-skeleton></p-skeleton>
            </td>
            <td>
              <p-skeleton></p-skeleton>
            </td>
            <td>
              <p-skeleton></p-skeleton>
            </td>
            <td>
              <p-skeleton></p-skeleton>
            </td>
            <td>
              <p-skeleton></p-skeleton>
            </td>
            <td>
              <p-skeleton></p-skeleton>
            </td>
          </tr>
        </ng-container>
        <ng-template #notFound>
          <tr pRowGroupHeader>
            <td colspan="10" class="not_found">
              <img
                src="assets/img/no-result.png"
                style="vertical-align: middle"
              />
              <span class="p-text-bold p-ml-2"
                >No encontramos resultados con esta busqueda
              </span>
            </td>
          </tr>
        </ng-template>
      </ng-template>
    </p-table>
  </div>
</section>

<app-depuracion-window-alerts [message]="this.messageAlert">
</app-depuracion-window-alerts>
