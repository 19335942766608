import { Injectable } from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import { NotificationComponent } from '../components/notification-component/notification.component';

export const SHORT_MSG_DURATION = 2000;
export const MSG_DURATION = 3000;
export const LONG_MSG_DURATION = 5000;

export interface ButtonSnack {
  type?: 'info' | 'error' | 'success' | 'warning' | 'custom';
  icon?: string;
  text: string;
  orientation: 'izq' | 'der';
  link?: string;
}
export interface ButtonSnackCustom {
  type?: 'info' | 'error' | 'success' | 'warning' | 'custom';
  icon?: string;
  text: string;
  orientation: 'izq' | 'der';
  background?: string;
  color?: string;
  link?: string;
}

@Injectable({
  providedIn: 'root'
})

export class NotifyService {
  constructor(
    public snackBar: MatSnackBar
  ) {}

  // metodos publicos
  public warning(
    title: string,
    message: string,
    duration: number = null,
    action: ButtonSnack = null
  ){
    if (action){
      action.type = 'warning';
    }

    return this.open(
      title,
      message,
      duration,
      'warning',
      'warning',
      action,
    );
  }

  public error(
    title: string,
    message: string,
    duration: number = null,
    action: ButtonSnack = null
  ){
    if (action){
      action.type = 'error';
    }
    return this.open(
      title,
      message,
      duration,
      'error',
      'cancel_bold',
      action,
    );
  }

  public success(
    title: string,
    message: string,
    duration: number = null,
    action: ButtonSnack = null
  ) {
    if (action){
      action.type = 'success';
    }
    return this.open(
      title,
      message,
      duration,
      'success',
      'check_solid',
      action,
    );
  }

  public info(
    title: string,
    message: string,
    duration: number = null,
    action: ButtonSnack = null
  ){
    if (action){
      action.type = 'info';
    }
    return this.open(
      title,
      message,
      duration,
      'info',
      'info',
      action,
    );
  }

  // logica interna
  private open(
    title: string = null,
    message: string = null,
    duration: number,
    type: 'info' | 'error' | 'success' | 'warning' | 'custom' = 'info',
    icon: string,
    button: ButtonSnack = null,
    link: string = null
  ) {

    const config = new MatSnackBarConfig();

    config.horizontalPosition = 'right',
    config.verticalPosition = 'top',
    config.panelClass = ['notify', `${type}`],
    config.duration = duration ? duration : 0;
    config.data = {
        type,
        icon,
        message,
        title,
        button,
        link
      };

    return this.snackBar.openFromComponent(NotificationComponent, config);
  }

}

