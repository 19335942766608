import { createAction, props } from '@ngrx/store';
import { AjusteDepuracionStore, ListAutorretencionesDepuracionDTO,  } from '../models';

export const GET_AJUSTEDEPURACION = '[ajusteDepuracion] get ajusteDepuracion';
export const GET_AJUSTEDEPURACION_SUCCESS = '[ajusteDepuracion] get ajusteDepuracion success';
export const GET_AJUSTEDEPURACION_ERROR = '[ajusteDepuracion] get ajusteDepuracion with error';

export const SET_AJUSTEDEPURACION = '[ajusteDepuracion] set ajusteDepuracion save info';
export const SET_AJUSTEDEPURACION_SUCCESS = '[ajusteDepuracion] set ajusteDepuracion save info success';
export const SET_AJUSTEDEPURACION_ERROR = '[ajusteDepuracion] set ajusteDepuracion save info with error';

export const SET_DELETE_AJUSTEDEPURACION = '[ajusteDepuracion] set delete ajusteDepuracion';
export const SET_DELETE_AJUSTEDEPURACION_SUCCESS = '[ajusteDepuracion] set delete ajusteDepuracion success';
export const SET_DELETE_AJUSTEDEPURACION_ERROR = '[ajusteDepuracion] set delete ajusteDepuracion with error';

//get AjusteDepuracion
export const getAjusteDepuracion = createAction(
  GET_AJUSTEDEPURACION,
  props<{projectId: number}>()
);

export const getAjusteDepuracionSuccess = createAction(
  GET_AJUSTEDEPURACION_SUCCESS,
  props<{ ajusteDepuracionSuccess: AjusteDepuracionStore  }>()
);

export const getAjusteDepuracionError = createAction(
  GET_AJUSTEDEPURACION_ERROR,
  props<{ error: string }>()
);

//create AjusteDepuracion
export const setAjusteDepuracion = createAction(
  SET_AJUSTEDEPURACION,
  props<{ ajusteDepuracionForm: AjusteDepuracionStore, projectId: number }>()
);

export const setAjusteDepuracionSuccess = createAction(
  SET_AJUSTEDEPURACION_SUCCESS,
  props<{ ajusteDepuracionForm: AjusteDepuracionStore }>()
);

export const setAjusteDepuracionError = createAction(
  SET_AJUSTEDEPURACION_ERROR,
  props<{ error: string }>()
);

//delete AjusteDepuracion
export const setDeleteAjusteDepuracion = createAction(
  SET_DELETE_AJUSTEDEPURACION,
  props<{ projectId: number, objAjusteDepuracion: ListAutorretencionesDepuracionDTO[] }>()
);

export const setDeleteAjusteDepuracionSuccess = createAction(
  SET_DELETE_AJUSTEDEPURACION_SUCCESS,
  props<{ ajusteDepuracion: any }>()
);

export const setDeleteAjusteDepuracionError = createAction(
  SET_DELETE_AJUSTEDEPURACION_ERROR,
  props<{ error: string }>()
);
