<section
  [class]="sticky ? 'fixed' : 'no_fixed'"
  [ngStyle]="{ 'background-color': backColor }"
>
  <div [class]="modal ? 'header modal' : 'header'">
    <div class="header_title">
      <div class="header_title_group">
        <div *ngIf="icon !== ''" class="header_title_group_icon">
          <mat-icon class="header_title_icon" [svgIcon]="icon"></mat-icon>
        </div>
        <div class="header_title_group_text">
          {{ title }}
        </div>
      </div>

      <div class="header_title_tools">
        <!--Button Help-->

        <div
          class="header_title_tools_ buttons"
          style="margin-left: 6px; margin-right: 6px"
        >
          <app-help-buttom></app-help-buttom>
        </div>

        <div class="header_title_tools_ buttons" *ngIf="buttons.length">
          <ng-container *ngIf="menuTools; else noMenuTools">
            <app-menu-tools
              [buttonsArray]="buttons"
              (clicker)="evClick($event)"
            >
            </app-menu-tools>
          </ng-container>
          <ng-template #noMenuTools>
            <ng-container *ngIf="!onlyIcons; else buttonsIcons">
              <app-button
                class="header_title_tools_buttons_normal"
                (clicker)="evClick($event)"
                *ngFor="let but of buttons"
                [icon]="but.icon"
                [clase]="but.class"
                [text]="but.label"
                [idButton]="but.idButton"
                [iconDir]="but.orientation"
                [disabling]="but.disabling"
              >
              </app-button>
            </ng-container>

            <ng-template #buttonsIcons>
              <app-button-icon
                class="header_title_tools_buttons_icons"
                (clicker)="evClick($event)"
                *ngFor="let but of buttons"
                [icon]="but.icon"
                [clase]="but.class"
                [message]="but.message"
                [idButton]="but.idButton"
                [disabledLine]="but.disabledLine"
              >
              </app-button-icon>
            </ng-template>
          </ng-template>
        </div>

        <app-select-status-annexed
          class="header_title_tools_state"
          *ngIf="buttonState"
          [eventStartStatus$]="eventStatusStart"
        >
        </app-select-status-annexed>
      </div>
    </div>
    <div class="header_line">
      <hr class="hr1" />
      <hr class="hr2" />
    </div>
  </div>
</section>
