import { createReducer, on, Action } from '@ngrx/store';
import { infoContribuyenteAdapter, InfoContribuyenteState } from '../states';
import * as actionsInfoContribuyente from '@store/actions/info-contribuyente.actions';

export const initialState: InfoContribuyenteState = infoContribuyenteAdapter.getInitialState({
});

const infoContribuyenteReducer = createReducer(
  initialState,

  on(actionsInfoContribuyente.getInfoContribuyenteSuccess, (state, { infoContribuyente }) => {
    state.entities = {};
    state.ids = [];
    return infoContribuyenteAdapter.upsertOne(infoContribuyente, state)
  }),

  on(actionsInfoContribuyente.setInfoContribuyenteSuccess, (state, { infoContribuyenteSaved }) => {
    state.entities = {};
    state.ids = [];
    return infoContribuyenteAdapter.upsertOne(infoContribuyenteSaved, state)
  }),
);

export function reducer(state: InfoContribuyenteState | undefined, action: Action) {
  return infoContribuyenteReducer(state, action);
}
