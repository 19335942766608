<div class="modalGrl_close" mat-dialog-title>
  <h4 class="modalGrl_close_title">Reporte de Errores</h4>
  <mat-icon mat-dialog-close svgIcon="close_rounded"></mat-icon>
</div>

<mat-dialog-content class="modalGrl">

  <div class="modalGrl_contain">

    <div class="modalGrl_contain_message">

      <form [formGroup]="form">

        <div class="form-group">
          <label>Asunto:</label>
          <input formControlName="subject" class="form-control input-form">
        </div>

        <div class="form-group">
          <label>Descripción:</label>
          <textarea formControlName="description" class="form-control input-form"></textarea>
        </div>

        <app-dropzone
          (filesEmit)="handlerDropzone($event)"
          [fileTypeAccept]="[
            'image/png',
            'image/jpg',
            'image/jpeg'
          ]"
          [maxFilesNumber]="3"
          [maxFileSize]="2000000"
          [maxFileSizeString]="'2 Megabytes'">
        </app-dropzone>

      </form>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">

<app-button
  [icon]="'send_plane'"
  [text]="'Enviar reporte'"
  [iconDir]="'izq'"
  [idButton]="'guardar'"
  [clase]="'btn_success'"
  [disabling]="this.form.invalid"
  (clicker)="confirm(this.form.value)"
  class="me-2">
</app-button>

<app-button-icon
  mat-dialog-close
  icon="cancel_bold"
  message="Cerrar"
  [idButton]="'Cerrar'"
  [disabledLine]="false"
  [clase]="'btn_icon_danger'"
>
</app-button-icon>

</mat-dialog-actions>
