import { Component, EventEmitter, OnInit, Inject} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Type } from '@app/shared/interfaces/type';
import { NotifyService } from '@app/shared/services/notify.service';
import { ListTypeService } from '@app/shared/services/type-list.service';
import { ListProyectosService } from '@proyectos/services/list-proyectos.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-depuracion-window-modal-resumen',
  templateUrl: './depuracion-window-modal-resumen.component.html',
  styleUrls: ['./depuracion-window-modal-resumen.component.scss'],
  providers: []
})
export class DepuracionModalResumen implements OnInit {


  constructor(
    private notifyService: NotifyService,
    public dialogRef: MatDialogRef<DepuracionModalResumen>,
    @Inject(MAT_DIALOG_DATA)
      public data: any
    ){}

  ngOnInit(): void {
  }

  cerrar() {
    this.dialogRef.close();
  }

}
