<mat-tab-group dynamicHeight>
  <mat-tab label="Lista de iconos">
    <div class="list">
      <div class="list_mat_icon_render" *ngFor="let icon of iconsList">
        <mat-icon class="icons" [svgIcon]="icon"> </mat-icon>
        <span>
          {{ icon }}
        </span>
      </div>
      <mat-icon> </mat-icon>
    </div>
  </mat-tab>

  <mat-tab label="Componentes">
    <div class="example-large-box mat-elevation-z4">
      <section class="docu">
        <!--DOCU BOTON-->
        <div class="docu_row">
          <div class="docu_row_one">
            <p class="title">Boton con icono</p>
            <div class="description">
              Boton que contiene icono svg y texto:
              <ul>
                <li>
                  Al darle click emite un evento de tipo string con el mismo
                  valor de id que se le asigne en [idButton].
                </li>
              </ul>
            </div>
          </div>
          <div class="docu_row_two">
            <div>
              <app-button
                [icon]="'user_line'"
                [text]="'Soy Button'"
                [idButton]="'hey_button'"
              >
              </app-button>
            </div>
            <pre>
                        {{ buttonJson }}
                    </pre
            >
          </div>
        </div>
        <!--DOCU BOTON ICON-->
        <div class="docu_row">
          <div class="docu_row_one">
            <p class="title">Boton SOLO icono</p>
            <div class="description">
              Boton que contiene icono svg:
              <ul>
                <li>
                  Al darle click emite un evento de tipo string con el mismo
                  valor de id que se le asigne en [idButton].
                </li>
              </ul>
            </div>
          </div>
          <div class="docu_row_two">
            <div>
              <p>Sin linea habilitada</p>
              <app-button-icon
                [icon]="'save_bold'"
                [message]="'Hola Click'"
                [idButton]="'Soy_Button_icon'"
                [disabledLine]="false"
              >
              </app-button-icon>

              <p>Con linea habilitada</p>
              <app-button-icon
                [icon]="'save_bold'"
                [message]="'Hola Click'"
                [idButton]="'Soy_Button_icon'"
              >
              </app-button-icon>
            </div>
            <pre>
                        {{ buttonOnlyIcon }}
                    </pre
            >
          </div>
        </div>
        <!--DOCU CABECERA-->
        <div class="docu_row">
          <div class="docu_row_one">
            <p class="title">Cabecera</p>
            <div class="description">
              Cabecera para renderizar con icono, al usar [sticky] ="true" queda
              en posicion fixed sobre el contenido en la parte superior, al ir
              en false se movera con el resto del contenido,
              <b>por defecto [sticky] esta en "true"</b>
            </div>
          </div>
          <div class="docu_row_two">
            <div>
              <app-title
                [title]="'Cabecera'"
                [icon]="'archive'"
                [sticky]="false"
              >
              </app-title>
            </div>
            <pre>
                    {{ titleJson }}
                </pre
            >
          </div>
        </div>

        <!--DOCU CABECERA BOTONES-->
        <div class="docu_row">
          <div class="docu_row_one">
            <p class="title">Cabecera con Botones</p>
            <div class="description">
              Cabecera que contiene Botones de accion controlada por eventos:
              <ul>
                <li>
                  Al darle click emite un evento de tipo string con el mismo
                  valor de id que se le asigne en [idButton].
                </li>
              </ul>
            </div>
          </div>
          <div class="docu_row_two">
            <div style="width: 600px">
              <app-title
                [title]="'Cabecera'"
                [icon]="'info'"
                [sticky]="false"
                [buttons]="buttonsExample"
                (clicked)="alertButton($event)"
              >
              </app-title>
            </div>
            <pre>
                        {{ titleButtonsJson }}

                        {{ arrayButtonsJson }}
                    </pre
            >
          </div>
        </div>
      </section>
    </div>
  </mat-tab>
</mat-tab-group>
