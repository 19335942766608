import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonTool } from '../menu-tools/menu-tools.component';
import { FilterService, SelectItem } from 'primeng/api';
@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent {

  @Input() title: string = '';
  @Input() icon: string = '';
  @Input() buttons = [];
  @Input() sticky: boolean = true;
  @Input() onlyIcons = false;
  @Input() menuTools = false;
  @Input() modal = false;
  @Input() backColor: string = '#e5e5e5';
  @Input() eventStatusStart: EventEmitter<boolean>  = new EventEmitter<boolean>(false);

  @Output() clicked: EventEmitter<string>  = new EventEmitter<string>();
  @Input() buttonState?: boolean;

  public TitlesRentaAumento: any[] = [
  
    {
      name: 'VALOR',
      width: '200px',
      fieldName: "valor"
    }
  ];

  public TitlesRentaDisminucion: any[] = [
   
    {
      name: 'VALOR',
      width: '200px',
      fieldName: "valor"
    }
  ];
  public isFilterActiveTable1: Array<boolean> = new Array(this.TitlesRentaAumento.length).fill(false);
  public isFilterActiveTable2: Array<boolean> = new Array(this.TitlesRentaDisminucion.length).fill(false);
  matchModeOptions: SelectItem[];

  constructor(
    private filterService: FilterService) { }

  evClick(event: string){
    this.clicked.emit(event);
  }
  searchText = ''; // Variable para almacenar el texto de búsqueda

  // Función para filtrar los botones en función del texto de búsqueda
  filterButtons() {
    return this.buttons.filter(button =>
      button.label.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }
  
ngOnInit():void{
  const customFilterName = "custom-filter";

  this.filterService.register(customFilterName, (value, filter): boolean => {
    
    if (filter === undefined || filter === null || filter.trim() === '') {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }

    value = value.toString().toLowerCase();
    filter = filter.toString().toLowerCase();

    let pattern = `^${filter.replace(/[-/\\^$*+?.()|[\]{}]/g, '\$&').replace(/\*/g, '.*')}$`
    const regex = new RegExp(pattern, "i");

    if (value === filter) {
      return true;
    } else if (value.includes(filter)) {
      return true;
    } else if (regex.test(value)) {
      return true;
    } else {
      return false;
    }

  } )
}
activateFilter(event: any, index: number, table: string): void {
  if (table === "aumento") {
    this.isFilterActiveTable1[index] = true;
  } else if (table === "disminucion") {
    this.isFilterActiveTable2[index] = true;
  }
}

deactivateFilter(event: any, index: number, table: string) {
  if (event.target.value === "" && table === "aumento") {
    this.isFilterActiveTable1[index] = false;
  } else if (event.target.value === "" && table === "disminucion") {
    this.isFilterActiveTable2[index] = false;
  }
}
}
