import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AjusteDepuracionIcaStore,
  PasivosIcaStore,
  RelacionDeclaracionesStore,
  RelacionDeclaracionesTabla,
} from '@app/store/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IndustriaComercioService {
  constructor(private http: HttpClient) {}

  //sección1
  getRelacionDeclaraciones(
    projectId: number
  ): Observable<RelacionDeclaracionesStore> {
    return this.http.get<RelacionDeclaracionesStore>(
      `/declaracion_ica/getDeclaracion?id=${projectId}`
    );
  }

  deleteRelacionDeclaraciones(
    projectId: number,
    relacionDeclaraciones: RelacionDeclaracionesStore
  ): Observable<any> {
    return this.http.request<any>(
      'delete',
      `/declaracion_ica/deleteDeclaracion?id=${projectId}`,
      { body: relacionDeclaraciones }
    );
  }

  saveRelacionDeclaraciones(
    projectId: number,
    objRelacionDeclaraciones: RelacionDeclaracionesStore
  ): Observable<RelacionDeclaracionesStore> {
    return this.http.post<RelacionDeclaracionesStore>(
      `/declaracion_ica/saveDeclaracion?id=${projectId}`,
      objRelacionDeclaraciones
    );
  }

  getValueRelacionDeclaraciones(
    objRelacionDeclaraciones: any
  ) {
    return this.http.post<any>(
      `/declaracion_ica/checkDeclaraciones`,
      objRelacionDeclaraciones
    );
  }

  //sección 2
  getAjusteDepuracionIca(
    projectId: number
  ): Observable<AjusteDepuracionIcaStore> {
    return this.http.get<AjusteDepuracionIcaStore>(
      `/declaracion_ica/getSegDeclaracion?id=${projectId}`
    );
  }

  deleteAjusteDepuracionIca(
    projectId: number,
    ajusteDepuracionIca: AjusteDepuracionIcaStore
  ): Observable<any> {
    return this.http.request<any>(
      'delete',
      `/declaracion_ica/deleteSegDeclaracion?id=${projectId}`,
      { body: ajusteDepuracionIca }
    );
  }

  saveAjusteDepuracionIca(
    projectId: number,
    objAjusteDepuracionIca: AjusteDepuracionIcaStore
  ): Observable<AjusteDepuracionIcaStore> {
    return this.http.post<AjusteDepuracionIcaStore>(
      `/declaracion_ica/saveSegDeclaracion?id=${projectId}`,
      objAjusteDepuracionIca
    );
  }

  //sección 3
  getAjustePasivosIca(projectId: number): Observable<PasivosIcaStore> {
    return this.http.get<PasivosIcaStore>(
      `/declaracion_ica/getTerDeclaracion?id=${projectId}`
    );
  }

  deleteAjustePasivosIca(
    projectId: number,
    ajustePasivosIca: PasivosIcaStore
  ): Observable<any> {
    return this.http.request<any>(
      'delete',
      `/declaracion_ica/deleteTerDeclaracion?id=${projectId}`,
      { body: ajustePasivosIca }
    );
  }

  saveAjustePasivosIca(
    projectId: number,
    objAjustePasivosIca: PasivosIcaStore
  ): Observable<PasivosIcaStore> {
    return this.http.post<PasivosIcaStore>(
      `/declaracion_ica/saveTerDeclaracion?id=${projectId}`,
      objAjustePasivosIca
    );
  }

  getAccountValueDepuracion(
    projectId: number,
    account: number
  ): Observable<any> {
    return this.http.get(
      `/autorentenciones/accountValue?projectId=${projectId}&account=${account}`
    );
  }
}
