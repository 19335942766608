import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@auth/services/auth.service';
import firebase from 'firebase/app'
import UserCredential = firebase.auth.UserCredential;
@Component({
  selector: 'app-sign-with-email',
  templateUrl: './sign-with-email.component.html',
  styleUrls: ['./sign-with-email.component.scss']
})
export class SignWithEmailComponent implements OnInit {

  confirmEmailControl = new FormControl('', [Validators.required]);
  password = new FormControl('', [Validators.required, Validators.minLength(6)]);
  repeatPass = new FormControl('', [Validators.required, Validators.minLength(6)]);
  email: string;
  existEmail = false;
  errorMessage = '';
  visibleClave = false;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    this.email = localStorage.getItem('emailForSignIn');
    if (this.email) {
      this.confirmEmailControl.setValue(this.email);
      this.existEmail = true;
    }
  }

  ngOnInit(): void {
    this.confirmEmailControl.valueChanges.subscribe(v => {
      if (v.includes("@")) {
        if (v.includes("gmail.com")) {
          this.visibleClave = false;
          this.password.clearValidators();
          this.password.updateValueAndValidity();
          this.repeatPass.clearValidators();
          this.repeatPass.updateValueAndValidity();
        }
        else {
          this.visibleClave = true;
          this.password.setValidators([Validators.required, Validators.minLength(6)])
          this.repeatPass.setValidators([Validators.required, Validators.minLength(6)])
          this.password.updateValueAndValidity();
          this.repeatPass.updateValueAndValidity();
        }
      }
      else {
        this.visibleClave = false;
        this.password.clearValidators();
        this.repeatPass.clearValidators();
        this.password.updateValueAndValidity();
        this.repeatPass.updateValueAndValidity();
      }
    })
  }

  confirmEmail() {
    const url = this.router.url;
    if (this.confirmEmailControl.valid && this.password.valid && this.repeatPass.valid) {
      if (this.password.value == this.repeatPass.value) {
        this.authService.confirmEmail(url, this.confirmEmailControl.value).subscribe(
          resp => {
            let usuario = resp as UserCredential;
            if(this.password.value!=""){
            usuario.user.updatePassword(this.password.value)}
            localStorage.setItem('user', JSON.stringify(resp));
            this.authService.confirmUser().subscribe(conf => {
              alert('Tu email ha sido confirmado');
              this.router.navigate(['/auth/login']);
            }, error => {
              alert("se presento un error");
              console.log(error)
            })
          },
          error => alert(error.message)
        )
      }
      else {
        alert('Las contraseñas no coinciden');
      }
    } else {
      alert('informacion requerida');
    }
  }
}
