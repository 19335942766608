import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppInterceptor } from './app-interceptor.service';
import { SpinnerInterceptorService } from './spinner-interceptor.service';
//servicios utilitarios
import { NotifyService } from './notify.service';
import { FormGenerate } from './form.service';
import { DepuracionService } from './depuracion.service';
import { LocalStorageService } from './local-storage.service';
import { AuthService } from './auth.service';
import { UserAppService } from './user-app.service';
import { LoadingMessageService } from './loading-message.service';
import { ERIService } from './eri.service';
import { StatusService } from './status-project.service';
import { CuatroxMilGmfService } from './cuatrox-mil-gmf.service';
import { AnticipoRentaService } from './anticipo-renta.service';
import { AutorretencionesService } from './autorretenciones.service';
import { RetencionesService } from './retenciones.service';
import { IndustriaComercioService } from './industria-comercio.service';
import { MailService } from './send-mail';
import { EsalService } from './esal.service';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS, useClass:
    AppInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: SpinnerInterceptorService,
    multi: true
  }
];

export const SERVICES = [
  NotifyService,
  FormGenerate,
  DepuracionService,
  LocalStorageService,
  AuthService,
  UserAppService,
  LoadingMessageService,
  ERIService,
  StatusService,
  CuatroxMilGmfService,
  AnticipoRentaService,
  AutorretencionesService,
  RetencionesService,
  IndustriaComercioService,
  MailService,
  EsalService
]
