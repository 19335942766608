import { ProjectEffects } from './projects.effects';
import { BalanceEffects } from './balance.effects';
import { DepuracionEffects } from './depuracion.effects ';
import { EriEffects } from './eri.effects';
import { ExtrasEffects } from './extars.effects';
import { AnnexedStatusEffects } from './annexed-status.effects';
import { Seccion1GmfEffects } from './seccion1-gmf.effects';
import { Seccion2GmfEffects } from './seccion2-gmf.effects';
import { Seccion3GmfEffects } from './seccion3-gmf.effects';
import { DonacionEffects } from './donacion.effects';

import { ProjectStatusEffects } from './projects-status.effects';
import { InfoContribuyenteEffects } from './info-contribuyente.effects';
import { LastYearInfoEffects } from './last-year.effects';
import { RegistroESFEffects } from './esf.effects';
import { RentaOpcionesEffects } from './renta-opciones.effects';
import { AnticipoRentaEffects } from './anticipo-renta.effects';
import { RentaGravableEffects } from './renta-gravable.effects';
import { RentaExentaEffects } from './renta-exanta.effects';
import { ProvisionImpuestoGananciaEffects } from './provison-impuesto-ganancia.effects';
import { AfectacionFiscalEffects } from './afectacion-fiscal.effects';
import { GananciasOcacionalesEffects } from './ganancias-ocaciones.effects';
import { ImpuestoExteriorEffects } from './impuesto-exterior.effects';
import { ImpuestoDiferidoEffects } from './impuesto-diferido.effects';
import { CalculoDescuentoEffects } from './calculo-descuento.effects';
import { AutorretencionesEffects } from './autorretenciones.effects';
import { AutorreTotalesEffects } from './autorre-totales.effects';
import { AjusteDepuracionEffects } from './ajuste-depuracion.effects';
//import { RetefuenteEffects } from './retefuente.effects';
import { RelacionDeclaracionesEffects } from './relacion-declaraciones.effects';
import { AjusteDepuracionIcaEffects } from './ajuste-depuracion-ica.effects';
import { AjustePasivosIcaEffects } from './ajuste-pasivos-ica.effects';
import { PerdidasCompensarEffects } from './perdidas-compensar.effects';
import { ExcesosPresuntivaEffects } from './excesos-presuntiva.effects';
import { PerdidasCompensarInsolvenciaEffects } from './perdidas-compensar-insolvencia.effects';
import { NominaEffects } from './nomina.effects';
import { DistribucionEffect } from './distribucion.effects';

export const EFFECTS = [
  ProjectEffects,
  ProjectStatusEffects,
  DepuracionEffects,
  BalanceEffects,
  EriEffects,
  ExtrasEffects,
  AnnexedStatusEffects,
  Seccion1GmfEffects,
  Seccion2GmfEffects,
  Seccion3GmfEffects,
  DonacionEffects,
  ImpuestoExteriorEffects,
  CalculoDescuentoEffects,
  InfoContribuyenteEffects,
  LastYearInfoEffects,
  RegistroESFEffects,
  RentaOpcionesEffects,
  AnticipoRentaEffects,
  RentaGravableEffects,
  RentaExentaEffects,
  ProvisionImpuestoGananciaEffects,
  AfectacionFiscalEffects,
  GananciasOcacionalesEffects,
  ImpuestoDiferidoEffects,
  AutorretencionesEffects,
  AutorreTotalesEffects,
  AjusteDepuracionEffects,
  RelacionDeclaracionesEffects,
  RelacionDeclaracionesEffects,
  AjusteDepuracionIcaEffects,
  AjustePasivosIcaEffects,
  PerdidasCompensarEffects,
  ExcesosPresuntivaEffects,
  PerdidasCompensarInsolvenciaEffects,
  NominaEffects,
  DistribucionEffect
];
