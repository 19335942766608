import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { switchMap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';

import { CuentaRentaGravable, RenglonDepuracion, RENGLONES_DEP, TipoCuentaGravable } from '../model/renta-gravable';
import { ListProyectosService } from '@proyectos/services/list-proyectos.service';
import { RegistroDepuracion } from '@app/pages/depuracion/models/registro-depuracion';

@Injectable({
  providedIn: 'root'
})
export class RentaFiscalService {

  constructor(
    private http: HttpClient,
    private projSrv: ListProyectosService
  ) { }

  private _currentAccountsAction$ = new Subject<RenglonDepuracion[]>();
  currentAccounts$ = this._currentAccountsAction$.asObservable();

  getCuentas(): Observable<TipoCuentaGravable[]> {
    const title = sessionStorage.getItem('section');
    const rentaType = title === 'fiscal' ? 'AFECTACION_FISCAL' : 'RENTA_GRAVABLE';
    return this.http.get<TipoCuentaGravable[]>(`/options/ctaAfecFisc?type=${rentaType}`);
  }

  getRenglonAccount(accountId: number): Observable<RegistroDepuracion> {
    return this.projSrv.currentProject$.pipe(
      switchMap(
        project =>
          this.http.get<RegistroDepuracion>(`/afectacion_fiscal/ctasAfecFiscOthers?project=${project.id}&account=${accountId}`)
      )
    )
  }

  getCuenta(id: number): Observable<CuentaRentaGravable> {
    return this.projSrv.currentProject$.pipe(
      switchMap(project => this.http.get<CuentaRentaGravable>(`/options/ctaAfecFiscById?id=${id}&project=${project.id}`))
    );
    // return this.http.get<CuentaRentaGravable>(`/options/ctaAfecFiscById?id=${id}`)
  }

  getCuentasGravables(): Observable<RenglonDepuracion[]> {
    this._currentAccountsAction$.next(RENGLONES_DEP);
    return of(RENGLONES_DEP);
  }

  createReglonRenta(newRenglon: RegistroDepuracion,idProyect:number): Observable<RegistroDepuracion> {
        const body = { ...newRenglon, projectId: idProyect };
        return this.http.post<RegistroDepuracion>('/afectacion_fiscal', body)
      
  }
}
