import { NgModule } from '@angular/core';
import { AuthSharedModule } from './auth-shared.module';
import { AuthRoutingModule } from '@auth/auth-routing.module';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import * as fromReducers from './reducer';
import * as fromEffects from './effects';

@NgModule({
  declarations: [],
  imports: [
    AuthSharedModule,
    AuthRoutingModule,
    StoreModule.forFeature('auth-module', fromReducers.reducers, {
      metaReducers: fromReducers.metaReducers
    }),
    EffectsModule.forFeature(fromEffects.EFFECTS),
  ]
})
export class AuthModule { }
