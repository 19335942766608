import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { NotifyService } from '@app/shared/services/notify.service';
import { RentaExentaStore } from '../models/renta-exenta.model';
import { RentaExentaService } from '@app/pages/anex-depuracion/services/renta-exenta.service';
import * as actionsRentaExenta from '../actions/renta-exenta.actions';
import { RentaExenta } from '@app/pages/anex-depuracion/model/renta-exenta';

@Injectable()
export class RentaExentaEffects {
  setSaveRentaExenta$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsRentaExenta.setCrearRentaExenta),
      switchMap((action) => {
        return this.rentaExentaService.saveRenta(action.payload).pipe(
          map((rentaExentaSaved: RentaExenta) => {
            const rentaExentaStore: RentaExentaStore = {
              projectId: action.projectId,
              newRentaExenta: rentaExentaSaved,
            };
            return actionsRentaExenta.setCrearRentaExentaSuccess({
              rentaExentaCreada: rentaExentaStore,
            });
          }),
          catchError((error) => {
            this.notify.error(
              'Algo salio mal! ',
              'No pudimos cargar la información del contribuyente',
              4000
            );
            return of(actionsRentaExenta.setCrearRentaExentaFail({ error }));
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private notify: NotifyService,
    private rentaExentaService: RentaExentaService
  ) {}
}
