import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  getInfoContribuyente,
  getInfoContribuyenteFail,
  getInfoContribuyenteSuccess,
  setInfoContribuyente,
  setInfoContribuyenteFail,
  setInfoContribuyenteSuccess,
} from '../actions';
import { NotifyService } from '@app/shared/services/notify.service';
import { InfoContribuyenteService } from '@app/pages/info-contribuyente/services/info-contribuyente.service';
import { InfoContribuyente } from '@app/pages/info-contribuyente/info-contribuyente';

@Injectable()
export class InfoContribuyenteEffects {
  getInfoContribuyente$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInfoContribuyente),
      switchMap((action) => {
        return this.infoService.obtenerInformacion(action.id).pipe(
          map((infoContribuyente: InfoContribuyente) => {
            const id = infoContribuyente?.id
              ? infoContribuyente?.id
              : action?.id;
            return getInfoContribuyenteSuccess({
              infoContribuyente: { ...infoContribuyente, id },
            });
          }),
          catchError((error) => {
            this.notify.error(
              'Algo salio mal! ',
              'No pudimos cargar la información del contribuyente',
              4000
            );
            return of(getInfoContribuyenteFail({ error }));
          })
        );
      })
    )
  );

  setInfoContribuyente$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setInfoContribuyente),
      switchMap((action) => {
        return this.infoService.guardarInformacion(action.payload).pipe(
          map((infoContribuyenteSaved: InfoContribuyente) => {
            const id = infoContribuyenteSaved?.id
            ? infoContribuyenteSaved?.id
            : action?.id;
            return setInfoContribuyenteSuccess({ infoContribuyenteSaved: { ...infoContribuyenteSaved, id } })}),
          catchError((error) => {
            this.notify.error(
              'Algo salio mal! ',
              'No pudimos guardar la infromación del contribuyente',
              4000
            );
            return of(setInfoContribuyenteFail({ error }));
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private notify: NotifyService,
    private infoService: InfoContribuyenteService
  ) {}
}
