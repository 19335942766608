import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as actionsRentaGravable from '../actions/renta-gravable.actions';
import { NotifyService } from '@app/shared/services/notify.service';
import { RentaFiscalService } from '@app/pages/renta-gravable/services/renta-fiscal.service';
import { RentaGravableStore } from '../models/renta-gravable';
import { RegistroDepuracion } from '@app/pages/depuracion/models/registro-depuracion';

@Injectable()
export class RentaGravableEffects {
  getCuentas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionsRentaGravable.setCrearRenglonRenta),
      switchMap((action) => {
        return this.rentaFiscalService.createReglonRenta(action.payload,action.projectId).pipe(
          map((tipoDeCuentasGravable: RegistroDepuracion) => {
            const rentaGravableStore: RentaGravableStore = {
              projectId: action.projectId,
              reglonCreadoRegistroDepuracion: tipoDeCuentasGravable,
            };
            return actionsRentaGravable.setCrearRenglonRentaSuccess({
              registroDepuracionCreado: rentaGravableStore,
            });
          }),
          catchError((error) => {
            this.notify.error(
              'Algo salio mal! ',
              'No pudimos cargar la información del contribuyente',
              4000
            );
            return of(actionsRentaGravable.setCrearRenglonRentaFail({ error }));
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private notify: NotifyService,
    private rentaFiscalService: RentaFiscalService
  ) {}
}
