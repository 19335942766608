import { createAction, props } from '@ngrx/store';
import { ExcesosPresuntivaSaveOne, ExcesosPresuntivaStore, TotalesExcesosPresuntiva } from '../models';

export const GET_EXCESOSPRESUNTIVA = '[ExcesosPresuntiva] get ExcesosPresuntiva';
export const GET_EXCESOSPRESUNTIVA_SUCCESS = '[ExcesosPresuntiva] get ExcesosPresuntiva success';
export const GET_EXCESOSPRESUNTIVA_ERROR = '[ExcesosPresuntiva] get ExcesosPresuntiva with error';

export const SET_EXCESOSPRESUNTIVA = '[ExcesosPresuntiva] set ExcesosPresuntiva save info';
export const SET_EXCESOSPRESUNTIVA_SUCCESS = '[ExcesosPresuntiva] set ExcesosPresuntiva save info success';
export const SET_EXCESOSPRESUNTIVA_ERROR = '[ExcesosPresuntiva] set ExcesosPresuntiva save info with error';

export const SET_DELETE_EXCESOSPRESUNTIVA = '[ExcesosPresuntiva] set delete ExcesosPresuntiva';
export const SET_DELETE_EXCESOSPRESUNTIVA_SUCCESS = '[ExcesosPresuntiva] set delete ExcesosPresuntiva success';
export const SET_DELETE_EXCESOSPRESUNTIVA_ERROR = '[ExcesosPresuntiva] set delete ExcesosPresuntiva with error';

//get ExcesosPresuntiva
export const getExcesosPresuntiva = createAction(
  GET_EXCESOSPRESUNTIVA,
  props<{projectId: number}>()
);

export const getExcesosPresuntivaSuccess = createAction(
  GET_EXCESOSPRESUNTIVA_SUCCESS,
  props<{ excesosPresuntivaSuccess: ExcesosPresuntivaStore  }>()
);

export const getExcesosPresuntivaError = createAction(
  GET_EXCESOSPRESUNTIVA_ERROR,
  props<{ error: string }>()
);

//create ExcesosPresuntiva
export const setExcesosPresuntiva = createAction(
  SET_EXCESOSPRESUNTIVA,
  props<{ excesosPresuntivaForm: ExcesosPresuntivaSaveOne, projectId: number }>()
);

export const setExcesosPresuntivaSuccess = createAction(
  SET_EXCESOSPRESUNTIVA_SUCCESS,
  props<{ excesosPresuntivaForm: ExcesosPresuntivaStore }>()
);

export const setExcesosPresuntivaError = createAction(
  SET_EXCESOSPRESUNTIVA_ERROR,
  props<{ error: string }>()
);

//delete ExcesosPresuntiva
export const setDeleteExcesosPresuntiva = createAction(
  SET_DELETE_EXCESOSPRESUNTIVA,
  props<{ projectId: number, idRow: number, objExcesosPresuntiva: TotalesExcesosPresuntiva }>()
);

export const setDeleteExcesosPresuntivaSuccess = createAction(
  SET_DELETE_EXCESOSPRESUNTIVA_SUCCESS,
  props<{ excesosPresuntiva: ExcesosPresuntivaStore }>()
);

export const setDeleteExcesosPresuntivaError = createAction(
  SET_DELETE_EXCESOSPRESUNTIVA_ERROR,
  props<{ error: string }>()
);
