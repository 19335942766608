import { createReducer, on, Action } from '@ngrx/store';
import { AnnexedStatusState, annexedAdapter } from '@store/states/annexed-status.state';
import {
  getAllAnnexedStatusSuccess,
  setAnnexedStatusSuccess,
  resetAnnexedStatusSuccess
} from '../actions/annexed-status.actions';

export const initialState: AnnexedStatusState = annexedAdapter.getInitialState({
});

const { selectAll } = annexedAdapter.getSelectors();

const annexedReducer = createReducer(
  initialState,

  on(getAllAnnexedStatusSuccess, (state,  { annexedStatusGroup }) => {
    state.ids = [];
    state.entities = {};
    return annexedAdapter.setAll(annexedStatusGroup, state)
  }),

  on(setAnnexedStatusSuccess, (state,  { annexedStatus }) => {
    return annexedAdapter.upsertOne(annexedStatus, state)
  }),

  on(resetAnnexedStatusSuccess, (state,  { annexedStatusGroup }) => {
    state.ids = [];
    state.entities = {};
    return annexedAdapter.setAll(annexedStatusGroup, state)
  }),
)

export function reducer(state: AnnexedStatusState | undefined, action: Action) {
  return annexedReducer(state, action);
}
