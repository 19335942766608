import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-desicion-excel-modal',
  templateUrl: './confirmation-desicion-excel-modal.component.html',
  styleUrls: ['./confirmation-desicion-excel-modal.component.scss']
})
export class ConfirmationDesicionExcelModalComponent implements OnInit {

  constructor(    public dialogRef: MatDialogRef<ConfirmationDesicionExcelModalComponent>,
  ) { }

  ngOnInit(): void {
  }
  
  actionDialog(type: string): void {
    this.dialogRef.close(type);
  }

}
