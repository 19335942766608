import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { map, switchMap, catchError, debounce, debounceTime } from "rxjs/operators";
import { of } from "rxjs";
import { routeAnex, ROUTES_ANEX } from "@app/shared/components/select-status-annexed/annexed";
import { NotifyService } from "@app/shared/services/notify.service";
import { AnnexedStatus } from "@store/models";
import { StatusService } from "@app/shared/services/status-project.service";
import {
  setAnnexedStatus,
  setAnnexedStatusSuccess,
  setAnnexedStatusError,
  getAllAnnexedStatus,
  getAllAnnexedStatusSuccess,
  getAllAnnexedStatusError,
  resetAnnexedStatus,
  resetAnnexedStatusSuccess,
  resetAnnexedStatusError
} from "../actions/annexed-status.actions";
import { selectAnnexedStatusList } from "../selectors/annexed-status.selectors";

@Injectable()
export class AnnexedStatusEffects {

  getAllStatusAnnexed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllAnnexedStatus),
      switchMap(({ projectId }) =>

        this.statusService.getAllStatusTabAnnexed(projectId).pipe(
          map((annexedstatus: AnnexedStatus[]) => {
            const annexGroup: AnnexedStatus[] = [];
             annexedstatus.map((anex:AnnexedStatus) => {
              const routeAnex = ROUTES_ANEX.find(route => route.tab === anex.tab && route.route !== '');
              if(routeAnex){
                annexGroup.push({
                  id: routeAnex.id,
                  projectId: projectId,
                  tab: anex.tab,
                  annexed: anex.annexed,
                  status: anex.status
                })
              }
              return routeAnex ? routeAnex.id : null;
            })
            return getAllAnnexedStatusSuccess({ annexedStatusGroup: annexGroup })

          }),
          catchError((error) => {
            return of(getAllAnnexedStatusError({ error }))
          })
        )
      )
    )
  );

  setStatusAnnexed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setAnnexedStatus),
      debounceTime(800),
      switchMap(({ annexedStatus }) => {
        return this.statusService.updateStatusProject(annexedStatus)
        .pipe(map((responseStatus: AnnexedStatus) => {
          console.log('la response en update status => ', responseStatus);
          //TODO: revisar funcionamiento cuando back retorne algo
          const status: AnnexedStatus = {
            id: annexedStatus.id,
            projectId: responseStatus.projectId,
            annexed: responseStatus.annexed,
            tab: responseStatus.tab,
            status: responseStatus.status
          }
          //const status: AnnexedStatus = { ...annexedStatus}

          return setAnnexedStatusSuccess({ annexedStatus: status })
        }),
          catchError((error) => {
            return of(setAnnexedStatusError({ error }))
          })
        )}
      )
    )
  );

  resetAnnexedStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetAnnexedStatus),
      switchMap(({ projectId, newStatus }) =>
        this.statusService.updateAllStatusProjects(projectId, newStatus)
        .pipe(map((statusChanged: AnnexedStatus[]) => {

          const annexGroup: AnnexedStatus[] = [];

          statusChanged.map((anex:AnnexedStatus) => {

            let newId: number;

            ROUTES_ANEX.find((route: routeAnex) => {
              //mapeamos los estados de cada tab dentro de los anexos y si tiene una ruta existente se guarda al store, si no se ignora
              if(route.tab === anex.tab && route.route !== ''){
                return newId = route.id
              } else {
                return newId = 0
              }
            })

            if(newId !== 0){
              annexGroup.push({
                id: newId,
                projectId: projectId,
                tab: anex.tab,
                annexed: anex.annexed,
                status: anex.status
              })
            }
          })

            return resetAnnexedStatusSuccess({ annexedStatusGroup: annexGroup })
          }),
          catchError((error) => {
            return of(resetAnnexedStatusError({ error }))
          })
        )
    )));



  public constructor(
    private actions$: Actions,
    private statusService: StatusService,
    private notify: NotifyService,
    private store: Store
  ) {}
}
