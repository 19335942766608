import { RegistroESF } from '@app/pages/formato2516/components/esfpatrimonio/model/esf';
import { createAction, props } from '@ngrx/store';
import { ESFRegisterStore } from '../models/esf.register.model';

export const GET_ESF = '[Register ESF] get esf';
export const GET_ESF_SUCCESS = '[Register ESF] get esf success';
export const GET_ESF_FAIL = '[Register ESF] get esf fail';

export const SET_ESF = '[Register ESF] set esf';
export const SET_ESF_SUCCESS = '[Register ESF] set esf success';
export const SET_ESF_FAIL = '[Register ESF] set esf fail';

export const getEsf = createAction(GET_ESF, props<{ id: number }>());

export const getEsfSuccess = createAction(
  GET_ESF_SUCCESS,
  props<{ resgiterEsf: ESFRegisterStore }>()
);

export const getEsfFail = createAction(GET_ESF_FAIL, props<{ error: any }>());

export const setEsf = createAction(
  SET_ESF,
  props<{ payload: any; id: number }>()
);

export const setEsfSuccess = createAction(
  SET_ESF_SUCCESS,
  props<{ resgiterEsfSaved: ESFRegisterStore }>()
);

export const setEsfFail = createAction(SET_ESF_FAIL, props<{ error: any }>());
