import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button-icon',
  templateUrl: './button-icon.component.html',
  styleUrls: ['./button-icon.component.scss']
})
export class ButtonIconComponent {
  @Input() icon: string = '';
  @Input() message: string = '';
  @Input() idButton: string = '';
  @Input() disabledLine: boolean = false;
  @Input() clase: string = 'btn_icon_tools' || 'btn_icon_primary' || 'btn_icon_success' || 'btn_icon_danger' || 'btn_icon_tools' || 'btn_icon_primary2';

  @Output() clicker: EventEmitter<string>  = new EventEmitter<string>();

  constructor(
  ) { }
  
  public onEmit(){
    this.clicker.emit(this.idButton)
  }
}
