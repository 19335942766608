import { createAction, props } from '@ngrx/store';
import { GanaciasOcacionalesStore } from '../models';

export const GET_GANANCIA_OCACIONAL_OTRA_LISTA =
  '[Ganancia Ocacional] get ganancia ocacional lista';
export const GET_GANANCIA_OCACIONAL_OTRA_LISTA_SUCCESS =
  '[Ganancia Ocacional] get ganancia ocacional lista success';
export const GET_GANANCIA_OCACIONAL_OTRA_LISTA_FAIL =
  '[Ganancia Ocacional] get ganancia ocacional lista fail';


export const getGanaciaOcacionalLista = createAction(
  GET_GANANCIA_OCACIONAL_OTRA_LISTA,
  props<{ projectId: number }>()
);
export const getGanaciaOcacionalListaSuccess = createAction(
  GET_GANANCIA_OCACIONAL_OTRA_LISTA_SUCCESS,
  props<{ gananciaOcacionalOtraLista: GanaciasOcacionalesStore }>( 
));

export const getGanaciaOcacionalListaFail = createAction(
  GET_GANANCIA_OCACIONAL_OTRA_LISTA_FAIL,
  props<{ error: any }>()
);
