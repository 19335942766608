import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  MSG_DURATION,
  NotifyService,
} from '@app/shared/services/notify.service';
import { getAjusteDepuracion, getAjusteDepuracionError, getAjusteDepuracionSuccess, setAjusteDepuracion, setAjusteDepuracionError, setAjusteDepuracionSuccess, setDeleteAjusteDepuracion, setDeleteAjusteDepuracionError, setDeleteAjusteDepuracionSuccess } from '../actions';
import { AutorretencionesService } from '@app/shared/services/autorretenciones.service';
import { AjusteDepuracionStore } from '../models';

@Injectable()
export class AjusteDepuracionEffects {
  getAjusteDepuracion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAjusteDepuracion),
      switchMap(({ projectId }) => {
        return this.autorretencionesService.getAjusteDepuracion(projectId).pipe(
          map((ajusteDepuracion) => {
            let ajusteDepuracionSuccess: AjusteDepuracionStore = {
              idProject: projectId,
              ...ajusteDepuracion,
            };
            return getAjusteDepuracionSuccess({ ajusteDepuracionSuccess });
          }),
          catchError((error) => {
            this.notify.error('Algo salio mal!', JSON.stringify(error), 5000, {
              text: 'Entendido',
              orientation: 'izq',
            });
            return of(getAjusteDepuracionError({ error }));
          })
        );
      })
    )
  );

  saveAjusteDepuracion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setAjusteDepuracion),
      switchMap(({ ajusteDepuracionForm, projectId }) => {
        return this.autorretencionesService
          .saveAjusteDepuracion(projectId, ajusteDepuracionForm)
          .pipe(
            map((ajusteDepuracion) => {
               let ajusteDepuracionForm: AjusteDepuracionStore = {
              idProject: projectId,
              ...ajusteDepuracion,
            };
              this.notify.success(
                '',
                'Guardado de registros exitoso',
                MSG_DURATION
              );
              return setAjusteDepuracionSuccess({ ajusteDepuracionForm });
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setAjusteDepuracionError({ error }));
            })
          );
      })
    )
  );

  deleteAjusteDepuracion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDeleteAjusteDepuracion),
      switchMap(({ projectId, objAjusteDepuracion }) => {
        return this.autorretencionesService
          .deleteAjusteDepuracion(projectId, objAjusteDepuracion)
          .pipe(
            map((ajusteDepuracion) => {
              this.notify.success(
                '',
                'Borrado de registros exitoso',
                MSG_DURATION
              );
              return setDeleteAjusteDepuracionSuccess({ ajusteDepuracion });
            }),
            catchError((error) => {
              console.log(error);
              this.notify.error(
                'Algo salio mal!',
                JSON.stringify(error),
                5000,
                {
                  text: 'Entendido',
                  orientation: 'izq',
                }
              );
              return of(setDeleteAjusteDepuracionError({ error }));
            })
          );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private autorretencionesService: AutorretencionesService,
    private notify: NotifyService
  ) {}
}
