import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { NotifyService } from '@app/shared/services/notify.service';

@Component({
  selector: 'app-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss']
})
export class DropzoneComponent implements OnInit{
  @Output() filesEmit: EventEmitter<any[]> =  new EventEmitter<any[]>();
  @Output() files64Emit: EventEmitter<any[]> =  new EventEmitter<any[]>();
  @Input() maxFileSize: number;
  @Input() maxFilesNumber: number = 1;
  @Input() maxFileSizeString: string;
  @Input() public fileTypeAccept: string[] = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'text/plain',
    'application',
    'docx',
    'doc',
    'xls',
    'xlsx',
    'ppt',
    'pptx',
    'pdf'
  ];

  files: any[] = [];
  base64Array: string[] = [];

  message: string = 'Arrastra tu archivo aquí';
  public filesCorrects: string = '';

  constructor(
    private renderer: Renderer2,
    private notify: NotifyService
  ) {}

   ngOnInit(): void {
    this.getFilesCorrects()
   }

    /**
     * on file drop handler
     */
     onFileDropped(upload: any) {
      if (this.checkCorrectFile(upload[0])) this.prepareFilesList(upload);
    }

    dragoverStatusHandler(drag: boolean){
      if(drag){
        this.message = 'Ahora sueltalo aquí';
      } else {
        this.message = 'Arrastra tu archivo aquí';
      }
    }

    /**
     * handle file from browsing
     */
    fileBrowseHandler(upload: any) {
      let file: any = upload.target.files

      if (this.checkCorrectFile(file[0])) this.prepareFilesList(file);

    }

    checkCorrectFile(file: File): boolean{
      let sizeFile = file.size;
      let type = file.type;

      if (sizeFile > this.maxFileSize) {

        this.notify.error('Error en el Archivo', `El archivo supera el pero maximo admintido de ${this.maxFileSizeString}`,
          5000,
          {
            text: 'Entendido',
            orientation: 'izq'
          }
        )
          return false;

      } else {

        if(this.fileTypeAccept.find(item => item === type)){
          return true
        } else {

          this.notify.error(
            'Error en el Archivo',
            `El archivo no posee la extension admintida: ${this.filesCorrects}`,
            5000,
            {
              text: 'Entendido',
              orientation: 'izq'
            }
          )

          return false
        }
      }
    }

    getFilesCorrects(){
      let listTypes: string = '';
          const arraStr: any[] = [];

          this.fileTypeAccept.map((type: string) => {
            if(type.includes('/')){
              let arra = type.split('/');

              arraStr.push(arra[1]);
              arraStr.push(' ');

            } else {
              arraStr.push(type)
            }
          })

        this.filesCorrects = String(arraStr);
    }

    /**
     * Delete file from files list
     * @param index (File index)
     */
    deleteFile(index: number) {
      this.files.splice(index, 1);
      this.base64Array.splice(index, 1);
      this.filesEmit.emit(this.files.map((e: any) => e.file));
      this.files64Emit.emit(this.base64Array);
    }

    /**
     * Simulate the upload process
     */
    uploadFilesSimulator(index: number) {
      setTimeout(() => {
        if (index === this.files.length) {
          return;
        } else {
          const progressInterval = setInterval(() => {
            if (this.files[index].progress === 100) {
              clearInterval(progressInterval);
              this.uploadFilesSimulator(index + 1);
            } else {
              this.files[index].progress += 5;
            }
          }, 200);
        }
      }, 1000);
    }

    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
    prepareFilesList(files: Array<any>) {
      for (let item of files) {
        // console.log('array de files = ', files);
        //item.progress = 0;

        this.files.push({ file: item, progress: 0 });

        this.uploadFile(this.files[this.files.length - 1]);
      }

      this.filesEmit.emit(this.files.map((e: any) => e.file));

      this.files64Emit.emit(this.base64Array);
    }

    /**
     * Upload the file
     * @param fileData (object)
    */
    uploadFile(fileData: { file: File; progress: number }) {
      const { file } = fileData;
      const reader = new FileReader();
  
      reader.onloadend = (e) => {
        // Progreso de la carga
        fileData.progress = 100;
      };
  
      reader.onprogress = (e) => {
        if (e.lengthComputable) {
          const percentage = (e.loaded / e.total) * 100;
          fileData.progress = percentage;
        }
      };

      reader.onload = () => {
        const base64String = reader.result as string;
        const base64Data = base64String.split(',')[1];
        this.base64Array.push(base64Data);
      }
      
      reader.readAsDataURL(file);
    }

    /**
     * format bytes
     * @param bytes (File size in bytes)
     * @param decimals (Decimals point)
     */
    public formatBytes(bytes = null, decimals = null) {
      if (bytes === 0) {
        return '0 Bytes';
      }
      const k = 1024;
      const dm = decimals <= 0 ? 0 : decimals || 2;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    decodeBase64(file){
      const strImage = file.replace(/^data:image\/[a-z]+;base64,/, "");

      const date = new Date().valueOf();
      let text = '';
      const possibleText = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (let i = 0; i < 5; i++) {
        text += possibleText.charAt(Math.floor(Math.random() * possibleText.length));
      }
      const imageName = date + '.' + text + '.jpeg';
      const imageBlob = this.dataURItoBlob(strImage);
      const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' })

      return imageFile;
    }

    dataURItoBlob(dataURI) {
      const byteString = window.atob(dataURI);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: 'image/jpeg' });
      return blob;
    }

}
