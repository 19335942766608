import { Component, OnInit } from '@angular/core';
import { NotificationsMessages } from '@app/shared/models/notifications-messages.model';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  public messages: NotificationsMessages[] = [
    {
      id: 0,
      message: "El Anexo de depuración ganacias ocasionales ha sido modificado",
      link: "proyecto/1/anx-depuracion/ganancia_ocasional",
      createdAt: "Hace 2 minutos"
    },
    {
      id: 1,
      message: "Se ha subido un nuevo balance",
      link: "",
      createdAt: "Hace 30 minutos"
    },
    {
      id: 2,
      message: "La información del contribuyente ha sido modificada",
      link: "",
      createdAt: "Hace 1 hora"
    },
    {
      id: 3,
      message: "El Anexo de Retefuente ha sido modificado",
      link: "proyecto/1/patrimonio/retefuente",
      createdAt: "Hace 2 horas"
    },
    {
      id: 4,
      message: "El Anexo de depuración ganacias ocasionales ha sido modificado",
      link: "proyecto/1/anx-depuracion/ganancia_ocasional",
      createdAt: "Hace 2 horas"
    },
    {
      id: 5,
      message: "Se ha subido un nuevo balance",
      link: "",
      createdAt: "Hace 3 horas"
    },
    {
      id: 6,
      message: "La información del contribuyente ha sido modificada",
      link: "",
      createdAt: "Hace 4 horas"
    },
    {
      id: 7,
      message: "El Anexo de Retefuente ha sido modificado",
      link: "proyecto/1/patrimonio/retefuente",
      createdAt: "Hace 5 horas"
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

  deleteMessage(index: number): void {
    this.messages = this.messages.filter((e, i) => i !== index);
  }

}
