import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() icon: string = '';
  @Input() text: string = '';
  @Input() idButton: string = '';
  @Input() clase: 'btn_primary' | 'btn_success' | 'btn_danger' | 'btn_empty_state' | 'btn_primary_login' = 'btn_primary';
  @Input() iconDir:'izq' | 'der' = 'izq';
  @Input() disabling: boolean = false;

  @Output() clicker: EventEmitter<string>  = new EventEmitter<string>();

  constructor(
  ) { }

  public onEmit(){
    this.clicker.emit(this.idButton)
  }
}
