import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnticipoRenta, AnticipoRentaOpciones } from '@app/store/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AnticipoRentaService {
  constructor(private http: HttpClient) {}

  getRentaOpciones(projectId: number): Observable<AnticipoRentaOpciones> {
    return this.http.get<AnticipoRentaOpciones>(
      `/anticipo_renta/1?id=${projectId}`
    );
  }

  saveAnticipoRenta(projectId: number, objAnticipoRenta: AnticipoRenta): Observable<AnticipoRenta> {
    return this.http.post<AnticipoRenta>(
      `/anticipo_renta/2?id=${projectId}`,
      objAnticipoRenta
    );
  }

  getAnticipoRenta(projectId: number): Observable<AnticipoRenta> {
    return this.http.get<AnticipoRenta>(
      `/anticipo_renta/3?id=${projectId}`
    );
  }
}
