import { createAction, props } from '@ngrx/store';
import { RentaExentaStore } from '../models';

export const SET_CREAR_NUEVA_RENTA_EXENTA = '[Renta Exenta] crear renta exenta';
export const SET_CREAR_NUEVA_RENTA_EXENTA_SUCCESS =
  '[Renta Exenta] crear renta exenta success';
export const SET_CREAR_NUEVA_RENTA_EXENTA_FAIL =
  '[Renta Exenta] crear renta exenta fail';

export const setCrearRentaExenta = createAction(
  SET_CREAR_NUEVA_RENTA_EXENTA,
  props<{ projectId: number, payload: any }>()
);

export const setCrearRentaExentaSuccess = createAction(
  SET_CREAR_NUEVA_RENTA_EXENTA_SUCCESS,
  props<{ rentaExentaCreada: RentaExentaStore }>()
);

export const setCrearRentaExentaFail = createAction(
  SET_CREAR_NUEVA_RENTA_EXENTA_FAIL,
  props<{ error: any }>()
);
