import { createSelector } from '@ngrx/store';
import { getProjectsModuleState } from '../reducers/index';
import { toArray } from 'lodash';

export const selectProvisionImpuestoGananciaList = createSelector(
  getProjectsModuleState,
  (state) => state.provisionImpuestoGanancia.entities
);

export const selectProvisionImpuestoGananciaById = (id: number) =>
  createSelector(getProjectsModuleState, (state) => {
    const data: any = state.provisionImpuestoGanancia.entities;
    if (data) return toArray(data).find((item) => item.projectId === id);
    else return {};
  });
