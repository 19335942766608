import { createAction, props } from "@ngrx/store";
import { Donacion, DonacionDTO, DonacionDTOSave, TotalDonacion } from "../models/descuento-tributario.model";


export const GET_DONACION = '[donacion] get donacion';
export const GET_DONACION_SUCCESS = '[donacion] get donacion success';
export const GET_DONACION_ERROR = '[donacion] get donacion with error';

export const SET_DONACION = '[donacion] set donacion save info';
export const SET_DONACION_SUCCESS = '[donacion] set donacion save info success';
export const SET_DONACION_ERROR = '[donacion] set donacion save info with error';

export const SET_DELETE_DONACION = '[donacion] set delete donacion';
export const SET_DELETE_DONACION_SUCCESS = '[donacion] set delete donacion success';
export const SET_DELETE_DONACION_ERROR = '[donacion] set delete donacion with error';

//get donacion
export const getDonacion = createAction(
  GET_DONACION,
  props<{projectId: number}>()
);

export const getDonacionSuccess = createAction(
  GET_DONACION_SUCCESS,
  props<{ donacionSuccess: DonacionDTO  }>()
);

export const getDonacionError = createAction(
  GET_DONACION_ERROR,
  props<{ error: string }>()
);

//create donacion
export const setDonacion = createAction(
  SET_DONACION,
  props<{ donacionForm:DonacionDTOSave, projectId: number }>()
);

export const setDonacionSuccess = createAction(
  SET_DONACION_SUCCESS,
  props<{ donacionForm:DonacionDTO }>()
);

export const setDonacionError = createAction(
  SET_DONACION_ERROR,
  props<{ error: string }>()
);

//delete donacion
export const setDeleteDonacion = createAction(
  SET_DELETE_DONACION,
  props<{ projectId: number, idDonacion: number,totales:TotalDonacion }>()
);

export const setDeleteDonacionSuccess = createAction(
  SET_DELETE_DONACION_SUCCESS,
  props<{ donacionForm:DonacionDTO }>()
);

export const setDeleteDonacionError = createAction(
  SET_DELETE_DONACION_ERROR,
  props<{ error: string }>()
);
